import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GrupoAgenda, GruposAgendas } from 'src/class/grupo-agenda';
import { Recurso } from 'src/class/recurso';
import { newUsuario } from 'src/class/usuario';
import { CepService } from 'src/services/cep.service';
import { GrupoAgendaService } from 'src/services/component/grupo-agenda.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { DataService } from 'src/services/data.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AgendaService } from 'src/services/component/agenda.service';
import { Agenda } from 'src/class/agenda';

declare var bootstrap: any;

@Component({
  selector: 'app-criar-grupo-agenda',
  templateUrl: './criar-grupo-agenda.component.html',
  styleUrls: ['./criar-grupo-agenda.component.css']
})
export class CriarGrupoAgendaComponent {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertGrupoAgendaModal', { static: false }) alertGrupoAgendaModal!: ElementRef;
  @ViewChild('agendasSelecionadas', {static: true}) agendasSelecionadas!: MatSelectionList;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  grupo: GrupoAgenda = new GrupoAgenda();
  grupos: GrupoAgenda[] = [];
  empresaSigla: any | undefined;

  agendas: any[] = [];

  constructor(
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private grupoAgendaService: GrupoAgendaService,
    private route: ActivatedRoute,
    private siglaService: SiglaService,
    private agendaService: AgendaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.agendaService.getAgendas(this.empresaSigla);
        });
      }
    });
  }

  async ngOnInit() {

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.GrupoUsuario;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        // console.log(agendas)
        // Faça algo com as notificações atualizadas

        agendas.sort((a, b) => {
          const nomeA = a.nome.valor.toUpperCase();
          const nomeB = b.nome.valor.toUpperCase();
          if (nomeA < nomeB) {
            return -1;
          }
          if (nomeA > nomeB) {
            return 1;
          }
          return 0;
        });

        this.agendas = agendas;

        // setTimeout(() => {
        //   const rec = this.agendas.slice(0,5)
        //   this.selectAllAgendas(rec);
        // }, 1000);

      },
      error => {
        // console.error('Erro ao listar os Tipos:', error);
        this.router.navigate(['/home']);
      }
    );

  }

  selectAllAgendas(agendas: any[]): void {
    const time = setInterval(()=>{
      if(this.agendasSelecionadas && this.agendasSelecionadas.options){
        clearInterval(time);

        agendas.forEach((rec: any) => {
            const resourceId = rec.id; // Obtém o ID do recurso
            const option = this.agendasSelecionadas.options.toArray().find((opt: any) => opt.value.id === resourceId);
            if(option){
              option.selected = true; // Seleciona a opção com o ID correspondente
            }
        });

      }
    })
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSelectedAgendas() {
    for (let index = 0; index < this.agendasSelecionadas.selectedOptions.selected.length; index++) {
      const element = this.agendasSelecionadas.selectedOptions.selected[index];
      // console.log(element.value)
    }
    return this.agendasSelecionadas.selectedOptions.selected.map(option => option.value);
  }

  async criarGrupoAgenda(){
    this.grupo.nome.status = this.validatorService.criarValidacao(this.grupo.nome.valor, this.grupo.nome.validacoesCustomizadas);
    this.grupo.descricao.status = this.validatorService.criarValidacao(this.grupo.descricao.valor, this.grupo.descricao.validacoesCustomizadas);

    const agendasSelecionadas_qtd = this.getSelectedAgendas();

    if(agendasSelecionadas_qtd.length == 0){
      this.showError("Por favor, selecione pelo menos 1 recurso para o grupo")
    }else if(this.grupo.nome.status.invalid ||
      this.grupo.descricao.status.invalid
    ){
      this.abrirModal(this.alertGrupoAgendaModal.nativeElement);
    }else{

      this.grupo.id = await this.contadorIndexService.contadorIndex();
      let new_grupo  = new GrupoAgenda();
      new_grupo = this.grupo;

      new_grupo.criado = new Date();
      new_grupo.atualizado = new Date();

      let agendas: GruposAgendas[] = [];

      // console.log(this.agendasSelecionadas.selectedOptions)
      for (let index = 0; index < this.agendasSelecionadas.selectedOptions.selected.length; index++) {
        const element = this.agendasSelecionadas.selectedOptions.selected[index];
        // console.log(element.value)
        const rec = new GruposAgendas();

        rec.idAgenda = element.value.id
        rec.NomeAgenda = element.value.nome.valor

        agendas.push(rec)
      }

      new_grupo.agendas = agendas;

      const grupo = JSON.stringify(new_grupo);
      const res = await this.grupoAgendaService.criarGrupoAgenda(JSON.parse(grupo), this.empresaSigla)
      // console.log(res)

      // this.showSuccess("GrupoAgenda criado com sucesso");
      this.grupo = new GrupoAgenda();
      this.router.navigate([`${this.empresaSigla}/grupos-agendas`]);
    }
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
