import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/guard/auth.guard';

import { LoginComponent } from './page/login/login.component';
import { UsuarioRecuperarSenhaComponent } from './page/usuario-recuperar-senha/usuario-recuperar-senha.component';

import { HomeComponent } from './page/home/home.component';

import { AgendaComponent } from './component/agendas_components/agenda/agenda.component';
// import { AgendaEnderecosComponent } from './component/agenda-enderecos/agenda-enderecos.component';
import { AgendaServicosComponent } from './component/agendas_servicos_components/agenda-servicos/agenda-servicos.component';
import { AgendaControleAcessoComponent } from './component/agendas_controle_acesso_components/agenda-controle-acesso/agenda-controle-acesso.component';
import { AgendaRegrasComponent } from './component/agendas_regras_components/agenda-regras/agenda-regras.component';

import { CriarAgendaComponent } from './component/agendas_components/criar-agenda/criar-agenda.component';
// import { CriarEnderecoAgendaComponent } from './component/criar-endereco-agenda/criar-endereco-agenda.component';
import { CriarServicoAgendaComponent } from './component/agendas_servicos_components/criar-servico-agenda/criar-servico-agenda.component';
import { CriarControleAcessoAgendaComponent } from './component/agendas_controle_acesso_components/criar-controle-acesso-agenda/criar-controle-acesso-agenda.component';
import { CriarRegraAgendaComponent } from './component/agendas_regras_components/criar-regra-agenda/criar-regra-agenda.component';

import { EditarAgendaComponent } from './component/agendas_components/editar-agenda/editar-agenda.component';
// import { EditarEnderecoComponent } from './component/editar-endereco/editar-endereco.component';
import { EditarServicoComponent } from './component/agendas_servicos_components/editar-servico/editar-servico.component';
import { EditarControleAcessoComponent } from './component/agendas_controle_acesso_components/editar-controle-acesso/editar-controle-acesso.component';
import { EditarRegraComponent } from './component/agendas_regras_components/editar-regra/editar-regra.component';

import { AgendamentosComponent } from './component/agendamentos_components/agendamentos/agendamentos.component';
import { CriarAgendamentoComponent } from './component/agendamentos_components/criar-agendamento/criar-agendamento.component';
import { EditarAgendamentoComponent } from './component/agendamentos_components/editar-agendamento/editar-agendamento.component';

import { CheckinComponent } from './component/checkins_components/checkin/checkin.component';
import { CheckinAguardandoComponent } from './component/checkins_components/checkin-aguardando/checkin-aguardando.component';
import { CheckinAndamentoComponent } from './component/checkins_components/checkin-andamento/checkin-andamento.component';
import { CheckinRealizadosComponent } from './component/checkins_components/checkin-realizados/checkin-realizados.component';

import { EditarCheckinComponent } from './component/checkins_components/editar-checkin/editar-checkin.component';

import { RelatoriosComponent } from './component/relatorios_components/relatorios/relatorios.component';

import { UsuariosComponent } from './component/usuarios_components/usuarios/usuarios.component';
import { UsuarioNovoComponent } from './component/usuarios_components/usuario-novo/usuario-novo.component';
import { UsuarioComponent } from './component/usuarios_components/usuario/usuario.component';

import { TipoComponent } from './component/tipos_components/tipo/tipo.component';
import { CriarTipoComponent } from './component/tipos_components/criar-tipo/criar-tipo.component';
import { EditarTipoComponent } from './component/tipos_components/editar-tipo/editar-tipo.component';

import { NotificacaoComponent } from './component/notificacao/notificacao.component';

import { ConfiguracaoSistemaComponent } from './component/configuracao-sistema/configuracao-sistema.component';
import { ConfiguracaoTelaAgendamentoComponent } from './component/configuracao-tela-agendamento/configuracao-tela-agendamento.component';

import { ContaComponent } from './component/contas_components/conta/conta.component';
import { CriarContaComponent } from './component/contas_components/criar-conta/criar-conta.component';
import { EditarContaComponent } from './component/contas_components/editar-conta/editar-conta.component';

import { PageNotFoundComponent } from './page/page-not-found/page-not-found.component';

import { RecursoComponent } from './component/recursos_components/recurso/recurso.component';
import { CriarRecursoComponent } from './component/recursos_components/criar-recurso/criar-recurso.component';
import { EditarRecursoComponent } from './component/recursos_components/editar-recurso/editar-recurso.component';

import { CriarGrupoUsuarioComponent } from './component/grupos_usuarios_components/criar-grupo-usuario/criar-grupo-usuario.component';
import { EditarGrupoUsuarioComponent } from './component/grupos_usuarios_components/editar-grupo-usuario/editar-grupo-usuario.component';
import { GrupoUsuarioComponent } from './component/grupos_usuarios_components/grupo-usuario/grupo-usuario.component';

import { GrupoAgendaComponent } from './component/grupos_agendas_components/grupo-agenda/grupo-agenda.component';
import { CriarGrupoAgendaComponent } from './component/grupos_agendas_components/criar-grupo-agenda/criar-grupo-agenda.component';
import { EditarGrupoAgendaComponent } from './component/grupos_agendas_components/editar-grupo-agenda/editar-grupo-agenda.component';

import { SlotComponent } from './component/slots_components/slot/slot.component';
import { CriarSlotComponent } from './component/slots_components/criar-slot/criar-slot.component';
import { EditarSlotComponent } from './component/slots_components/editar-slot/editar-slot.component';

import { FormComponent } from './component/forms_components/form/form.component';
import { CriarFormComponent } from './component/forms_components/criar-form/criar-form.component';
import { EditarFormComponent } from './component/forms_components/editar-form/editar-form.component';

const routes: Routes = [

  // { path: '', redirectTo: '', pathMatch: 'full'},
  { path: '', component: PageNotFoundComponent},

  // Rotas com a sigla da empresa
  { path: ':empresaSigla/login', component: LoginComponent},
  { path: ':empresaSigla/recuperar-senha', component: UsuarioRecuperarSenhaComponent},
  { path: ':empresaSigla/home', component: HomeComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/agendas', component: AgendaComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/enderecos', component: AgendaEnderecosComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendas/servicos', component: AgendaServicosComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendas/controle-acesso', component: AgendaControleAcessoComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendas/regras', component: AgendaRegrasComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/agendas/adicionar', component: CriarAgendaComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/enderecos/adicionar', component: CriarEnderecoAgendaComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendas/servicos/adicionar', component: CriarServicoAgendaComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendas/controle-acesso/adicionar', component: CriarControleAcessoAgendaComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendas/regras/adicionar', component: CriarRegraAgendaComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/agendas/agenda/:id', component: EditarAgendaComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/endereco/:id', component: EditarEnderecoComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendas/servico/:id', component: EditarServicoComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendas/controle-acesso/:id', component: EditarControleAcessoComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendas/regra/:id', component: EditarRegraComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/agendamentos', component: AgendamentosComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendamentos/adicionar', component: CriarAgendamentoComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/agendamentos/agendamento/:id', component: EditarAgendamentoComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/checkin', component: CheckinComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/checkin/aguardando', component: CheckinAguardandoComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/checkin/andamento', component: CheckinAndamentoComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/checkin/realizados', component: CheckinRealizadosComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/checkin/:id', component: EditarCheckinComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/relatorios', component: RelatoriosComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/usuarios', component: UsuariosComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/usuario/:id', component: UsuarioComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/usuarios/adicionar', component: UsuarioNovoComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/tipos', component: TipoComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/tipos/adicionar', component: CriarTipoComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/tipos/tipo/:id', component: EditarTipoComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/notificacoes', component: NotificacaoComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/configuracao/sistema', component: ConfiguracaoSistemaComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/configuracao/tela-agendamento', component: ConfiguracaoTelaAgendamentoComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/grupos-usuarios', component: GrupoUsuarioComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/grupos-usuarios/adicionar', component: CriarGrupoUsuarioComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/grupos-usuarios/grupo/:id', component: EditarGrupoUsuarioComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/grupos-agendas', component: GrupoAgendaComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/grupos-agendas/adicionar', component: CriarGrupoAgendaComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/grupos-agendas/grupo/:id', component: EditarGrupoAgendaComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/slots', component: SlotComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/slots/adicionar', component: CriarSlotComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/slots/slot/:id', component: EditarSlotComponent, canActivate: [AuthGuard]},

  { path: ':empresaSigla/contas', component: ContaComponent, canActivate: [AuthGuard], data: {requiredPermission: 'DNHVNmHzrqyWZhqfwBay'}},
  { path: ':empresaSigla/contas/adicionar', component: CriarContaComponent, canActivate: [AuthGuard], data: {requiredPermission: 'DNHVNmHzrqyWZhqfwBay'}},
  { path: ':empresaSigla/contas/conta/:id', component: EditarContaComponent, canActivate: [AuthGuard], data: {requiredPermission: 'DNHVNmHzrqyWZhqfwBay'}},

  { path: ':empresaSigla/recursos', component: RecursoComponent, canActivate: [AuthGuard], data: {requiredPermission: 'DNHVNmHzrqyWZhqfwBay'}},
  { path: ':empresaSigla/recursos/adicionar', component: CriarRecursoComponent, canActivate: [AuthGuard], data: {requiredPermission: 'DNHVNmHzrqyWZhqfwBay'}},
  { path: ':empresaSigla/recursos/recurso/:id', component: EditarRecursoComponent, canActivate: [AuthGuard], data: {requiredPermission: 'DNHVNmHzrqyWZhqfwBay'}},

  { path: ':empresaSigla/formularios', component: FormComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/formularios/adicionar', component: CriarFormComponent, canActivate: [AuthGuard]},
  { path: ':empresaSigla/formularios/formulario/:id', component: EditarFormComponent, canActivate: [AuthGuard]},

  // { path: '', redirectTo: '/login', pathMatch: 'full'},
  // { path: 'login', component: LoginComponent},
  // { path: 'recuperar-senha', component: UsuarioRecuperarSenhaComponent},
  // { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},

  // { path: 'agendas', component: AgendaComponent, canActivate: [AuthGuard]},
  // // { path: 'agendas/enderecos', component: AgendaEnderecosComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/servicos', component: AgendaServicosComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/controle-acesso', component: AgendaControleAcessoComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/regras', component: AgendaRegrasComponent, canActivate: [AuthGuard]},

  // { path: 'agendas/adicionar', component: CriarAgendaComponent, canActivate: [AuthGuard]},
  // // { path: 'agendas/enderecos/adicionar', component: CriarEnderecoAgendaComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/servicos/adicionar', component: CriarServicoAgendaComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/controle-acesso/adicionar', component: CriarControleAcessoAgendaComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/regras/adicionar', component: CriarRegraAgendaComponent, canActivate: [AuthGuard]},

  // { path: 'agendas/agenda/:id', component: EditarAgendaComponent, canActivate: [AuthGuard]},
  // // { path: 'agendas/endereco/:id', component: EditarEnderecoComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/servico/:id', component: EditarServicoComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/controle-acesso/:id', component: EditarControleAcessoComponent, canActivate: [AuthGuard]},
  // { path: 'agendas/regra/:id', component: EditarRegraComponent, canActivate: [AuthGuard]},

  // { path: 'agendamentos', component: AgendamentosComponent, canActivate: [AuthGuard]},
  // { path: 'agendamentos/adicionar', component: CriarAgendamentoComponent, canActivate: [AuthGuard]},
  // { path: 'agendamentos/agendamento/:id', component: EditarAgendamentoComponent, canActivate: [AuthGuard]},

  // { path: 'checkin', component: CheckinComponent, canActivate: [AuthGuard]},
  // { path: 'checkin/aguardando', component: CheckinAguardandoComponent, canActivate: [AuthGuard]},
  // { path: 'checkin/andamento', component: CheckinAndamentoComponent, canActivate: [AuthGuard]},
  // { path: 'checkin/realizados', component: CheckinRealizadosComponent, canActivate: [AuthGuard]},

  // { path: 'checkin/:id', component: EditarCheckinComponent, canActivate: [AuthGuard]},

  // { path: 'relatorios', component: RelatoriosComponent, canActivate: [AuthGuard]},

  // { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard]},
  // { path: 'usuario/:id', component: UsuarioComponent, canActivate: [AuthGuard]},
  // { path: 'usuarios/adicionar', component: UsuarioNovoComponent, canActivate: [AuthGuard]},

  // { path: 'tipos', component: TipoComponent, canActivate: [AuthGuard]},
  // { path: 'tipos/adicionar', component: CriarTipoComponent, canActivate: [AuthGuard]},
  // { path: 'tipos/tipo/:id', component: EditarTipoComponent, canActivate: [AuthGuard]},

  // { path: 'notificacoes', component: NotificacaoComponent, canActivate: [AuthGuard]},

  // { path: 'configuracao/sistema', component: ConfiguracaoSistemaComponent, canActivate: [AuthGuard]},
  // { path: 'configuracao/tela-agendamento', component: ConfiguracaoTelaAgendamentoComponent, canActivate: [AuthGuard]},

  // { path: 'contas', component: ContaComponent, canActivate: [AuthGuard]},
  // { path: 'contas/adicionar', component: CriarContaComponent, canActivate: [AuthGuard]},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
