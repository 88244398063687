import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { ConfiguracaoTelaAgendamento } from 'src/class/configuracao-tela-agendamento';
import { DataService } from '../data.service';
import { NotificacaoBadgeService } from '../notificacao-badge.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoTelaAgendamentoService {

  private configuracaoTelaAgendamento: ConfiguracaoTelaAgendamento[] = [];
  private configuracaoTelaAgendamentoSubject = new BehaviorSubject<ConfiguracaoTelaAgendamento[]>(this.configuracaoTelaAgendamento);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificationService: NotificacaoBadgeService,
    private toast: ToastService
  ) {
    this.configuracaoTelaAgendamento = [];
    this.getConfiguracao();
  }

  salvarConfiguracao(tipo: ConfiguracaoTelaAgendamento) {
    // Salve a nova agenda no Firestore do Firebase
    return this.localhostService.postDadosId('configuracaoTelaAgendamento', tipo).then(
      async (response: any) => {
        const res = await response
        // console.log('Configuração salva sucesso:', res);

        // Atualize a lista local de agendas
        this.configuracaoTelaAgendamento.push(tipo);
        this.configuracaoTelaAgendamentoSubject.next(this.configuracaoTelaAgendamento);

        // Atualize o localStorage (opcional, dependendo dos requisitos)
        // this.updateLocalStorage();

        // Notifique o usuário sobre o sucesso
        this.showSuccess('Configuração salva sucesso');

        // Adicione uma notificação
        this.notificationService.addNotification(`${tipo.nome.valor}, configuração salva sucesso`);

        // Navegue para a página de agendas
        // this.router.navigate(['/agendas/tipo']);
        return true
      },
      (error: any) => {
        // console.error('Erro ao salvar configuração:', error);
        // Trate o erro conforme necessário
        return false
      }
    );
  }

  getAllConfiguracao() {
    return this.configuracaoTelaAgendamentoSubject.asObservable().pipe(
      map(configuracaoTelaAgendamento => configuracaoTelaAgendamento)
    );
  }

  getConfiguracao() {
    this.localhostService.getDados('configuracaoTelaAgendamento').subscribe(
      (configuracaoFromServer: any[]) => {
        if (configuracaoFromServer) {
          // Mapeia os dados para a estrutura desejada
          const configuracaoArray: ConfiguracaoTelaAgendamento[] = configuracaoFromServer.map(configuracao => plainToClass(ConfiguracaoTelaAgendamento, configuracao));

          // console.log(configuracaoArray);

          this.configuracaoTelaAgendamento = configuracaoArray;
          this.configuracaoTelaAgendamentoSubject.next(this.configuracaoTelaAgendamento);
          // this.updateLocalStorage();
        } else {
          this.configuracaoTelaAgendamento = [];
        }
      },
      (error) => {
        // console.error('Erro ao obter configuracaoTelaAgendamento:', error);
      }
    );
  }

  private updateLocalStorage() {
    localStorage.setItem("ConfiguracaoTelaAgendamento", JSON.stringify(this.configuracaoTelaAgendamento));
    DataService.set("ConfiguracaoTelaAgendamento", this.configuracaoTelaAgendamento);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
