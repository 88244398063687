<div class="pb-4">

  <div class="container text-center min-width p-0">

    <!-- Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('contas')" class="text-padrao">Contas</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Conta {{id}}</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('contas')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>


    <!-- Conta -->
    <div class="row p-0 m-0">
      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Conta - <span style="font-style: italic; font-size: 13px;">{{id}}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Dados Gerais -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">
          <div class="row p-0 m-0">

            <!-- conta.cnpj -->
            <div class="col-lg-6 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="conta.cnpj.valor"
                  [label]="conta.cnpj.title"
                  [tooltip]="conta.cnpj.info"
                  [value]="conta.cnpj.valor"
                  [isInvalid]="conta.cnpj.status?.invalid"
                  [AgStatus]="conta.cnpj.status"
                  [id]="'conta_cnpj'"
                  AgPlaceholder="{{conta.cnpj.placeholder}}"
                  AgInputType="{{conta.cnpj.inputType}}"
                  AgMaxlength="{{conta.cnpj.maxlength}}"
                  AgInputMode="{{conta.cnpj.inputMode}}"
                  (ngModelChange)="conta.mascara(conta.cnpj.mascara, 'cnpj');"
                  (change)="conta.validarInput('cnpj');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- conta.sigla -->
            <div class="col-lg-6 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="conta.sigla.valor"
                  [label]="conta.sigla.title"
                  [tooltip]="conta.sigla.info"
                  [value]="conta.sigla.valor"
                  [isInvalid]="conta.sigla.status?.invalid"
                  [AgStatus]="conta.sigla.status"
                  [id]="'conta_sigla'"
                  AgPlaceholder="{{conta.sigla.placeholder}}"
                  AgInputType="{{conta.sigla.inputType}}"
                  AgMaxlength="{{conta.sigla.maxlength}}"
                  AgInputMode="{{conta.sigla.inputMode}}"
                  (ngModelChange)="conta.mascara(conta.fantasia.mascara, 'sigla');"
                  (change)="conta.validarInput('sigla');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- conta.razao -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="conta.razao.valor"
                  [label]="conta.razao.title"
                  [tooltip]="conta.razao.info"
                  [value]="conta.razao.valor"
                  [isInvalid]="conta.razao.status?.invalid"
                  [AgStatus]="conta.razao.status"
                  [id]="'conta_razao'"
                  AgPlaceholder="{{conta.razao.placeholder}}"
                  AgInputType="{{conta.razao.inputType}}"
                  AgMaxlength="{{conta.razao.maxlength}}"
                  AgInputMode="{{conta.razao.inputMode}}"
                  (ngModelChange)="conta.mascara(conta.razao.mascara, 'razao');"
                  (change)="conta.validarInput('razao');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- conta.fantasia -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="conta.fantasia.valor"
                  [label]="conta.fantasia.title"
                  [tooltip]="conta.fantasia.info"
                  [value]="conta.fantasia.valor"
                  [isInvalid]="conta.fantasia.status?.invalid"
                  [AgStatus]="conta.fantasia.status"
                  [id]="'conta_fantasia'"
                  AgPlaceholder="{{conta.fantasia.placeholder}}"
                  AgInputType="{{conta.fantasia.inputType}}"
                  AgMaxlength="{{conta.fantasia.maxlength}}"
                  AgInputMode="{{conta.fantasia.inputMode}}"
                  (ngModelChange)="conta.mascara(conta.fantasia.mascara, 'fantasia');"
                  (change)="conta.validarInput('fantasia');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- conta.logoURL -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="conta.logoURL.valor"
                  [label]="conta.logoURL.title"
                  [tooltip]="conta.logoURL.info"
                  [value]="conta.logoURL.valor"
                  [isInvalid]="conta.logoURL.status?.invalid"
                  [AgStatus]="conta.logoURL.status"
                  [id]="'conta_logoURL'"
                  AgPlaceholder="{{conta.logoURL.placeholder}}"
                  AgInputType="{{conta.logoURL.inputType}}"
                  AgMaxlength="{{conta.logoURL.maxlength}}"
                  AgInputMode="{{conta.logoURL.inputMode}}"
                  (ngModelChange)="conta.mascara(conta.logoURL.mascara, 'logoURL');"
                  (change)="conta.validarInput('logoURL');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- conta.email -->
            <div class="col-lg-6 reset-padding-mobile">

              <app-ag-input-field class="w-100"
                [(ngModel)]="conta.email.valor"
                [label]="conta.email.title"
                [tooltip]="conta.email.info"
                [value]="conta.email.valor"
                [isInvalid]="conta.email.status?.invalid"
                [AgStatus]="conta.email.status"
                [id]="'conta_email'"
                AgPlaceholder="{{conta.email.placeholder}}"
                AgInputType="{{conta.email.inputType}}"
                AgMaxlength="{{conta.email.maxlength}}"
                AgInputMode="{{conta.email.inputMode}}"
                (ngModelChange)="conta.mascara(conta.email.mascara, 'email');"
                (change)="conta.validarInput('email');"
                >
              </app-ag-input-field>

            </div>

            <!-- conta.telefone -->
            <div class="col-lg-6 reset-padding-mobile">

              <app-ag-input-field class="w-100"
                [(ngModel)]="conta.telefone.valor"
                [label]="conta.telefone.title"
                [tooltip]="conta.telefone.info"
                [value]="conta.telefone.valor"
                [isInvalid]="conta.telefone.status?.invalid"
                [AgStatus]="conta.telefone.status"
                [id]="'conta_telefone'"
                AgPlaceholder="{{conta.telefone.placeholder}}"
                AgInputType="{{conta.telefone.inputType}}"
                AgMaxlength="{{conta.telefone.maxlength}}"
                AgInputMode="{{conta.telefone.inputMode}}"
                (ngModelChange)="conta.mascara(conta.telefone.mascara, 'telefone');"
                (change)="conta.validarInput('telefone');"
                >
              </app-ag-input-field>

            </div>

            <!-- conta.whatsapp -->
            <div class="col-lg-6 reset-padding-mobile">

              <app-ag-input-field class="w-100"
                [(ngModel)]="conta.whatsapp.valor"
                [label]="conta.whatsapp.title"
                [tooltip]="conta.whatsapp.info"
                [value]="conta.whatsapp.valor"
                [isInvalid]="conta.whatsapp.status?.invalid"
                [AgStatus]="conta.whatsapp.status"
                [id]="'conta_whatsapp'"
                AgPlaceholder="{{conta.whatsapp.placeholder}}"
                AgInputType="{{conta.whatsapp.inputType}}"
                AgMaxlength="{{conta.whatsapp.maxlength}}"
                AgInputMode="{{conta.whatsapp.inputMode}}"
                (ngModelChange)="conta.mascara(conta.whatsapp.mascara, 'whatsapp');"
                (change)="conta.validarInput('whatsapp');"
                >
              </app-ag-input-field>

            </div>

            <!-- conta.site -->
            <div class="col-lg-6 reset-padding-mobile">

              <app-ag-input-field class="w-100"
                [(ngModel)]="conta.site.valor"
                [label]="conta.site.title"
                [tooltip]="conta.site.info"
                [value]="conta.site.valor"
                [isInvalid]="conta.site.status?.invalid"
                [AgStatus]="conta.site.status"
                [id]="'conta_site'"
                AgPlaceholder="{{conta.site.placeholder}}"
                AgInputType="{{conta.site.inputType}}"
                AgMaxlength="{{conta.site.maxlength}}"
                AgInputMode="{{conta.site.inputMode}}"
                (ngModelChange)="conta.mascara(conta.site.mascara, 'site');"
                (change)="conta.validarInput('site');"
                >
              </app-ag-input-field>

            </div>

            <!-- conta.subTitulo -->
            <div class="col-lg-12 reset-padding-mobile">

              <app-ag-textarea-field class="w-100"
                [(ngModel)]="conta.subTitulo.valor"
                [label]="conta.subTitulo.title"
                [tooltip]="conta.subTitulo.info"
                [value]="conta.subTitulo.valor"
                [isInvalid]="conta.subTitulo.status?.invalid"
                [AgStatus]="conta.subTitulo.status"
                [id]="'conta_subTitulo'"
                AgPlaceholder="{{conta.subTitulo.placeholder}}"
                AgInputType="{{conta.subTitulo.inputType}}"
                AgMaxlength="{{conta.subTitulo.maxlength}}"
                AgInputMode="{{conta.subTitulo.inputMode}}"
                (ngModelChange)="conta.mascara(conta.subTitulo.mascara, 'subTitulo');"
                (change)="conta.validarInput('subTitulo');"
                >
              </app-ag-textarea-field>
            </div>

            <!-- tipo.status -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="conta.status.valor"
                [label]="conta.status.title"
                [tooltip]="conta.status.info"
                [value]="conta.status.valor"
                [id]="'conta_status'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>
        </div>
      </div>

      <!-- Endereço -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12 reset-padding-mobile">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Endereço </h3>
              <p class="p-0 m-0 text-cor-padrao text-start w-100" >Seu endereço principal de atendimento. É exibido na tela de agendamento e usado como endereço padrão em suas agendas.</p>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">
            <!-- conta.cep -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="conta.cep.valor"
                  [label]="conta.cep.title"
                  [tooltip]="conta.cep.info"
                  [value]="conta.cep.valor"
                  [isInvalid]="conta.cep.status?.invalid"
                  [AgStatus]="conta.cep.status"
                  [id]="'cep_busca'"
                  AgPlaceholder="{{conta.cep.placeholder}}"
                  AgInputType="{{conta.cep.inputType}}"
                  AgMaxlength="{{conta.cep.maxlength}}"
                  AgInputMode="{{conta.cep.inputMode}}"
                  (ngModelChange)="conta.mascara(conta.cep.mascara, 'cep');"
                  (change)="conta.validarInput('cep');"
                  >
                </app-ag-input-field>
                <button type="button" class="btn btn-secondary" (click)="buscarCep()" style="height: 45.5px;position: relative;top: 4px;border-radius: 0px 5px 5px 0px !important;">Buscar</button>
              </div>
            </div>

            <!-- conta.logradouro -->
            <div class="col-lg-8 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.logradouro.valor"
                [label]="conta.logradouro.title"
                [tooltip]="conta.logradouro.info"
                [value]="conta.logradouro.valor"
                [isInvalid]="conta.logradouro.status?.invalid"
                [AgStatus]="conta.logradouro.status"
                [id]="'conta_logradouro'"
                AgPlaceholder="{{conta.logradouro.placeholder}}"
                AgInputType="{{conta.logradouro.inputType}}"
                AgMaxlength="{{conta.logradouro.maxlength}}"
                AgInputMode="{{conta.logradouro.inputMode}}"
                (ngModelChange)="conta.mascara(conta.logradouro.mascara, 'logradouro');"
                (change)="conta.validarInput('logradouro');"
                >
              </app-ag-input-field>
            </div>

            <!-- conta.numero -->
            <div class="col-lg-4 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.numero.valor"
                [label]="conta.numero.title"
                [tooltip]="conta.numero.info"
                [value]="conta.numero.valor"
                [isInvalid]="conta.numero.status?.invalid"
                [AgStatus]="conta.numero.status"
                [id]="'conta_numero'"
                AgPlaceholder="{{conta.numero.placeholder}}"
                AgInputType="{{conta.numero.inputType}}"
                AgMaxlength="{{conta.numero.maxlength}}"
                AgInputMode="{{conta.numero.inputMode}}"
                (ngModelChange)="conta.mascara(conta.numero.mascara, 'numero');"
                (change)="conta.validarInput('numero');"
                >
              </app-ag-input-field>
            </div>

            <!-- conta.bairro -->
            <div class="col-lg-4 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.bairro.valor"
                [label]="conta.bairro.title"
                [tooltip]="conta.bairro.info"
                [value]="conta.bairro.valor"
                [isInvalid]="conta.bairro.status?.invalid"
                [AgStatus]="conta.bairro.status"
                [id]="'conta_bairro'"
                AgPlaceholder="{{conta.bairro.placeholder}}"
                AgInputType="{{conta.bairro.inputType}}"
                AgMaxlength="{{conta.bairro.maxlength}}"
                AgInputMode="{{conta.bairro.inputMode}}"
                (ngModelChange)="conta.mascara(conta.bairro.mascara, 'bairro');"
                (change)="conta.validarInput('bairro');"
                >
              </app-ag-input-field>
            </div>

            <!-- conta.localidade -->
            <div class="col-lg-4 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.localidade.valor"
                [label]="conta.localidade.title"
                [tooltip]="conta.localidade.info"
                [value]="conta.localidade.valor"
                [isInvalid]="conta.localidade.status?.invalid"
                [AgStatus]="conta.localidade.status"
                [id]="'conta_localidade'"
                AgPlaceholder="{{conta.localidade.placeholder}}"
                AgInputType="{{conta.localidade.inputType}}"
                AgMaxlength="{{conta.localidade.maxlength}}"
                AgInputMode="{{conta.localidade.inputMode}}"
                (ngModelChange)="conta.mascara(conta.localidade.mascara, 'localidade');"
                (change)="conta.validarInput('localidade');"
                >
              </app-ag-input-field>
            </div>

            <!-- conta.uf -->
            <div class="col-lg-4 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.uf.valor"
                [label]="conta.uf.title"
                [tooltip]="conta.uf.info"
                [value]="conta.uf.valor"
                [isInvalid]="conta.uf.status?.invalid"
                [AgStatus]="conta.uf.status"
                [id]="'conta_uf'"
                AgPlaceholder="{{conta.uf.placeholder}}"
                AgInputType="{{conta.uf.inputType}}"
                AgMaxlength="{{conta.uf.maxlength}}"
                AgInputMode="{{conta.uf.inputMode}}"
                (ngModelChange)="conta.mascara(conta.uf.mascara, 'uf');"
                (change)="conta.validarInput('uf');"
                >
              </app-ag-input-field>
            </div>

            <!-- conta.complemento -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-textarea-field
                [(ngModel)]="conta.complemento.valor"
                [label]="conta.complemento.title"
                [tooltip]="conta.complemento.info"
                [value]="conta.complemento.valor"
                [isInvalid]="conta.complemento.status?.invalid"
                [AgStatus]="conta.complemento.status"
                [id]="'conta_complemento'"
                AgPlaceholder="{{conta.complemento.placeholder}}"
                AgInputType="{{conta.complemento.inputType}}"
                AgMaxlength="{{conta.complemento.maxlength}}"
                AgInputMode="{{conta.complemento.inputMode}}"
                (ngModelChange)="conta.mascara(conta.complemento.mascara, 'complemento');"
                (change)="conta.validarInput('complemento');"
                >
              </app-ag-textarea-field>
            </div>

          </div>

        </div>
      </div>

      <!-- Redes Sociais -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12 reset-padding-mobile">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Links para Redes Sociais </h3>
              <p class="p-0 m-0 text-cor-padrao text-start w-100" >Os links são exibidos no rodapé da sua tela de agendamento
              </p>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">

            <!-- conta.linkedin -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.linkedin.valor"
                [label]="conta.linkedin.title"
                [tooltip]="conta.linkedin.info"
                [value]="conta.linkedin.valor"
                [isInvalid]="conta.linkedin.status?.invalid"
                [AgStatus]="conta.linkedin.status"
                [id]="'conta_linkedin'"
                AgPlaceholder="{{conta.linkedin.placeholder}}"
                AgInputType="{{conta.linkedin.inputType}}"
                AgMaxlength="{{conta.linkedin.maxlength}}"
                AgInputMode="{{conta.linkedin.inputMode}}"
                (ngModelChange)="conta.mascara(conta.linkedin.mascara, 'linkedin');"
                (change)="conta.validarInput('linkedin');"
                >
              </app-ag-input-field>
            </div>

            <!-- conta.instagram -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.instagram.valor"
                [label]="conta.instagram.title"
                [tooltip]="conta.instagram.info"
                [value]="conta.instagram.valor"
                [isInvalid]="conta.instagram.status?.invalid"
                [AgStatus]="conta.instagram.status"
                [id]="'conta_instagram'"
                AgPlaceholder="{{conta.instagram.placeholder}}"
                AgInputType="{{conta.instagram.inputType}}"
                AgMaxlength="{{conta.instagram.maxlength}}"
                AgInputMode="{{conta.instagram.inputMode}}"
                (ngModelChange)="conta.mascara(conta.instagram.mascara, 'instagram');"
                (change)="conta.validarInput('instagram');"
                >
              </app-ag-input-field>
            </div>

            <!-- conta.facebook -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.facebook.valor"
                [label]="conta.facebook.title"
                [tooltip]="conta.facebook.info"
                [value]="conta.facebook.valor"
                [isInvalid]="conta.facebook.status?.invalid"
                [AgStatus]="conta.facebook.status"
                [id]="'conta_facebook'"
                AgPlaceholder="{{conta.facebook.placeholder}}"
                AgInputType="{{conta.facebook.inputType}}"
                AgMaxlength="{{conta.facebook.maxlength}}"
                AgInputMode="{{conta.facebook.inputMode}}"
                (ngModelChange)="conta.mascara(conta.facebook.mascara, 'facebook');"
                (change)="conta.validarInput('facebook');"
                >
              </app-ag-input-field>
            </div>

            <!-- conta.twitter -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.twitter.valor"
                [label]="conta.twitter.title"
                [tooltip]="conta.twitter.info"
                [value]="conta.twitter.valor"
                [isInvalid]="conta.twitter.status?.invalid"
                [AgStatus]="conta.twitter.status"
                [id]="'conta_twitter'"
                AgPlaceholder="{{conta.twitter.placeholder}}"
                AgInputType="{{conta.twitter.inputType}}"
                AgMaxlength="{{conta.twitter.maxlength}}"
                AgInputMode="{{conta.twitter.inputMode}}"
                (ngModelChange)="conta.mascara(conta.twitter.mascara, 'twitter');"
                (change)="conta.validarInput('twitter');"
                >
              </app-ag-input-field>
            </div>

            <!-- conta.youtube -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="conta.youtube.valor"
                [label]="conta.youtube.title"
                [tooltip]="conta.youtube.info"
                [value]="conta.youtube.valor"
                [isInvalid]="conta.youtube.status?.invalid"
                [AgStatus]="conta.youtube.status"
                [id]="'conta_youtube'"
                AgPlaceholder="{{conta.youtube.placeholder}}"
                AgInputType="{{conta.youtube.inputType}}"
                AgMaxlength="{{conta.youtube.maxlength}}"
                AgInputMode="{{conta.youtube.inputMode}}"
                (ngModelChange)="conta.mascara(conta.youtube.mascara, 'youtube');"
                (change)="conta.validarInput('youtube');"
                >
              </app-ag-input-field>
            </div>

          </div>

        </div>
      </div>
      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="salvarConfiguracao()">Salvar</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('contas')" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>

    </div>


  </div>
</div>

<!-- alerta Configuração Tela Agendamento Modal -->
<div class="modal" id="configuracaoTelaAgendamento" #configuracaoTelaAgendamento data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="conta.cnpj.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.cnpj.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.cnpj.info }}</p>
          </div>

          <div *ngIf="conta.razao.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.razao.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.razao.info }}</p>
          </div>

          <div *ngIf="conta.sigla.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.sigla.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.sigla.info }}</p>
          </div>

          <div *ngIf="conta.email.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.email.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.email.info }}</p>
          </div>

          <div *ngIf="conta.telefone.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.telefone.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.telefone.info }}</p>
          </div>

          <div *ngIf="conta.whatsapp.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.whatsapp.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.whatsapp.info }}</p>
          </div>

          <div *ngIf="conta.site.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.site.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.site.info }}</p>
          </div>

          <div *ngIf="conta.subTitulo.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.subTitulo.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.subTitulo.info }}</p>
          </div>

          <div *ngIf="conta.cep.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.cep.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.cep.info }}</p>
          </div>

          <div *ngIf="conta.logradouro.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.logradouro.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.logradouro.info }}</p>
          </div>

          <div *ngIf="conta.numero.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.numero.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.numero.info }}</p>
          </div>

          <div *ngIf="conta.complemento.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.complemento.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.complemento.info }}</p>
          </div>

          <div *ngIf="conta.bairro.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.bairro.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.bairro.info }}</p>
          </div>

          <div *ngIf="conta.localidade.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.localidade.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.localidade.info }}</p>
          </div>

          <div *ngIf="conta.uf.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ conta.uf.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ conta.uf.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>
