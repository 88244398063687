import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subscription } from 'rxjs';
import { Links } from 'src/class/links';
import { objUsuario } from 'src/class/usuario';
import { StatusAgendamento } from 'src/enum/statusAgendamento.enum';
import { NotificacaoInt, Leitura } from 'src/interface/notificacao-int';
import { SearchNotificacaoPipe } from 'src/pipes/search-notificacao.pipe';
import { AuthService } from 'src/services/auth.service';
import { ContaService } from 'src/services/component/conta.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { UsuarioService } from 'src/services/component/usuario.service';
import { DataService } from 'src/services/data.service';
import { LinksService } from 'src/services/links.service';
import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ThemeService } from 'src/services/theme.service';
import { ToastService } from 'src/services/toast.service';

@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.css']
})
export class NotificacaoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj = new objUsuario;

  agenda_arr: any [] = [];

  pesquisa="";
  pag = 1;
  contador = 10;
  pageSize = 10;
  pageIndex = 0;
  totalizador = 0;
  maxSize = 10;
  visualizando = this.contador;
  lightMode: any;
  modoView = false;
  modoViewNum = 12;

  private subscription: Subscription;

  linksAgenda: Links[] = [];

  statusAgendamento = StatusAgendamento;

  loading: boolean = true;
  sortedData: NotificacaoInt[] = [];

  loader = this.loadingBar.useRef('http');

  all_notificacoes: NotificacaoInt[] = [];
  displayedNotificacoes: NotificacaoInt[] = [];
  empresaSigla: any | undefined;

  usuario: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService,
    private linksService: LinksService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private paginatorIntl: MatPaginatorIntl,
    private SearchNotificacaoPipe: SearchNotificacaoPipe,
    private loadingBar: LoadingBarService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    private notificacaoService: NotificacaoSistemaService,
    private siglaService: SiglaService,
    private userService: AuthService,
    private usuarioService: UsuarioService,
    private conta: ContaService
  ){
    this.subscription = this.themeService.lightMode$.subscribe(lightMode => {
      this.lightMode = lightMode;
    });

    this.paginatorIntl.itemsPerPageLabel = 'Itens por página:';
    this.paginatorIntl.nextPageLabel = 'Próxima página';
    this.paginatorIntl.previousPageLabel = 'Página anterior';
    this.paginatorIntl.firstPageLabel = 'Primeira página';
    this.paginatorIntl.lastPageLabel = 'Última página';

    // Personalize a indexação para começar em 1
    this.paginatorIntl.getRangeLabel = this.getPortugueseRangeLabel;

    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(async params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.notificacaoService.getNotificacoes(this.empresaSigla?.toString());
          this.usuarioService.getUsuario(this.empresaSigla?.toString());

          this.usuario = await this.userService.carregarUsuario(this.empresaSigla)
          this.loading = false;

          // console.log(this.usuario)

        });
      }
    });
  }

  async ngOnInit() {

    this.notificacaoService.getAllNotificacoes().subscribe(
      (notificacao: NotificacaoInt[]) => {
        // Faça algo com as notificações atualizadas
        if(notificacao.length > 0){
          this.all_notificacoes = notificacao;

          this.reordenar();

          this.displayedNotificacoes = this.all_notificacoes.slice(
            this.pageIndex * this.pageSize,
            (this.pageIndex + 1) * this.pageSize
          );

        }


      },
      error => {
        // console.error('Erro ao receber notificações:', error);
      }
    );

    this.getCache();

    // console.log(this.statusAgendamento)

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.changeDetectorRef.detectChanges();

    this.route.queryParams.subscribe((params: any) => {
      // O parâmetro de consulta 'search' estará em params.search
      this.pesquisa = params.search ? params.search : '';
      // console.log(params)
      // Agora você pode usar o valor da pesquisa em sua função searchAgenda
      if(this.pesquisa != ''){
        this.searchNotificacao();
      }
    });
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  abrirLinkEmNovaGuia(link: string): void {
    window.open(link, '_blank');
  }

  getCache(){

    let modoView = localStorage.getItem("modoView");

    let modoViewNum = localStorage.getItem("modoViewNum");

    if(modoView){
      this.modoView = modoView == 'true' ? true : false
    }

    if(modoViewNum){
      this.modoViewNum = parseInt(modoViewNum);
    }

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Notificacao;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    // console.log(this.loading)

  }

  searchNotificacao(){
    const retorno = this.SearchNotificacaoPipe.transform(this.all_notificacoes, this.pesquisa);

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.displayedNotificacoes = retorno;

    if(this.pesquisa == ""){
      this.displayedNotificacoes = this.all_notificacoes.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      this.paginator.length = this.all_notificacoes.length;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { search: null },
        queryParamsHandling: 'merge',
      });

    }else{

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ['search']: this.pesquisa },
        queryParamsHandling: 'merge',
      });

      this.displayedNotificacoes = retorno.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      if (this.paginator) {
        this.paginator.length = retorno.length;
      }
    }
  }

  handlePage(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    // Calcule o índice de início e fim dos itens a serem exibidos na página atual
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    // Atualize os itens a serem exibidos na tabela
    this.displayedNotificacoes = this.all_notificacoes.slice(startIndex, endIndex);

  }

  sortData(sort: Sort) {
    const data = this.all_notificacoes.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      this.all_notificacoes = this.sortedData
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'id':
          return this.compare(a.id!, b.id!, isAsc);
        case 'titulo':
          return this.compare(a.titulo, b.titulo, isAsc);
        case 'texto':
          return this.compare(a.texto, b.texto, isAsc);
        case 'criado':
          return this.compare(a.dataCriacao.toString(), b.dataCriacao.toString(), isAsc);
        // case 'readDate':
        //   return this.compare(a.readDate!.toString(), b.readDate!.toString(), isAsc);
        default:
          return 0;
      }
    });

    // console.log(this.sortedData)
    this.all_notificacoes = this.sortedData

    this.displayedNotificacoes = this.all_notificacoes.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // Personalize a função getRangeLabel para começar em 1
  getPortugueseRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }

  reload(){
    try {
      this.all_notificacoes = [];
      this.loading = true;
      // this.getTotalNotificacaosUsuarios();
      // this.getUsuario();


      this.loader.start()

      setTimeout(async () => {
        let notificacao = await new Promise<NotificacaoInt[]>((resolve, reject) => {
          this.notificacaoService.getNotificacoes(this.empresaSigla).subscribe(
              (tipo: NotificacaoInt[]) => {
                  // console.log(notificacao);
                  resolve(tipo);
              },
              error => {
                  reject(error);
              }
          );
        });
        // console.log(notificacao);
        if(notificacao != undefined){
          this.all_notificacoes = notificacao;
        }else{
          setTimeout(() => {
            this.loading = false;
            this.loader.complete()

          }, 500);
        }

        this.showInfo("Notificações atualizadas com sucesso!!")
        this.loading = false;
        this.loader.complete()
      }, 1000);


    } catch (error) {
      this.showError("Ocorreu um erro na atualização das notificações")
      this.loader.complete()
    }

  }

  resetSearch(){
    this.pesquisa = "";
    this.displayedNotificacoes = this.all_notificacoes.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
    this.paginator.length = this.all_notificacoes.length;

    // Use o serviço Router para navegar para a mesma rota sem o parâmetro de consulta
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: null },
      queryParamsHandling: 'merge',
    });
  }

  pags(pags: any) {
    const target = pags as HTMLTextAreaElement;
    this.contador = parseInt(target.value)
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }
  }

  modoTabela(){
    this.modoView = true;
    localStorage.setItem("modoView", "true");
  }


  modoCard(){
    this.modoView = false;
    this.modoViewNum = 12;
    localStorage.setItem("modoViewNum", "12");
    localStorage.setItem("modoView", "false");
  }

  reordenar(){
    // Ordenar this.notifications por createdDate (maior para menor)
    this.all_notificacoes.sort((a, b) => {
      const dateA = a.dataCriacao ? new Date(a.dataCriacao) : null;
      const dateB = b.dataCriacao ? new Date(b.dataCriacao) : null;

      if (dateA instanceof Date && dateB instanceof Date) {
        return dateB.getTime() - dateA.getTime();
      } else if (dateA instanceof Date) {
        return -1;
      } else if (dateB instanceof Date) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Função para reordenar as notificações
  reorderNotifications(notifications: any[]): any[] {

    if(notifications.length > 0){
      const unreadNotifications = notifications.filter(notification => !notification.read);
      const readNotifications = notifications.filter(notification => notification.read);

      // Ordene as notificações não lidas por data de criação (maior para menor)
      unreadNotifications.sort((a, b) => {
        const dateA = a.createdDate ? new Date(a.createdDate) : null;
        const dateB = b.createdDate ? new Date(b.createdDate) : null;

        if (dateA instanceof Date && dateB instanceof Date) {
          return dateB.getTime() - dateA.getTime();
        } else if (dateA instanceof Date) {
          return -1;
        } else if (dateB instanceof Date) {
          return 1;
        } else {
          return 0;
        }
      });

      // Ordene as notificações lidas por data de leitura (maior para menor)
      readNotifications.sort((a, b) => {
        const dateA = a.readDate ? new Date(a.readDate) : null;
        const dateB = b.readDate ? new Date(b.readDate) : null;

        if (dateA instanceof Date && dateB instanceof Date) {
          return dateB.getTime() - dateA.getTime();
        } else if (dateA instanceof Date) {
          return -1;
        } else if (dateB instanceof Date) {
          return 1;
        } else {
          return 0;
        }
      });

      // Combine as notificações não lidas e lidas reordenadas
      return [...unreadNotifications, ...readNotifications];
    }
    return notifications;
  }

  marcarComoLido(notification: NotificacaoInt) {

    const index = this.all_notificacoes.findIndex((item: NotificacaoInt) => item.id === notification.id);

    if (index !== -1) {

      // let titulo = 'Tem certeza de que deseja marcar a notificação como lida?';
      // let mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
      //                   <div class="d-flex justify-content-start flex-column">
      //                     <span class="card-padrao-list-img-title ag-pl-5 ag-pb-2">${this.all_notificacoes[index].titulo}</span>
      //                     <span class="card-padrao-list-box-nobg-value">${this.all_notificacoes[index].texto}</span>
      //                   </div>
      //                 </div>`;

      // this.modalConfirmacaoService.openModal(titulo, mensagem).then((confirmation: boolean) => {

      //   if (confirmation) {
      //     console.log('SIM!');

          const leituraUsuario: Leitura = {
            idUsuario: this.usuario.id,
            nomeUsuario: this.usuario.displayName.valor,
            dataLeitura: new Date().toISOString(),
            visivel: true
          };

          this.notificacaoService.addUsuarioLeitura(this.all_notificacoes[index], leituraUsuario, this.empresaSigla)

          this.showSuccess(`Notificação ${this.all_notificacoes[index].titulo} marcada como lida.`);
      //   } else {
      //     console.log('NÃO');
      //   }
      // });

    } else {
      // O item não foi encontrado no array, você pode tratar isso conforme necessário
      this.showError("Notificação não encontrada para atualização");
    }
  }

  isNotificacaoLidaPorUsuarioAtual(notificacao: NotificacaoInt): boolean {
    if (!notificacao || !notificacao.leitura) {
      return false;
    }

    if (this.usuario) {
      return notificacao.leitura.some(leitura => leitura.idUsuario === this.usuario.id && leitura.dataLeitura);
    }else{
      return false;
    }
  }

  isNotLidaPorNenhumUsuario(notificacao: NotificacaoInt): boolean {
    if (!notificacao.leitura) {
      return true; // Se não houver leitura, consideramos a notificação não lida
    }

    if (this.usuario) {
      return !notificacao.leitura.some(leitura => leitura.idUsuario === this.usuario.id);
    }else{
      return false;
    }
  }

  marcarTodasNotificacoesComoLidas(){
    const leituraUsuario: Leitura = {
      idUsuario: this.usuario.id,
      nomeUsuario: this.usuario.displayName.valor,
      dataLeitura: new Date().toISOString(),
      visivel: true
    };

    for (let index = 0; index < this.all_notificacoes.length; index++) {
      this.notificacaoService.addUsuarioLeitura(this.all_notificacoes[index], leituraUsuario, this.empresaSigla);
    }

    this.showSuccess(`As notificações foram marcadas como lidas.`);
  }

  existeNotificacaoNaoLidaPorUsuarioAtual(): boolean {
    if (!this.usuario || !this.usuario.id) {
      return true; // Se não há usuário ou o ID do usuário é inválido, retorna true
    }

    // Assumindo que all_notificacoes é um array contendo todas as notificações disponíveis

    for (const notificacao of this.all_notificacoes) {
      if (!notificacao.leitura || !notificacao.leitura.some(leitura => leitura.idUsuario === this.usuario.id && leitura.dataLeitura)) {
        return false; // Se encontrou pelo menos uma notificação não lida pelo usuário, retorna false
      }
    }

    return true; // Se todas as notificações foram lidas pelo usuário, retorna true
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }


}
