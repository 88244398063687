// shared-data.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContaService } from './component/conta.service';
import { AuthService } from './auth.service';
import { UsuarioService } from './component/usuario.service';
import { LocalhostService } from './localhost.service';

@Injectable({
  providedIn: 'root'
})
export class SiglaService {
  private empresaSiglaSource = new BehaviorSubject<string | any>('');
  empresaSigla$ = this.empresaSiglaSource.asObservable();

  constructor(
    private conta: ContaService,
    private authService: AuthService,
    private usuario: UsuarioService,
    private localhostService: LocalhostService
  ){}

  setEmpresaSigla(empresaSigla: string | any) {
    this.empresaSiglaSource.next(empresaSigla);
    // console.log("SiglaService", empresaSigla)
    localStorage.setItem("empresaSigla", empresaSigla.toString());
    this.verificarConta(empresaSigla.toString())
  }

  async verificarConta(empresaSigla: string | any){
    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: p_getSiglaConta', conta);

      const usrString = localStorage.getItem('user');
      // console.log(usrString);

      if (usrString) {
        const usr = JSON.parse(usrString); // Converte a string para um objeto

        if (usr.uid) {
          this.localhostService.p_getUsuarioId(conta.id + "_usuarios", usr.uid)
            .then((usuario) => {
              // Faça algo com o objeto de usuário
              // console.log('Dados do usuário:', usuario);
              if(!usuario.status.valor){
                this.authService.fazerLogout(empresaSigla)
                // this.showInfo("O usuário está desativado. Para ativar sua conta, por favor, entre em contato com o administrador da empresa e solicite a ativação.")
              }
            })
            .catch((erro) => {
              // console.log('Erro ao obter dados do usuário:', erro);
              this.authService.fazerLogout(empresaSigla);
            });
        }
      }else{
        this.authService.fazerLogout(empresaSigla);
      }

    })
    .catch((error) => {
      // Trate o erro
      this.authService.fazerLogout(empresaSigla);
      // console.log('Erro ao obter dados da conta:', error);
    });

    // this.conta.o_getSiglaConta('contas', empresaSigla)
    // .subscribe((conta) => {
    //   console.log('Dados da conta:', conta);
    // }, (erro) => {
    //   this.authService.fazerLogout(empresaSigla);
    //   console.log('Erro ao obter dados da conta:', erro);
    // });
  }
}
