import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { Servico } from 'src/class/servico';
import { DataService } from '../data.service';
import { NotificacaoBadgeService } from '../notificacao-badge.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})

export class ServicoService {
  private servicos: Servico[] = [];
  private servicosSubject = new BehaviorSubject<Servico[]>(this.servicos);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificationService: NotificacaoBadgeService,
    private toast: ToastService,
    private conta: ContaService,
    private authService: AuthService
  ) {
    this.servicos = [];
    // this.getServico();
  }

  async criarServico(servico: Servico, empresaSigla: any): Promise<boolean> {
    // console.log("criarServico", servico);

    servico.duracaoAtendimento.status = null;
    servico.instrucoesConfirmacao.status = null;
    servico.linkPagamento.status = null;
    servico.nome.status = null;
    servico.numeroPessoasPorHorario.status = null;
    servico.valor.status = null;

    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarServico', conta);

      const response = await this.localhostService.postDadosId(`${conta.id}_servicos`, servico);

      // console.log('Serviço criado com sucesso:', response);

      // Atualize a lista local de agendas
      this.servicos.push(servico);
      this.servicosSubject.next(this.servicos);

      // Notifique o usuário sobre o sucesso
      this.showSuccess('Serviço criado com sucesso');

      // Adicione uma notificação
      this.notificationService.addNotification(`${servico.id} - ${servico.nome.valor}, serviço criado com sucesso`);

      return true;
    } catch (error) {
      // console.error('Erro ao criar serviço:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllServico() {
    return this.servicosSubject.asObservable().pipe(
      map(servicos => servicos)
    );
  }

  async getServico(empresaSigla: any) {
    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: criarAgenda', conta);
      this.localhostService.getDados(`${conta.id}_servicos`).subscribe(
        (servicoFromServer: any[]) => {
          if (servicoFromServer) {
            // Mapeia os dados para a estrutura desejada
            const servicoArray: Servico[] = servicoFromServer.map(servico => plainToClass(Servico, servico));

            // console.log(servicoArray);

            this.servicos = servicoArray;
            this.servicosSubject.next(this.servicos);
            // this.updateLocalStorage();
          } else {
            this.servicos = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter serviços:', error);
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao obter serviços:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });


  }

  private updateLocalStorage() {
    localStorage.setItem("Servico", JSON.stringify(this.servicos));
    DataService.set("Servico", this.servicos);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
