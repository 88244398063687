import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

// NPMs
import { NgxPaginationModule } from 'ngx-pagination';
import { NgChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgSelectModule } from '@ng-select/ng-select';
import { FullCalendarModule } from '@fullcalendar/angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale); // Opcional, para localização em português
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ModalModule } from 'ngx-bootstrap/modal';

// Material
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';


// Guards
import { AuthGuard } from 'src/guard/auth.guard';

// Pipes
import { SearchUsuarioPipe } from 'src/pipes/search-usuario.pipe';
import { SearchAgendaPipe } from 'src/pipes/search-agenda.pipe';
import { SearchEnderecoPipe } from 'src/pipes/search-endereco.pipe';
import { SearchServicoPipe } from 'src/pipes/search-servico.pipe';
import { SearchAgendamentoPipe } from 'src/pipes/search-agendamento.pipe';
import { SearchCheckinPipe } from 'src/pipes/search-checkin.pipe';
import { SearchTipoPipe } from 'src/pipes/search-tipo.pipe';
import { SearchFormularioPipe } from 'src/pipes/search-formulario.pipe';
import { SearchRecursoPipe } from 'src/pipes/search-recurso.pipe';
import { SearchNotificacaoPipe } from 'src/pipes/search-notificacao.pipe';
import { SearchGrupoUsuarioPipe } from 'src/pipes/search-grupo-usuario.pipe';
import { SearchGrupoAgendaPipe } from 'src/pipes/search-grupo-agenda.pipe';
import { DiferencaEntreDatasPipe } from 'src/pipes/diferenca-entre-datas.pipe';
import { ValidatorService } from 'src/services/validator.service';
import { FiltroDestaquePipe } from 'src/pipes/filtroDestaque.pipe';
import { NotificacaoBadgePipe } from 'src/pipes/notificacao-badge.pipe';
import { StepProgressPipe } from 'src/pipes/stepProgress.pipe';
import { SearchSlotPipe } from 'src/pipes/search-slot.pipe';
import { TempoDecorridoPipe } from 'src/pipes/tempoDecorrido.pipe';
import { TempoMedioPipe } from 'src/pipes/tempoMedio.pipe';
import { TempoPicoPipe } from 'src/pipes/tempoPico.pipe';

// Element Library
import { AgInputFieldComponent } from './element-library/ag-input-field/ag-input-field.component';
import { AgTextareaFieldComponent } from './element-library/ag-textarea-field/ag-textarea-field.component';
import { AgMatCheckboxComponent } from './element-library/ag-mat-checkbox/ag-mat-checkbox.component';
import { AgMultipleCheckboxComponent } from './element-library/ag-multiple-checkbox/ag-multiple-checkbox.component';
import { AgNgSelectComponent } from './element-library/ag-ng-select/ag-ng-select.component';
import { AgInputFieldDatepickerComponent } from './element-library/ag-input-field-datepicker/ag-input-field-datepicker.component';

// Services
import { LoadingBarControlService } from 'src/services/loading-bar-control.service';
import { RouteLoadingBarControlService } from 'src/services/route-loading-bar-control.service';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './page/login/login.component';
import { HomeComponent } from './page/home/home.component';
import { MenuComponent } from './component/menu/menu.component';
import { UsuariosComponent } from './component/usuarios_components/usuarios/usuarios.component';
import { UsuarioComponent } from './component/usuarios_components/usuario/usuario.component';
import { UsuarioNovoComponent } from './component/usuarios_components/usuario-novo/usuario-novo.component';
import { UsuarioRecuperarSenhaComponent } from './page/usuario-recuperar-senha/usuario-recuperar-senha.component';

import { AgendaComponent } from './component/agendas_components/agenda/agenda.component';
import { AgendaEnderecosComponent } from './component/agendas_enderecos_components/agenda-enderecos/agenda-enderecos.component';
import { AgendaServicosComponent } from './component/agendas_servicos_components/agenda-servicos/agenda-servicos.component';
import { AgendaControleAcessoComponent } from './component/agendas_controle_acesso_components/agenda-controle-acesso/agenda-controle-acesso.component';
import { AgendaRegrasComponent } from './component/agendas_regras_components/agenda-regras/agenda-regras.component';

import { CriarAgendaComponent } from './component/agendas_components/criar-agenda/criar-agenda.component';
import { CriarEnderecoAgendaComponent } from './component/agendas_enderecos_components/criar-endereco-agenda/criar-endereco-agenda.component';
import { CriarServicoAgendaComponent } from './component/agendas_servicos_components/criar-servico-agenda/criar-servico-agenda.component';
import { CriarControleAcessoAgendaComponent } from './component/agendas_controle_acesso_components/criar-controle-acesso-agenda/criar-controle-acesso-agenda.component';
import { CriarRegraAgendaComponent } from './component/agendas_regras_components/criar-regra-agenda/criar-regra-agenda.component';
import { CriarAgendamentoComponent } from './component/agendamentos_components/criar-agendamento/criar-agendamento.component';

import { EditarAgendaComponent } from './component/agendas_components/editar-agenda/editar-agenda.component';
import { EditarEnderecoComponent } from './component/agendas_enderecos_components/editar-endereco/editar-endereco.component';
import { EditarServicoComponent } from './component/agendas_servicos_components/editar-servico/editar-servico.component';
import { EditarControleAcessoComponent } from './component/agendas_controle_acesso_components/editar-controle-acesso/editar-controle-acesso.component';
import { EditarRegraComponent } from './component/agendas_regras_components/editar-regra/editar-regra.component';

import { AgendamentosComponent } from './component/agendamentos_components/agendamentos/agendamentos.component';
import { EditarAgendamentoComponent } from './component/agendamentos_components/editar-agendamento/editar-agendamento.component';

import { CheckinComponent } from './component/checkins_components/checkin/checkin.component';
import { CheckinAguardandoComponent } from './component/checkins_components/checkin-aguardando/checkin-aguardando.component';
import { CheckinAndamentoComponent } from './component/checkins_components/checkin-andamento/checkin-andamento.component';
import { CheckinRealizadosComponent } from './component/checkins_components/checkin-realizados/checkin-realizados.component';

import { EditarCheckinComponent } from './component/checkins_components/editar-checkin/editar-checkin.component';

import { RelatoriosComponent } from './component/relatorios_components/relatorios/relatorios.component';
import { ModalConfirmacaoComponent } from './element-library/modal-confirmacao/modal-confirmacao.component';

import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';
import { TipoComponent } from './component/tipos_components/tipo/tipo.component';
import { NotificacaoBadgeComponent } from './element-library/notificacao-badge/notificacao-badge.component';
import { environment } from 'src/environment/environment';
import { CriarTipoComponent } from './component/tipos_components/criar-tipo/criar-tipo.component';
import { EditarTipoComponent } from './component/tipos_components/editar-tipo/editar-tipo.component';
import { NotificacaoComponent } from './component/notificacao/notificacao.component';
import { ConfiguracaoSistemaComponent } from './component/configuracao-sistema/configuracao-sistema.component';
import { ConfiguracaoTelaAgendamentoComponent } from './component/configuracao-tela-agendamento/configuracao-tela-agendamento.component';
import { ContaComponent } from './component/contas_components/conta/conta.component';
import { CriarContaComponent } from './component/contas_components/criar-conta/criar-conta.component';
import { PageNotFoundComponent } from './page/page-not-found/page-not-found.component';
import { EditarContaComponent } from './component/contas_components/editar-conta/editar-conta.component';
import { ModalLinkComponent } from './element-library/modal-link/modal-link.component';
import { ModalLinkService } from 'src/services/modal-link.service';
import { RecursoComponent } from './component/recursos_components/recurso/recurso.component';
import { CriarRecursoComponent } from './component/recursos_components/criar-recurso/criar-recurso.component';
import { EditarRecursoComponent } from './component/recursos_components/editar-recurso/editar-recurso.component';
import { GrupoUsuarioComponent } from './component/grupos_usuarios_components/grupo-usuario/grupo-usuario.component';
import { CriarGrupoUsuarioComponent } from './component/grupos_usuarios_components/criar-grupo-usuario/criar-grupo-usuario.component';
import { EditarGrupoUsuarioComponent } from './component/grupos_usuarios_components/editar-grupo-usuario/editar-grupo-usuario.component';
import { GrupoAgendaComponent } from './component/grupos_agendas_components/grupo-agenda/grupo-agenda.component';
import { CriarGrupoAgendaComponent } from './component/grupos_agendas_components/criar-grupo-agenda/criar-grupo-agenda.component';
import { EditarGrupoAgendaComponent } from './component/grupos_agendas_components/editar-grupo-agenda/editar-grupo-agenda.component';
import { SlotComponent } from './component/slots_components/slot/slot.component';
import { CriarSlotComponent } from './component/slots_components/criar-slot/criar-slot.component';
import { EditarSlotComponent } from './component/slots_components/editar-slot/editar-slot.component';
import { FormComponent } from './component/forms_components/form/form.component';
import { CriarFormComponent } from './component/forms_components/criar-form/criar-form.component';
import { EditarFormComponent } from './component/forms_components/editar-form/editar-form.component';
import { AgTempoDecorridoComponent } from './element-library/ag-tempo-decorrido/ag-tempo-decorrido.component';
import { AgTempoMedioComponent } from './element-library/ag-tempo-medio/ag-tempo-medio.component';
import { AgTempoPicoComponent } from './element-library/ag-tempo-pico/ag-tempo-pico.component';
import { AgMatRadioComponent } from './element-library/ag-mat-radio/ag-mat-radio.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MenuComponent,
    UsuariosComponent,
    UsuarioComponent,
    UsuarioNovoComponent,
    UsuarioRecuperarSenhaComponent,
    AgendaComponent,
    AgendaEnderecosComponent,
    AgendaServicosComponent,
    AgendaControleAcessoComponent,
    AgendaRegrasComponent,
    CriarAgendaComponent,
    CriarEnderecoAgendaComponent,
    CriarServicoAgendaComponent,
    CriarControleAcessoAgendaComponent,
    CriarRegraAgendaComponent,
    CriarAgendamentoComponent,
    EditarAgendaComponent,
    EditarEnderecoComponent,
    EditarServicoComponent,
    EditarControleAcessoComponent,
    EditarRegraComponent,
    AgendamentosComponent,
    EditarAgendamentoComponent,
    CheckinComponent,
    CheckinAguardandoComponent,
    CheckinAndamentoComponent,
    CheckinRealizadosComponent,
    EditarCheckinComponent,
    RelatoriosComponent,
    SearchUsuarioPipe,
    SearchAgendaPipe,
    SearchEnderecoPipe,
    SearchServicoPipe,
    SearchAgendamentoPipe,
    SearchCheckinPipe,
    SearchSlotPipe,
    SearchTipoPipe,
    SearchFormularioPipe,
    SearchRecursoPipe,
    SearchNotificacaoPipe,
    SearchGrupoUsuarioPipe,
    SearchGrupoAgendaPipe,
    DiferencaEntreDatasPipe,
    FiltroDestaquePipe,
    NotificacaoBadgePipe,
    StepProgressPipe,
    TempoDecorridoPipe,
    TempoMedioPipe,
    TempoPicoPipe,
    AgInputFieldComponent,
    AgTextareaFieldComponent,
    AgMatCheckboxComponent,
    AgMultipleCheckboxComponent,
    AgInputFieldDatepickerComponent,
    AgNgSelectComponent,
    ModalConfirmacaoComponent,
    ModalLinkComponent,
    TipoComponent,
    NotificacaoBadgeComponent,
    CriarTipoComponent,
    EditarTipoComponent,
    NotificacaoComponent,
    ConfiguracaoSistemaComponent,
    ConfiguracaoTelaAgendamentoComponent,
    ContaComponent,
    CriarContaComponent,
    PageNotFoundComponent,
    EditarContaComponent,
    RecursoComponent,
    CriarRecursoComponent,
    EditarRecursoComponent,
    GrupoUsuarioComponent,
    CriarGrupoUsuarioComponent,
    EditarGrupoUsuarioComponent,
    CriarGrupoAgendaComponent,
    EditarGrupoAgendaComponent,
    GrupoAgendaComponent,
    SlotComponent,
    CriarSlotComponent,
    EditarSlotComponent,
    FormComponent,
    CriarFormComponent,
    EditarFormComponent,
    AgTempoDecorridoComponent,
    AgTempoMedioComponent,
    AgTempoPicoComponent,
    AgMatRadioComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AngularFireAuthModule,
    NgxPaginationModule,
    NgChartsModule,
    NgApexchartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatMenuModule,
    MatSlideToggleModule,
    NgSelectModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    FullCalendarModule,
    MatExpansionModule,
    MatTabsModule,
    MatProgressBarModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    ModalModule.forRoot(),
    MatBadgeModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule
  ],
  providers: [
    AuthGuard,
    SearchUsuarioPipe,
    SearchAgendaPipe,
    SearchEnderecoPipe,
    SearchServicoPipe,
    SearchSlotPipe,
    SearchTipoPipe,
    SearchFormularioPipe,
    SearchRecursoPipe,
    SearchNotificacaoPipe,
    SearchAgendamentoPipe,
    SearchCheckinPipe,
    SearchGrupoUsuarioPipe,
    SearchGrupoAgendaPipe,
    DiferencaEntreDatasPipe,
    ValidatorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingBarControlService,
      multi: true,
    },
    ModalConfirmacaoService,
    ModalLinkService,
    ModalConfirmacaoComponent,
    ModalLinkComponent,
    FiltroDestaquePipe,
    NotificacaoBadgePipe,
    StepProgressPipe,
    TempoDecorridoPipe,
    TempoMedioPipe,
    TempoPicoPipe,
    RouteLoadingBarControlService,
    LoginComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
