import { Pipe, PipeTransform, ChangeDetectorRef, NgZone } from '@angular/core';

@Pipe({
  name: 'tempoMedio',
  pure: false // Torna o pipe impuro para atualização constante
})
export class TempoMedioPipe implements PipeTransform {

  private lastUpdate: number = 0;

  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.ngZone.run(() => {
          this.lastUpdate = Date.now();
          this.changeDetectorRef.markForCheck(); // Marca para detecção de mudanças
          // this.changeDetectorRef.detectChanges(); // Marca para detecção de mudanças
        });
      }, 1000);
    });
  }

  transform(items: any[]): string {
    const now = new Date();
    let totalDiffMs = 0;
    let count = 0;

    items.forEach(item => {
      if (item.status.valor === 'Aguardando') {
        const date = new Date(item.statusAguardando.inicio);
        const diffMs = now.getTime() - date.getTime();
        totalDiffMs += diffMs;
        count++;
      }

      if (item.status.valor === 'Em andamento') {
        const date = new Date(item.statusEmAndamento.inicio);
        const diffMs = now.getTime() - date.getTime();
        totalDiffMs += diffMs;
        count++;
      }

      if (item.status.valor === 'Realizado') {
        const dateInicio = new Date(item.statusRealizado.inicio);
        const dateFim = new Date(item.statusRealizado.fim);
        const diffMs = dateFim.getTime() - dateInicio.getTime();
        totalDiffMs += diffMs;
        count++;
      }
    });

    if (count === 0) {
      return '00:00:00:00';
    }

    const averageDiffMs = totalDiffMs / count;

    const diffDays = Math.floor(averageDiffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((averageDiffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((averageDiffMs % (1000 * 60 * 60)) / (1000 * 60));
    const diffSeconds = Math.floor((averageDiffMs % (1000 * 60)) / 1000);

    const formattedDays = diffDays.toString().padStart(2, '0');
    const formattedHours = diffHours.toString().padStart(2, '0');
    const formattedMinutes = diffMinutes.toString().padStart(2, '0');
    const formattedSeconds = diffSeconds.toString().padStart(2, '0');

    return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
}
