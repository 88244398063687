import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../services/auth.service";
import { ToastService } from 'src/services/toast.service';
import { SiglaService } from 'src/services/siglaService.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
empresaSigla: any | undefined;
  constructor(
    public authService: AuthService,
    public router: Router,
    public toast: ToastService,
    private siglaService: SiglaService
  ){
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     window.scrollTo(0, 0);
        this.siglaService.empresaSigla$.subscribe(empresaSigla => {
          this.empresaSigla = empresaSigla;
          // console.log('AuthGuard subscribe - Sigla da Empresa:', this.empresaSigla);
          if(!empresaSigla){
            this.empresaSigla = localStorage.getItem('empresaSigla');
            // console.log('AuthGuard localStorage - Sigla da Empresa:', this.empresaSigla);
          }
        });
    //   }
    // });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean> | Promise<boolean> | boolean {

      // if(true) {
      if(this.authService.validateToken()) {

        const requiredPermission = route.data['requiredPermission'];

        // Verifica se a propriedade requiredPermission está presente
        if (requiredPermission !== undefined && requiredPermission !== null) {
          return this.authService.hasPermission(requiredPermission, this.empresaSigla)
            .then((hasPermission: boolean) => {
              if (hasPermission) {
                this.authService.mostrarMenuEmitter.emit(true);
                return true;
              } else {
                // this.authService.mostrarMenuEmitter.emit(false);
                this.showError("Você não tem permissão para acessar esta página.");
                this.router.navigate([`${this.empresaSigla}/home`]);
                return false;
              }
            })
            .catch((error: any) => {
              // console.error('Erro ao verificar permissão:', error);
              // this.authService.mostrarMenuEmitter.emit(false);
              this.showError("Erro ao verificar permissão.");
              return false;
            });
        }


        // console.log(true)
        this.authService.mostrarMenuEmitter.emit(true);
        return true;
      }

      this.authService.mostrarMenuEmitter.emit(false);
      // console.log(false)
      this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.")
      this.empresaSigla = localStorage.getItem("empresaSigla");

      this.router.navigate([`${this.empresaSigla}/login`])
      return false;

  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
