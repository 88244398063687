import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario, newUsuario, objUsuario } from 'src/class/usuario';
import { HttpClient } from '@angular/common/http'; // Importe o HttpClient para fazer requisições HTTP
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { TokenValidationService } from './token-validation.service';
import { ToastService } from './toast.service';



@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  endpoint = 'https://localhost\:44377/api/';

  constructor(
    private router: Router,
    private http: HttpClient, // Injete o HttpClient para fazer requisições HTTP
    private tokenValidationService: TokenValidationService,
    public toast: ToastService
  ){

  }

  async getUsuario(token: any): Promise<any> {
    if(this.tokenValidationService.validateToken(token)){
      const httpOptions = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Accept', 'application/json')
          .set('Authorization', 'Bearer ' + token)
      };

      try {
        const response = await this.http.get<any>(this.endpoint + 'usuarios', httpOptions).toPromise();
        return response;
      }catch (error){
        return error;
      }
    }else{
      // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.");
      return "Desculpe, mas sua sessão expirou. Por favor, faça login novamente."
    }

  }

  async getUsuarioId(id: any, token: any): Promise<any> {
    if(this.tokenValidationService.validateToken(token)){
      const httpOptions = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Accept', 'application/json')
          .set('Authorization', 'Bearer ' + token)
      };

      try {
        const response = await this.http.get<any>(this.endpoint + 'usuarios?id='+id, httpOptions).toPromise();
        return response;
      }catch (error){
        return error;
      }
    }else{
      // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.");
      return "Desculpe, mas sua sessão expirou. Por favor, faça login novamente."
    }

  }

  async getTotalTiposUsuarios(token: any): Promise<any> {
    if(this.tokenValidationService.validateToken(token)){

      const httpOptions = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Accept', 'application/json')
          .set('Authorization', 'Bearer ' + token)
      };

      try {
        const response = await this.http.get<any>(this.endpoint + 'totalusuarios', httpOptions).toPromise();
        return response;
      }catch (error){
        return error;
      }
    }else{
      // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.");
      return "Desculpe, mas sua sessão expirou. Por favor, faça login novamente."
    }
  }

  async updateUsuario(usr: objUsuario, token: any): Promise<any> {
    if(this.tokenValidationService.validateToken(token)){
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      const raw = JSON.stringify({
        "tipo": usr.Tipo!,
        "nome": usr.Nome!,
        "email": usr.Email!,
        "foto": usr.Foto
      });

      const requestOptions: RequestInit = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow' // ou 'manual'
      };

      const url = `https://localhost:44377/api/usuarios?id=${usr.Id}`;

      try {
        const response = await fetch(url, requestOptions);
        const result = await response.text();
        return result;
      } catch (error) {
        throw error;
      }
    }else{
      // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.");
      return "Desculpe, mas sua sessão expirou. Por favor, faça login novamente."
    }
  }

  async newUsuario(usr: newUsuario, token: any): Promise<any> {

    if(this.tokenValidationService.validateToken(token)){
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      const raw = JSON.stringify({
        "usuario": usr.Usuario!,
        "senha": usr.Senha!,
        "tipo": usr.Tipo!,
        "nome": usr.Nome!,
        "email": usr.Email!,
        "foto": usr.Foto
      });

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow' // ou 'manual'
      };

      const url = `https://localhost:44377/api/usuarios`;

      try {
        const response = await fetch(url, requestOptions);
        const result = await response.text();
        return result;
      } catch (error) {
        throw error;
      }
    }else{
      // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.");
      return "Desculpe, mas sua sessão expirou. Por favor, faça login novamente."
    }
  }

  async deleteUsuario(usr: objUsuario, token: any): Promise<any> {
    if(this.tokenValidationService.validateToken(token)){
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      // const raw = JSON.stringify({
      //   "tipo": usr.Tipo!,
      //   "nome": usr.Nome!,
      //   "email": usr.Email!
      // });

      const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: myHeaders,
        // body: raw,
        redirect: 'follow' // ou 'manual'
      };

      const url = `https://localhost:44377/api/usuarios?id=${usr.Id}`;

      try {
        const response = await fetch(url, requestOptions);
        const result = await response.text();
        return result;
      } catch (error) {
        throw error;
      }
    }else{
      // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.");
      return "Desculpe, mas sua sessão expirou. Por favor, faça login novamente."
    }
  }

  async recuperarSenha(email: any): Promise<any> {
    // if(this.tokenValidationService.validateToken(token)){
      const httpOptions = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Accept', 'application/json')
          // .set('Authorization', 'Bearer ' + token)
      };

      try {
        const response = await this.http.post<any>(this.endpoint + 'recuperarsenha?email='+email, httpOptions).toPromise();
        return response;
      }catch (error){
        return error;
      }
    // }else{
    //   // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.");
    //   return "Desculpe, mas sua sessão expirou. Por favor, faça login novamente."
    // }

  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
