import { FormControl, Validators } from "@angular/forms";
import { campo } from "../interface/campo";
// import { Endereco } from "./endereco";
import { FormCampos } from "./formCampos";
import { FormCamposObrigatorios } from "./formCamposObrigatorios";
import { Servico } from "./servico";
import { ValidatorService } from "../services/validator.service";
import { ContadorIndexService } from "src/services/contador-index.service";
import { DiaDaSemana } from "src/enum/DiaDaSemana.enum";
import { SiglaService } from "src/services/siglaService.service";

const idx = ContadorIndexService.createInstance();
const validatorService: ValidatorService  = new ValidatorService();

export class Agenda {

  id: any = null;

  nome: campo = {
    title: 'Nome da Agenda',
    info: 'Este é o título principal da sua agenda, e é o nome que será exibido para os clientes quando eles estiverem agendando. É importante escolher um nome claro e descritivo que ajude os clientes a identificar facilmente o propósito da agenda.',
    valor: '',
    placeholder: 'Título da agenda',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  subNome: campo = {
    title: 'Subtítulo da agenda',
    info: 'O subtítulo da agenda é um campo opcional que fornece informações adicionais sobre o que a agenda trata.',
    valor: '',
    placeholder: 'Subtítulo da agenda',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  link: campo = {
    title: 'Link da Agenda',
    info: 'Link para acessar a agenda.',
    valor: '',
    placeholder: 'URL da agenda',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  instrucoes: campo = {
    title: 'Instruções exibidas no agendamento',
    info: 'Aqui, você pode fornecer instruções específicas que serão exibidas para os clientes durante o processo de agendamento. Isso pode incluir informações sobre políticas de cancelamento, requisitos especiais ou qualquer orientação relevante.',
    valor: '',
    placeholder: 'Instruções',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  confirmacaoAdmin: campo = {
    title: 'Confirmação do Administrador',
    info: 'Esse campo indica se um administrador precisa confirmar ou aprovar os agendamentos feitos pelos clientes antes que eles sejam oficialmente agendados.',
    valor: false,
  };

  notificacoes: campo = {
    title: 'Notificações da Agenda',
    info: 'Este campo permite configurar notificações que serão enviadas aos clientes antes de seus compromissos agendados. As notificações podem incluir lembretes, informações importantes ou outras mensagens relevantes.',
    valor: false,
  };

  bloquearFeriadosNacionais: campo = {
    title: 'Bloquear agenda em feriados Nacionais',
    info: 'Ao ativar esta opção, a agenda não estará disponível para agendamento nos feriados nacionais, garantindo que não haja conflitos com essas datas.',
    valor: false,
  };

  bloquearFeriadosEstaduais:  campo = {
    title: 'Bloquear agenda em feriados Estaduais',
    info: 'Ao ativar esta opção, a agenda não estará disponível para agendamento nos feriados estaduais, garantindo que não haja conflitos com essas datas.',
    valor: false,
  };

  duracaoAtendimento: campo = {
    title: 'Duração de Atendimento',
    info: 'Este campo define a duração padrão de cada compromisso ou atendimento na agenda. Pode ser expresso em minutos ou horas, dependendo das necessidades da agenda. Por exemplo, se o atendimento dura 50min e você quer 10min de intervalo até o próximo atendimento, preencha esse campo com 60min (01:00).',
    valor: '',
    placeholder: 'Duração, ex.: 01:00 para 60 minutos',
    inputType: 'time',
    inputMode: 'numeric',
    mascara: '##:##',
    maxlength: '5',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  antecedenciaMinimaHoras:   campo = {
    title: 'Antecedência Mínima para Agendamento (em horas)',
    info: 'Indica o período mínimo de tempo que os clientes devem aguardar antes de fazer um agendamento. Por exemplo, se você preencher 24h (23:59), só vai ser disponibilizado horário de atendimento para o dia seguinte. Se você preencher 0 (zero) (00:00), o agendamento fica disponível até 15min antes do horário do atendimento.',
    valor: '',
    placeholder: 'Antecedência mínima (em horas)',
    inputType: 'time',
    inputMode: 'numeric',
    mascara: '##:##',
    maxlength: '5',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  antecedenciaMaximaDias:  campo = {
    title: 'Antecedência Máxima para Agendamento (em dias)',
    info: 'Este campo estabelece o limite máximo de dias com antecedência que os clientes podem fazer agendamentos. Por exemplo, se você preencher esse campo com 7 dias, só vai ser disponibilizado horários até 7 dias pra frente. O seu plano permite agendamentos com antecedência máxima de 90 dias.',
    valor: '',
    placeholder: 'Antecedência máxima (em dias)',
    inputType: 'number',
    inputMode: 'numeric',
    mascara: '#####',
    maxlength: '5',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  formCampos:                 FormCampos = new FormCampos();

  formCamposObrigatorios:     FormCamposObrigatorios = new FormCamposObrigatorios();

  // enderecos:                  Endereco[] = [];

  servicos: Servico[] = [];

  linkImagem: campo = {
    title: 'Link da Imagem',
    info: 'Este é o link para uma imagem que será usada como plano de fundo visual na página da agenda online. A imagem pode ser escolhida para refletir a identidade da agenda ou criar um ambiente acolhedor para os clientes.',
    valor: `https://picsum.photos/id/${idx.contadorIndex()}/1080/720`,
    placeholder: 'URL da imagem',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  criado: Date = new Date();

  agenda_inicio: campo = {
    title: 'Data Inicial da Agenda',
    info: 'Este campo define a data de início da agenda, especificando quando a agenda estará disponível para agendamento.',
    valor: '',
    placeholder: 'Data de início',
    inputType: 'text',
    inputMode: 'numeric',
    mascara: '',
    maxlength: '10',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  agenda_fim: campo = {
    title: 'Data Final da Agenda',
    info: 'A data final da agenda determina até quando a agenda estará ativa e aceitando agendamentos. Depois dessa data, a agenda não estará mais disponível para agendamentos.',
    valor: '',
    placeholder: 'Data de término',
    inputType: 'text',
    inputMode: 'numeric',
    mascara: '',
    maxlength: '10',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  status: campo = {
    title: 'Agenda Ativa',
    info: 'Agenda Ativa',
    valor: true,
  };

  horarios: Horario[] = [];

  constructor() {
    for (let dia = DiaDaSemana.Domingo; dia <= DiaDaSemana.Sabado; dia++) {
      const horario = new Horario();
      horario.id = idx.contadorIndex();
      horario.dia.title = this.obterTituloDoDia(dia);
      horario.dia.info = this.obterTituloDoDia(dia);
      // console.log(horario)
      // const horarios = new HorariosDia();
      // horarios.id = idx.contadorIndex();
      // horario.horariosDia.push(horarios)
      this.horarios.push(horario);
    }
    // this.diaDaSemana()


  }

  // async diaDaSemana() {
  //   for (let dia = DiaDaSemana.Domingo; dia <= DiaDaSemana.Sabado; dia++) {
  //     const horario = new Horario();
  //     horario.id = await idx.contadorIndex();
  //     horario.dia.title = this.obterTituloDoDia(dia);
  //     horario.dia.info = this.obterTituloDoDia(dia);
  //     this.horarios.push(horario);
  //   }
  // }


  obterTituloDoDia(dia: DiaDaSemana): string {
    switch (dia) {
      case DiaDaSemana.Domingo:
        return 'Domingo';
      case DiaDaSemana.Segunda:
        return 'Segunda-feira';
      case DiaDaSemana.Terca:
        return 'Terça-feira';
      case DiaDaSemana.Quarta:
        return 'Quarta-feira';
      case DiaDaSemana.Quinta:
        return 'Quinta-feira';
      case DiaDaSemana.Sexta:
        return 'Sexta-feira';
      case DiaDaSemana.Sabado:
        return 'Sábado';
      default:
        return '';
    }
  }

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "nome":
        this.nome.valor = validatorService.aplicarMascara(this.nome.valor, mascara);
        break;
      case "subNome":
        this.subNome.valor = validatorService.aplicarMascara(this.subNome.valor, mascara);
        break;
      case "link":
        this.link.valor = validatorService.aplicarMascara(this.link.valor, mascara);
        break;
      case "instrucoes":
        this.instrucoes.valor = validatorService.aplicarMascara(this.instrucoes.valor, mascara);
        break;
      case "duracaoAtendimento":
        this.duracaoAtendimento.valor = validatorService.aplicarMascara(this.duracaoAtendimento.valor, mascara);
        break;
      case "antecedenciaMinimaHoras":
        this.antecedenciaMinimaHoras.valor = validatorService.aplicarMascara(this.antecedenciaMinimaHoras.valor, mascara);
        break;
      case "antecedenciaMaximaDias":
        this.antecedenciaMaximaDias.valor = validatorService.aplicarMascara(this.antecedenciaMaximaDias.valor, mascara);
        break;
      case "linkImagem":
        this.linkImagem.valor = validatorService.aplicarMascara(this.linkImagem.valor, mascara);
        break;
      case "agenda_inicio":
        this.agenda_inicio.valor = validatorService.aplicarMascara(this.agenda_inicio.valor, mascara);
        break;
      case "agenda_fim":
        this.agenda_fim.valor = validatorService.aplicarMascara(this.agenda_fim.valor, mascara);
        break;
      default:
        break;
    }
  }

  // Função para validar o campo com base nos validadores personalizados
  validarInput(tipo: string) {
    switch (tipo) {
      case "nome":
        this.nome.valor = this.nome.valor.trim();
        this.nome.status = validatorService.criarValidacao(this.nome.valor, this.nome.validacoesCustomizadas);
        break;
      case "subNome":
        this.subNome.valor = this.subNome.valor.trim();
        this.subNome.status = validatorService.criarValidacao(this.subNome.valor, this.subNome.validacoesCustomizadas);
        break;
      case "link":
        this.link.valor = this.link.valor.trim();
        this.link.status = validatorService.criarValidacao(this.link.valor, this.link.validacoesCustomizadas);
        break;
      case "instrucoes":
        this.instrucoes.valor = this.instrucoes.valor.trim();
        this.instrucoes.status = validatorService.criarValidacao(this.instrucoes.valor, this.instrucoes.validacoesCustomizadas);
        break;
      case "duracaoAtendimento":
        this.duracaoAtendimento.valor = this.duracaoAtendimento.valor.trim();
        this.duracaoAtendimento.status = validatorService.criarValidacao(this.duracaoAtendimento.valor, this.duracaoAtendimento.validacoesCustomizadas);
        break;
      case "antecedenciaMinimaHoras":
        this.antecedenciaMinimaHoras.valor = this.antecedenciaMinimaHoras.valor.trim();
        this.antecedenciaMinimaHoras.status = validatorService.criarValidacao(this.antecedenciaMinimaHoras.valor, this.antecedenciaMinimaHoras.validacoesCustomizadas);
        break;
      case "antecedenciaMaximaDias":
        this.antecedenciaMaximaDias.valor = this.antecedenciaMaximaDias.valor.trim();
        this.antecedenciaMaximaDias.status = validatorService.criarValidacao(this.antecedenciaMaximaDias.valor, this.antecedenciaMaximaDias.validacoesCustomizadas);
        break;
      case "linkImagem":
        this.linkImagem.valor = this.linkImagem.valor.trim();
        this.linkImagem.status = validatorService.criarValidacao(this.linkImagem.valor, this.linkImagem.validacoesCustomizadas);
        break;
      case "agenda_inicio":
        this.agenda_inicio.valor = this.agenda_inicio.valor.trim();
        this.agenda_inicio.status = validatorService.criarValidacao(this.agenda_inicio.valor, this.agenda_inicio.validacoesCustomizadas);
        break;
      case "agenda_fim":
        this.agenda_fim.valor = this.agenda_fim.valor.trim();
        this.agenda_fim.status = validatorService.criarValidacao(this.agenda_fim.valor, this.agenda_fim.validacoesCustomizadas);
        break;
      default:
        break;
    }
    // console.log(this)
  }

}

export class Horario {
  id:                         any | null = null;
  dia:                        campo = {
                                title: '',
                                info: '',
                                valor: '',
                                placeholder: '',
                                inputType: 'text',
                                inputMode: 'text',
                                mascara: '',
                                maxlength: '1000',
                                tipo: null,
                                validacoesCustomizadas: ['required'],
                                status: new FormControl()
                              };
  horariosDia:                HorariosDia[] = []
}

export class HorariosDia {
  id:                         any | null = null;
  hora_inicial:               campo = {
                                title: 'Hora Inicial',
                                info: 'Hora Inicial',
                                valor: '00:00',
                                placeholder: 'Hora Inicial',
                                inputType: 'time',
                                inputMode: 'numeric',
                                mascara: '##:##',
                                maxlength: '5',
                                tipo: null,
                                validacoesCustomizadas: ['required'],
                                status: new FormControl()
                              };
hora_final:                 campo = {
                              title: 'Hora Final',
                              info: 'Hora Final',
                              valor: '00:00',
                              placeholder: 'Hora Final',
                              inputType: 'time',
                              inputMode: 'numeric',
                              mascara: '##:##',
                              maxlength: '5',
                              tipo: null,
                              validacoesCustomizadas: ['required'],
                              status: new FormControl()
                            };
num_pes_horario:            campo = {
                              title: 'Atendimentos Simultâneos',
                              info: 'Atendimentos Simultâneos',
                              valor: '1',
                              placeholder: 'Atendimentos Simultâneos',
                              inputType: 'number',
                              inputMode: 'numeric',
                              mascara: '#####',
                              maxlength: '5',
                              tipo: null,
                              validacoesCustomizadas: [],
                              status: new FormControl()
                            };
}
