import { FormControl } from "@angular/forms";
import { campo } from "src/interface/campo";
import { ValidatorService } from "src/services/validator.service";

const validatorService: ValidatorService  = new ValidatorService();

export class Slot {
  id: any | null = null;

  nome: campo = {
    title: 'Nome do slot',
    info: 'Nome do slot',
    valor: '',
    placeholder: 'Nome do slot',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  descricao: campo = {
    title: 'Descrição do slot',
    info: 'Descrição do slot',
    valor: '',
    placeholder: 'Descrição do slot',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  duracaoSlot: campo = {
    title: 'Duração do slot',
    info: 'Duração do slot',
    valor: '',
    placeholder: 'Duração, ex.: 01:00 para 60 minutos',
    inputType: 'time',
    inputMode: 'numeric',
    mascara: '##:##',
    maxlength: '5',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  status: campo = {
    title: 'Status',
    info: 'Status',
    valor: true,
  };

  agendas: Agendas[] = [];

  responsavel: UsuarioResponsavel[] = [];

  regraCampoFixo: RegraCampoFixo[] = [];

  regraCampoFormulario: [] = [];

  criado: Date = new Date();

  atualizado: Date = new Date();

  constructor(){}

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "nome":
        this.nome.valor = validatorService.aplicarMascara(this.nome.valor, mascara);
        break;
      case "descricao":
        this.descricao.valor = validatorService.aplicarMascara(this.descricao.valor, mascara);
        break;
      default:
        break;
    }
  }

  validarInput(tipo: string) {
    switch (tipo) {
      case "nome":
        this.nome.valor = this.nome.valor.trim();
        this.nome.status = validatorService.criarValidacao(this.nome.valor, this.nome.validacoesCustomizadas);
        break;
      case "descricao":
        this.descricao.valor = this.descricao.valor.trim();
        this.descricao.status = validatorService.criarValidacao(this.descricao.valor, this.descricao.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }
}

export class Agendas{
  idAgenda?: string;
  NomeAgenda?: string;
}

export class UsuarioResponsavel{
  idUsuario?: string;
  NomeUsuario?: string;
}

export class RegraCampoFixo{

  id: any | null = null;

  nomeCampo: campo = {
    title: 'Nome do Campo Fixo',
    info: 'Nome do Campo Fixo',
    valor: '',
    placeholder: 'Nome do Campo Fixo',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  regraCampo: campo = {
    title: 'Regra do Campo Fixo',
    info: 'Regra do Campo Fixo',
    valor: '',
    placeholder: 'Regra do Campo Fixo',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  regraValor1: campo = {
    title: 'Valor',
    info: 'Valor',
    valor: '',
    placeholder: 'Valor',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  regraValor2: campo = {
    title: 'Valor',
    info: 'Valor',
    valor: '',
    placeholder: 'Valor',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  constructor(){}

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "nomeCampo":
        this.nomeCampo.valor = validatorService.aplicarMascara(this.nomeCampo.valor, mascara);
        break;
      case "descricao":
        this.nomeCampo.valor = validatorService.aplicarMascara(this.nomeCampo.valor, mascara);
        break;
      default:
        break;
    }
  }

  validarInput(tipo: string) {
    switch (tipo) {
      case "nomeCampo":
        this.nomeCampo.valor = this.nomeCampo.valor.trim();
        this.nomeCampo.status = validatorService.criarValidacao(this.nomeCampo.valor, this.nomeCampo.validacoesCustomizadas);
        break;
      case "regraCampo":
        this.regraCampo.valor = this.regraCampo.valor.trim();
        this.regraCampo.status = validatorService.criarValidacao(this.regraCampo.valor, this.regraCampo.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }
}

