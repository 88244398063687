import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario, UsuarioEmailSenha, objUsuario } from 'src/class/usuario';
import { HttpClient } from '@angular/common/http'; // Importe o HttpClient para fazer requisições HTTP
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { TokenValidationService } from './token-validation.service';
import { ToastService } from './toast.service';
import { getAuth, signInWithPopup, GoogleAuthProvider, UserCredential, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { LocalhostService } from '../services/localhost.service';

import { NgZone } from '@angular/core';

import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  } from "firebase/auth";
  import { DocumentData, getFirestore } from "firebase/firestore";
  import { doc, setDoc } from "firebase/firestore";
  import { initializeApp } from "firebase/app";
  import { getAnalytics } from "firebase/analytics";
  import { environment} from 'src/environment/environment';
import { ContaService } from './component/conta.service';
import { Conta } from 'src/class/conta';
import { UsuarioService } from './component/usuario.service';
import { Subscription, interval, take } from 'rxjs';


  const app = initializeApp(environment.firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);

  const auth = getAuth();
  var authCurrentUser = auth.currentUser;
  const googleAuthProvider = new GoogleAuthProvider();
  const IDConta = LocalhostService.getIDConta();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any; // Save logged in user data
  private usuarioAutenticado: boolean = false;
  endpoint = 'https://localhost\:44377/api/';
  mostrarMenuEmitter = new EventEmitter<boolean>();

  private urlAPI = "https://us-central1-logwise-dev-9cf97.cloudfunctions.net/api";

  constructor(
    private router: Router,
    private http: HttpClient, // Injete o HttpClient para fazer requisições HTTP
    private tokenValidationService: TokenValidationService,
    public toast: ToastService,
    private localhostService: LocalhostService,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private route: ActivatedRoute,
    private conta: ContaService
  ) {

    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    // onAuthStateChanged(auth, (user) => {
    //   // console.log(auth, user)
    //   if (user) {
    //     this.userData = user;
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     JSON.parse(localStorage.getItem('user')!);
    //   } else {
    //     localStorage.setItem('user', 'null');
    //     JSON.parse(localStorage.getItem('user')!);
    //   }
    // });

    this.scheduleTokenRenewal()

  }

  // Função para renovar o token antes da expiração
  scheduleTokenRenewal() {
    let lastActivityTime = Date.now();

    const renewToken = async () => {
        const currentTime = Date.now();
        const timeSinceLastActivity = currentTime - lastActivityTime;

        // const hours = Math.floor(timeSinceLastActivity / 3600000);
        // const minutes = Math.floor((timeSinceLastActivity % 3600000) / 60000);
        // const seconds = Math.floor((timeSinceLastActivity % 60000) / 1000);
        // const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        // console.log('Inatividade:', timeString);

        // Verifica se a inatividade foi maior que 55 minutos (3300000 ms)
        if (timeSinceLastActivity < 3300000) {
            auth.currentUser?.getIdTokenResult().then(tokenResult => {
                const expiresIn = new Date(tokenResult.expirationTime).getTime() - new Date().getTime();

                // Converte milissegundos para horas, minutos e segundos
                // const hours = Math.floor(expiresIn / 3600000);
                // const minutes = Math.floor((expiresIn % 3600000) / 60000);
                // const seconds = Math.floor((expiresIn % 60000) / 1000);
                // const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                // console.log('Tempo restante para expiração:', timeString);

                if (expiresIn < 1800000) { // Se faltar menos de 30 minutos para expirar
                    auth.currentUser?.getIdToken(true).then(newToken => {
                        this.userData = auth.currentUser;
                        localStorage.setItem('user', JSON.stringify(auth.currentUser));
                        // console.log('Token do usuário atualizado.');
                        // Exemplo de como você pode exibir uma mensagem de sucesso
                        // const userToken = JSON.parse(localStorage.getItem('user')!);
                        // const tkn = new Date(userToken.stsTokenManager.expirationTime).getTime() - 1800000;
                        // console.log('Novo token será renovado em:', tkn);
                        // this.showSuccess(Token do usuário atualizado. O token será renovado em ${new Date(tkn).toLocaleString()});
                    });
                }
            });
        } else {
          // console.log('Inatividade por mais de 55 minutos. Realizando logout.');
          await signOut(auth);

          this.usuarioAutenticado = false;
          this.mostrarMenuEmitter.emit(false);
          this.userData = null

          if(localStorage.getItem('user')){
            this.showError("Usuário deslogado")
          }

          localStorage.removeItem('user');
          window.location.reload();
        }
    };

    // Atualiza o tempo da última atividade
    const updateLastActivityTime = () => {
        lastActivityTime = Date.now();
    };

    // Eventos para monitorar a atividade do usuário
    window.addEventListener('mousemove', updateLastActivityTime);
    window.addEventListener('click', updateLastActivityTime);
    window.addEventListener('keydown', updateLastActivityTime);
    window.addEventListener('scroll', updateLastActivityTime);

    // Verificar a cada minuto
    interval(60000).pipe(take(Infinity)).subscribe(renewToken);

    // Verificar quando a guia se torna visível
    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
            renewToken();
        }
    });

    // Verificar quando a janela ganha foco
    window.addEventListener('focus', renewToken);
  }




  async fazerLoginComEmailSenha(user: UsuarioEmailSenha, empresaSigla: any): Promise<any> {
    // const auth = getAuth();
    const login = user.Nome?.toString();
    const senha = user.Senha?.toString();
    try {
      if(login && senha){
        const authResult: UserCredential = await signInWithEmailAndPassword(auth, login, senha);
        // console.log(authResult)

        this.usuarioAutenticado = true;
        this.mostrarMenuEmitter.emit(true);
        localStorage.setItem('user', JSON.stringify(authResult.user));
        localStorage.setItem('userNome', JSON.stringify({'Nome': authResult.user.displayName}));
        await this.SetUserData(authResult.user, empresaSigla);
        this.router.navigate([`${empresaSigla}/home`]);
        this.showSuccess("Usuário logado com sucesso!!")

        return authResult.user;
      }else{
        return false;
      }
    } catch (error) {
      throw error;
    }
  }

  async fazerLoginComGoogle(): Promise<any> {
    // const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const authResult: UserCredential = await signInWithPopup(auth, provider);
      // console.log(authResult)

      this.usuarioAutenticado = true;
      this.mostrarMenuEmitter.emit(true);
      localStorage.setItem('user', JSON.stringify(authResult.user));
      localStorage.setItem('userNome', JSON.stringify({'Nome': authResult.user.displayName}))

      // this.router.navigate(['/home']);
      this.showSuccess("Usuário logado com sucesso!!")

      return authResult.user;
    } catch (error) {
      throw error;
    }
  }


  usuarioEstaAutenticado() {
    return this.usuarioAutenticado;
  }

  async fazerLogout(empresaSigla: string = '') {

    // const auth = getAuth();

    try {
      await signOut(auth);

      this.usuarioAutenticado = false;
      this.mostrarMenuEmitter.emit(false);
      this.userData = null

      if(localStorage.getItem('user')){
        this.showError("Usuário deslogado")
      }

      localStorage.removeItem('user');
      // this.showError("Usuário deslogado")
      const rotaAtiva = this.router.url;
      // this.router.navigate(['/login']);
      if (empresaSigla != '' && !rotaAtiva.includes('recuperar-senha')) {
        this.router.navigate([`${empresaSigla}/login`]);
      }
    } catch (error) {
      // console.error(error);
      // Trate o erro, se necessário
    }
  }

  // isLoggedIn(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user')!);
  //   return user !== null;
  // }

  validateToken(): boolean{
    const userToken = JSON.parse(localStorage.getItem('user')!);

    if (userToken) {
      // ativo
      // cliente
      // token
      return this.tokenValidationService.validateToken(userToken.stsTokenManager.accessToken);
    } else {
      // Usuário não autenticado, redireciona para a página de login
      return this.tokenValidationService.navigateLogin();
    }
    // return true
  }

  usuarioLogado(empresaSigla: string): boolean{
    const userToken = JSON.parse(localStorage.getItem('user')!);
    if (userToken) {
      const validationToken = this.tokenValidationService.validateToken(userToken.stsTokenManager.accessToken);

      if(validationToken){
        // Autenticação bem-sucedida
        this.usuarioAutenticado = true;
        this.mostrarMenuEmitter.emit(true);
        // this.router.navigate(['/home']);
        // console.log('Sigla da Empresa:', empresaSigla);
        if (empresaSigla != '') {
          this.router.navigate([`${empresaSigla}/home`]);
        }
        // else {
        //   this.router.navigate(['/home']);
        // }
        // this.showSuccess(`Bem vindo, ${userToken.displayName}`)
        return true;
      }else{
        // Autenticação falhou
        this.usuarioAutenticado = false;
        this.mostrarMenuEmitter.emit(false);
        localStorage.removeItem('user');
        // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.")
        return false;
      }
    } else {
      // Usuário não autenticado, redireciona para a página de login

      // return this.tokenValidationService.navigateLogin();
      return false;
    }
  }

  SignIn(email: string, password: string) {
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        // Acesso autorizado
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        // Tratar erros de login
        window.alert(error.message);
      });
  }

  // Sign in with email/password
  SignIn2(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string, repassword: string, empresaSigla: any): Promise<any> {
    const auth2 = getAuth();

    return new Promise(async (resolve, reject) => {
      if (password !== repassword) {
        const mensagem = "A senha digitada nos campos de senha estão diferentes";
        window.alert(mensagem);
        reject(new Error(mensagem));
      } else {
        try {
          const result = await createUserWithEmailAndPassword(auth2, email, password);
          // await this.SetUserData(result.user, empresaSigla);
          // await this.SendVerificationMail();
          resolve(result);  // Retorna o resultado em caso de sucesso
        } catch (error) {
          // console.error(error);
          reject(error);
        }
      }
    });
  }

  async criarNovoUsuario(email: string, senha: string, token: any): Promise<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const body = { email, senha };
    try {
      const result = await this.http.post(`${this.urlAPI}/authentication/create-user`, body, { headers }).toPromise();
      // console.log('Resultado da criação do usuário:', result);
      return result;
    } catch (error) {
      // console.error('Erro ao criar novo usuário:', error);
      throw error;
    }
  }

  async verificarEmailUsuario(token: any, email: any): Promise<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    try {
      const response = await this.http.get(`${this.urlAPI}/authentication/users/${email}`, { headers }).toPromise();
      // console.log('Resposta da API:', response);
      return response;
    } catch (error) {
      // console.error('Erro ao chamar API:', error);
      throw error; // Lançando o erro para que possa ser tratado no código que chama esta função
    }
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    if (authCurrentUser!==null){
      return sendEmailVerification(authCurrentUser)
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
    }else{
      return null;
    }
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return sendPasswordResetEmail(auth, passwordResetEmail)
      .then((a) => {
        // console.log(a)
        // window.alert('Password reset email sent, check your inbox.');
        return 'Se houver uma conta associada com os dados fornecidos, você receberá um e-mail com um link para redefinir sua senha. Por favor, verifique sua caixa de entrada.'
      })
      .catch((error) => {
        // window.alert(error);
        // console.log(error)
        return error
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Sign in with Google
  GoogleAuth(empresaSigla: string) {
    // console.log('auth.service');
    return this.AuthLogin(new GoogleAuthProvider(), empresaSigla).then((res: any) => {
      // console.log(res);
      if (res) {
        // this.router.navigate(['/home']);
        // Agora você tem a sigla da empresa e pode usá-la conforme necessário
        // console.log('Sigla da Empresa:', empresaSigla);
        if (empresaSigla != '') {
          this.router.navigate([`${empresaSigla}/home`]);
        }
        // else {
        //   this.router.navigate(['/home']);
        // }
      }
    });
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any, empresaSigla: string) {
    // const auth = getAuth();
    return signInWithPopup(auth, provider)
      .then(async (authResult) => {
        this.ngZone.run(() => {

          if (empresaSigla != '') {
            this.router.navigate([`${empresaSigla}/home`]);
          }
          // else {
          //   this.router.navigate(['/home']);
          // }
        });
        // console.log(authResult)
        localStorage.setItem('user', JSON.stringify(authResult.user));
        localStorage.setItem('userNome', JSON.stringify({'Nome': authResult.user.displayName}));
        this.usuarioAutenticado = true;
        this.mostrarMenuEmitter.emit(true);
        this.showSuccess("Usuário logado com sucesso!!")
        await this.SetUserData(authResult.user, empresaSigla);
        return authResult.user;
      })
      .catch((error) => {
        throw error;
      });
  }
  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */

  async SetUserData(user: any, empresaSigla: string = '') {

    //   this.localhostService.postDadosId('user', user).then(
    //     (response: any) => {
    //       //---
    //       return true;
    //   },
    //   (error: any) => {
           // console.error('Erro ao criar UsuarioEmailSenha:', error);
    //     // Trate o erro conforme necessário
    //   }
    // );

    this.conta.o_getSiglaConta('contas', empresaSigla)
    .subscribe(async (conta) => {
      // console.log('Dados contas:', conta);

      let usuario_obj = new Usuario();

      // Dados usuário
      usuario_obj.id = user.uid;
      usuario_obj.displayName.valor = user.displayName;
      usuario_obj.email.valor = user.email;
      usuario_obj.phoneNumber.valor = user.phoneNumber;
      usuario_obj.photoURL.valor = user.photoURL;
      usuario_obj.sigla.valor = conta[0].sigla;
      usuario_obj.idConta = conta[0].id;

      let providerIds: Set<string> = new Set<string>();

      user.providerData.forEach((provider: any) => {
        if (!providerIds.has(provider.providerId)) {
          providerIds.add(provider.providerId);
        }
      });

      usuario_obj.providerId = Array.from(providerIds);

      // Limpar o status formControll
      usuario_obj.displayName.status = null;
      usuario_obj.email.status = null;
      usuario_obj.phoneNumber.status = null;
      usuario_obj.photoURL.status = null;
      usuario_obj.validadePlano.status = null;
      usuario_obj.sigla.status = null;
      usuario_obj.tipo.status = null;

      await this.localhostService.p_getUsuarioId(conta[0].id+"_usuarios", user.uid)
      .then((usuario) => {
        // console.log('Dados usuario:', usuario);

        if(!usuario.status.valor){
          this.fazerLogout(empresaSigla)
          this.showInfo("O usuário está desativado. Para ativar sua conta, por favor, entre em contato com o administrador da empresa e solicite a ativação.")
        }else{
          // Dados usuário
          usuario_obj.id = usuario.id;
          usuario_obj.displayName.valor = usuario.displayName.valor;
          usuario_obj.email.valor = usuario.email.valor;
          usuario_obj.phoneNumber.valor = usuario.phoneNumber.valor;

          if(!usuario.photoURL.valor){
            usuario_obj.photoURL.valor = user.photoURL;
          }else{
            usuario_obj.photoURL.valor = usuario.photoURL.valor;
          }

          usuario_obj.sigla.valor = conta[0].sigla.valor;
          usuario_obj.idConta = conta[0].id;
          usuario_obj.criado = usuario.criado;
          usuario_obj.atualizado = usuario.atualizado;
          usuario_obj.status.valor = usuario.status.valor;
          usuario_obj.tipo.valor = usuario.tipo.valor;
          usuario_obj.grupo = usuario.grupo;
          usuario_obj.grupoAgenda = usuario.grupoAgenda;

          // console.log(JSON.stringify(usuario))
          const json = JSON.stringify(usuario_obj);
          const jsonUsuario = JSON.parse(json);
          // console.log(jsonUsuario)

          setDoc(doc(db, conta[0].id+`_usuarios/${user.uid}`), jsonUsuario, { merge: true });
        }

      })
      .catch(async (erro) => {
        // console.log('Erro ao obter dados do usuario:', erro);
        // Dados usuário
        usuario_obj.status.valor = false;

        // console.log(JSON.stringify(usuario))
        const json = JSON.stringify(usuario_obj);
        const jsonUsuario = JSON.parse(json);
        // console.log(jsonUsuario)

        // setDoc(doc(db, conta[0].id+`_usuarios/${user.uid}`), jsonUsuario, { merge: true });

        await this.localhostService.postDadosNoId(conta[0].id+`_usuarios`, jsonUsuario)
        .then((response: any) => {
            // console.log('Usuário Criado', response);
            this.fazerLogout(empresaSigla)
            this.showInfo("Seu usuário foi criado com sucesso, porém, no momento, está desativado. Para ativar sua conta, por favor, entre em contato com o administrador da empresa e solicite a ativação.")
          },
          (error: any) => {
            // console.error('Erro ao criar UsuarioEmailSenha:', error);
          }
        );
      });

    }, (erro) => {
      // console.log('Erro ao obter dados da conta:', erro);
    });


  }

  async SetUserData2(user: any, empresaSigla: string = '') {

    //   this.localhostService.postDadosId('user', user).then(
    //     (response: any) => {
    //       //---
    //       return true;
    //   },
    //   (error: any) => {
           // console.error('Erro ao criar UsuarioEmailSenha:', error);
    //     // Trate o erro conforme necessário
    //   }
    // );

    this.conta.o_getSiglaConta('contas', empresaSigla)
    .subscribe(async (conta) => {
      // console.log('Dados contas:', conta);

      let usuario_obj = new Usuario();

      // Dados usuário
      usuario_obj.id = user.uid;
      usuario_obj.displayName.valor = user.displayName;
      usuario_obj.email.valor = user.email;
      usuario_obj.phoneNumber.valor = user.phoneNumber;
      usuario_obj.photoURL.valor = user.photoURL;
      usuario_obj.sigla.valor = conta[0].sigla;
      usuario_obj.idConta = conta[0].id;

      // Limpar o status formControll
      usuario_obj.displayName.status = null;
      usuario_obj.email.status = null;
      usuario_obj.phoneNumber.status = null;
      usuario_obj.photoURL.status = null;
      usuario_obj.validadePlano.status = null;
      usuario_obj.sigla.status = null;

      await this.localhostService.p_getUsuarioId(conta[0].id+"_usuarios", user.uid)
      .then((usuario) => {
        // console.log('Dados usuario:', usuario);

        if(!usuario.status.valor){
          // this.fazerLogout(empresaSigla)
          this.showInfo("O usuário está desativado. Para ativar sua conta, por favor, entre em contato com o administrador da empresa e solicite a ativação.")
        }else{
          // Dados usuário
          usuario_obj.id = usuario.id;
          usuario_obj.displayName.valor = usuario.displayName.valor;
          usuario_obj.email.valor = usuario.email.valor;
          usuario_obj.phoneNumber.valor = usuario.phoneNumber.valor;
          usuario_obj.photoURL.valor = usuario.photoURL.valor;
          usuario_obj.sigla.valor = conta[0].sigla.valor;
          usuario_obj.idConta = conta[0].id;
          usuario_obj.criado = usuario.criado;
          usuario_obj.atualizado = usuario.atualizado;
          usuario_obj.status.valor = usuario.status.valor;

          // console.log(JSON.stringify(usuario))
          const json = JSON.stringify(usuario_obj);
          const jsonUsuario = JSON.parse(json);
          // console.log(jsonUsuario)

          setDoc(doc(db, conta[0].id+`_usuarios/${user.uid}`), jsonUsuario, { merge: true });
        }

      })
      .catch(async (erro) => {
        // console.log('Erro ao obter dados do usuario:', erro);
        // Dados usuário
        usuario_obj.status.valor = false;

        // console.log(JSON.stringify(usuario))
        const json = JSON.stringify(usuario_obj);
        const jsonUsuario = JSON.parse(json);
        // console.log(jsonUsuario)

        // setDoc(doc(db, conta[0].id+`_usuarios/${user.uid}`), jsonUsuario, { merge: true });

        await this.localhostService.postDadosNoId(conta[0].id+`_usuarios`, jsonUsuario)
        .then((response: any) => {
            // console.log('Usuário Criado', response);
            // this.fazerLogout(empresaSigla)
            this.showInfo("Seu usuário foi criado com sucesso, porém, no momento, está desativado. Para ativar sua conta, por favor, entre em contato com o administrador da empresa e solicite a ativação.")
          },
          (error: any) => {
            // console.error('Erro ao criar UsuarioEmailSenha:', error);
          }
        );
      });

    }, (erro) => {
      // console.log('Erro ao obter dados da conta:', erro);
    });


  }

  // Sign out
  SignOut() {
    return signOut(auth).then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }

  hasPermission(id: any, empresaSigla: any): Promise<boolean> {
    return this.conta.p_getSiglaConta('contas', empresaSigla)
      .then((conta) => {
        // A promessa foi resolvida com sucesso, agora verificamos se a condição é atendida
        if (conta.id == id) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error: any) => {
        // A promessa foi rejeitada, tratamos o erro aqui
        // console.error('Erro ao obter conta:', error);
        return false;
      });
  }

  getUsuario(){
    return getAuth();
  }

  async carregarUsuario(empresaSigla: any): Promise<any> {
    try {

      const user = JSON.parse(localStorage.getItem('user')!);

      if(user){

        const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);
        const usuarios = await this.localhostService.p_getUsuarioId(conta.id+"_usuarios", user?.uid);

        if (usuarios) {
          // console.log(usuarios);
          return usuarios;
        } else {
          // console.log('Usuário não encontrado');
          return null; // ou algum outro valor padrão, caso nenhum usuário seja encontrado
        }
      }
    } catch (error) {
      // console.log('Erro ao carregar usuário:', error);
      throw error; // ou faça algo com o erro, dependendo da sua lógica de tratamento de erros
    }
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
