import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore,  } from '@angular/fire/compat/firestore';
import { Observable, map } from 'rxjs';
// import { AngularFirestore } from '@angular/fire/compat/firestore';
// import { AngularFireDatabase } from '@angular/fire/compat/database';



//----------------------------------------------------------------------------
import { getAuth, signInWithPopup, GoogleAuthProvider, UserCredential, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  } from "firebase/auth";
  import { DocumentData, getFirestore } from "firebase/firestore";
  import { doc, setDoc, getDoc } from "firebase/firestore";
  import { initializeApp } from "firebase/app";
  import { getAnalytics } from "firebase/analytics";
  import { environment} from 'src/environment/environment';

  const app = initializeApp(environment.firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);

  const IDConta = "OEypKDypuWrga9nXyTJ9";

//---------------------------------------------------------------------


@Injectable({
  providedIn: 'root'
})
export class LocalhostService {
  private serverUrl = 'https://us-central1-paperoff-agenda.cloudfunctions.net/api'; // Substitua pela URL do seu servidor

  constructor(private http: HttpClient,private firestore: AngularFirestore) { }

  static getIDConta(){
    return IDConta;
  }
  //
  getDados(collectionName: string): Observable<any[]> {
    return this.firestore.collection(collectionName).valueChanges({ idField: 'id' });
  }

  getDadosId(collectionName: string, id: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection(collectionName, ref => ref.where('id', '==', id)).get().toPromise()
        .then((snapshot) => {
          if (snapshot && !snapshot.empty) {
            // Retorna os dados do primeiro documento encontrado (assumindo que há apenas um)
            const doc = snapshot.docs[0];
            resolve(doc.data()); // Resolve a Promise com os dados obtidos
          } else {
            reject(new Error('Documento não encontrado')); // Rejeita a Promise se não houver dados
          }
        })
        .catch((error) => {
          reject(error); // Rejeita a Promise em caso de erro
        });
    });
  }

  postDados(collectionName: string, data: any): Promise<any> {
    data.id = this.firestore.createId();
    // return this.firestore.collection(collectionName).add(data);
    return this.firestore.collection(collectionName).doc(data.id).set(data);
  }

  postDadosId(collectionName: string, data: any): Promise<any> {
    data.id = this.firestore.createId();
    return this.firestore.collection(collectionName).doc(data.id).set(data);
  }

  postDadosComId(collectionName: string, data: any): Promise<any> {
    return this.firestore.collection(collectionName).doc(data.id).set(data);
  }


  postDadosNoId(collectionName: string, data: any): Promise<any> {
    return this.firestore.collection(collectionName).doc(data.id).set(data);
  }

  putDados(collectionName: string, documentId: string, data: any): Promise<void> {
    // console.log(collectionName,documentId,data)
    return this.firestore.collection(collectionName).doc(documentId).update(data);
  }

  putDadosQuery(collectionName: string, queryParams: { [key: string]: string | number | boolean } = {}, data: any): Promise<void> {
    return this.getSingleDocumentId(collectionName, queryParams).then(documentId => {
      if (documentId) {
        return this.firestore.collection(collectionName).doc(documentId).update(data);
      } else {
        throw new Error('Documento não encontrado com base nos parâmetros fornecidos.');
      }
    });
  }

  deleteDados(collectionName: string, queryParams: { [key: string]: any }): Promise<void> {
    return this.getSingleDocumentId(collectionName, queryParams).then(documentId => {
      if (documentId) {
        return this.firestore.collection(collectionName).doc(documentId).delete();
      } else {
        throw new Error('Documento não encontrado com base nos parâmetros fornecidos.');
      }
    });
  }

  // Método para excluir um documento com um ID específico
  deleteDadosId(collectionName: string, documentId: string): Promise<void> {
    return this.firestore.collection(collectionName).doc(documentId).delete();
  }

  private getSingleDocumentId(collectionName: string, queryParams: { [key: string]: any }): Promise<string | null> {
    return this.firestore.collection(collectionName, ref => {
      let query: firebase.default.firestore.CollectionReference | firebase.default.firestore.Query = ref;

      Object.entries(queryParams).forEach(([key, value]) => {
        query = query.where(key, '==', value);
      });

      return query;
    }).get().toPromise().then(querySnapshot => {
      if (querySnapshot) {
        const firstDoc = querySnapshot.docs[0];
        return firstDoc ? firstDoc.id : null;
      } else {
        return null;
      }
    });
  }

  p_getUsuarioId(colecao: string, id: any): Promise<any> {
    return this.firestore.collection(colecao).doc(id).get().toPromise()
      .then((doc) => {
        if (doc && doc.exists) {
          return doc.data();
        } else {
          throw new Error('Documento não encontrado');
        }
      })
      .catch((error) => {
        // console.error('Erro ao obter dados:', error);
        throw error;
      });
  }


  // New
  getDados_new(collectionName: string): Observable<any[]> {
    return this.firestore.collection(IDConta).doc(collectionName).collection(collectionName).valueChanges({ idField: 'id' });
  }

  postDados_new(collectionName: string, data: any): Promise<any> {
    data.id = this.firestore.createId();
    return this.firestore.collection(IDConta).doc(collectionName).collection(collectionName).add(data);
  }

  postDadosId_new(collectionName: string, data: any): Promise<any> {
    data.id = this.firestore.createId();
    return this.firestore.collection(IDConta).doc(collectionName).collection(collectionName).doc(data.id).set(data);
  }

  putDados_new(collectionName: string, documentId: string, data: any): Promise<void> {
    return this.firestore.collection(IDConta).doc(collectionName).collection(collectionName).doc(documentId).update(data);
  }

  putDadosQuery_new(collectionName: string, queryParams: { [key: string]: string | number | boolean } = {}, data: any): Promise<void> {
    return this.getSingleDocumentId_new(collectionName, queryParams).then(documentId => {
      if (documentId) {
        return this.firestore.collection(IDConta).doc(collectionName).collection(collectionName).doc(documentId).update(data);
      } else {
        throw new Error('Documento não encontrado com base nos parâmetros fornecidos.');
      }
    });
  }

  deleteDados_new(collectionName: string, queryParams: { [key: string]: any }): Promise<void> {
    return this.getSingleDocumentId_new(collectionName, queryParams).then(documentId => {
      if (documentId) {
        return this.firestore.collection(IDConta).doc(collectionName).collection(collectionName).doc(documentId).delete();
      } else {
        throw new Error('Documento não encontrado com base nos parâmetros fornecidos.');
      }
    });
  }

  deleteDadosId_new(collectionName: string, documentId: string): Promise<void> {
    return this.firestore.collection(IDConta).doc(collectionName).collection(collectionName).doc(documentId).delete();
  }

  private getSingleDocumentId_new(collectionName: string, queryParams: { [key: string]: any }): Promise<string | null> {
    return this.firestore.collection(IDConta).doc(collectionName).collection(collectionName, ref => {
      let query: firebase.default.firestore.CollectionReference | firebase.default.firestore.Query = ref;

      Object.entries(queryParams).forEach(([key, value]) => {
        query = query.where(key, '==', value);
      });

      return query;
    }).get().toPromise().then(querySnapshot => {
      if (querySnapshot) {
        const firstDoc = querySnapshot.docs[0];
        return firstDoc ? firstDoc.id : null;
      } else {
        return null;
      }
    });
  }

  async enviarEmailAgendamento(email: any, assunto: any, mensagem: any) {

    const url = "https://us-central1-logwise-dev-9cf97.cloudfunctions.net/api/enviar-email";

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const requestBody = {
      "email": `${email}`,
      "assunto": `${assunto}`,
      "mensagem": `${mensagem}`
    };

    try {
      const response = await this.http.post(url, requestBody, httpOptions).toPromise();
      // console.log(response);
      return response;
    } catch (error) {
      // console.error('Error:', error);
      throw error;
    }
  }

  // // Método para realizar uma solicitação GET para obter dados de uma coleção
  // getDados(collectionName: string): Observable<any[]> {
  //   const url = `${this.serverUrl}/get-dados/${collectionName}`;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //   });
  //   return this.http.get<any[]>(url, { headers: headers });
  // }

  // // getRooms2(): Observable<any[]> {

  // //   return this.db.list('notificacoes').snapshotChanges().pipe(
  // //     map(changes => {
  // //       return changes.map(c => c.payload.val());
  // //     })
  // //   );

  // // }


  // // Método para realizar uma solicitação POST para enviar dados para uma coleção
  // postDados(collectionName: string, data: any): Observable<any> {
  //   const url = `${this.serverUrl}/post-dados/${collectionName}`;
  //   return this.http.post<any>(url, data);
  // }

  // // Método para realizar uma solicitação PUT para atualizar dados em uma coleção
  // putDados(collectionName: string, queryParams: { [key: string]: string | number | boolean } = {}, data: any): Observable<any> {
  //   // Construir a string de URL com o nome da coleção
  //   const url = `${this.serverUrl}/put-dados/${collectionName}`;

  //   // Verificar se há parâmetros de consulta e adicionar à URL, se houver
  //   let params = new HttpParams();
  //   Object.entries(queryParams).forEach(([key, value]) => {
  //     // Converter o valor para string se necessário
  //     const stringValue = typeof value === 'number' ? value.toString() : value;
  //     params = params.append(key, stringValue);
  //   });

  //   // Realizar a solicitação PUT com os dados e parâmetros de consulta
  //   return this.http.put<any>(url, data, { params });
  // }

  // // Método para realizar uma solicitação DELETE para deletar dados de uma coleção
  // deleteDados(collectionName: string, queryParams: any): Observable<any> {
  //   const url = `${this.serverUrl}/del-dados/${collectionName}`;
  //   const params = new HttpParams({ fromObject: queryParams });
  //   return this.http.delete<any>(url, { params });
  // }
}
