import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { objUsuario } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { UsuariosService } from 'src/services/usuarios.services';

@Component({
  selector: 'app-editar-controle-acesso',
  templateUrl: './editar-controle-acesso.component.html',
  styleUrls: ['./editar-controle-acesso.component.css']
})
export class EditarControleAcessoComponent  implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;

  id: any;
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_edt = new objUsuario;

  rec_pwd_load = false;

  selectedFile: File | null = null;
  uploadProgress: number | null = null;

  showBtnMdl = false;
  empresaSigla: any | undefined;

  constructor(
    private route: ActivatedRoute,
    private usuarios: UsuariosService,
    private router: Router,
    private toast: ToastService,
    private authService: AuthService,
    private siglaService: SiglaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      }
    });
  }

  async ngOnInit() {

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    // await this.getUsuario()


    setTimeout(() => {

      for (let index = 1; index < 10; index++) {
        const element = new objUsuario;

        element.Id = index.toString();
        element.Usuario = `Usuário ${index}`;
        element.Nome = `Nome ${index}`;
        element.Email = `email${index}@gmail.com`;
        element.Tipo = "A";
        element.Criado = "01/01/2023 14:22";
        element.Atualizado = "01/09/2023 15:22";
        element.Foto = "https://picsum.photos/1080/720";

        this.usr_obj.push(element)

      }

      for (let index = 0; index < this.usr_obj.length; index++) {
        const usr = this.usr_obj[index];
        if(usr.Id == this.id){
          Object.assign(this.usr_edt, usr);
          // console.log(this.usr_edt)
        }
      }

    }, 1000);

  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async getUsuario(){
    const usuario = await this.usuarios.getUsuarioId(this.id,this.usr_token);
    // console.log(usuarios)
    this.usr_obj = usuario;

    for (let index = 0; index < this.usr_obj.length; index++) {
      const usr = this.usr_obj[index];
      if(usr.Id == this.id){
        Object.assign(this.usr_edt, usr);
        // console.log(this.usr_edt)
      }
    }
  }

  async updateUsuario(){

    let imageUrl = "";

    // console.log('i');

    try {
      // imageUrl = await this.upload();
      imageUrl = "";
      // Aqui você pode usar a URL da imagem para prosseguir com a outra parte do código
      // console.log('URL da imagem:', imageUrl);
      this.usr_edt.Foto = imageUrl;
    } catch (error) {
      // console.log('Erro ao fazer upload:', error);
    }

    // console.log('f');

    const update = await this.usuarios.updateUsuario(this.usr_edt,this.usr_token);
    const response = JSON.parse(update);

    if(response.Message){
      // console.log(response.Message);
      this.showError(response.Message)
    }else{
      const button = document.getElementById('btn-close-x');
      if (button) {
        button.click();
      }
      this.showSuccess(update.replaceAll("\"", ""))

      if(this.usr_edt.Id == this.usr_id && (this.usr_edt.Tipo != "M" && this.usr_edt.Tipo != "A")){
        this.authService.fazerLogout();
      }

    }
  }

  async delUsuario(){
    const delUsr = await this.usuarios.deleteUsuario(this.usr_edt,this.usr_token);
    const response = JSON.parse(delUsr);

    if(response.Message){
      // console.log(response.Message);
      this.showError(response.Message)

    }else{
      const button1 = document.getElementById('btn-close-x-del');

      if (button1) {
        button1.click();
      }

      this.showSuccess(delUsr.replaceAll("\"", ""));

      this.router.navigate(['/usuarios']);

      if(this.usr_edt.Id == this.usr_id){
        this.authService.fazerLogout();
      }
    }

  }

  async recuperarSenha(){
    this.rec_pwd_load = true;
    const usuario = await this.usuarios.recuperarSenha(this.usr_edt.Email);
    // console.log(usuario)
    if(usuario.Message){
      this.rec_pwd_load = false;
      this.showError(usuario.Message)
    }else if(usuario.error?.Message){
      this.rec_pwd_load = false;
      this.showError(usuario.error?.Message)
    }else{
      this.rec_pwd_load = false;
      this.showSuccess(usuario.replaceAll("\"", ""))
    }

  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile)
  }

  async upload(){
  // async upload(): Promise<string> {

    //   if (this.selectedFile) {
    //     const filePath = `usuarios/${this.usr_edt.Usuario}_${this.selectedFile.name}`;
    //     const fileRef = this.storage.ref(filePath);
    //     const task = this.storage.upload(filePath, this.selectedFile);

    //     task.percentageChanges().subscribe(progress => {
    //       this.uploadProgress = progress !== undefined ? progress : null;
    //     });

    //     await task.snapshotChanges().toPromise();

    //     const url = await fileRef.getDownloadURL().toPromise();
    //     console.log('URL da imagem:', url);

    //     return url;
    //   }

    //   throw new Error('Nenhum arquivo selecionado.');
    // }
  }

  deleteFileByUrl(fileUrl: string){
    // deleteFileByUrl(fileUrl: string): void {
    //   // Obtenha o caminho do arquivo a partir da URL
    //   const filePath = this.getFilePathFromUrl(fileUrl);

    //   this.fileInput.nativeElement.value = '';
    //   this.selectedFile = null;

    //   if (filePath) {
    //     const fileRef = this.storage.ref(filePath);
    //     fileRef.delete().toPromise()
    //       .then(() => {
    //         console.log('Arquivo excluído com sucesso!');
    //         this.showSuccess('Arquivo excluído com sucesso!')
    //         this.usr_edt.Foto = "";

    //         this.updateUsuario();
    //         const button = document.getElementById('btn-close-x-foto');
    //         if (button) {
    //           button.click();
    //         }
    //       })
    //       .catch((error) => {
    //         this.showError('Erro ao excluir o arquivo: '+ error)

            // console.error('Erro ao excluir o arquivo:', error);
    //       });
    //   } else {
    //     this.showError('URL inválida do arquivo.')

        // console.error('URL inválida do arquivo.');
    //   }
    // }
  }


  private getFilePathFromUrl(fileUrl: string): string | null {
    // Verifique se a URL contém o parâmetro 'o' que indica o caminho do arquivo
    const pathParam = 'o/';
    const startIndex = fileUrl.indexOf(pathParam);

    if (startIndex !== -1) {
      const endIndex = fileUrl.indexOf('?');
      const filePath = fileUrl.substring(startIndex + pathParam.length, endIndex !== -1 ? endIndex : undefined);
      return decodeURIComponent(filePath);
    }

    return null;
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
