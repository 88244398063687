import { Injectable } from '@angular/core';
import { Links } from '../class/links'

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  constructor() { }

  getLinksAgenda(): Links[] {
    let agenda: Links[] = [];

    let card_agenda = new Links();
    card_agenda.title = "Agendas";
    card_agenda.subTitle = "Agendas";
    card_agenda.rota = "/agendas";
    card_agenda.tipo = "agenda";

    agenda.push(card_agenda);

    // let card_agenda_endereco = new Links();
    // card_agenda_endereco.title = "Endereços";
    // card_agenda_endereco.subTitle = "Endereços";
    // card_agenda_endereco.rota = "/agendas/enderecos";
    // card_agenda_endereco.tipo = "agenda-enderecos";

    // agenda.push(card_agenda_endereco);

    let card_agenda_servico = new Links();
    card_agenda_servico.title = "Serviços";
    card_agenda_servico.subTitle = "Serviços";
    card_agenda_servico.rota = "/agendas/servicos";
    card_agenda_servico.tipo = "agenda-servicos";

    agenda.push(card_agenda_servico);

    // let card_agenda_controle_acesso = new Links();
    // card_agenda_controle_acesso.title = "Controle de Acesso";
    // card_agenda_controle_acesso.subTitle = "Controle de Acesso";
    // card_agenda_controle_acesso.rota = "/agendas/controle-acesso";
    // card_agenda_controle_acesso.tipo = "agenda-controle-acesso";

    // agenda.push(card_agenda_controle_acesso);

    // let card_agenda_regras = new Links();
    // card_agenda_regras.title = "Regras";
    // card_agenda_regras.subTitle = "Regras";
    // card_agenda_regras.rota = "/agendas/regras";
    // card_agenda_regras.tipo = "agenda-regras";

    // agenda.push(card_agenda_regras);

    return agenda;
  }
}
