import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Recurso } from 'src/class/recurso';
import { newUsuario } from 'src/class/usuario';
import { CepService } from 'src/services/cep.service';
import { RecursoService } from 'src/services/component/recurso.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { DataService } from 'src/services/data.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-criar-recurso',
  templateUrl: './criar-recurso.component.html',
  styleUrls: ['./criar-recurso.component.css']
})
export class CriarRecursoComponent {
  @ViewChild('alertRecursoModal', { static: false }) alertRecursoModal!: ElementRef;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  recurso: Recurso = new Recurso();
  recursos: Recurso[] = [];
  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private recursoService: RecursoService,
    private route: ActivatedRoute,
    private siglaService: SiglaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      }
    });
  }

  async ngOnInit() {
    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Recurso;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    // Agora você pode usar o ID como quiser

  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async criarRecurso(){
    this.recurso.nome.status = this.validatorService.criarValidacao(this.recurso.nome.valor, this.recurso.nome.validacoesCustomizadas);
    this.recurso.descricao.status = this.validatorService.criarValidacao(this.recurso.descricao.valor, this.recurso.descricao.validacoesCustomizadas);

    if(this.recurso.nome.status.invalid ||
      this.recurso.descricao.status.invalid
    ){
      this.abrirModal(this.alertRecursoModal.nativeElement);
    }else{

      this.recurso.id = await this.contadorIndexService.contadorIndex();
      let new_recurso  = new Recurso();
      new_recurso = this.recurso;

      new_recurso.criado = new Date();
      new_recurso.atualizado = new Date();

      const recurso = JSON.stringify(new_recurso);
      const res = await this.recursoService.criarRecurso(JSON.parse(recurso), this.empresaSigla)
      // console.log(res)


      this.recurso = new Recurso();
      this.router.navigate([`${this.empresaSigla}/recursos`]);
    }
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
