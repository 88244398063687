import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { GrupoUsuario } from 'src/class/grupo-usuario';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';

@Injectable({
  providedIn: 'root'
})
export class GrupoUsuarioService {

  private gruposUsuario: GrupoUsuario[] = [];
  private grupoUsuarioSubject = new BehaviorSubject<GrupoUsuario[]>(this.gruposUsuario);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService
  ) {
    this.gruposUsuario = [];
    // this.getGrupoUsuario();
  }



  async criarGrupoUsuario(grupoUsuario: GrupoUsuario, empresaSigla: any) {
    // console.log("criarGrupoUsuario", tipo);
    grupoUsuario.descricao.status = null;
    grupoUsuario.nome.status = null;

    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarGrupoUsuario', conta);

      const response = await this.localhostService.postDadosId(`${conta.id}_grupos_usuarios`, grupoUsuario);
      // console.log('GrupoUsuario criado com sucesso:', response);

      // Atualize a lista local de agendas
      this.gruposUsuario.push(grupoUsuario);
      this.grupoUsuarioSubject.next(this.gruposUsuario);

      // Notifique o usuário sobre o sucesso
      this.showSuccess(`${grupoUsuario.nome.valor}, Grupo de usuários criado.`);

      // Nova notificação
      // 1 - empresaSigla: any,
      // 2 - titulo: string,
      // 3 - texto: string,
      // 4 - rotaSemParamNome: string,
      // 5 - rotaSemParamListar: string,
      // 6 - rotaComParamNome: string = "",
      // 7 - rotaComParamCaminho: string = "",
      // 8 - rotaComParamParam: string = "",
      // 9 - linkExternoNome: string = "",
      // 10 - linkExternoURL: string = "",
      // 11 - idReferencia: string,
      // 12 - tipo: string,
      // 13 - idResponsavel: string,
      // 14 - nomeResponsavel: string

      const usr = await this.userService.carregarUsuario(empresaSigla)

      this.notificacaoService.criarNotificacao(
        empresaSigla,
        `${grupoUsuario.nome.valor}`,
        `Grupo de usuários criado.`,
        'Listar Grupos Usuários',
        'grupos-usuarios',
        'Editar Grupo Usuários',
        `grupos-usuarios/grupo`,
        grupoUsuario.id?.toString(),
        '',
        '',
        grupoUsuario.id!.toString(),
        `Grupo Usuários`,
        `${usr.id}`,
        `${usr.displayName.valor}`
      )

      return true;
    } catch (error) {
      // console.error('Erro ao criar tipo:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllGrupoUsuario() {
    return this.grupoUsuarioSubject.asObservable().pipe(
      map(gruposUsuario => gruposUsuario)
    );
  }

  async getGrupoUsuario(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getGrupoUsuario', conta);

      this.localhostService.getDados(`${conta.id}_grupos_usuarios`).subscribe(
        (tipoFromServer: any[]) => {
          if (tipoFromServer) {
            // Mapeia os dados para a estrutura desejada
            const tipoArray: GrupoUsuario[] = tipoFromServer.map(tipo => plainToClass(GrupoUsuario, tipo));

            // console.log(tipoArray);

            this.gruposUsuario = tipoArray;
            this.grupoUsuarioSubject.next(this.gruposUsuario);
            // this.updateLocalStorage();
          } else {
            this.gruposUsuario = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter gruposUsuario:', error);
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao obter gruposUsuario:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });


  }

  private updateLocalStorage() {
    localStorage.setItem("GrupoUsuario", JSON.stringify(this.gruposUsuario));
    DataService.set("GrupoUsuario", this.gruposUsuario);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
