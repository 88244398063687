<div class="logo-details">

  <!-- <i class="bi bi-app-indicator"></i> -->

  <div style=" height: 50px; min-width: 70px; text-align: center; line-height: 50px; ">
    <svg
      width="40"
      height="40"
      viewBox="0 0 180 180"
      version="1.1"
      id="svg1"
      inkscape:version="1.3 (0e150ed6c4, 2023-07-21)"
      sodipodi:docname="logo-logwise.svg"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg" style=" position: relative; left: 3px; ">
      <defs id="defs1" />
        <g
          inkscape:label="Camada 1"
          inkscape:groupmode="layer"
          id="layer1">
          <g
            id="g1"
            transform="translate(-3.1499377,-2.3223201)">
            <path
              id="path1"
              style="fill:var(--lw-cor-p);fill-opacity:1;stroke-width:1.34008"
              d="M 27.306309,54.662884 92.905506,16.790039 110.70327,27.065873 45.10312,64.938886 V 120.1336 l 41.998014,24.24667 -2.81e-4,20.55284 -59.7946,-34.52319 z"
              sodipodi:nodetypes="ccccccccc" />
            <path
              id="path2"
              style="fill:var(--lw-cor-p);fill-opacity:1;stroke-width:1.34008"
              d="m 158.50174,130.40869 -65.596338,37.87302 0.01183,-75.938455 -42.11568,-24.114095 18.356081,-10.595195 42.100417,24.306073 2.4e-4,55.173132 5.70748,-3.29403 0.003,-55.176142 -42.101644,-24.306683 18.197234,-10.505466 42.10126,24.305285 4.4e-4,55.173476 5.52006,-3.18647 2.2e-4,-55.171906 -42.102145,-24.307631 17.817645,-10.286395 42.09784,24.30618 z"
              sodipodi:nodetypes="ccccccccccccccccccc" />
          </g>
        </g>
    </svg>
  </div>

  <span class="logo_name" style="font-family: 'Kanit', sans-serif;font-weight: bold;margin-top: 3px;">LOGWISE</span>

  <div style="display: flex;justify-content: space-between;width: 100%;">
    <i class="bi bi-toggle2-off toogle-fix" style="color: var(--cor-secundaria) !important;" (click)="togglePinSidebar()" *ngIf="!app.isSidebarPinned"></i>

    <i class="bi bi-toggle2-on toogle-fix" style="color: var(--lw-cor-p) !important;" (click)="togglePinSidebar()" *ngIf="app.isSidebarPinned"></i>
  </div>

</div>
<ul class="nav-links">
  <li>
    <a (click)="routerLink('home')" [ngClass]="{ 'menu-active': isLinkActive('/home') }" (click)="toggleSidebar()">
      <i class='bi bi-grid'></i>
      <span class="link_name">Dashboard</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('home')" [ngClass]="{ 'menu-active': isLinkActive('/home') }"  (click)="toggleSidebar()">Dashboard</a>
      </li>
    </ul>
  </li>
  <li>
    <div class="iocn-link" (click)="toggleSubMenu('agendas');" >
      <div href="javascript:void(0)" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }">
        <i class='bi bi-journal-bookmark-fill'></i>
        <i class="bi bi-chevron-compact-right" *ngIf="app.isSidebarClosed" style="position: absolute;left: 25px;opacity: 0.6;"></i>
        <span class="link_name">Agenda</span>
      </div>
      <i class='bi bi-chevron-down arrow' [ngClass]="{ 'rotate': isSubMenuOpen('agendas') }"></i>
    </div>
    <ul class="sub-menu" *ngIf="isSubMenuOpen('agendas')" >
      <li *ngIf="!app.isSidebarClosed">
        <a class="link_name" href="javascript:void(0)" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }">Agenda</a>
      </li>
      <!-- <li>
        <a (click)="routerLink('agendas')" [href]="['/agendas']" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }" (click)="toggleSidebar()">Resumo</a>
      </li> -->
      <li>
        <a (click)="routerLink('agendas')" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }" (click)="toggleSidebar()" style="padding: 14px 0px 7px 70px;">Agendas</a>
      </li>
      <!-- <li>
        <a (click)="routerLink('agendas/enderecos')" [href]="['/agendas/enderecos']" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }" (click)="toggleSidebar()">Endereços</a>
      </li> -->
      <li>
        <a (click)="routerLink('agendas/servicos')" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }" (click)="toggleSidebar()" style="/*padding: 7px 0px 7px 70px;*/padding: 7px 0px 14px 70px;">Serviços</a>
      </li>
      <li>
        <a (click)="routerLink('agendas/controle-acesso')" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }" (click)="toggleSidebar()" style="padding: 7px 0px 7px 70px; display: none;">Controle de Acesso</a>
      </li>
      <li>
        <a (click)="routerLink('agendas/regras')" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }" (click)="toggleSidebar()" style="padding: 7px 0px 14px 70px; display: none">Regras</a>
      </li>
    </ul>
  </li>
  <!-- <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('agendas')" [href]="['/agendas']" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }" (click)="toggleSidebar()">
      <i class='bi bi-journal-bookmark-fill'></i>
      <span class="link_name">Agenda</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('agendas')" [href]="['/agendas']" [ngClass]="{ 'menu-active': isLinkActive('/agendas') }"  (click)="toggleSidebar()">Agenda</a>
      </li>
    </ul>
  </li> -->
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('agendamentos')" [ngClass]="{ 'menu-active': isLinkActive('/agendamentos') }" (click)="toggleSidebar()">
      <i class='bi bi-clock'></i>
      <span class="link_name">Agendamentos</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('agendamentos')" [ngClass]="{ 'menu-active': isLinkActive('/agendamentos') }"  (click)="toggleSidebar()">Agendamentos</a>
      </li>
    </ul>
  </li>
  <li>
    <div class="iocn-link" (click)="toggleSubMenu('checkin');">
      <div href="javascript:void(0)" [ngClass]="{ 'menu-active': isLinkActive('/checkin') }">
        <i class='bi bi-clipboard-check'></i>
        <i class="bi bi-chevron-compact-right" *ngIf="app.isSidebarClosed" style="position: absolute;left: 25px;opacity: 0.6;"></i>
        <span class="link_name">Checkin</span>
      </div>
      <i class='bi bi-chevron-down arrow' [ngClass]="{ 'rotate': isSubMenuOpen('checkin') }"></i>
    </div>
    <ul class="sub-menu" *ngIf="isSubMenuOpen('checkin')">
      <li *ngIf="!app.isSidebarClosed">
        <a class="link_name" href="javascript:void(0)" [ngClass]="{ 'menu-active': isLinkActive('/checkin') }">Checkin</a>
      </li>
      <li>
        <a (click)="routerLink('checkin')" [ngClass]="{ 'menu-active': isLinkActive('/checkin') }" (click)="toggleSidebar()" style="padding: 7px 0px 7px 70px;">Kanban</a>
      </li>
      <li>
        <a (click)="routerLink('checkin/aguardando')" [ngClass]="{ 'menu-active': isLinkActive('/checkin') }" (click)="toggleSidebar()" style="padding: 7px 0px 7px 70px;">Aguardando</a>
      </li>
      <li>
        <a (click)="routerLink('checkin/andamento')" [ngClass]="{ 'menu-active': isLinkActive('/checkin') }" (click)="toggleSidebar()" style="padding: 7px 0px 7px 70px;">Em andamento</a>
      </li>
      <li>
        <a (click)="routerLink('checkin/realizados')" [ngClass]="{ 'menu-active': isLinkActive('/checkin') }" (click)="toggleSidebar()" style="padding: 7px 0px 14px 70px;">Realizados</a>
      </li>
    </ul>
  </li>
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'" style="display: none">
    <a (click)="routerLink('relatorios')" [ngClass]="{ 'menu-active': isLinkActive('/relatorios') }" (click)="toggleSidebar()">
      <i class='bi bi-bar-chart-line'></i>
      <span class="link_name">Relatórios</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('relatorios')" [ngClass]="{ 'menu-active': isLinkActive('/relatorios') }"  (click)="toggleSidebar()">Relatórios</a>
      </li>
    </ul>
  </li>
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('tipos')" [ngClass]="{ 'menu-active': isLinkActive('/tipos') || isLinkActive('/tipos') }" (click)="toggleSidebar()">
      <i class='bi bi-layers'></i>
      <span class="link_name">Tipos</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('tipos')" [ngClass]="{ 'menu-active': isLinkActive('/tipos') || isLinkActive('/tipos') }" (click)="toggleSidebar()">Tipos</a>
      </li>
    </ul>
  </li>
  <!-- <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a [routerLink]="['/empresa']" [href]="['/empresa']" [ngClass]="{ 'menu-active': isLinkActive('/empresa') || isLinkActive('/empresa') }" (click)="toggleSidebar()">
      <i class="bi bi-building-fill-gear"></i>
      <span class="link_name">Empresa</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" [routerLink]="['/empresa']" [href]="['/empresa']" [ngClass]="{ 'menu-active': isLinkActive('/empresa') || isLinkActive('/empresa') }"  (click)="toggleSidebar()">Empresa</a>
      </li>
    </ul>
  </li> -->
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('usuarios')" [ngClass]="{ 'menu-active': isLinkActive('/usuarios') || isLinkActive('/usuario') }" (click)="toggleSidebar()">
      <i class='bi bi-people-fill'></i>
      <span class="link_name">Usuários</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('usuarios')" [ngClass]="{ 'menu-active': isLinkActive('/usuarios') || isLinkActive('/usuario') }"  (click)="toggleSidebar()">Usuários</a>
      </li>
    </ul>
  </li>
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('grupos-usuarios')" [ngClass]="{ 'menu-active': isLinkActive('/grupos-usuarios') || isLinkActive('/grupos-usuarios') }" (click)="toggleSidebar()">
      <i class='bi bi-person-fill-lock'></i>
      <span class="link_name">Grupos Usuários</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('grupos-usuarios')" [ngClass]="{ 'menu-active': isLinkActive('/grupos-usuarios') || isLinkActive('/grupos-usuarios') }"  (click)="toggleSidebar()">Grupos Usuários</a>
      </li>
    </ul>
  </li>
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('grupos-agendas')" [ngClass]="{ 'menu-active': isLinkActive('/grupos-agendas') || isLinkActive('/grupos-agendas') }" (click)="toggleSidebar()">
      <i class='bi bi-lock-fill'></i>
      <span class="link_name">Grupos Agendas</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('grupos-agendas')" [ngClass]="{ 'menu-active': isLinkActive('/grupos-agendas') || isLinkActive('/grupos-agendas') }"  (click)="toggleSidebar()">Grupos Agendas</a>
      </li>
    </ul>
  </li>
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('slots')" [ngClass]="{ 'menu-active': isLinkActive('/slots') || isLinkActive('/slots') }" (click)="toggleSidebar()">
      <i class='bi bi-sliders'></i>
      <span class="link_name">Slots</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('slots')" [ngClass]="{ 'menu-active': isLinkActive('/slots') || isLinkActive('/slots') }"  (click)="toggleSidebar()">Slot</a>
      </li>
    </ul>
  </li>
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('formularios')" [ngClass]="{ 'menu-active': isLinkActive('/formularios') }" (click)="toggleSidebar()">
      <i class='bi bi-clipboard2-fill'></i>
      <span class="link_name">Formulários</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('formularios')" [ngClass]="{ 'menu-active': isLinkActive('/formularios') }"  (click)="toggleSidebar()">Formulários</a>
      </li>
    </ul>
  </li>
  <li>
    <div class="iocn-link" (click)="toggleSubMenu('configuracao');">
      <div href="javascript:void(0)" [ngClass]="{ 'menu-active': isLinkActive('/configuracao') }">
        <i class='bi bi-gear-fill'></i>
        <i class="bi bi-chevron-compact-right" *ngIf="app.isSidebarClosed" style="position: absolute;left: 25px;opacity: 0.6;"></i>
        <span class="link_name">Configuração</span>
      </div>
      <i class='bi bi-chevron-down arrow' [ngClass]="{ 'rotate': isSubMenuOpen('configuracao') }"></i>
    </div>
    <ul class="sub-menu" *ngIf="isSubMenuOpen('configuracao')">
      <li *ngIf="!app.isSidebarClosed">
        <a class="link_name" href="javascript:void(0)" [ngClass]="{ 'menu-active': isLinkActive('/configuracao') }">Configuração</a>
      </li>
      <li>
        <a (click)="routerLink('configuracao/tela-agendamento')" [ngClass]="{ 'menu-active': isLinkActive('/configuracao/tela-agendamento') }" (click)="toggleSidebar()" style="padding: 14px 0px 14px 70px;">Tela de Agendamento</a>
      </li>
      <!-- <li>
        <a [routerLink]="['/configuracao/sistema']" [href]="['/configuracao/sistema']" [ngClass]="{ 'menu-active': isLinkActive('/configuracao/sistema') }" (click)="toggleSidebar()" style="padding: 7px 0px 7px 70px;">Sistema</a>
      </li> -->
    </ul>
  </li>
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('notificacoes')" [ngClass]="{ 'menu-active': isLinkActive('/notificacoes') || isLinkActive('/notificacoes') }" (click)="toggleSidebar()">
      <i class='bi bi-bell' style="cursor: pointer;position: relative;" title="notifications">
        <span *ngIf="unreadCount > 0 | notificacaoBadge as unreadCount" class="position-absolute translate-middle badge rounded-pill background-cor-danger text-cor-branco" style="font-size: 10px;top: 25% !important;left: 65% !important;" >
          {{unreadCount}}
        </span>
      </i>
      <span class="link_name">Notificações</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('notificacoes')" [ngClass]="{ 'menu-active': isLinkActive('/notificacoes') || isLinkActive('/notificacoes') }"  (click)="toggleSidebar()">Notificações</a>
      </li>
    </ul>
  </li>
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('contas')" [ngClass]="{ 'menu-active': isLinkActive('/contas') }" (click)="toggleSidebar()">
      <i class='bi bi-person-vcard-fill'></i>
      <span class="link_name">Contas</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('contas')" [ngClass]="{ 'menu-active': isLinkActive('/contas') }"  (click)="toggleSidebar()">Contas</a>
      </li>
    </ul>
  </li>
  <li *ngIf="usr_tipo == 'M' || usr_tipo == 'A'">
    <a (click)="routerLink('recursos')" [ngClass]="{ 'menu-active': isLinkActive('/recursos') }" (click)="toggleSidebar()">
      <i class='bi bi-list-task'></i>
      <span class="link_name">Recursos</span>
    </a>
    <ul class="sub-menu blank">
      <li>
        <a class="link_name" (click)="routerLink('recursos')" [ngClass]="{ 'menu-active': isLinkActive('/recursos') }"  (click)="toggleSidebar()">Recursos</a>
      </li>
    </ul>
  </li>
<!--
  <li>

    <div class="profile-details">
      <div class="profile-content" (click)="fazerLogout()">
        <i class='bi bi-box-arrow-left menu-i' ></i>
      </div>
      <div class="name-job">
        <div class="profile_name">{{usr_nome}}</div>
        <div class="job">{{usr_tipo_string}}</div>
      </div>
    </div>
  </li> -->
</ul>
