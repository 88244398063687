<div class="pb-4">

  <div class="container text-center min-width p-0">

    <!-- Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('formularios')" class="text-padrao">Formulários</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('formularios')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>


    <!-- Tipo -->
    <div class="row p-0 m-0">
      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Novo Formulário
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Formulário -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">
          <div class="row p-0 m-0">
            <!-- form.titulo -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="form.titulo.valor"
                  [label]="form.titulo.title"
                  [tooltip]="form.titulo.info"
                  [value]="form.titulo.valor"
                  [isInvalid]="form.titulo.status?.invalid"
                  [AgStatus]="form.titulo.status"
                  [id]="'form_titulo'"
                  AgPlaceholder="{{form.titulo.placeholder}}"
                  AgInputType="{{form.titulo.inputType}}"
                  AgMaxlength="{{form.titulo.maxlength}}"
                  AgInputMode="{{form.titulo.inputMode}}"
                  (ngModelChange)="form.mascara(form.titulo.mascara, 'titulo');"
                  (change)="form.validarInput('titulo');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- form.descricao -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-textarea-field class="w-100"
                  [(ngModel)]="form.descricao.valor"
                  [label]="form.descricao.title"
                  [tooltip]="form.descricao.info"
                  [value]="form.descricao.valor"
                  [isInvalid]="form.descricao.status?.invalid"
                  [AgStatus]="form.descricao.status"
                  [id]="'form_descricao'"
                  AgPlaceholder="{{form.descricao.placeholder}}"
                  AgInputType="{{form.descricao.inputType}}"
                  AgMaxlength="{{form.descricao.maxlength}}"
                  AgInputMode="{{form.descricao.inputMode}}"
                  (ngModelChange)="form.mascara(form.descricao.mascara, 'descricao');"
                  (change)="form.validarInput('descricao');"
                  >
                </app-ag-textarea-field>
              </div>
            </div>

            <!-- form.descricao -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-ng-select class="w-100" [(ngModel)]="form.tipoFormulario.valor"
                   [label]="form.tipoFormulario.title"
                   [id]="'form_tipo'"
                   [tooltip]="form.tipoFormulario.placeholder ? form.tipoFormulario.placeholder : ''"
                   [AgPlaceholder]="form.tipoFormulario.placeholder ? form.tipoFormulario.placeholder : ''"
                   [isInvalid]="form.tipoFormulario.status?.invalid"
                   [AgStatus]="form.tipoFormulario.status"
                   [AgOptions]="formularioTipoArray"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   AgSelectType="checkbox"
                   (change)="form.validarInput('tipoFormulario')">
                </app-ag-ng-select>
              </div>
            </div>

            <!-- tipo.status -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="form.status.valor"
                [label]="form.status.title"
                [tooltip]="form.status.info"
                [value]="form.status.valor"
                [id]="'form_status'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>
        </div>
      </div>

      <!-- Campo Formulário -->
      <div class="row p-0 m-0">
        <div class="row p-0 m-0">
          <div class="min-width col-lg mb-3">
            <div class="box-gen-card">
              <div class="row p-0 m-0">
                <div class="col-lg-12 reset-padding-mobile">
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Campos do formulário </h3>
                      <p class="p-0 m-0 text-cor-padrao text-start w-100">Defina as questões do formulário.</p>
                    </div>
                    <div (click)="openNovaPerguntaFormulario()" class="ag-icon-click">
                      <i class="bi bi-plus-circle-fill"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="existeCamposDinamicos()" style="height: 10px;">

              </div>
              <!--  -->

              <div class="row d-flex flex-wrap flex-column justify-content-center p-0 m-0 ">
                <div *ngFor="let cpForm of getKeysCamposDinamicos(form.camposDinamicos)" class="row col-12 m-0 reset-padding-mobile">
                  <div class="row col-lg-12 p-2 m-0 ag-mt-5 text-cor-padrao" style="border: var(--border-padrao);position: relative;border-radius: 5px;">

                    <div class="col-lg-3 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2">

                      <p class="p-0 m-0">
                        Pergunta:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{form.camposDinamicos[cpForm].title}}</span>
                      </p>

                      <p class="p-0 m-0 mt-2">
                        Tipo de resposta:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{form.camposDinamicos[cpForm].tipoPergunta}}</span>
                      </p>
                    </div>

                    <div class="col-lg-2 col-md-3 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2" style=" text-align: left; ">

                      <p class="p-0 m-0">
                        Campo obrigatório:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{form.camposDinamicos[cpForm].validacoesCustomizadas.includes('required') ? 'Sim' : 'Não'}}</span>
                      </p>

                      <p class="p-0 m-0 mt-2">
                        Tipo do campo:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{form.camposDinamicos[cpForm].tipoCampo ? form.camposDinamicos[cpForm].tipoCampo : form.camposDinamicos[cpForm].inputType}}</span>
                      </p>
                    </div>

                    <div class="col-lg-3 col-md-6 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2" style=" text-align: left; ">

                      <p class="p-0 m-0">
                        Máscara:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{form.camposDinamicos[cpForm].mascara ? form.camposDinamicos[cpForm].mascara : '-'}}</span>
                      </p>

                      <p class="p-0 m-0 mt-2">
                        Tamanho máximo do campo:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{form.camposDinamicos[cpForm].maxlength != '' ? form.camposDinamicos[cpForm].maxlength : '-'}}</span>
                      </p>
                    </div>

                    <div class="col-lg-2 col-md-3 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2" style=" text-align: left; ">
                      <p class="p-0 m-0">
                        Ordem:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{form.camposDinamicos[cpForm].ordem}}</span>
                      </p>

                      <p class="p-0 m-0 mt-2">
                        Total:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{form.camposDinamicos[cpForm].ordem}} de {{tamanhoCamposDinamicos()}}</span>
                      </p>
                    </div>


                    <div class="col-lg-2 text-left d-flex flex-row flex-wrap justify-content-between align-items-center pb-2 pt-2">

                      <button type="button" class="btn btn-outline-primary-s" (click)="openEditarPerguntaFormulario(form.camposDinamicos[cpForm])" style="display: flex;justify-content: space-around;align-items: center;" >
                        <i class="bi bi-pencil-fill"></i> Editar
                      </button>

                      <button type="button" class="btn btn-outline-danger" (click)="deletarPerguntaFormulario(form.camposDinamicos[cpForm].id)" style="display: flex;justify-content: space-around;align-items: center;" >
                        <i class="bi bi-trash3-fill"></i> Excluir
                      </button>

                    </div>

                  </div>
                </div>
              </div>
              <!--  -->
            </div>
          </div>
        </div>
      </div>

      <!-- GrupoAgenda -->
      <div class="row p-0 m-0">
        <div class="row p-0 m-0">
          <div class="min-width col-lg mb-3">
            <div class="box-gen-card">
              <div class="row p-0 m-0">
                <div class="col-lg-12 reset-padding-mobile">
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100 mt-2 mb-2" style=" font-weight: 500; ">
                    Agendas Disponíveis
                  </h3>
                </div>

                <div class="col-lg-12 reset-padding-mobile">

                  <!-- <h3 class="p-0 m-0 text-cor-padrao text-start w-100 ag-pl-10">Recursos Disponíveis</h3> -->
                  <mat-selection-list #agendasSelecionadas>
                    <mat-list-option *ngFor="let agenda of agendas" [value]="agenda" class="p-0 m-0" style="border-top: var(--border-padrao)">
                      {{agenda.nome.valor}} - <span style=" font-size: 13px; font-style: italic; ">{{agenda.subNome.valor}}</span>
                      <!-- <div style="display: flex;flex-diagendation: column;flex-wrap: wrap;align-items: flex-start;justify-content: center;">
                        <span>{{agenda.descricao.valor}}</span>
                      </div> -->
                    </mat-list-option>
                  </mat-selection-list>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <!-- <div class="w-100 mb-3" style="text-align: left; display: flex; align-items: center; ">
            <mat-checkbox [(ngModel)]="tipo.status.valor" [color]="'radial-primary'">
              {{tipo.status.title}}
            </mat-checkbox>
            <span mat-raised-button #status="matTooltip" [matTooltip]="tipo.status.info" matTooltipPosition="right" (click)="status.show()" class="tooltip-input-icon">
              <i class="bi bi-info-circle-fill"></i>
            </span>
          </div> -->

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="criarFormulario()">Criar Formulário</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('formularios')" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<!-- alert Tipo Modal -->
<div class="modal" id="alertFormularioModal" #alertFormularioModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="form.titulo.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ form.titulo.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ form.titulo.info }}</p>
          </div>

          <div *ngIf="form.descricao.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ form.descricao.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ form.descricao.info }}</p>
          </div>

          <div *ngIf="form.tipoFormulario.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ form.tipoFormulario.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ form.tipoFormulario.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>

<!-- Adicionar Pergunta Formulário -->
<div class="modal" id="novaPerguntaFormulario" #novaPerguntaFormulario data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Adicionar campo formulário</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" (click)="closeModalFormulario(novaPerguntaFormulario)" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >

        <div class="ag-pt-10 ag-pl-5 ag-pr-5 mb-3">
          <div class="row p-0 m-0">

            <div class="col-lg-12">
              <app-ag-textarea-field
                [(ngModel)]="formularioPergunta.pergunta.valor"
                [label]="formularioPergunta.pergunta.title"
                [tooltip]="formularioPergunta.pergunta.info"
                [value]="formularioPergunta.pergunta.valor"
                [isInvalid]="formularioPergunta.pergunta.status?.invalid"
                [AgStatus]="formularioPergunta.pergunta.status"
                [id]="'formulario_pergunta'"
                AgPlaceholder="{{formularioPergunta.pergunta.placeholder}}"
                AgInputType="{{formularioPergunta.pergunta.inputType}}"
                AgMaxlength="{{formularioPergunta.pergunta.maxlength}}"
                AgInputMode="{{formularioPergunta.pergunta.inputMode}}"
                (change)="formularioPergunta.validarInput('pergunta')"
                >
              </app-ag-textarea-field>
            </div>

            <div class="col-lg-12">
              <app-ag-input-field
                [(ngModel)]="formularioPergunta.placeholder.valor"
                [label]="formularioPergunta.placeholder.title"
                [tooltip]="formularioPergunta.placeholder.info"
                [value]="formularioPergunta.placeholder.valor"
                [isInvalid]="formularioPergunta.placeholder.status?.invalid"
                [AgStatus]="formularioPergunta.placeholder.status"
                [id]="'formulario_placeholder'"
                AgPlaceholder="{{formularioPergunta.placeholder.placeholder}}"
                AgInputType="{{formularioPergunta.placeholder.inputType}}"
                AgMaxlength="{{formularioPergunta.placeholder.maxlength}}"
                AgInputMode="{{formularioPergunta.placeholder.inputMode}}"
                (change)="formularioPergunta.validarInput('placeholder')"
                >
              </app-ag-input-field>
            </div>

            <div class="col-lg-12">
              <app-ag-mat-checkbox
                [(ngModel)]="formularioPergunta.obrigatorio.valor"
                [label]="formularioPergunta.obrigatorio.title"
                [tooltip]="formularioPergunta.obrigatorio.info"
                [value]="formularioPergunta.obrigatorio.valor"
                [id]="'formulario_obrigatorio'"
                >
              </app-ag-mat-checkbox>
            </div>

            <div class="col-lg-12">
              <app-ag-ng-select [(ngModel)]="formularioPergunta.tipoPergunta.valor"
                   [label]="formularioPergunta.tipoPergunta.title"
                   [id]="'formulario_tipoPergunta'"
                   [tooltip]="formularioPergunta.tipoPergunta.placeholder ? formularioPergunta.tipoPergunta.placeholder : ''"
                   [AgPlaceholder]="formularioPergunta.tipoPergunta.placeholder ? formularioPergunta.tipoPergunta.placeholder : ''"
                   [isInvalid]="formularioPergunta.tipoPergunta.status?.invalid"
                   [AgStatus]="formularioPergunta.tipoPergunta.status"
                   [AgOptions]="formularioTipoPerguntaArray"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   AgSelectType="checkbox"
                   [AgAppendTo]="'#novaPerguntaFormulario'"
                   (change)="formularioPergunta.validarInput('tipoPergunta')"
                   (ngModelChange)="changeTipoPergunta(formularioPergunta.tipoPergunta.valor)">
              </app-ag-ng-select>
            </div>

            <div *ngIf="formularioConfigInput">

              <div class="col-lg-12 mt-3" *ngIf="formularioConfigInput.tamanhoInput">
                <app-ag-input-field
                  [(ngModel)]="formularioConfigInput.tamanhoInput.valor"
                  [label]="formularioConfigInput.tamanhoInput.title"
                  [tooltip]="formularioConfigInput.tamanhoInput.info"
                  [value]="formularioConfigInput.tamanhoInput.valor"
                  [isInvalid]="formularioConfigInput.tamanhoInput.status?.invalid"
                  [AgStatus]="formularioConfigInput.tamanhoInput.status"
                  [id]="'formulario_tamanhoInput'"
                  AgPlaceholder="{{formularioConfigInput.tamanhoInput.placeholder}}"
                  AgInputType="{{formularioConfigInput.tamanhoInput.inputType}}"
                  AgMaxlength="{{formularioConfigInput.tamanhoInput.maxlength}}"
                  AgInputMode="{{formularioConfigInput.tamanhoInput.inputMode}}"
                  (ngModelChange)="formularioConfigInput.mascara(formularioConfigInput.tamanhoInput.mascara, 'tamanhoInput')"
                  (change)="formularioConfigInput.validarInput('tamanhoInput')"
                  >
                </app-ag-input-field>
              </div>

              <div class="col-lg-12 mt-3" *ngIf="formularioConfigInput.mascaraInput">
                <app-ag-input-field
                  [(ngModel)]="formularioConfigInput.mascaraInput.valor"
                  [label]="formularioConfigInput.mascaraInput.title"
                  [tooltip]="formularioConfigInput.mascaraInput.info"
                  [value]="formularioConfigInput.mascaraInput.valor"
                  [isInvalid]="formularioConfigInput.mascaraInput.status?.invalid"
                  [AgStatus]="formularioConfigInput.mascaraInput.status"
                  [id]="'formulario_mascaraInput'"
                  AgPlaceholder="{{formularioConfigInput.mascaraInput.placeholder}}"
                  AgInputType="{{formularioConfigInput.mascaraInput.inputType}}"
                  AgMaxlength="{{formularioConfigInput.mascaraInput.maxlength}}"
                  AgInputMode="{{formularioConfigInput.mascaraInput.inputMode}}"
                  (ngModelChange)="formularioConfigInput.mascara(formularioConfigInput.mascaraInput.mascara, 'mascaraInput')"
                  (change)="formularioConfigInput.validarInput('mascaraInput');formularioConfigInput.validateInputPattern('mascaraInput', '[#\.\-/,:]+')"
                  >
                </app-ag-input-field>
              </div>
            </div>


          </div>

          <div *ngIf="formularioConfigInput && formularioConfigInput.alternativas">

            <div class="row p-0 m-0">
              <div class="col-lg-12 mt-3">
                <h4 class="p-0 m-0">Informe os valores</h4>
              </div>
            </div>

            <div class="row p-0 m-0" *ngFor="let alternativas of formularioConfigInput.alternativas; let i = index" style=" border: var(--border-padrao); margin: 13px !important; border-radius: 6px; ">
              <div class="col-lg-5 mt-3">
                <app-ag-input-field
                  [(ngModel)]="alternativas.label.valor"
                  [label]="alternativas.label.title"
                  [tooltip]="alternativas.label.info"
                  [value]="alternativas.label.valor"
                  [isInvalid]="alternativas.label.status?.invalid"
                  [AgStatus]="alternativas.label.status"
                  [id]="'formulario_alternativas_label_'+i"
                  AgPlaceholder="{{alternativas.label.placeholder}}"
                  AgInputType="{{alternativas.label.inputType}}"
                  AgMaxlength="{{alternativas.label.maxlength}}"
                  AgInputMode="{{alternativas.label.inputMode}}"
                  (ngModelChange)="alternativas.mascara(alternativas.label.mascara, 'label')"
                  (change)="alternativas.validarInput('label')"
                  >
                </app-ag-input-field>
              </div>

              <div class="col-lg-5 mt-3">
                <app-ag-input-field
                  [(ngModel)]="alternativas.valor.valor"
                  [label]="alternativas.valor.title"
                  [tooltip]="alternativas.valor.info"
                  [value]="alternativas.valor.valor"
                  [isInvalid]="alternativas.valor.status?.invalid"
                  [AgStatus]="alternativas.valor.status"
                  [id]="'formulario_alternativas_valor_'+i"
                  AgPlaceholder="{{alternativas.valor.placeholder}}"
                  AgInputType="{{alternativas.valor.inputType}}"
                  AgMaxlength="{{alternativas.valor.maxlength}}"
                  AgInputMode="{{alternativas.valor.inputMode}}"
                  (ngModelChange)="alternativas.mascara(alternativas.valor.mascara, 'valor')"
                  (change)="alternativas.validarInput('valor')"
                  >
                </app-ag-input-field>
              </div>

              <div class="col-lg-2 mt-2 mb-3" style="display: flex;justify-content: center;align-items: flex-end;">
                <button type="button" class="btn btn-outline-danger" (click)="deletarAlternativa(alternativas.id)" style="display: flex;justify-content: space-around;align-items: center;">
                  <i class="bi bi-trash3-fill"></i> Excluir
                </button>
              </div>
            </div>

            <div class="row p-0 m-0 mt-3">
              <div class="col-lg-12">
                <button type="button" class="btn btn-primary" (click)="adicionarAlternativa(formularioTipoPerguntaSelecionados)" style="display: flex;justify-content: space-around;align-items: center;">
                  <i class="bi bi-plus"></i> Adicionar nova linha
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" (click)="closeModalFormulario(novaPerguntaFormulario)">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="adicionarPergunta()">Adicionar campo formulário</button>
      </div>

    </div>
  </div>
</div>

<!-- Editar Pergunta Formulário -->
<div class="modal" id="editarPerguntaFormulario" #editarPerguntaFormulario data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Editar campo formulário</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" (click)="closeModalFormulario(editarPerguntaFormulario)" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >

        <div class="ag-pt-10 ag-pl-5 ag-pr-5 mb-3">
          <div class="row p-0 m-0">

            <!-- formularioPergunta.pergunta -->
            <div class="col-lg-12">
              <app-ag-textarea-field
                [(ngModel)]="formularioPergunta.pergunta.valor"
                [label]="formularioPergunta.pergunta.title"
                [tooltip]="formularioPergunta.pergunta.info"
                [value]="formularioPergunta.pergunta.valor"
                [isInvalid]="formularioPergunta.pergunta.status?.invalid"
                [AgStatus]="formularioPergunta.pergunta.status"
                [id]="'formulario_pergunta'"
                AgPlaceholder="{{formularioPergunta.pergunta.placeholder}}"
                AgInputType="{{formularioPergunta.pergunta.inputType}}"
                AgMaxlength="{{formularioPergunta.pergunta.maxlength}}"
                AgInputMode="{{formularioPergunta.pergunta.inputMode}}"
                (change)="formularioPergunta.validarInput('pergunta')"
                >
              </app-ag-textarea-field>
            </div>

            <!-- formularioPergunta.placeholder -->
            <div class="col-lg-12">
              <app-ag-input-field
                [(ngModel)]="formularioPergunta.placeholder.valor"
                [label]="formularioPergunta.placeholder.title"
                [tooltip]="formularioPergunta.placeholder.info"
                [value]="formularioPergunta.placeholder.valor"
                [isInvalid]="formularioPergunta.placeholder.status?.invalid"
                [AgStatus]="formularioPergunta.placeholder.status"
                [id]="'formulario_placeholder'"
                AgPlaceholder="{{formularioPergunta.placeholder.placeholder}}"
                AgInputType="{{formularioPergunta.placeholder.inputType}}"
                AgMaxlength="{{formularioPergunta.placeholder.maxlength}}"
                AgInputMode="{{formularioPergunta.placeholder.inputMode}}"
                (change)="formularioPergunta.validarInput('placeholder')"
                >
              </app-ag-input-field>
            </div>

            <!-- formularioPergunta.obrigatorio -->
            <div class="col-lg-12">
              <app-ag-mat-checkbox
                [(ngModel)]="formularioPergunta.obrigatorio.valor"
                [label]="formularioPergunta.obrigatorio.title"
                [tooltip]="formularioPergunta.obrigatorio.info"
                [value]="formularioPergunta.obrigatorio.valor"
                [id]="'formulario_obrigatorio'"
                >
              </app-ag-mat-checkbox>
            </div>

            <!-- formularioPergunta.tipoPergunta -->
            <div class="col-lg-12">
              <app-ag-ng-select [(ngModel)]="formularioPergunta.tipoPergunta.valor"
                   [label]="formularioPergunta.tipoPergunta.title"
                   [id]="'formulario_tipoPergunta'"
                   [tooltip]="formularioPergunta.tipoPergunta.placeholder ? formularioPergunta.tipoPergunta.placeholder : ''"
                   [AgPlaceholder]="formularioPergunta.tipoPergunta.placeholder ? formularioPergunta.tipoPergunta.placeholder : ''"
                   [isInvalid]="formularioPergunta.tipoPergunta.status?.invalid"
                   [AgStatus]="formularioPergunta.tipoPergunta.status"
                   [AgOptions]="formularioTipoPerguntaArray"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   AgSelectType="checkbox"
                   [AgAppendTo]="'#editarPerguntaFormulario'"
                   (change)="formularioPergunta.validarInput('tipoPergunta')"
                   (ngModelChange)="changeTipoPergunta(formularioPergunta.tipoPergunta.valor)">
              </app-ag-ng-select>
            </div>

            <div *ngIf="formularioConfigInput">

              <!-- formularioConfigInput.tamanhoInput -->
              <div class="col-lg-12 mt-3" *ngIf="formularioConfigInput.tamanhoInput">
                <app-ag-input-field
                  [(ngModel)]="formularioConfigInput.tamanhoInput.valor"
                  [label]="formularioConfigInput.tamanhoInput.title"
                  [tooltip]="formularioConfigInput.tamanhoInput.info"
                  [value]="formularioConfigInput.tamanhoInput.valor"
                  [isInvalid]="formularioConfigInput.tamanhoInput.status?.invalid"
                  [AgStatus]="formularioConfigInput.tamanhoInput.status"
                  [id]="'formulario_tamanhoInput'"
                  AgPlaceholder="{{formularioConfigInput.tamanhoInput.placeholder}}"
                  AgInputType="{{formularioConfigInput.tamanhoInput.inputType}}"
                  AgMaxlength="{{formularioConfigInput.tamanhoInput.maxlength}}"
                  AgInputMode="{{formularioConfigInput.tamanhoInput.inputMode}}"
                  (ngModelChange)="formularioConfigInput.mascara(formularioConfigInput.tamanhoInput.mascara, 'tamanhoInput')"
                  (change)="formularioConfigInput.validarInput('tamanhoInput')"
                  >
                </app-ag-input-field>
              </div>

              <!-- formularioConfigInput.mascaraInput -->
              <div class="col-lg-12 mt-3" *ngIf="formularioConfigInput.mascaraInput">
                <app-ag-input-field
                  [(ngModel)]="formularioConfigInput.mascaraInput.valor"
                  [label]="formularioConfigInput.mascaraInput.title"
                  [tooltip]="formularioConfigInput.mascaraInput.info"
                  [value]="formularioConfigInput.mascaraInput.valor"
                  [isInvalid]="formularioConfigInput.mascaraInput.status?.invalid"
                  [AgStatus]="formularioConfigInput.mascaraInput.status"
                  [id]="'formulario_mascaraInput'"
                  AgPlaceholder="{{formularioConfigInput.mascaraInput.placeholder}}"
                  AgInputType="{{formularioConfigInput.mascaraInput.inputType}}"
                  AgMaxlength="{{formularioConfigInput.mascaraInput.maxlength}}"
                  AgInputMode="{{formularioConfigInput.mascaraInput.inputMode}}"
                  (ngModelChange)="formularioConfigInput.mascara(formularioConfigInput.mascaraInput.mascara, 'mascaraInput')"
                  (change)="formularioConfigInput.validarInput('mascaraInput');formularioConfigInput.validateInputPattern('mascaraInput', '[#\.\-/,:]+')"
                  >
                </app-ag-input-field>
              </div>
            </div>

          </div>

          <!-- formularioConfigInput.alternativas -->
          <div *ngIf="formularioConfigInput && formularioConfigInput.alternativas">

            <div class="row p-0 m-0">
              <div class="col-lg-12 mt-3">
                <h4 class="p-0 m-0">Informe os valores</h4>
              </div>
            </div>

            <div class="row p-0 m-0" *ngFor="let alternativas of formularioConfigInput.alternativas; let i = index" style=" border: var(--border-padrao); margin: 13px !important; border-radius: 6px; ">
              <div class="col-lg-5 mt-3">
                <app-ag-input-field
                  [(ngModel)]="alternativas.label.valor"
                  [label]="alternativas.label.title"
                  [tooltip]="alternativas.label.info"
                  [value]="alternativas.label.valor"
                  [isInvalid]="alternativas.label.status?.invalid"
                  [AgStatus]="alternativas.label.status"
                  [id]="'formulario_alternativas_label_'+i"
                  AgPlaceholder="{{alternativas.label.placeholder}}"
                  AgInputType="{{alternativas.label.inputType}}"
                  AgMaxlength="{{alternativas.label.maxlength}}"
                  AgInputMode="{{alternativas.label.inputMode}}"
                  (ngModelChange)="alternativas.mascara(alternativas.label.mascara, 'label')"
                  (change)="alternativas.validarInput('label')"
                  >
                </app-ag-input-field>
              </div>

              <div class="col-lg-5 mt-3">
                <app-ag-input-field
                  [(ngModel)]="alternativas.valor.valor"
                  [label]="alternativas.valor.title"
                  [tooltip]="alternativas.valor.info"
                  [value]="alternativas.valor.valor"
                  [isInvalid]="alternativas.valor.status?.invalid"
                  [AgStatus]="alternativas.valor.status"
                  [id]="'formulario_alternativas_valor_'+i"
                  AgPlaceholder="{{alternativas.valor.placeholder}}"
                  AgInputType="{{alternativas.valor.inputType}}"
                  AgMaxlength="{{alternativas.valor.maxlength}}"
                  AgInputMode="{{alternativas.valor.inputMode}}"
                  (ngModelChange)="alternativas.mascara(alternativas.valor.mascara, 'valor')"
                  (change)="alternativas.validarInput('valor')"
                  >
                </app-ag-input-field>
              </div>

              <div class="col-lg-2 mt-2 mb-3" style="display: flex;justify-content: center;align-items: flex-end;">
                <button type="button" class="btn btn-outline-danger" (click)="deletarAlternativa(alternativas.id)" style="display: flex;justify-content: space-around;align-items: center;">
                  <i class="bi bi-trash3-fill"></i> Excluir
                </button>
              </div>
            </div>

            <div class="row p-0 m-0 mt-3">
              <div class="col-lg-12">
                <button type="button" class="btn btn-primary" (click)="adicionarAlternativa(formularioTipoPerguntaSelecionados)" style="display: flex;justify-content: space-around;align-items: center;">
                  <i class="bi bi-plus"></i> Adicionar nova linha
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" (click)="closeModalFormulario(editarPerguntaFormulario)">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="salvarPergunta(editID)">Salvar campo formulário</button>
      </div>

    </div>
  </div>
</div>
