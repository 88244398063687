import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ag-ng-select',
  templateUrl: './ag-ng-select.component.html',
  styleUrls: ['./ag-ng-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgNgSelectComponent),
      multi: true,
    },
  ],
})
export class AgNgSelectComponent {
  @Input() label: string = '';
  @Input() tooltip: string = '';
  @Input() id: string = '';
  @Input() idTooltip: string = '';
  @Input() AgPlaceholder: string = '';
  @Input() AgMultiple: boolean = false;
  @Input() AgCloseOnSelect: boolean = true;
  @Input() AgOptions: any[] = [];
  @Input() ngModelChange: any;
  @Input() AgSelectType: any; // interface campo, checkbox. diasSemana
  @Input() isInvalid: boolean = false;
  @Input() AgStatus: any;
  @Input() AgAppendTo: string = '';
  // Usando um método getter e setter para acessar a innerValue
  private _innerValue: any;

  get innerValue(): any {
    return this._innerValue;
  }

  set innerValue(value: any) {
    this._innerValue = value == null ? undefined : value;
    this.onChange(value); // Notifica o Angular sobre as mudanças
  }

  constructor() {}

  writeValue(value: any) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  // Quando o valor muda dentro do componente, atualize o valor interno
  onValueChange(value: any) {
    // console.log(value)
    this.innerValue = value;
  }

  // Define uma função para tratar a mudança do ngModel, se necessário
  onNgModelChange(event: any) {
    this.ngModelChange(event);
  }
}
