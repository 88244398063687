import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';

@Component({
  selector: 'app-modal-confirmacao',
  templateUrl: './modal-confirmacao.component.html',
  styleUrls: ['./modal-confirmacao.component.css']
})
export class ModalConfirmacaoComponent {
  @Input() titulo!: string;
  @Input() mensagem!: string;

  constructor(public modalRef: BsModalRef, private modalConfirmacaoService: ModalConfirmacaoService) {}

  confirmAction(confirmed: boolean) {
    return new Promise<void>((resolve) => {
      this.modalRef.hide();
      this.modalConfirmacaoService.setConfirmation(confirmed); // Informa o resultado para o serviço
      // if (confirmed) {
      //   console.log('Ação confirmada!');
      // } else {
      //   console.log('Ação cancelada.');
      // }
      resolve();
    });
  }
}
