<div class="pb-4">

  <div class="container text-center min-width p-0">

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('usuarios')" class="text-padrao">Usuários</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('usuarios')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>

     <!-- Tipo -->
     <div class="row p-0 m-0">
      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Novo usuário
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tipo Dados -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">
          <div class="row p-0 m-0">
            <!-- usuario.displayName -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="usuario.displayName.valor"
                  [label]="usuario.displayName.title"
                  [tooltip]="usuario.displayName.info"
                  [value]="usuario.displayName.valor"
                  [isInvalid]="usuario.displayName.status?.invalid"
                  [AgStatus]="usuario.displayName.status"
                  [id]="'usuario_displayName'"
                  AgPlaceholder="{{usuario.displayName.placeholder}}"
                  AgInputType="{{usuario.displayName.inputType}}"
                  AgMaxlength="{{usuario.displayName.maxlength}}"
                  AgInputMode="{{usuario.displayName.inputMode}}"
                  (ngModelChange)="usuario.mascara(usuario.displayName.mascara, 'displayName');"
                  (change)="usuario.validarInput('displayName');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- usuario.email -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="usuario.email.valor"
                  [label]="usuario.email.title"
                  [tooltip]="usuario.email.info"
                  [value]="usuario.email.valor"
                  [isInvalid]="usuario.email.status?.invalid"
                  [AgStatus]="usuario.email.status"
                  [id]="'usuario_email'"
                  AgPlaceholder="{{usuario.email.placeholder}}"
                  AgInputType="{{usuario.email.inputType}}"
                  AgMaxlength="{{usuario.email.maxlength}}"
                  AgInputMode="{{usuario.email.inputMode}}"
                  (ngModelChange)="usuario.mascara(usuario.email.mascara, 'email');"
                  (change)="usuario.validarInput('email');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- usuario.phoneNumber -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="usuario.phoneNumber.valor"
                  [label]="usuario.phoneNumber.title"
                  [tooltip]="usuario.phoneNumber.info"
                  [value]="usuario.phoneNumber.valor"
                  [isInvalid]="usuario.phoneNumber.status?.invalid"
                  [AgStatus]="usuario.phoneNumber.status"
                  [id]="'usuario_phoneNumber'"
                  AgPlaceholder="{{usuario.phoneNumber.placeholder}}"
                  AgInputType="{{usuario.phoneNumber.inputType}}"
                  AgMaxlength="{{usuario.phoneNumber.maxlength}}"
                  AgInputMode="{{usuario.phoneNumber.inputMode}}"
                  (ngModelChange)="usuario.mascara(usuario.phoneNumber.mascara, 'phoneNumber');"
                  (change)="usuario.validarInput('phoneNumber');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- usuario.photoURL -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="usuario.photoURL.valor"
                  [label]="usuario.photoURL.title"
                  [tooltip]="usuario.photoURL.info"
                  [value]="usuario.photoURL.valor"
                  [isInvalid]="usuario.photoURL.status?.invalid"
                  [AgStatus]="usuario.photoURL.status"
                  [id]="'usuario_photoURL'"
                  AgPlaceholder="{{usuario.photoURL.placeholder}}"
                  AgInputType="{{usuario.photoURL.inputType}}"
                  AgMaxlength="{{usuario.photoURL.maxlength}}"
                  AgInputMode="{{usuario.photoURL.inputMode}}"
                  (ngModelChange)="usuario.mascara(usuario.photoURL.mascara, 'photoURL');"
                  (change)="usuario.validarInput('photoURL');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- usuario.tipo -->
            <div class="col-lg-12 pb-3 reset-padding-mobile">
              <app-ag-ng-select [(ngModel)]="usuario.tipo.valor"
                   [label]="usuario.tipo.title"
                   [id]="'tipo'"
                   [tooltip]="usuario.tipo.info"
                   AgPlaceholder="{{usuario.tipo.placeholder}}"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   [AgOptions]="usuario.tipoOptions"
                   [isInvalid]="usuario.tipo.status?.invalid"
                   [AgStatus]="usuario.tipo.status"
                   AgSelectType="checkbox"
                   (ngModelChange)="usuario.validarInput('tipo')">
              </app-ag-ng-select>
            </div>

            <!-- usuario.photoURL -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="password"
                  [label]="'password'"
                  [tooltip]="'password'"
                  [value]="password"
                  [id]="'usuario_password'"
                  AgPlaceholder="password"
                  >
                </app-ag-input-field>
              </div>
            </div>


            <!-- usuario.photoURL -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="repassword"
                  [label]="'repassword'"
                  [tooltip]="'repassword'"
                  [value]="repassword"
                  [id]="'usuario_repassword'"
                  AgPlaceholder="repassword"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- usuario.status -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="usuario.status.valor"
                [label]="usuario.status.title"
                [tooltip]="usuario.status.info"
                [value]="usuario.status.valor"
                [id]="'usuario_status'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>
        </div>
      </div>

      <!-- GrupoUsuario -->
      <div class="row p-0 m-0">
        <div class="row p-0 m-0">
          <div class="min-width col-lg mb-3">
            <div class="box-gen-card">
              <div class="row p-0 m-0">
                <div class="col-lg-12 reset-padding-mobile">
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100 mt-2 mb-2">
                    Grupos de Usuários Disponíveis
                  </h3>
                </div>

                <div class="col-lg-12 reset-padding-mobile">

                  <!-- <h3 class="p-0 m-0 text-cor-padrao text-start w-100 ag-pl-10">Recursos Disponíveis</h3> -->
                  <mat-selection-list #gruposDeUsuarios>
                    <mat-list-option *ngFor="let rec of grupos" [value]="rec" class="p-0 m-0" style="border-top: var(--border-padrao)">
                      {{rec.nome.valor}} - <span style=" font-size: 13px; font-style: italic; ">{{rec.descricao.valor}}</span>
                      <!-- <div style="display: flex;flex-direction: column;flex-wrap: wrap;align-items: flex-start;justify-content: center;">
                        <span>{{rec.descricao.valor}}</span>
                      </div> -->
                    </mat-list-option>
                  </mat-selection-list>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- GrupoUsuarioAgenda -->
      <div class="row p-0 m-0">
        <div class="row p-0 m-0">
          <div class="min-width col-lg mb-3">
            <div class="box-gen-card">
              <div class="row p-0 m-0">
                <div class="col-lg-12 reset-padding-mobile">
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100 mt-2 mb-2">
                    Grupos de Agendas Disponíveis
                  </h3>
                </div>

                <div class="col-lg-12 reset-padding-mobile">

                  <!-- <h3 class="p-0 m-0 text-cor-padrao text-start w-100 ag-pl-10">Recursos Disponíveis</h3> -->
                  <mat-selection-list #gruposDeAgendas>
                    <mat-list-option *ngFor="let rec of gruposAgendas" [value]="rec" class="p-0 m-0" style="border-top: var(--border-padrao)">
                      {{rec.nome.valor}} - <span style=" font-size: 13px; font-style: italic; ">{{rec.descricao.valor}}</span>
                      <!-- <div style="display: flex;flex-direction: column;flex-wrap: wrap;align-items: flex-start;justify-content: center;">
                        <span>{{rec.descricao.valor}}</span>
                      </div> -->
                    </mat-list-option>
                  </mat-selection-list>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <!-- <div class="w-100 mb-3" style="text-align: left; display: flex; align-items: center; ">
            <mat-checkbox [(ngModel)]="agenda.status.valor" [color]="'radial-primary'">
              {{agenda.status.title}}
            </mat-checkbox>
            <span mat-raised-button #status="matTooltip" [matTooltip]="agenda.status.info" matTooltipPosition="right" (click)="status.show()" class="tooltip-input-icon">
              <i class="bi bi-info-circle-fill"></i>
            </span>
          </div> -->

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="criarUsuario()">Criar Usuário</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('usuarios')" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


