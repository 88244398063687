import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { GrupoAgenda } from 'src/class/grupo-agenda';
import { GrupoUsuario } from 'src/class/grupo-usuario';
import { GruposUsuarios, GruposUsuariosAgenda, Usuario, newUsuario } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { GrupoAgendaService } from 'src/services/component/grupo-agenda.service';
import { GrupoUsuarioService } from 'src/services/component/grupo-usuario.service';
import { UsuarioService } from 'src/services/component/usuario.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';
// import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-usuario-novo',
  templateUrl: './usuario-novo.component.html',
  styleUrls: ['./usuario-novo.component.css']
})
export class UsuarioNovoComponent implements OnInit,OnDestroy {

  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('gruposDeUsuarios', {static: true}) gruposDeUsuarios!: MatSelectionList;
  @ViewChild('gruposDeAgendas', {static: true}) gruposDeAgendas!: MatSelectionList;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  selectedFile: File | null = null;
  uploadProgress: number | null = null;
  empresaSigla: any | undefined;

  usuario: Usuario = new Usuario();
  password: string = '';
  repassword: string = '';

  grupos: any[] = [];
  gruposAgendas: any[] = [];

  constructor(
    private router: Router,
    private usuarios: UsuariosService,
    private usuarioService: UsuarioService,
    private validatorService: ValidatorService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private siglaService: SiglaService,
    private authService: AuthService,
    private grupoUsuarioService: GrupoUsuarioService,
    private grupoAgendaService: GrupoAgendaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.grupoUsuarioService.getGrupoUsuario(this.empresaSigla);
          this.grupoAgendaService.getGrupoAgenda(this.empresaSigla);
        });
      }
    });
  }

  async ngOnInit() {

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    // Agora você pode usar o ID como quiser

    this.grupoUsuarioService.getAllGrupoUsuario()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (grupos: GrupoUsuario[]) => {
        // console.log(grupos)
        // Faça algo com as notificações atualizadas
        if(grupos.length > 0){
          this.grupos = grupos;

            // setTimeout(() => {
            let rec: any[] = [];

            // console.log(this.grupo.recursos)

            for (let index1 = 0; index1 < this.usuario.grupo.length; index1++) {
              const element = this.usuario.grupo[index1];

              for (let index = 0; index < this.grupos.length; index++) {
                const recId = this.grupos[index];

                if(element.idGrupo == recId.id){
                  rec.push(element);
                  break;
                }
              }
            }

            // console.log(rec)

            this.selectAllResources(rec);

          // }, 1000);
        }

      },
      error => {
        // console.error('Erro ao listar os GrupoUsuario:', error);
        this.router.navigate(['/usuarios']);
      }
    );

    this.grupoAgendaService.getAllGrupoAgenda()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (gruposAgendas: GrupoAgenda[]) => {
        // console.log(gruposAgendas)
        // Faça algo com as notificações atualizadas
        if(gruposAgendas.length > 0){
          this.gruposAgendas = gruposAgendas;

            // setTimeout(() => {
            let rec: any[] = [];

            // console.log(this.grupo.recursos)

            for (let index1 = 0; index1 < this.usuario.grupoAgenda.length; index1++) {
              const element = this.usuario.grupoAgenda[index1];

              for (let index = 0; index < this.gruposAgendas.length; index++) {
                const recId = this.gruposAgendas[index];

                if(element.idGrupoAgenda == recId.id){
                  rec.push(element);
                  break;
                }
              }
            }

            // console.log(rec)

            this.selectAllAgendas(rec);

          // }, 1000);
        }

      },
      error => {
        // console.error('Erro ao listar os GrupoUsuario:', error);
        this.router.navigate(['/usuarios']);
      }
    );

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  resetNewUsr(){
    this.usr_new = new newUsuario;
  }

  async newUsuario(){

    let imageUrl = "";

    // console.log('i');

    try {
      // imageUrl = await this.upload();
      imageUrl = "";
      // Aqui você pode usar a URL da imagem para prosseguir com a outra parte do código
      // console.log('URL da imagem:', imageUrl);
      this.usr_new.Foto = imageUrl;
    } catch (error) {
      // console.log('Erro ao fazer upload:', error);
    }

    // console.log('f');

    const newUrs = await this.usuarios.newUsuario(this.usr_new,this.usr_token);
    const response = JSON.parse(newUrs);

    if(response.Message){
      // console.log(response.Message);
      this.showError(response.Message)

    }else{
      const button = document.getElementById('btn-close-x-new');
      if (button) {
        button.click();
      }
      this.showSuccess(newUrs.replaceAll("\"", ""))

      this.resetNewUsr();
    }
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
  }

  async upload(){
    // async upload(): Promise<string> {
    //   if (this.selectedFile) {
    //     const filePath = `usuarios/${this.usr_new.Usuario}_${this.selectedFile.name}`;
    //     const fileRef = this.storage.ref(filePath);
    //     const task = this.storage.upload(filePath, this.selectedFile);

    //     task.percentageChanges().subscribe(progress => {
    //       this.uploadProgress = progress !== undefined ? progress : null;
    //     });

    //     await task.snapshotChanges().toPromise();

    //     const url = await fileRef.getDownloadURL().toPromise();
    //     console.log('URL da imagem:', url);

    //     return url;
    //   }

    //   throw new Error('Nenhum arquivo selecionado.');
    // }
  }

  getSelectedResources() {
    for (let index = 0; index < this.gruposDeUsuarios.selectedOptions.selected.length; index++) {
      const element = this.gruposDeUsuarios.selectedOptions.selected[index];
      // console.log(element.value)
    }
    return this.gruposDeUsuarios.selectedOptions.selected.map(option => option.value);
  }

  selectAllResources(recursos: any[]): void {
    const time = setInterval(()=>{
      if(this.gruposDeUsuarios && this.gruposDeUsuarios.options){
        clearInterval(time);

        recursos.forEach((rec: any) => {
            const resourceId = rec.idGrupo; // Obtém o ID do recurso
            const option = this.gruposDeUsuarios.options.toArray().find((opt: any) => opt.value.id === resourceId);
            if(option){
              option.selected = true; // Seleciona a opção com o ID correspondente
            }
        });

      }
    })
  }

  getSelectedAgendas() {
    for (let index = 0; index < this.gruposDeAgendas.selectedOptions.selected.length; index++) {
      const element = this.gruposDeAgendas.selectedOptions.selected[index];
      // console.log(element.value)
    }
    return this.gruposDeAgendas.selectedOptions.selected.map(option => option.value);
  }

  selectAllAgendas(recursos: any[]): void {
    const time = setInterval(()=>{
      if(this.gruposDeAgendas && this.gruposDeAgendas.options){
        clearInterval(time);

        recursos.forEach((rec: any) => {
            const resourceId = rec.idGrupoAgenda; // Obtém o ID do recurso
            const option = this.gruposDeAgendas.options.toArray().find((opt: any) => opt.value.id === resourceId);
            if(option){
              option.selected = true; // Seleciona a opção com o ID correspondente
            }
        });

      }
    })
  }

  async criarUsuario(){
    this.usuario.displayName.status = this.validatorService.criarValidacao(this.usuario.displayName.valor, this.usuario.displayName.validacoesCustomizadas);
    this.usuario.email.status = this.validatorService.criarValidacao(this.usuario.email.valor, this.usuario.email.validacoesCustomizadas);
    this.usuario.tipo.status = this.validatorService.criarValidacao(this.usuario.tipo.valor, this.usuario.tipo.validacoesCustomizadas);

    if(this.usuario.displayName.status.invalid ||
      this.usuario.email.status.invalid ||
      this.usuario.tipo.status.invalid
    ){
      // this.abrirModal(this.alertTipoModal.nativeElement);
    }else{

      // this.tipo.id = await this.contadorIndexService.contadorIndex();
      let new_usuario  = new Usuario();
      new_usuario = this.usuario;

      new_usuario.criado = new Date();
      new_usuario.atualizado = new Date();

      let grupo: GruposUsuarios[] = [];

      // console.log(this.resources.selectedOptions)
      for (let index = 0; index < this.gruposDeUsuarios.selectedOptions.selected.length; index++) {
        const element = this.gruposDeUsuarios.selectedOptions.selected[index];
        // console.log(element.value)
        const rec = new GruposUsuarios();

        rec.idGrupo = element.value.id
        rec.NomeGrupo = element.value.nome.valor

        grupo.push(rec)
      }

      new_usuario.grupo = grupo;

      let agendas: GruposUsuariosAgenda[] = [];

      // console.log(this.resources.selectedOptions)
      for (let index = 0; index < this.gruposDeAgendas.selectedOptions.selected.length; index++) {
        const element = this.gruposDeAgendas.selectedOptions.selected[index];
        // console.log(element.value)
        const rec = new GruposUsuariosAgenda();

        rec.idGrupoAgenda = element.value.id
        rec.NomeGrupoAgenda = element.value.nome.valor

        agendas.push(rec)
      }

      new_usuario.grupoAgenda = agendas;

      const userToken = JSON.parse(localStorage.getItem('user')!);

      if (userToken) {

        await this.authService.verificarEmailUsuario(userToken.stsTokenManager.accessToken, this.usuario.email.valor)
        .then(async (response) => {
          // Tratar a resposta aqui
          // console.log("verificarEmailUsuario 1",response)
          if(response.id){
            new_usuario.id = response.id;
            const usuario = JSON.stringify(new_usuario);
            const res = await this.usuarioService.criarUsuario(JSON.parse(usuario), this.empresaSigla)
            // console.log(res)

            this.showSuccess("Usuário criado com sucesso");
            this.usuario = new Usuario();
            this.router.navigate([`${this.empresaSigla}/usuarios`]);
          }
        })
        .catch(async (error) => {
          // Tratar o erro aqui
          // console.log("verificarEmailUsuario 2",error.error);
          if(this.password == this.repassword){

            await this.authService.criarNovoUsuario(this.usuario.email.valor, this.password,userToken.stsTokenManager.accessToken)
            .then(async (response) => {
              new_usuario.id = response.id;
              const usuario = JSON.stringify(new_usuario);
              const res = await this.usuarioService.criarUsuario(JSON.parse(usuario), this.empresaSigla)
              // console.log(res)

              // this.showSuccess("Usuário criado com sucesso");
              this.usuario = new Usuario();
              this.router.navigate([`${this.empresaSigla}/usuarios`]);
            })
            .catch(async (error) => {
              this.showError(error)
            });

          }else{
            this.showError("A senha digitada nos campos de senha estão diferentes")
          }

        });

      } else {
        // Usuário não autenticado, redireciona para a página de login
        // console.log("Sem TOKEN")

      }

      // const tipo = JSON.stringify(new_usuario);
      // const res = await this.usuarioService.criarUsuario(JSON.parse(tipo), this.empresaSigla)
      // console.log(res)

      // let tipo = DataService.get("Tipo");
      // console.log(tipo);

      // if(tipo == undefined){
      //   let obj = [];
      //   new_tipo.criado = new Date();
      //   new_tipo.atualizado = new Date();

      //   // if(!new_tipo.vinculoAgenda.some(subArray => subArray.includes(this.agenda.id))){
      //   //   new_tipo.vinculoAgenda.push(this.agenda.id)
      //   // }

      //   obj.push(new_tipo);
      //   DataService.setItem("Tipo", obj);
      //   localStorage.setItem("Tipo", JSON.stringify(obj))

      //   // this.listaTipo = DataService.get("Tipo");
      // }else{
      //   new_tipo.criado = new Date();
      //   new_tipo.atualizado = new Date();

      //   // if(!new_tipo.vinculoAgenda.some(subArray => subArray.includes(this.agenda.id))){
      //   //   new_tipo.vinculoAgenda.push(this.agenda.id)
      //   // }

      //   tipo.push(new_tipo);
      //   DataService.setItem("Tipo", tipo);
      //   localStorage.setItem("Tipo", JSON.stringify(tipo))
      //   // this.listaTipo = DataService.get("Tipo");
      // }

      // this.showSuccess("Tipo criado com sucesso");
      // this.usuario = new Usuario();
      // this.router.navigate([`${this.empresaSigla}/usuarios`]);
    }
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
