
<div class="pb-4">

  <div class="container text-center min-width p-0">

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Usuários</li>
          </ol>
        </nav>
      </div>
    </div>

    <!-- Loading Cards -->
    <div class="row p-0 m-0">
      <div class=" col-lg-4 mb-3" style=" cursor: pointer; ">
        <button (click)="routerLink('usuarios/adicionar')" type="button" class="btn btn-sm p-0 m-0 btn-principal-primary">
          <span class="btn-principal-primary-nome">
            Novo Usuário
          </span>
          <span class="btn-principal-primary-icon">
            <i class="bi bi-person-plus"></i>
          </span>
        </button>
      </div>
    </div>


    <!-- 👇 Informações / Configurações / Filtro -->
    <div class="row p-0 m-0 mb-3">
      <div class="min-width col-12">
        <div class="p-0 m-0 box-gen-card">

          <!-- Informações / Configurações -->
          <div class="row p-0 m-0">

            <!-- Informações -->
            <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100 box-gen-card-h3">
                <span *ngIf="usuarios.length == 0">
                  Nenhum usuário
                </span>
                <span *ngIf="usuarios.length > 0">
                  <span class="box-gen-card-h3-span">{{usuarios.length}}</span> {{usuarios.length == 1 ? "Usuário disponível" : "Usuários disponíveis"}}
                </span>
              </h3>
            </div>

            <!-- Configurações -->
            <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0">

              <div class="d-flex justify-content-between align-items-center flex-nowrap">
                <button type="button" class="btn btn-outline-primary" (click)="reload()" style=" background: transparent; ">
                  Atualizar <i class="bi bi-arrow-clockwise"></i>
                </button>
                <button mat-icon-button [matMenuTriggerFor]="modoButton" aria-label="modo" class="btn btn-outline-primary" style=" background: transparent; " [title]="modoView == true ? 'Tabela' : 'Card'">
                  {{modoView == true ? 'Tabela' : 'Card'}}
                  <i *ngIf="modoView" class="bi bi-table ag-pl-10"></i>
                  <i *ngIf="!modoView" class="bi bi-card-text ag-pl-10"></i>
                </button>
                <mat-menu #modoButton="matMenu">
                  <button mat-menu-item (click)="modoTabela()">
                    <i class="bi bi-table ag-pr-10"></i>
                    <span>Tabela</span>
                  </button>
                  <button mat-menu-item (click)="modoCard()">
                    <i class="bi bi-card-text ag-pr-10"></i>
                    <span>Card</span>
                  </button>
                </mat-menu>

                <button [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_'" aria-expanded="false" [attr.aria-controls]="'collapse_'" class="btn btn-outline-primary mb-1 mt-1" style=" background: transparent; ">
                  Filtro <i class="bi bi-search" title="expand" ></i>
                </button>

              </div>
            </div>
          </div>

          <!-- Filtro -->
          <div [id]="'collapse_'" class="accordion-collapse collapse col-lg-12 pt-2">
            <div class="p-0 m-0" >
              <div *ngIf="usuarios.length > 0" style="position: relative;">
                <div class="form-floating w-100 ">
                  <input type="text" class="form-control input-text-padrao m-0 search-box-result input-text-padrao" id="filtrarUsuarioSearch" [(ngModel)]="pesquisa" (keydown)="searchUsuario()" (keyup)="searchUsuario()" (keypress)="searchUsuario()" placeholder="&nbsp;">
                  <label for="filtrarUsuarioSearch" class="label-input-text-padrao ag-pt-18 ag-pb-18 ag-pl-12 ag-pr-12">Filtrar usuário ...</label>
                </div>
                <i *ngIf="pesquisa != ''" class="bi bi-x-circle-fill text-secondary reset-search-usr" (click)="resetSearch()"></i>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- Informações / Configurações / Filtro 👆 -->

    <!-- 👇 Modo Card -->
    <div class="row p-0 m-0" *ngIf="!modoView">
      <div class="min-width col-lg-12 pb-5 pt-1">

        <!-- Loading -->
        <div class="row" *ngIf="usuarios.length == 0 && loading && modoViewNum == 12">
          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" >
            <div class="p-0 m-0 pulsate" >
            </div>
          </div>
        </div>

        <!-- Card -->
        <div class="row" *ngIf="usuarios.length > 0 && !loading">

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" *ngFor="let usr of displayedUsuarios | searchUsuario: pesquisa">

            <div class="card card-padrao-list p-0 m-0">

              <div class="card-body">
                <div class="row card-body-row">

                  <div class="col-lg-6 mb-1 mt-1">
                    <div class="card-body-row-div">
                      <div class="card-padrao-list-img" [style.background-image]="usr.photoURL?.valor ? 'url(' + usr.photoURL.valor + ')' : 'url(../assets/img/sem-foto-min.jpg)'" >
                        <!-- <div class="card-padrao-list-img-sombra"></div> -->
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <div class="mb-1 d-flex" style="flex-direction: column; align-items: flex-start; justify-content: center; ">
                          <span class="card-padrao-list-img-title" [innerHTML]="usr.displayName.valor | filtroDestaque: pesquisa">
                            <!-- {{usr.displayName.valor}} -->
                          </span>
                        </div>
                        <div class="d-flex justify-content-start flex-row align-items-center">
                          <div class="card-body-time p-0 m-0 ag-pl-10">
                            <span class="card-padrao-list-box-nobg-value" [innerHTML]="usr.email.valor | filtroDestaque: pesquisa">
                              <!-- {{agenda.duracaoAtendimento.valor}} -->
                            </span>
                          </div>
                        </div>
                        <div class="d-flex justify-content-start flex-row align-items-center">
                          <div class="card-body-time p-0 m-0 ag-pl-15">
                            <span>
                              <i class="bi bi-circle-fill text-cor-sucesso ag-pr-5" *ngIf="usr.status.valor"></i>
                              <i class="bi bi-circle-fill text-cor-danger ag-pr-5" *ngIf="!usr.status.valor"></i>
                              {{usr.status.valor ? "Ativo" : "Desativado"}}
                            </span>
                            <div style=" display: flex; ">
                              <span class="card-padrao-list-box-nobg-value" [innerHTML]="usr.tipo.valor | filtroDestaque: pesquisa">
                                <!-- {{usr.tipo.valor}} -->
                              </span>
                              <!-- <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" style="background: var(--background);border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--text-cor-padrao);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                                <span [innerHTML]="usr.tipo.valor | filtroDestaque: pesquisa" style="line-height: 17px;"></span>
                              </span> -->
                            </div>
                            <div style=" display: flex; ">
                              <ng-container *ngFor="let providerId of usr.providerId">
                                <div *ngIf="providerId == 'google.com'">
                                  <i class="bi bi-google text-padrao ag-ml-10" title="Login google"></i>
                                </div>
                                <div *ngIf="providerId == 'password'">
                                  <i class="bi bi-envelope-fill text-padrao ag-ml-10" title="Login password"></i>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 mb-1 mt-1">
                    <div class="card-padrao-list-box">
                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          Criado
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="(usr.criado == '' ? '--/--/----, --:--' : (usr.criado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa">
                          <!-- {{agenda.duracaoAtendimento.valor}} -->
                        </span>
                      </div>
                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          Atualizado
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="(usr.atualizado == '' ? '--/--/----, --:--' : (usr.atualizado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa">
                          <!-- {{agenda.duracaoAtendimento.valor}} -->
                        </span>
                      </div>
                      <div class="d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          Último acesso
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="(usr.ultimoLogin == '' ? '--/--/----, --:--' : (usr.ultimoLogin | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa">
                          <!-- {{agenda.duracaoAtendimento.valor}} -->
                        </span>
                      </div>

                    </div>
                  </div>

                  <!-- Informações ocultas -->
                  <div [id]="'collapse_'+usr.id" class="accordion-collapse collapse col-12 mt-2">
                    <div class="row card-body-row-oculta">

                      <div class="col-lg-6 d-flex flex-column justify-content-start flex-wrap">
                        <div class="d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">
                            {{usr.phoneNumber.title}}
                          </span>
                          <span class="card-padrao-list-box-nobg-value" [innerHTML]="usr.phoneNumber.valor | filtroDestaque: pesquisa">
                            <!-- {{agenda.duracaoAtendimento.valor}} -->
                          </span>
                        </div>
                      </div>

                      <div class="col-lg-6 d-flex flex-column justify-content-start flex-wrap">

                        <div class="d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">
                            ID
                          </span>
                          <span class="card-padrao-list-box-nobg-value" [innerHTML]="usr.id | filtroDestaque: pesquisa">
                            <!-- {{agenda.duracaoAtendimento.valor}} -->
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

                <div class="card-padrao-footer-div-menu">
                  <i class="bi bi-three-dots" title="menu" mat-icon-button [matMenuTriggerFor]="editarTipo_i"></i>
                  <mat-menu #editarTipo_i="matMenu">
                    <button mat-menu-item (click)="routerLink('usuario', usr.id)">
                      <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                      <span>Editar</span>
                    </button>
                    <button mat-menu-item (click)="atualizarStatusUsuario(usr.id)">
                      <i class="bi bi-dash-circle ag-pr-10"  [ngClass]="{'text-cor-danger': usr.status.valor, 'text-cor-sucesso': !usr.status.valor}"></i>
                      <span>{{usr.status.valor ? "Desativar" : "Ativar"}}</span>
                    </button>
                    <button mat-menu-item (click)="deletarUsuario(usr.id)">
                      <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                      <span>Deletar</span>
                    </button>
                  </mat-menu>
                  <i class="bi bi-chevron-expand" title="expand" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + usr.id" aria-expanded="false" [attr.aria-controls]="'collapse_' + usr.id"></i>

                </div>
              </div>
            </div>

          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedUsuarios.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao mt-3">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="col-12 mt-3  ">
            <div class="box-gen-card" style=" padding: 7px 0px !important; ">
              <mat-paginator
              [length]="usuarios.length"
              [pageSize]="maxSize"
              [pageSizeOptions]="[5, 10, 25, 50]"
              [showFirstLastButtons]="true"
              (page)="handlePage($event)"
            ></mat-paginator>
            </div>

          </div>

        </div>

      </div>
    </div>
    <!-- Modo Card -->

    <!-- 👇 Modo Tabela -->
    <div class="row p-0 m-0" *ngIf="modoView">

      <!-- Loading -->
      <div class="min-width col-lg-12 pb-5 pt-3" *ngIf="usuarios.length == 0 && loading">
        <div class="p-0 m-0 pulsate box-gen-card">
        </div>
      </div>


      <div class="min-width col-lg-12 pb-5 pt-1" >
        <div class="p-0 m-0 box-gen-card mb-3" *ngIf="usuarios.length > 0 && !loading">

          <!-- Tabela -->
          <div class="w-100 overflow-auto">
            <table id="excel-table" class="mb-1 mt-1 table table-responsive table-hover table-sm" [ngClass]="{'table-dark': !lightMode,'table-light': lightMode}" matSort (matSortChange)="sortData($event)">
              <thead style=" height: 40px; vertical-align: middle; " *ngIf="displayedUsuarios.length > 0">
                  <tr>
                    <th class="text-cor-padrao ag-pl-15" mat-sort-header="id" style="min-width: 55px;border-right: 1px solid var(--border-cor);">ID</th>
                    <th class="text-cor-padrao text-align-start" mat-sort-header="status" style="min-width: 55px;border-right: 1px solid var(--border-cor);">Status</th>
                    <th class="text-cor-padrao" mat-sort-header="nome" style="text-align: left;min-width: 180px;border-right: 1px solid var(--border-cor);">Nome</th>
                    <th class="text-cor-padrao" mat-sort-header="descricao" style="text-align: left;min-width: 180px;border-right: 1px solid var(--border-cor);">E-mail</th>
                    <th class="text-cor-padrao" mat-sort-header="criado" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Criado</th>
                    <th class="text-cor-padrao" mat-sort-header="atualizado" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Atualizado</th>
                    <!-- <th class="text-cor-padrao" mat-sort-header="uf" >Hora Final</th> -->
                    <th class="text-cor-padrao sticky-col" style=" text-align: center;" >&nbsp;</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="text-cor-padrao ag-table-tr" *ngFor="let usr of displayedUsuarios | searchUsuario: pesquisa">
                    <td class="ag-table-td ag-pl-15" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="usr.id | filtroDestaque: pesquisa">
                        <!-- {{usr.id | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div >
                        <i class="bi bi-circle-fill text-cor-sucesso ag-pr-5" *ngIf="usr.status.valor"></i>
                        <i class="bi bi-circle-fill text-cor-danger ag-pr-5" *ngIf="!usr.status.valor"></i>
                        {{usr.status.valor ? "Ativo" : "Desativado"}}
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="usr.displayName.valor | filtroDestaque: pesquisa">
                        <!-- {{usr.placaVeiculo.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="usr.email.valor | filtroDestaque: pesquisa">
                        <!-- {{usr.placaVeiculo.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="(usr.criado == '' ? '--/--/----, --:--' : (usr.criado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa">
                        <!-- {{usr.fornecedor.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="(usr.atualizado == '' ? '--/--/----, --:--' : (usr.atualizado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa">
                        <!-- {{usr.notaFiscal.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <!-- <td class="ag-table-td">
                      <div >
                        {{usr.hora_final.valor}}
                      </div>
                    </td> -->
                    <td class="ag-table-td sticky-col">
                      <button mat-icon-button [matMenuTriggerFor]="editarAgendamento" aria-label="modo" class="btn-outline-primary sticky-col-buttom">
                        <i class="bi bi-three-dots"></i>
                      </button>
                      <mat-menu #editarAgendamento="matMenu">
                        <button mat-menu-item (click)="routerLink('usuario', usr.id)">
                          <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                          <span>Editar</span>
                        </button>
                        <button mat-menu-item (click)="atualizarStatusUsuario(usr.id)">
                          <i class="bi bi-dash-circle ag-pr-10" [ngClass]="{'text-cor-danger': usr.status.valor, 'text-cor-sucesso': !usr.status.valor}"></i>
                          <span>{{usr.status.valor ? "Desativar" : "Ativar"}}</span>
                        </button>
                        <button mat-menu-item (click)="deletarUsuario(usr.id)">
                          <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                          <span>Deletar</span>
                        </button>
                      </mat-menu>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedUsuarios.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="text-right row pagination-box-result" *ngIf="usuarios.length > 0">
            <div class="col-12 p-0 m-0">
              <mat-paginator
                [length]="usuarios.length"
                [pageSize]="maxSize"
                [pageSizeOptions]="[5, 10, 25, 50]"
                [showFirstLastButtons]="true"
                (page)="handlePage($event)"
              ></mat-paginator>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- Modo Tabela 👆 -->

  </div>
</div>
