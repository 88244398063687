import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ModalConfirmacaoComponent } from 'src/app/element-library/modal-confirmacao/modal-confirmacao.component';

@Injectable({
  providedIn: 'root'
})
export class ModalConfirmacaoService {
  modalRef!: BsModalRef;
  private confirmationSubject = new Subject<boolean>();

  constructor(private modalService: BsModalService) {}


  openModal(titulo: string,mensagem: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const initialState = {
        titulo: titulo,
        mensagem: mensagem,
        confirmationSubject: this.confirmationSubject
      };
      this.modalRef = this.modalService.show(
        ModalConfirmacaoComponent,
        {
          initialState,
          ignoreBackdropClick: true,
          animated: false,
          class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md'
        }
      );
      this.confirmationSubject.subscribe((confirmation: boolean) => {
        resolve(confirmation);
      });

    });
  }

  confirm(mensagem: string) {
    return this.confirmationSubject.asObservable();
  }

  setConfirmation(value: boolean) {
    this.confirmationSubject.next(value);
  }
}
