import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'diferencaEntreDatas'
})
export class DiferencaEntreDatasPipe implements PipeTransform {

  transform(dataInicio: any, args?: any): any {
    // console.log("Diferença entre datas");

    const data_inicio = dataInicio;
    const data_fim = new Date().toLocaleDateString();

    const [dia2, mes2, ano2] = data_inicio.split('/');
    const [dia1, mes1, ano1] = data_fim.split('/');

    const data1 = new Date(parseInt(ano1), parseInt(mes1) - 1, parseInt(dia1),0,0,0);
    const data2 = new Date(parseInt(ano2), parseInt(mes2) - 1, parseInt(dia2),23,59,59);

    const diferencaEmMilissegundos = data2.getTime() - data1.getTime();
    const diferencaEmDias = Math.round(diferencaEmMilissegundos / (1000 * 3600 * 24)); // converte para dias
    let resultadoDiferencaEmDias = "";

    if (diferencaEmDias > 0) {
      if(diferencaEmDias > 1){
        resultadoDiferencaEmDias = `${diferencaEmDias} dias restantes`;
      }else if (diferencaEmDias === 0 || diferencaEmDias === 1) {
        // resultadoDiferencaEmDias = `${diferencaEmDias} dia restante`;
        resultadoDiferencaEmDias = `Vence hoje <i class="bi bi-exclamation-triangle-fill card-padrao-list-dataPipe-alerta"></i>`;
      }
    }  else {
      resultadoDiferencaEmDias = `Vencido <i class="bi bi-exclamation-triangle-fill card-padrao-list-dataPipe-danger"></i>`;
    }

    // console.log(data1, data2);
    // console.log(diferencaEmMilissegundos);
    // console.log(diferencaEmDias);
    // console.log(resultadoDiferencaEmDias);

    return resultadoDiferencaEmDias;
  }

}
