import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Agenda } from 'src/class/agenda';
import { Agendamento } from 'src/class/agendamento';
import { Links } from 'src/class/links';
import { AuthService } from 'src/services/auth.service';
import { AgendaService } from 'src/services/component/agenda.service';
import { AgendamentoService } from 'src/services/component/agendamento.service';
import { ContaService } from 'src/services/component/conta.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { LinksService } from 'src/services/links.service';
import { LocalhostService } from 'src/services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-editar-agendamento',
  templateUrl: './editar-agendamento.component.html',
  styleUrls: ['./editar-agendamento.component.css']
})
export class EditarAgendamentoComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertNovoAgendamentoModal', { static: false }) alertNovoAgendamentoModal!: ElementRef;
  @ViewChild('ConfirmacaoNovoAgendamentoModal', { static: false }) ConfirmacaoNovoAgendamentoModal!: ElementRef;

  id: any;
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  rec_pwd_load = false;

  selectedFile: File | null = null;
  uploadProgress: number | null = null;

  showBtnMdl = false;

  agendamento: Agendamento = new Agendamento();
  agendamentos: Agendamento[] = [];

  linksAgenda: Links[] = [];

  agendaSelecionada: number | null = null;

  agendas: Agenda[] = [];
  empresaSigla: any | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private userService: AuthService,
    private linksService: LinksService,
    private validatorService: ValidatorService,
    private localhostService: LocalhostService,
    private agendamentoService: AgendamentoService,
    private agendaService: AgendaService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private notificacaoService: NotificacaoSistemaService
  ){
    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.agendamentoService.getAgendamentos(this.empresaSigla);
          this.agendaService.getAgendas(this.empresaSigla);
        });
      // }
    // });
  }

  async ngOnInit() {

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        // console.log(agendas)
        // Faça algo com as notificações atualizadas
        // if(agendas.length > 0){
          this.agendas = agendas;
        // }
      },
      error => {
        // console.error('Erro ao listar as Agendas:', error);
      }
    );

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.agendamentoService.getAllAgendamentos()
    .pipe(takeUntil(this.destroy$))
    .subscribe(agendamentos => {
      if(agendamentos.length > 0){
        this.agendamentos = agendamentos;

        for (let index = 0; index < this.agendamentos.length; index++) {
          const agd = this.agendamentos[index];
          if(agd.id == this.id){
            Object.assign(this.agendamento, agd);
            this.agendaSelecionada = this.agendamento.agendaId
          }
        }

        if(this.agendamento.id == null){
          this.router.navigate([`${this.empresaSigla}/agendamentos`]);
        }
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async salvarAgendamento(){
    this.agendamento.placaVeiculo.status = this.validatorService.criarValidacao(this.agendamento.placaVeiculo.valor, this.agendamento.placaVeiculo.validacoesCustomizadas);
    this.agendamento.veiculo.status = this.validatorService.criarValidacao(this.agendamento.veiculo.valor, this.agendamento.veiculo.validacoesCustomizadas);
    this.agendamento.peso.status = this.validatorService.criarValidacao(this.agendamento.peso.valor, this.agendamento.peso.validacoesCustomizadas);
    this.agendamento.nomeMotorista.status = this.validatorService.criarValidacao(this.agendamento.nomeMotorista.valor, this.agendamento.nomeMotorista.validacoesCustomizadas);
    this.agendamento.cpfMotorista.status = this.validatorService.criarValidacao(this.agendamento.cpfMotorista.valor, this.agendamento.cpfMotorista.validacoesCustomizadas);
    this.agendamento.telefoneMotorista.status = this.validatorService.criarValidacao(this.agendamento.telefoneMotorista.valor, this.agendamento.telefoneMotorista.validacoesCustomizadas);
    this.agendamento.emailMotorista.status = this.validatorService.criarValidacao(this.agendamento.emailMotorista.valor, this.agendamento.emailMotorista.validacoesCustomizadas);
    this.agendamento.notaFiscal.status = this.validatorService.criarValidacao(this.agendamento.notaFiscal.valor, this.agendamento.notaFiscal.validacoesCustomizadas);
    this.agendamento.numeroPedido.status = this.validatorService.criarValidacao(this.agendamento.numeroPedido.valor, this.agendamento.numeroPedido.validacoesCustomizadas);
    this.agendamento.fornecedor.status = this.validatorService.criarValidacao(this.agendamento.fornecedor.valor, this.agendamento.fornecedor.validacoesCustomizadas);
    this.agendamento.duracaoAtendimento.status = this.validatorService.criarValidacao(this.agendamento.duracaoAtendimento.valor, this.agendamento.duracaoAtendimento.validacoesCustomizadas);
    this.agendamento.hora_inicial.status = this.validatorService.criarValidacao(this.agendamento.hora_inicial.valor, this.agendamento.hora_inicial.validacoesCustomizadas);

    if(
      this.agendamento.placaVeiculo.status.invalid ||
      this.agendamento.veiculo.status.invalid ||
      this.agendamento.peso.status.invalid ||
      this.agendamento.nomeMotorista.status.invalid ||
      this.agendamento.cpfMotorista.status.invalid ||
      this.agendamento.telefoneMotorista.status.invalid ||
      this.agendamento.emailMotorista.status.invalid ||
      this.agendamento.notaFiscal.status.invalid ||
      this.agendamento.numeroPedido.status.invalid ||
      this.agendamento.fornecedor.status.invalid ||
      this.agendamento.duracaoAtendimento.status.invalid ||
      this.agendamento.hora_inicial.status.invalid ||
      (this.agendaSelecionada == null)
    ){
      this.abrirModal(this.alertNovoAgendamentoModal.nativeElement);

  }else{
      // console.log(this.agendamento)

      // Obtenha o array existente do DataService
      let agendamento = this.agendamentos;

      // Encontre o índice do item correspondente em agendamento com base no ID
      const index = agendamento.findIndex((item: Agendamento) => item.id === this.agendamento.id);

      if (index !== -1) {
        this.agendamento.atualizado = new Date();
        // Atualize o item existente com as alterações de this.agendamento
        agendamento[index] = this.agendamento;

        agendamento[index].agendaId = this.agendaSelecionada;

        const tempo = this.tempo(agendamento[index].hora_inicial.valor, agendamento[index].duracaoAtendimento.valor)
        agendamento[index].hora_final.valor = tempo;


        const docId = agendamento[index].id != null ? agendamento[index].id?.toString() : "";

        const plainObject = JSON.stringify(agendamento[index])
        const plainString = JSON.parse(plainObject)

        plainString.cpfMotorista.status = null;
        plainString.dataAtendimento.status = null;
        plainString.duracaoAtendimento.status = null;
        plainString.emailMotorista.status = null;
        plainString.fornecedor.status = null;
        plainString.hora_final.status = null;
        plainString.hora_inicial.status = null;
        plainString.nomeMotorista.status = null;
        plainString.notaFiscal.status = null;
        plainString.numeroPedido.status = null;
        plainString.observacao.status = null;
        plainString.placaVeiculo.status = null;
        plainString.telefoneMotorista.status = null;
        plainString.status.status = null;
        plainString.statusExecucao.status = null;

        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: criarAgenda', conta);

          this.localhostService.putDados(`${conta.id}_agendamentos`, docId!, plainString)
          .then(async (response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess("Agendamento atualizado com sucesso");

            // Nova notificação
            // 1 - empresaSigla: any,
            // 2 - titulo: string,
            // 3 - texto: string,
            // 4 - rotaSemParamNome: string,
            // 5 - rotaSemParamListar: string,
            // 6 - rotaComParamNome: string = "",
            // 7 - rotaComParamCaminho: string = "",
            // 8 - rotaComParamParam: string = "",
            // 9 - linkExternoNome: string = "",
            // 10 - linkExternoURL: string = "",
            // 11 - idReferencia: string,
            // 12 - tipo: string,
            // 13 - idResponsavel: string,
            // 14 - nomeResponsavel: string

            const usr = await this.userService.carregarUsuario(this.empresaSigla)

            this.notificacaoService.criarNotificacao(
              this.empresaSigla,
              `NF ${plainString.notaFiscal.valor}`,
              `O agendamento do dia ${plainString.dataAtendimento.valor}, das ${plainString.hora_inicial.valor} às ${plainString.hora_final.valor}, foi atualizado.`,
              'Listar Agendamentos',
              'agendamentos',
              'Editar Agendamento',
              `agendamentos/agendamento`,
              plainString.id!.toString(),
              '',
              '',
              plainString.agendaId!.toString(),
              `Agendamento`,
              `${usr.id}`,
              `${usr.displayName.valor}`
            )
            this.router.navigate([`${this.empresaSigla}/agendamentos`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Agendamento não encontrado para atualização \n" + error);
          });

        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // this.authService.fazerLogout(this.empresaSigla);
          // console.log('Erro ao obter dados da conta:', error);
        });

        // // Salve o array de volta no DataService
        // DataService.setItem("Agendamento", agendamento);

        // this.showSuccess("Agendamento atualizado com sucesso");
        // this.router.navigate([`${this.empresaSigla}/agendamentos`]);
      } else {
        // O item não foi encontrado no array, você pode tratar isso conforme necessário
        this.showError("Agendamento não encontrado para atualização");
      }

    }
  }

  tempo(inicial: any, duraco: any){
    // Hora Inicial no formato "hh:mm"
    const horaInicial = inicial;

    // Duração de Atendimento no formato "hh:mm"
    const duracaoAtendimento = duraco;

    // Dividir as horas e minutos da Hora Inicial
    const [hiHoras, hiMinutos] = horaInicial.split(":").map(Number);

    // Dividir as horas e minutos da Duração de Atendimento
    const [duracaoHoras, duracaoMinutos] = duracaoAtendimento.split(":").map(Number);

    // Adicionar a duração ao horário inicial
    let horasFinais = hiHoras + duracaoHoras;
    let minutosFinais = hiMinutos + duracaoMinutos;

    // Verificar e corrigir se os minutos ultrapassarem 60
    if (minutosFinais >= 60) {
      horasFinais += Math.floor(minutosFinais / 60);
      minutosFinais %= 60;
    }

    // Formatar o Hora Final com 2 dígitos para horas e minutos
    const horaFinal = `${horasFinais.toString().padStart(2, "0")}:${minutosFinais.toString().padStart(2, "0")}`;

    // console.log("Hora Final:", horaFinal);

    return horaFinal;
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
