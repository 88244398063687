<div class="pb-4">

  <div class="container text-center min-width p-0">

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Relatórios</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row p-0 m-0">

      <div class="min-width col-12">
        <div class="row p-0 m-0 box-gen-card box-gen-card-top">
          <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0 mb-2">
            <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
              <span >
                Relatórios
              </span>
            </h3>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0">
            <div class="d-flex justify-content-between align-items-center flex-nowrap">
              <button type="button" class="btn btn-outline-primary" style="color: #fff; margin-right: 5px;">
                Atualizar <i class="bi bi-arrow-clockwise"></i>
              </button>

              <!-- <button type="button" class="btn btn-outline-primary" style="color: #fff; margin-right: 5px;" (click)="modo()">
                Modo <i class="bi bi-gear"></i>
              </button> -->
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
