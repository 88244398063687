import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ag-input-field',
  templateUrl: './ag-input-field.component.html',
  styleUrls: ['./ag-input-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgInputFieldComponent),
      multi: true,
    },
  ],
})
export class AgInputFieldComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() tooltip: string = '';
  @Input() isInvalid: boolean = false;
  @Input() value: string = '';
  @Input() id: string = '';
  @Input() idTooltip: string = '';
  @Input() AgPlaceholder: string = '';
  @Input() AgInputType: string = 'text';
  @Input() AgMaxlength: string = '';
  @Input() AgInputMode: string = 'text';
  @Input() ngModelChange: any;
  @Input() AgStatus: any;
  @Input() AgDisabled: boolean = false;

  // Usando um método getter e setter para acessar a innerValue
  private _innerValue: any;

  get innerValue(): any {
    return this._innerValue;
  }

  set innerValue(value: any) {
    this._innerValue = value;
    this.onChange(value); // Notifica o Angular sobre as mudanças
  }

  constructor() {}

  writeValue(value: any) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  // Quando o valor muda dentro do componente, atualize o valor interno
  onValueChange(value: any) {
    // console.log(value)
    this.innerValue = value;
  }

  // Define uma função para tratar a mudança do ngModel, se necessário
  onNgModelChange(event: any) {
    this.ngModelChange(event);
  }

}
