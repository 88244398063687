import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';
import { Slot } from 'src/class/slot';

@Injectable({
  providedIn: 'root'
})
export class SlotService {

  private slots: Slot[] = [];
  private slotsSubject = new BehaviorSubject<Slot[]>(this.slots);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService
  ) {
    this.slots = [];
    // this.getSlot();
  }



  async criarSlot(slot: Slot, empresaSigla: any) {
    // console.log("criarSlot", slot);
    slot.descricao.status = null;
    slot.nome.status = null;
    slot.duracaoSlot.status = null;

    for (let index = 0; index < slot.regraCampoFixo.length; index++) {
      const regra = slot.regraCampoFixo[index];
      regra.nomeCampo.status = null;
      regra.regraCampo.status = null;
      regra.regraValor1.status = null;
      regra.regraValor2.status = null;
    }

    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarSlot', conta);

      const response = await this.localhostService.postDadosId(`${conta.id}_slots`, slot);
      // console.log('Slot criado com sucesso:', response);

      // Atualize a lista local de agendas
      this.slots.push(slot);
      this.slotsSubject.next(this.slots);

      // Notifique o usuário sobre o sucesso
      this.showSuccess('Slot criado com sucesso');

      // Nova notificação
      // 1 - empresaSigla: any,
      // 2 - titulo: string,
      // 3 - texto: string,
      // 4 - rotaSemParamNome: string,
      // 5 - rotaSemParamListar: string,
      // 6 - rotaComParamNome: string = "",
      // 7 - rotaComParamCaminho: string = "",
      // 8 - rotaComParamParam: string = "",
      // 9 - linkExternoNome: string = "",
      // 10 - linkExternoURL: string = "",
      // 11 - idReferencia: string,
      // 12 - tipo: string,
      // 13 - idResponsavel: string,
      // 14 - nomeResponsavel: string

      const usr = await this.userService.carregarUsuario(empresaSigla)

      this.notificacaoService.criarNotificacao(
        empresaSigla,
        `${slot.nome.valor}`,
        `Slot criado.`,
        'Listar Slots',
        'slots',
        'Editar Slot',
        `slots/slot`,
        slot.id?.toString(),
        '',
        '',
        slot.id!.toString(),
        `Slot`,
        `${usr.id}`,
        `${usr.displayName.valor}`
      )

      return true;
    } catch (error) {
      // console.error('Erro ao criar slot:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllSlot() {
    return this.slotsSubject.asObservable().pipe(
      map(slots => slots)
    );
  }

  async getSlot(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
      .then((conta) => {
        // Faça algo com os dados obtidos
        // console.log('Dados da conta: getSlot', conta);

        this.localhostService.getDados(`${conta.id}_slots`).subscribe(
          (slotFromServer: any[]) => {
            if (slotFromServer) {
              // Mapeia os dados para a estrutura desejada
              const slotArray: Slot[] = slotFromServer.map(slot => plainToClass(Slot, slot));

              // console.log(slotArray);

              this.slots = slotArray;
              this.slotsSubject.next(this.slots);
              // this.updateLocalStorage();
            } else {
              this.slots = [];
            }
          },
          (error) => {
            // console.error('Erro ao obter slots:', error);
          }
        );
      },
        (error: any) => {
          // console.error('Erro ao obter slots:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // console.log('Erro ao obter dados da conta:', error);
        });

  }

  private updateLocalStorage() {
    localStorage.setItem("Slot", JSON.stringify(this.slots));
    DataService.set("Slot", this.slots);
  }

  private showSuccess(msg: string) {
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string) {
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
