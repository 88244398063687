import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-ag-input-field-datepicker',
  templateUrl: './ag-input-field-datepicker.component.html',
  styleUrls: ['./ag-input-field-datepicker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgInputFieldDatepickerComponent),
      multi: true,
    },
  ],
})
export class AgInputFieldDatepickerComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() tooltip: string = '';
  @Input() isInvalid: boolean = false;
  @Input() value: string = '';
  @Input() id: string = '';
  @Input() idTooltip: string = '';
  @Input() AgPlaceholder: string = '';
  @Input() AgInputType: string = 'text';
  @Input() AgMaxlength: string = '';
  @Input() AgInputMode: string = 'text';
  @Input() ngModelChange: any;
  @Input() AgBsConfigSimples: any;
  @Input() AgStatus: any;

  bsConfigSimples = {
    containerClass: 'theme-custom-datepicker', // Classe CSS personalizada
    rangeInputFormat: 'DD/MM/YYYY', // Formato de saída para datas selecionadas
    showWeekNumbers: false, // Mostrar números da semana
    selectFromOtherMonth: true, // Permite seleção de dias de outros meses
    customTodayClass: 'custom-datepicker-today',
    adaptivePosition: true, // Posicionamento adaptativo do calendário
    // placement: 'bottom', // Posição do calendário (top, bottom, left, right)
  };

  pickerConfig = {
    showMeridian: true, // Permite o formato AM/PM (12 horas)
  };

  // Usando um método getter e setter para acessar a innerValue
  private _innerValue: any;

  get innerValue(): any {
    return this._innerValue;
  }

  set innerValue(value: any) {
    this._innerValue = value;
    this.onChange(value); // Notifica o Angular sobre as mudanças
  }

  constructor(private localeService: BsLocaleService) {
    defineLocale('pt-br', ptBrLocale);
    this.localeService.use('pt-br');
  }

  writeValue(value: any) {
    // console.log(value.replace(/[\D]+/g, ''))

    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  // Quando o valor muda dentro do componente, atualize o valor interno
  onValueChange(value: any) {
    // console.log(value)
    if (value instanceof Date && !isNaN(value.getTime())) {
      this.innerValue = value.toLocaleDateString();
    } else if (value == "Invalid Date") {
      this.innerValue = "";
    } else if (value && value.trim() !== "") {
      this.innerValue = value;
    }else{
      this.innerValue = "";
    }
    // console.log(value)

  }

  // Define uma função para tratar a mudança do ngModel, se necessário
  onNgModelChange(event: any) {
    this.ngModelChange(event);
  }

  openCalendar() {
    const element = document.querySelector('#'+this.id) as HTMLElement;
    if (element) {
      element.click();
    }
  }
}
