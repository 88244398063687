<div class="pb-4 min-width">

  <div class="container text-center p-0">

    <!-- 👇 Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('agendamentos')" class="text-padrao">Agendamentos</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('agendamentos')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>
    <!-- Breadcrumb 👆-->

    <!-- 👇 Novo Agendamento -->
    <div class="row p-0 m-0">

      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Novo Agendamento
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Dados da Agenda -->

      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12 reset-padding-mobile">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Agenda </h3>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">

            <div class="col-lg-6 pb-3 reset-padding-mobile">

              <!-- Loading -->
              <div *ngIf="loading" title="Loading...">
                <div style=" display: flex; align-items: center; ">
                  <label class="form-label label-input-text-padrao" style="display: flex; align-items: center; flex-wrap: nowrap;margin-bottom: 5px;">
                    Agendas disponíveis
                  </label>
                </div>
                <div class="d-flex flex-column align-items-start flex-wrap">
                  <div class="p-0 m-0 pulsate-input" >
                  </div>
                </div>
              </div>

              <app-ag-ng-select *ngIf="!loading"
                   [(ngModel)]="agendaSelecionada"
                   label="Agendas disponíveis"
                   [id]="'agendaDisponivel'"
                   tooltip="Agendas disponíveis"
                   [AgPlaceholder]="'Selecione uma agenda...'"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   [AgOptions]="agendas"
                   AgSelectType="campo">
              </app-ag-ng-select>

            </div>

            <!-- <div class="col-lg-6 pb-3 reset-padding-mobile">
              <app-ag-ng-select [(ngModel)]="agendamento.status.valor"
                   [label]="agendamento.status.title"
                   [id]="'status'"
                   [tooltip]="agendamento.status.info"
                   AgPlaceholder="{{agendamento.status.placeholder}}"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   [AgOptions]="agendamento.statusAgendamentoOptions"
                   [isInvalid]="agendamento.status.status?.invalid"
                   [AgStatus]="agendamento.status.status"
                   AgSelectType="checkbox"
                   (ngModelChange)="agendamento.validarInput('status')">
              </app-ag-ng-select>
            </div> -->

            <!-- agendamento.dataAtendimento -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field-datepicker
                [(ngModel)]="agendamento.dataAtendimento.valor"
                [label]="agendamento.dataAtendimento.title"
                [tooltip]="agendamento.dataAtendimento.info"
                [value]="agendamento.dataAtendimento.valor"
                [isInvalid]="agendamento.dataAtendimento.status?.invalid"
                [AgStatus]="agendamento.dataAtendimento.status"
                [id]="'dataAtendimento_agenda'"
                AgPlaceholder="{{agendamento.dataAtendimento.placeholder}}"
                AgInputType="{{agendamento.dataAtendimento.inputType}}"
                AgMaxlength="{{agendamento.dataAtendimento.maxlength}}"
                AgInputMode="{{agendamento.dataAtendimento.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.dataAtendimento.mascara, 'dataAtendimento');"
                (change)="agendamento.validarInput('dataAtendimento');"
                >
              </app-ag-input-field-datepicker>
            </div>
            <!-- agendamento.hora_inicial -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.hora_inicial.valor"
                [label]="agendamento.hora_inicial.title"
                [tooltip]="agendamento.hora_inicial.info"
                [value]="agendamento.hora_inicial.valor"
                [isInvalid]="agendamento.hora_inicial.status?.invalid"
                [AgStatus]="agendamento.hora_inicial.status"
                [id]="'hora_inicial'"
                AgPlaceholder="{{agendamento.hora_inicial.placeholder}}"
                AgInputType="{{agendamento.hora_inicial.inputType}}"
                AgMaxlength="{{agendamento.hora_inicial.maxlength}}"
                AgInputMode="{{agendamento.hora_inicial.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.hora_inicial.mascara, 'hora_inicial');"
                (change)="agendamento.validarInput('hora_inicial');"
              ></app-ag-input-field>
            </div>

            <!-- agendamento.duracaoAtendimento -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.duracaoAtendimento.valor"
                [label]="agendamento.duracaoAtendimento.title"
                [tooltip]="agendamento.duracaoAtendimento.info"
                [value]="agendamento.duracaoAtendimento.valor"
                [isInvalid]="agendamento.duracaoAtendimento.status?.invalid"
                [AgStatus]="agendamento.duracaoAtendimento.status"
                [id]="'duracaoAtendimento'"
                AgPlaceholder="{{agendamento.duracaoAtendimento.placeholder}}"
                AgInputType="{{agendamento.duracaoAtendimento.inputType}}"
                AgMaxlength="{{agendamento.duracaoAtendimento.maxlength}}"
                AgInputMode="{{agendamento.duracaoAtendimento.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.duracaoAtendimento.mascara, 'duracaoAtendimento');"
                (change)="agendamento.validarInput('duracaoAtendimento');"
              ></app-ag-input-field>
            </div>

          </div>
        </div>
      </div>

      <!-- Dados do Motorista -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12 reset-padding-mobile">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Dados do Motorista </h3>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">

            <!-- agendamento.nomeMotorista -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.nomeMotorista.valor"
                [label]="agendamento.nomeMotorista.title"
                [tooltip]="agendamento.nomeMotorista.info"
                [value]="agendamento.nomeMotorista.valor"
                [isInvalid]="agendamento.nomeMotorista.status?.invalid"
                [AgStatus]="agendamento.nomeMotorista.status"
                [id]="'nomeMotorista'"
                [AgStatus]="agendamento.nomeMotorista.status"
                AgPlaceholder="{{agendamento.nomeMotorista.placeholder}}"
                AgInputType="{{agendamento.nomeMotorista.inputType}}"
                AgMaxlength="{{agendamento.nomeMotorista.maxlength}}"
                AgInputMode="{{agendamento.nomeMotorista.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.nomeMotorista.mascara, 'nomeMotorista');"
                (change)="agendamento.validarInput('nomeMotorista');"
              ></app-ag-input-field>
            </div>

            <!-- agendamento.cpfMotorista -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.cpfMotorista.valor"
                [label]="agendamento.cpfMotorista.title"
                [tooltip]="agendamento.cpfMotorista.info"
                [value]="agendamento.cpfMotorista.valor"
                [isInvalid]="agendamento.cpfMotorista.status?.invalid"
                [AgStatus]="agendamento.cpfMotorista.status"
                [id]="'cpfMotorista'"
                AgPlaceholder="{{agendamento.cpfMotorista.placeholder}}"
                AgInputType="{{agendamento.cpfMotorista.inputType}}"
                AgMaxlength="{{agendamento.cpfMotorista.maxlength}}"
                AgInputMode="{{agendamento.cpfMotorista.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.cpfMotorista.mascara, 'cpfMotorista');"
                (change)="agendamento.validarInput('cpfMotorista');"
              ></app-ag-input-field>
            </div>

            <!-- agendamento.placaVeiculo -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.placaVeiculo.valor"
                [label]="agendamento.placaVeiculo.title"
                [tooltip]="agendamento.placaVeiculo.info"
                [value]="agendamento.placaVeiculo.valor"
                [isInvalid]="agendamento.placaVeiculo.status?.invalid"
                [AgStatus]="agendamento.placaVeiculo.status"
                [id]="'placaVeiculo'"
                AgPlaceholder="{{agendamento.placaVeiculo.placeholder}}"
                AgInputType="{{agendamento.placaVeiculo.inputType}}"
                AgMaxlength="{{agendamento.placaVeiculo.maxlength}}"
                AgInputMode="{{agendamento.placaVeiculo.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.placaVeiculo.mascara, 'placaVeiculo');"
                (change)="agendamento.validarInput('placaVeiculo');"
              ></app-ag-input-field>
            </div>

            <!-- agendamento.veiculo -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.veiculo.valor"
                [label]="agendamento.veiculo.title"
                [tooltip]="agendamento.veiculo.info"
                [value]="agendamento.veiculo.valor"
                [isInvalid]="agendamento.veiculo.status?.invalid"
                [AgStatus]="agendamento.veiculo.status"
                [id]="'veiculo'"
                AgPlaceholder="{{agendamento.veiculo.placeholder}}"
                AgInputType="{{agendamento.veiculo.inputType}}"
                AgMaxlength="{{agendamento.veiculo.maxlength}}"
                AgInputMode="{{agendamento.veiculo.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.veiculo.mascara, 'veiculo');"
                (change)="agendamento.validarInput('veiculo');"
              ></app-ag-input-field>
            </div>

            <!-- agendamento.peso -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.peso.valor"
                [label]="agendamento.peso.title"
                [tooltip]="agendamento.peso.info"
                [value]="agendamento.peso.valor"
                [isInvalid]="agendamento.peso.status?.invalid"
                [AgStatus]="agendamento.peso.status"
                [id]="'peso'"
                AgPlaceholder="{{agendamento.peso.placeholder}}"
                AgInputType="{{agendamento.peso.inputType}}"
                AgMaxlength="{{agendamento.peso.maxlength}}"
                AgInputMode="{{agendamento.peso.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.peso.mascara, 'peso');"
                (change)="agendamento.validarInput('peso');"
              ></app-ag-input-field>
            </div>

            <!-- agendamento.telefoneMotorista -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.telefoneMotorista.valor"
                [label]="agendamento.telefoneMotorista.title"
                [tooltip]="agendamento.telefoneMotorista.info"
                [value]="agendamento.telefoneMotorista.valor"
                [isInvalid]="agendamento.telefoneMotorista.status?.invalid"
                [AgStatus]="agendamento.telefoneMotorista.status"
                [id]="'telefoneMotorista'"
                AgPlaceholder="{{agendamento.telefoneMotorista.placeholder}}"
                AgInputType="{{agendamento.telefoneMotorista.inputType}}"
                AgMaxlength="{{agendamento.telefoneMotorista.maxlength}}"
                AgInputMode="{{agendamento.telefoneMotorista.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.telefoneMotorista.mascara, 'telefoneMotorista');"
                (change)="agendamento.validarInput('telefoneMotorista');"
              ></app-ag-input-field>
            </div>

            <!-- agendamento.emailMotorista -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.emailMotorista.valor"
                [label]="agendamento.emailMotorista.title"
                [tooltip]="agendamento.emailMotorista.info"
                [value]="agendamento.emailMotorista.valor"
                [isInvalid]="agendamento.emailMotorista.status?.invalid"
                [AgStatus]="agendamento.emailMotorista.status"
                [id]="'emailMotorista'"
                AgPlaceholder="{{agendamento.emailMotorista.placeholder}}"
                AgInputType="{{agendamento.emailMotorista.inputType}}"
                AgMaxlength="{{agendamento.emailMotorista.maxlength}}"
                AgInputMode="{{agendamento.emailMotorista.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.emailMotorista.mascara, 'emailMotorista');"
                (change)="agendamento.validarInput('emailMotorista');"
              ></app-ag-input-field>
            </div>

          </div>

        </div>
      </div>

      <!-- Dados Complementares -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12 reset-padding-mobile">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Dados complementares </h3>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">

            <!-- agendamento.notaFiscal -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.notaFiscal.valor"
                [label]="agendamento.notaFiscal.title"
                [tooltip]="agendamento.notaFiscal.info"
                [value]="agendamento.notaFiscal.valor"
                [isInvalid]="agendamento.notaFiscal.status?.invalid"
                [AgStatus]="agendamento.notaFiscal.status"
                [id]="'notaFiscal'"
                AgPlaceholder="{{agendamento.notaFiscal.placeholder}}"
                AgInputType="{{agendamento.notaFiscal.inputType}}"
                AgMaxlength="{{agendamento.notaFiscal.maxlength}}"
                AgInputMode="{{agendamento.notaFiscal.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.notaFiscal.mascara, 'notaFiscal');"
                (change)="agendamento.validarInput('notaFiscal');"
              ></app-ag-input-field>
            </div>

            <!-- agendamento.numeroPedido -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.numeroPedido.valor"
                [label]="agendamento.numeroPedido.title"
                [tooltip]="agendamento.numeroPedido.info"
                [value]="agendamento.numeroPedido.valor"
                [isInvalid]="agendamento.numeroPedido.status?.invalid"
                [AgStatus]="agendamento.numeroPedido.status"
                [id]="'numeroPedido'"
                AgPlaceholder="{{agendamento.numeroPedido.placeholder}}"
                AgInputType="{{agendamento.numeroPedido.inputType}}"
                AgMaxlength="{{agendamento.numeroPedido.maxlength}}"
                AgInputMode="{{agendamento.numeroPedido.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.numeroPedido.mascara, 'numeroPedido');"
                (change)="agendamento.validarInput('numeroPedido');"
              ></app-ag-input-field>
            </div>

            <!-- agendamento.fornecedor -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agendamento.fornecedor.valor"
                [label]="agendamento.fornecedor.title"
                [tooltip]="agendamento.fornecedor.info"
                [value]="agendamento.fornecedor.valor"
                [isInvalid]="agendamento.fornecedor.status?.invalid"
                [AgStatus]="agendamento.fornecedor.status"
                [id]="'fornecedor'"
                AgPlaceholder="{{agendamento.fornecedor.placeholder}}"
                AgInputType="{{agendamento.fornecedor.inputType}}"
                AgMaxlength="{{agendamento.fornecedor.maxlength}}"
                AgInputMode="{{agendamento.fornecedor.inputMode}}"
                (ngModelChange)="agendamento.mascara(agendamento.fornecedor.mascara, 'fornecedor');"
                (change)="agendamento.validarInput('fornecedor');"
              ></app-ag-input-field>
            </div>

          </div>

    </div>

    <!-- Links Relacionados -->
    <!-- <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg  text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div> -->

      </div>

      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="criarAgendamento()">Criar Agendamento</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('agendamentos')" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>

    </div>
    <!-- Novo Agendamento 👆 -->


  </div>
</div>

<!-- alert Novo Agendamento Modal -->
<div class="modal" id="alertNovoAgendamentoModal" #alertNovoAgendamentoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="agendaSelecionada == null" class="border-alerta gen-card">
            <p class="text-cor-padrao gen-card-title">Atenção</p>
            <p class="text-cor-padrao gen-card-info">Selecione uma agenda</p>
          </div>

          <div *ngIf="agendamento.nomeMotorista.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.nomeMotorista.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.nomeMotorista.info }}</p>
          </div>
          <div *ngIf="agendamento.cpfMotorista.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.cpfMotorista.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.cpfMotorista.info }}</p>
          </div>
          <div *ngIf="agendamento.placaVeiculo.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.placaVeiculo.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.placaVeiculo.info }}</p>
          </div>
          <div *ngIf="agendamento.veiculo.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.veiculo.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.veiculo.info }}</p>
          </div>
          <div *ngIf="agendamento.peso.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.peso.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.peso.info }}</p>
          </div>
          <div *ngIf="agendamento.telefoneMotorista.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.telefoneMotorista.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.telefoneMotorista.info }}</p>
          </div>
          <div *ngIf="agendamento.emailMotorista.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.emailMotorista.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.emailMotorista.info }}</p>
          </div>
          <div *ngIf="agendamento.notaFiscal.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.notaFiscal.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.notaFiscal.info }}</p>
          </div>
          <div *ngIf="agendamento.numeroPedido.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.numeroPedido.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.numeroPedido.info }}</p>
          </div>
          <div *ngIf="agendamento.fornecedor.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agendamento.fornecedor.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agendamento.fornecedor.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>

<!-- alert Novo Agendamento Modal -->
<div class="modal" id="ConfirmacaoNovoAgendamentoModal" #ConfirmacaoNovoAgendamentoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Confirmação dos dados</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div class="row p-0 m-0">

          <div class="col-lg-12 pb-3">
            Dados
          </div>

          <div class="col-lg-12 pb-3">
            <app-ag-ng-select [(ngModel)]="agendamento.status.valor"
                 [label]="agendamento.status.title"
                 [id]="'status'"
                 [tooltip]="agendamento.status.info"
                 AgPlaceholder="{{agendamento.status.placeholder}}"
                 [AgMultiple]="false"
                 [AgCloseOnSelect]="true"
                 [AgOptions]="agendamento.statusAgendamentoOptions"
                 [isInvalid]="agendamento.status.status?.invalid"
                 [AgStatus]="agendamento.status.status"
                 AgSelectType="checkbox"
                 [AgAppendTo]="'#ConfirmacaoNovoAgendamentoModal'"
                 (ngModelChange)="agendamento.validarInput('status')">
            </app-ag-ng-select>
          </div>


        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>
