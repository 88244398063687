import { FormControl } from "@angular/forms";
import { campo } from "src/interface/campo";
import { ValidatorService } from "src/services/validator.service";

const validatorService: ValidatorService  = new ValidatorService();

export class GrupoUsuario {

  id: any | null = null;

  nome: campo = {
    title: 'Nome do grupo',
    info: 'Nome do grupo',
    valor: '',
    placeholder: 'Nome do grupo',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  descricao: campo = {
    title: 'Descrição do grupo',
    info: 'Descrição do grupo',
    valor: '',
    placeholder: 'Descrição do grupo',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  status: campo = {
    title: 'Status',
    info: 'Status',
    valor: true,
  };

  recursos: RecursoGrupo[] = [];

  criado: Date = new Date();

  atualizado: Date = new Date();

  constructor(){}

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "nome":
        this.nome.valor = validatorService.aplicarMascara(this.nome.valor, mascara);
        break;
      case "descricao":
        this.descricao.valor = validatorService.aplicarMascara(this.descricao.valor, mascara);
        break;
      default:
        break;
    }
  }

  validarInput(tipo: string) {
    switch (tipo) {
      case "nome":
        this.nome.valor = this.nome.valor.trim();
        this.nome.status = validatorService.criarValidacao(this.nome.valor, this.nome.validacoesCustomizadas);
        break;
      case "descricao":
        this.descricao.valor = this.descricao.valor.trim();
        this.descricao.status = validatorService.criarValidacao(this.descricao.valor, this.descricao.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }
}

export class RecursoGrupo{
  idRecurso?: string;
  NomeRecurso?: string;
}
