<div class="pb-4">

  <div class="container text-center min-width p-0">

    <!-- Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('agendas')" class="text-padrao">Agendas</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('agendas/servicos')" class="text-padrao">Serviços</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('agendas/servicos')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>

    <!-- Serviço -->
    <div class="row p-0 m-0">

      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Novo Serviço
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Serviço Dados -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0">
            <!-- servico.nome -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="servico.nome.valor"
                [label]="servico.nome.title"
                [tooltip]="servico.nome.info"
                [value]="servico.nome.valor"
                [isInvalid]="servico.nome.status?.invalid"
                [AgStatus]="servico.nome.status"
                [id]="'new_servico_nome'"
                AgPlaceholder="{{servico.nome.placeholder}}"
                AgInputType="{{servico.nome.inputType}}"
                AgMaxlength="{{servico.nome.maxlength}}"
                AgInputMode="{{servico.nome.inputMode}}"
                (ngModelChange)="servico.mascara(servico.nome.mascara, 'nome');"
                (change)="servico.validarInput('nome');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.valor -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="servico.valor.valor"
                [label]="servico.valor.title"
                [tooltip]="servico.valor.info"
                [value]="servico.valor.valor"
                [isInvalid]="servico.valor.status?.invalid"
                [AgStatus]="servico.valor.status"
                [id]="'new_servico_valor'"
                AgPlaceholder="{{servico.valor.placeholder}}"
                AgInputType="{{servico.valor.inputType}}"
                AgMaxlength="{{servico.valor.maxlength}}"
                AgInputMode="{{servico.valor.inputMode}}"
                (ngModelChange)="servico.mascara(servico.valor.mascara, 'valor');"
                (change)="servico.validarInput('valor');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.linkPagamento -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="servico.linkPagamento.valor"
                [label]="servico.linkPagamento.title"
                [tooltip]="servico.linkPagamento.info"
                [value]="servico.linkPagamento.valor"
                [isInvalid]="servico.linkPagamento.status?.invalid"
                [AgStatus]="servico.linkPagamento.status"
                [id]="'new_servico_linkPagamento'"
                AgPlaceholder="{{servico.linkPagamento.placeholder}}"
                AgInputType="{{servico.linkPagamento.inputType}}"
                AgMaxlength="{{servico.linkPagamento.maxlength}}"
                AgInputMode="{{servico.linkPagamento.inputMode}}"
                (ngModelChange)="servico.mascara(servico.linkPagamento.mascara, 'linkPagamento');"
                (change)="servico.validarInput('linkPagamento');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.duracaoAtendimento -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="servico.duracaoAtendimento.valor"
                [label]="servico.duracaoAtendimento.title"
                [tooltip]="servico.duracaoAtendimento.info"
                [value]="servico.duracaoAtendimento.valor"
                [isInvalid]="servico.duracaoAtendimento.status?.invalid"
                [AgStatus]="servico.duracaoAtendimento.status"
                [id]="'new_servico_duracaoAtendimento'"
                AgPlaceholder="{{servico.duracaoAtendimento.placeholder}}"
                AgInputType="{{servico.duracaoAtendimento.inputType}}"
                AgMaxlength="{{servico.duracaoAtendimento.maxlength}}"
                AgInputMode="{{servico.duracaoAtendimento.inputMode}}"
                (ngModelChange)="servico.mascara(servico.duracaoAtendimento.mascara, 'duracaoAtendimento');"
                (change)="servico.validarInput('duracaoAtendimento');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.numeroPessoasPorHorario -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="servico.numeroPessoasPorHorario.valor"
                [label]="servico.numeroPessoasPorHorario.title"
                [tooltip]="servico.numeroPessoasPorHorario.info"
                [value]="servico.numeroPessoasPorHorario.valor"
                [isInvalid]="servico.numeroPessoasPorHorario.status?.invalid"
                [AgStatus]="servico.numeroPessoasPorHorario.status"
                [id]="'new_servico_numeroPessoasPorHorario'"
                AgPlaceholder="{{servico.numeroPessoasPorHorario.placeholder}}"
                AgInputType="{{servico.numeroPessoasPorHorario.inputType}}"
                AgMaxlength="{{servico.numeroPessoasPorHorario.maxlength}}"
                AgInputMode="{{servico.numeroPessoasPorHorario.inputMode}}"
                (ngModelChange)="servico.mascara(servico.numeroPessoasPorHorario.mascara, 'numeroPessoasPorHorario');"
                (change)="servico.validarInput('numeroPessoasPorHorario');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.instrucoesConfirmacao -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-textarea-field
                [(ngModel)]="servico.instrucoesConfirmacao.valor"
                [label]="servico.instrucoesConfirmacao.title"
                [tooltip]="servico.instrucoesConfirmacao.info"
                [value]="servico.instrucoesConfirmacao.valor"
                [isInvalid]="servico.instrucoesConfirmacao.status?.invalid"
                [AgStatus]="servico.instrucoesConfirmacao.status"
                [id]="'new_servico_instrucoesConfirmacao'"
                AgPlaceholder="{{servico.instrucoesConfirmacao.placeholder}}"
                AgInputType="{{servico.instrucoesConfirmacao.inputType}}"
                AgMaxlength="{{servico.instrucoesConfirmacao.maxlength}}"
                AgInputMode="{{servico.instrucoesConfirmacao.inputMode}}"
                (ngModelChange)="servico.mascara(servico.instrucoesConfirmacao.mascara, 'instrucoesConfirmacao');"
                (change)="servico.validarInput('instrucoesConfirmacao');"
                >
              </app-ag-textarea-field>
            </div>

            <!-- servico.mostrarValorParaClientes -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="servico.mostrarValorParaClientes.valor"
                [label]="servico.mostrarValorParaClientes.title"
                [tooltip]="servico.mostrarValorParaClientes.info"
                [value]="servico.mostrarValorParaClientes.valor"
                [id]="'new_servico_mostrar_valor_para_cliente'"
                >
              </app-ag-mat-checkbox>
            </div>

            <!-- servico.vincularEmTodasAgendas -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="servico.vincularEmTodasAgendas.valor"
                [label]="servico.vincularEmTodasAgendas.title"
                [tooltip]="servico.vincularEmTodasAgendas.info"
                [value]="servico.vincularEmTodasAgendas.valor"
                [id]="'new_servico_vincular_em_todas_agendas'"
                >
              </app-ag-mat-checkbox>
            </div>



          </div>
        </div>
      </div>

      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <!-- servico.status -->
          <app-ag-mat-checkbox
            [(ngModel)]="servico.status.valor"
            [label]="servico.status.title"
            [tooltip]="servico.status.info"
            [value]="servico.status.valor"
            [id]="'new_servico_status'"
            >
          </app-ag-mat-checkbox>

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="criarServico()">Criar Serviço</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('agendas/servicos')" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>


      <!-- <input type="file" (change)="onFileSelected($event)">
      <button (click)="upload()">Upload</button> -->

    </div>

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg  text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div>

  </div>
</div>


<!-- alert Serviço Modal -->
<div class="modal" id="alertServicoModal" #alertServicoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="servico.nome.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.nome.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.nome.info }}</p>
          </div>

          <div *ngIf="servico.valor.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.valor.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.valor.info }}</p>
          </div>

          <div *ngIf="servico.mostrarValorParaClientes.status" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.mostrarValorParaClientes.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.mostrarValorParaClientes.info }}</p>
          </div>

          <div *ngIf="servico.linkPagamento.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.linkPagamento.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.linkPagamento.info }}</p>
          </div>

          <div *ngIf="servico.duracaoAtendimento.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.duracaoAtendimento.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.duracaoAtendimento.info }}</p>
          </div>

          <div *ngIf="servico.numeroPessoasPorHorario.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.numeroPessoasPorHorario.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.numeroPessoasPorHorario.info }}</p>
          </div>

          <div *ngIf="servico.instrucoesConfirmacao.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.instrucoesConfirmacao.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.instrucoesConfirmacao.info }}</p>
          </div>

          <div *ngIf="servico.vincularEmTodasAgendas.status" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.vincularEmTodasAgendas.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.vincularEmTodasAgendas.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>
