import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ag-mat-radio',
  templateUrl: './ag-mat-radio.component.html',
  styleUrls: ['./ag-mat-radio.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgMatRadioComponent),
      multi: true,
    },
  ],
})
export class AgMatRadioComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() tooltip: string = '';
  @Input() id: string = '';
  @Input() isInvalid: boolean = false;
  @Input() AgStatus: any;
  @Input() options: any[] = []; // Lista de opções para os radios
  @Input() ngModelChange: any;

  private _innerValue: any;

  get innerValue(): any {
    return this._innerValue;
  }

  set innerValue(value: any) {
    this._innerValue = value;
    this.onChange(this._innerValue);
  }

  constructor() {}

  writeValue(value: any) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  onRadioChange(option: any) {
    this.innerValue = option.value;
    this.onChange(this.innerValue);
  }

  isChecked(option: any): boolean {
    return this.innerValue === option.value;
  }
}

