import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ThemeService } from 'src/services/theme.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Conta } from 'src/class/conta';
import { Links } from 'src/class/links';
import { objUsuario } from 'src/class/usuario';
import { StatusAgendamento } from 'src/enum/statusAgendamento.enum';
import { SearchTipoPipe } from 'src/pipes/search-tipo.pipe';
import { LinksService } from 'src/services/links.service';
import { LocalhostService } from 'src/services/localhost.service';
import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';
import { ToastService } from 'src/services/toast.service';
import { ContaService } from 'src/services/component/conta.service';
import { SiglaService } from 'src/services/siglaService.service';

@Component({
  selector: 'app-conta',
  templateUrl: './conta.component.html',
  styleUrls: ['./conta.component.css']
})
export class ContaComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj = new objUsuario;

  agenda_arr: any [] = [];

  pesquisa="";
  pag = 1;
  contador = 5;
  pageSize = 5;
  pageIndex = 0;
  totalizador = 0;
  maxSize = 5;
  visualizando = this.contador;
  lightMode: any;
  modoView = false;
  modoViewNum = 12;

  private subscription: Subscription;

  linksAgenda: Links[] = [];

  statusAgendamento = StatusAgendamento;

  contas: Conta[] = [];
  displayedContas: Conta[] = [];
  loading: boolean = true;
  sortedData: Conta[] = [];

  loader = this.loadingBar.useRef('http');
  empresaSigla: any | undefined;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService,
    private linksService: LinksService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private paginatorIntl: MatPaginatorIntl,
    private searchTipoPipe: SearchTipoPipe,
    private loadingBar: LoadingBarService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    private contaService: ContaService,
    private localhostService: LocalhostService,
    private siglaService: SiglaService
  ){
    this.subscription = this.themeService.lightMode$.subscribe(lightMode => {
      this.lightMode = lightMode;
    });

    this.paginatorIntl.itemsPerPageLabel = 'Itens por página:';
    this.paginatorIntl.nextPageLabel = 'Próxima página';
    this.paginatorIntl.previousPageLabel = 'Página anterior';
    this.paginatorIntl.firstPageLabel = 'Primeira página';
    this.paginatorIntl.lastPageLabel = 'Última página';

    // Personalize a indexação para começar em 1
    // this.paginatorIntl.getRangeLabel = this.getPortugueseRangeLabel;

    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.contaService.getConta();
        });
      // }
    // });
  }

  ngOnInit(): void {
    this.getCache();

    // console.log(this.statusAgendamento)

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.changeDetectorRef.detectChanges();

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  getCache(){

    let modoView = localStorage.getItem("modoView");

    let modoViewNum = localStorage.getItem("modoViewNum");

    if(modoView){
      this.modoView = modoView == 'true' ? true : false
    }

    if(modoViewNum){
      this.modoViewNum = parseInt(modoViewNum);
    }

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    this.changeDetectorRef.detectChanges();

    this.contaService.getAllConta()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (contas: Conta[]) => {
        // console.log(contas)
        // Faça algo com as notificações atualizadas
        this.contas = contas;

        this.loading = false;

        this.displayedContas = this.contas.slice(
          this.pageIndex * this.pageSize,
          (this.pageIndex + 1) * this.pageSize
        );

        this.route.queryParams.subscribe((params: any) => {
          // O parâmetro de consulta 'search' estará em params.search
          this.pesquisa = params.search ? params.search : '';
          // console.log(params)
          // Agora você pode usar o valor da pesquisa em sua função searchAgenda
          if(this.pesquisa != ''){
            this.searchTipo();
          }
        });

      },
      error => {
        // console.error('Erro ao listar os Tipos:', error);
        this.router.navigate(['/home']);
      }
    );

  }

  searchTipo(){
    const retorno = this.searchTipoPipe.transform(this.contas, this.pesquisa);

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.displayedContas = retorno;

    if(this.pesquisa == ""){
      this.displayedContas = this.contas.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      this.paginator.length = this.contas.length;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { search: null },
        queryParamsHandling: 'merge',
      });

    }else{

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ['search']: this.pesquisa },
        queryParamsHandling: 'merge',
      });

      this.displayedContas = retorno.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      if (this.paginator) {
        this.paginator.length = retorno.length;
      }
    }
  }

  handlePage(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    // Calcule o índice de início e fim dos itens a serem exibidos na página atual
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    // Atualize os itens a serem exibidos na tabela
    this.displayedContas = this.contas.slice(startIndex, endIndex);

    // console.log(this.displayedContas)
  }

  sortData(sort: Sort) {
    const data = this.contas.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      this.contas = this.sortedData
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'id':
          return this.compare(a.id!, b.id!, isAsc);
        case 'status':
          return this.compare(a.status.valor, b.status.valor, isAsc);
        // case 'nome':
        //   return this.compare(a.nome.valor, b.nome.valor, isAsc);
        // case 'descricao':
        //   return this.compare(a.descricao.valor, b.descricao.valor, isAsc);
        // case 'criado':
        //   return this.compare(a.criado.toString(), b.criado.toString(), isAsc);
        // case 'atualizado':
        //   return this.compare(a.atualizado.toString(), b.atualizado.toString(), isAsc);
        default:
          return 0;
      }
    });

    // console.log(this.sortedData)
    this.contas = this.sortedData

    this.displayedContas = this.contas.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // Personalize a função getRangeLabel para começar em 1
  getPortugueseRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }

  reload(){
    try {
      this.contas = [];
      this.loading = true;
      // this.getTotalTiposUsuarios();
      // this.getUsuario();


    this.loader.start()

      setTimeout(async () => {
        let conta = await this.contaService.getConta();
        // console.log(conta)

        if(conta != undefined){
          this.contas = conta;
        }else{
          setTimeout(() => {
            this.loading = false;
            this.loader.complete()

          }, 500);
        }

        this.showInfo("Contas atualizadas com sucesso!!")
        this.loading = false;
        this.loader.complete()


      }, 1000);


    } catch (error) {
      this.showError("Ocorreu um erro na atualização da agenda")
      this.loader.complete()
    }

  }

  resetSearch(){
    this.pesquisa = "";
    this.displayedContas = this.contas.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
    this.paginator.length = this.contas.length;

    // Use o serviço Router para navegar para a mesma rota sem o parâmetro de consulta
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: null },
      queryParamsHandling: 'merge',
    });
  }

  pags(pags: any) {
    const target = pags as HTMLTextAreaElement;
    this.contador = parseInt(target.value)
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }
  }

  modoTabela(){
    this.modoView = true;
    localStorage.setItem("modoView", "true");
  }


  modoCard(){
    this.modoView = false;
    this.modoViewNum = 12;
    localStorage.setItem("modoViewNum", "12");
    localStorage.setItem("modoView", "false");
  }

  deletarConta(id: any){
    const index = this.contas.findIndex(conta => conta.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja excluir a conta?';
      let mensagem = ``;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then((confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Encontrou um item com o ID correspondente, exclua-o

          const docId = this.contas[index].id != null ? this.contas[index].id?.toString() : "";

          this.localhostService.deleteDados('contas', { id: docId })
          .then((response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess(`Conta deletada com sucesso`);
            this.displayedContas = this.contas.slice(
              this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize
            );
          })
          .catch((error: any) => {
            // console.error('Erro ao deletar dados:', error);
            this.showError("Conta não encontrada para exclusão \n" + error);
          });
        } else {
          // console.log('NÃO');
        }
      });

    } else {
      // Não encontrou um item com o ID correspondente
      this.showError("Tipo não encontrado para exclusão");
    }
  }

  atualizarStatusConta(id: any){

    const index = this.contas.findIndex((item: Conta) => item.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja alterar o status da conta?';
      let mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                        <p class="ag-mb-5">
                          <span>${this.contas[index].razao.valor}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-danger">Status atual: </span>
                          <span>${this.contas[index].status.valor ? "Ativo" : "Desativado"}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-sucesso">Novo status: </span>
                          <span>${!this.contas[index].status.valor ? "Ativo" : "Desativado"}</span>
                        </p>
                      </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then((confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Atualize o item existente
          this.contas[index].status.valor = !this.contas[index].status.valor;

          const docId = this.contas[index].id != null ? this.contas[index].id?.toString() : "";
          const plainObject = JSON.stringify(this.contas[index])
          const plainString = JSON.parse(plainObject)

          this.localhostService.putDados('contas', docId!, plainString)
          .then((response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess(`Status da conta ${this.contas[index].razao.valor} alterado para ${this.contas[index].status.valor ? "ativado" : "desativado"}`);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Conta não encontrada para atualização \n" + error);
          });

        } else {
          // console.log('NÃO');
        }
      });


    } else {
      // O item não foi encontrado no array, você pode tratar isso conforme necessário
      this.showError("Conta não encontrada para atualização");
    }
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
