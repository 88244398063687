import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Links } from 'src/class/links';
import { GrupoAgenda } from 'src/class/grupo-agenda';
import { objUsuario } from 'src/class/usuario';
import { StatusAgendamento } from 'src/enum/statusAgendamento.enum';
import { SearchGrupoAgendaPipe } from 'src/pipes/search-grupo-agenda.pipe';
import { AuthService } from 'src/services/auth.service';
import { ContaService } from 'src/services/component/conta.service';
import { GrupoAgendaService } from 'src/services/component/grupo-agenda.service';
import { DataService } from 'src/services/data.service';
import { LinksService } from 'src/services/links.service';
import { LocalhostService } from 'src/services/localhost.service';
import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ThemeService } from 'src/services/theme.service';
import { ToastService } from 'src/services/toast.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';

@Component({
  selector: 'app-grupo-agenda',
  templateUrl: './grupo-agenda.component.html',
  styleUrls: ['./grupo-agenda.component.css']
})
export class GrupoAgendaComponent {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj = new objUsuario;

  agenda_arr: any [] = [];

  pesquisa="";
  pag = 1;
  contador = 5;
  pageSize = 5;
  pageIndex = 0;
  totalizador = 0;
  maxSize = 5;
  visualizando = this.contador;
  lightMode: any;
  modoView = false;
  modoViewNum = 12;

  private subscription: Subscription;

  linksAgenda: Links[] = [];

  statusAgendamento = StatusAgendamento;

  grupos: GrupoAgenda[] = [];
  displayedGrupoAgenda: GrupoAgenda[] = [];
  loading: boolean = true;
  sortedData: GrupoAgenda[] = [];

  loader = this.loadingBar.useRef('http');
  empresaSigla: any | undefined;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService,
    private linksService: LinksService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private paginatorIntl: MatPaginatorIntl,
    private searchGrupoAgendaPipe: SearchGrupoAgendaPipe,
    private loadingBar: LoadingBarService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    private grupoAgendaService: GrupoAgendaService,
    private localhostService: LocalhostService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private userService: AuthService,
    private notificacaoService: NotificacaoSistemaService
  ){
    this.subscription = this.themeService.lightMode$.subscribe(lightMode => {
      this.lightMode = lightMode;
    });

    this.paginatorIntl.itemsPerPageLabel = 'Itens por página:';
    this.paginatorIntl.nextPageLabel = 'Próxima página';
    this.paginatorIntl.previousPageLabel = 'Página anterior';
    this.paginatorIntl.firstPageLabel = 'Primeira página';
    this.paginatorIntl.lastPageLabel = 'Última página';

    // Personalize a indexação para começar em 1
    this.paginatorIntl.getRangeLabel = this.getPortugueseRangeLabel;

    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.grupoAgendaService.getGrupoAgenda(this.empresaSigla);
        });
      // }
    // });
  }


  ngOnInit() {

    this.getCache();

    // console.log(this.statusAgendamento)

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.changeDetectorRef.detectChanges();

    // this.displayedGrupoAgenda = this.grupos.slice(
    //   this.pageIndex * this.pageSize,
    //   (this.pageIndex + 1) * this.pageSize
    // );

    // this.route.queryParams.subscribe((params: any) => {
    //   // O parâmetro de consulta 'search' estará em params.search
    //   this.pesquisa = params.search ? params.search : '';
    //   console.log(params)
    //   // Agora você pode usar o valor da pesquisa em sua função searchAgenda
    //   if(this.pesquisa != ''){
    //     this.searchGrupoAgenda();
    //   }
    // });
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCache(){

    let modoView = localStorage.getItem("modoView");

    let modoViewNum = localStorage.getItem("modoViewNum");

    if(modoView){
      this.modoView = modoView == 'true' ? true : false
    }

    if(modoViewNum){
      this.modoViewNum = parseInt(modoViewNum);
    }

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    this.changeDetectorRef.detectChanges();

    this.grupoAgendaService.getAllGrupoAgenda()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (grupos: GrupoAgenda[]) => {
        // console.log(grupos)
        // Faça algo com as notificações atualizadas
        this.grupos = grupos;

        this.loading = false;

        this.displayedGrupoAgenda = this.grupos.slice(
          this.pageIndex * this.pageSize,
          (this.pageIndex + 1) * this.pageSize
        );

        this.route.queryParams.subscribe((params: any) => {
          // O parâmetro de consulta 'search' estará em params.search
          this.pesquisa = params.search ? params.search : '';
          // console.log(params)
          // Agora você pode usar o valor da pesquisa em sua função searchAgenda
          if(this.pesquisa != ''){
            this.searchGrupoAgenda();
          }
        });

      },
      error => {
        // console.error('Erro ao listar os GrupoAgenda:', error);
        this.router.navigate(['/home']);
      }
    );

  }

  searchGrupoAgenda(){
    const retorno = this.searchGrupoAgendaPipe.transform(this.grupos, this.pesquisa);

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.displayedGrupoAgenda = retorno;

    if(this.pesquisa == ""){
      this.displayedGrupoAgenda = this.grupos.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      this.paginator.length = this.grupos.length;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { search: null },
        queryParamsHandling: 'merge',
      });

    }else{

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ['search']: this.pesquisa },
        queryParamsHandling: 'merge',
      });

      this.displayedGrupoAgenda = retorno.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      if (this.paginator) {
        this.paginator.length = retorno.length;
      }
    }
  }

  handlePage(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    // Calcule o índice de início e fim dos itens a serem exibidos na página atual
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    // Atualize os itens a serem exibidos na tabela
    this.displayedGrupoAgenda = this.grupos.slice(startIndex, endIndex);

    // console.log(this.displayedGrupoAgenda)
  }

  sortData(sort: Sort) {
    const data = this.grupos.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      this.grupos = this.sortedData
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'id':
          return this.compare(a.id!, b.id!, isAsc);
        case 'status':
          return this.compare(a.status.valor, b.status.valor, isAsc);
        case 'nome':
          return this.compare(a.nome.valor, b.nome.valor, isAsc);
        case 'descricao':
          return this.compare(a.descricao.valor, b.descricao.valor, isAsc);
        case 'criado':
          return this.compare(a.criado.toString(), b.criado.toString(), isAsc);
        case 'atualizado':
          return this.compare(a.atualizado.toString(), b.atualizado.toString(), isAsc);
        default:
          return 0;
      }
    });

    // console.log(this.sortedData)
    this.grupos = this.sortedData

    this.displayedGrupoAgenda = this.grupos.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // Personalize a função getRangeLabel para começar em 1
  getPortugueseRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }

  reload(){
    try {
      this.grupos = [];
      this.loading = true;
      // this.getTotalGrupoAgendaAgendas();
      // this.getAgenda();


    this.loader.start()

      setTimeout(async () => {
        let grupo = await this.grupoAgendaService.getGrupoAgenda(this.empresaSigla);
        // console.log(grupo)

        if(grupo != undefined){
          this.grupos = grupo;
        }else{
          setTimeout(() => {
            this.loading = false;
            this.loader.complete()

          }, 500);
        }

        this.showInfo("GrupoAgenda atualizados com sucesso!!")
        this.loading = false;
        this.loader.complete()


      }, 1000);


    } catch (error) {
      this.showError("Ocorreu um erro na atualização da agenda")
      this.loader.complete()
    }

  }

  resetSearch(){
    this.pesquisa = "";
    this.displayedGrupoAgenda = this.grupos.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
    this.paginator.length = this.grupos.length;

    // Use o serviço Router para navegar para a mesma rota sem o parâmetro de consulta
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: null },
      queryParamsHandling: 'merge',
    });
  }

  pags(pags: any) {
    const target = pags as HTMLTextAreaElement;
    this.contador = parseInt(target.value)
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }
  }

  modoTabela(){
    this.modoView = true;
    localStorage.setItem("modoView", "true");
  }


  modoCard(){
    this.modoView = false;
    this.modoViewNum = 12;
    localStorage.setItem("modoViewNum", "12");
    localStorage.setItem("modoView", "false");
  }

  deletarGrupoAgenda(id: any){
    const index = this.grupos.findIndex(agenda => agenda.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja excluir o grupo?';
      let mensagem = ``;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Encontrou um item com o ID correspondente, exclua-o

          const docId = this.grupos[index].id != null ? this.grupos[index].id?.toString() : "";

          const grupo = this.grupos[index];

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: deletarGrupoAgenda', conta);
            this.localhostService.deleteDados(`${conta.id}_grupos_usuarios`, { id: docId })
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              this.showSuccess(`GrupoAgenda deletado com sucesso`);

              // Nova notificação
              // 1 - empresaSigla: any,
              // 2 - titulo: string,
              // 3 - texto: string,
              // 4 - rotaSemParamNome: string,
              // 5 - rotaSemParamListar: string,
              // 6 - rotaComParamNome: string = "",
              // 7 - rotaComParamCaminho: string = "",
              // 8 - rotaComParamParam: string = "",
              // 9 - linkExternoNome: string = "",
              // 10 - linkExternoURL: string = "",
              // 11 - idReferencia: string,
              // 12 - tipo: string,
              // 13 - idResponsavel: string,
              // 14 - nomeResponsavel: string

              const usr = await this.userService.carregarUsuario(this.empresaSigla)

              this.notificacaoService.criarNotificacao(
                this.empresaSigla,
                `${grupo.nome.valor}`,
                `O grupo de agendas foi deletado.`,
                'Listar Grupos Agendas',
                'grupos-agendas',
                ``,
                ``,
                ``,
                ``,
                '',
                ``,
                `Grupo Agendas`,
                `${usr.id}`,
                `${usr.displayName.valor}`
              )

              this.displayedGrupoAgenda = this.grupos.slice(
                this.pageIndex * this.pageSize,
                (this.pageIndex + 1) * this.pageSize
              );
            })
            .catch((error: any) => {
              // console.error('Erro ao deletar dados:', error);
              this.showError("GrupoAgenda não encontrada para exclusão \n" + error);
            });
          },
          (error: any) => {
            // console.error('Erro ao deletar dados:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // console.log('Erro ao obter dados da conta:', error);
          });


          // this.grupos.splice(index, 1);
          // DataService.setItem("GrupoAgenda", this.grupos);
          // localStorage.setItem("GrupoAgenda", JSON.stringify(this.grupos))

          // this.showSuccess("GrupoAgenda deletado com sucesso");

          // this.displayedGrupoAgenda = this.grupos.slice(
          //   this.pageIndex * this.pageSize,
          //   (this.pageIndex + 1) * this.pageSize
          // );
        } else {
          // console.log('NÃO');
        }
      });

    } else {
      // Não encontrou um item com o ID correspondente
      this.showError("GrupoAgenda não encontrado para exclusão");
    }
  }

  atualizarStatusGrupoAgenda(id: any){

    const index = this.grupos.findIndex((item: GrupoAgenda) => item.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja alterar o status do grupo?';
      let mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                        <p class="ag-mb-5">
                          <span>${this.grupos[index].nome.valor}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-danger">Status atual: </span>
                          <span>${this.grupos[index].status.valor ? "Ativo" : "Desativado"}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-sucesso">Novo status: </span>
                          <span>${!this.grupos[index].status.valor ? "Ativo" : "Desativado"}</span>
                        </p>
                      </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Atualize o item existente
          this.grupos[index].status.valor = !this.grupos[index].status.valor;

          const docId = this.grupos[index].id != null ? this.grupos[index].id?.toString() : "";
          const plainObject = JSON.stringify(this.grupos[index])
          const plainString = JSON.parse(plainObject)

          plainString.descricao.status = null;
          plainString.nome.status = null;

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: atualizarStatusServico', conta);
            this.localhostService.putDados(`${conta.id}_grupos_usuarios`, docId!, plainString)
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              this.showSuccess(`Status do grupo ${this.grupos[index].nome.valor} alterado para ${this.grupos[index].status.valor ? "ativado" : "desativado"}`);

              // Nova notificação
              // empresaSigla: any,
              // titulo: string,
              // texto: string,
              // rotaSemParamNome: string,
              // rotaSemParamListar: string,
              // rotaComParamNome: string = "",
              // rotaComParamCaminho: string = "",
              // rotaComParamParam: string = "",
              // linkExternoNome: string = "",
              // linkExternoURL: string = "",
              // idReferencia: string,
              // tipo: string,
              // 13 - idResponsavel: string,
              // 14 - nomeResponsavel: string

              const usr = await this.userService.carregarUsuario(this.empresaSigla)

              this.notificacaoService.criarNotificacao(
                this.empresaSigla,
                `${this.grupos[index].nome.valor}`,
                `O status do grupo de agendas foi atualizado para ${this.grupos[index].status.valor ? "ativado" : "desativado"}.`,
                'Listar Grupos Agendas',
                'grupos-agendas',
                'Editar Grupo Agendas',
                `grupos-agendas/grupo`,
                this.grupos[index].id?.toString(),
                '',
                '',
                this.grupos[index].id!.toString(),
                `Grupo Agendas`,
                `${usr.id}`,
                `${usr.displayName.valor}`
              )
            })
            .catch((error: any) => {
              // console.error('Erro ao atualizar dados:', error);
              this.showError("GrupoAgenda não encontrado para atualização \n" + error);
            });

          },
          (error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // console.log('Erro ao obter dados da conta:', error);
          });

          // DataService.setItem("GrupoAgenda", this.grupos);
          // localStorage.setItem("GrupoAgenda", JSON.stringify(this.grupos))
          // this.showSuccess(`Status do grupo ${this.grupos[index].status.valor ? "ativado" : "desativado"}`);
        } else {
          // console.log('NÃO');
        }
      });


    } else {
      // O item não foi encontrado no array, você pode tratar isso conforme necessário
      this.showError("GrupoAgenda não encontrada para atualização");
    }
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
