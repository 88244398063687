import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Agenda } from 'src/class/agenda';
import { Alternativas, Formulario, FormularioConfigInput, FormularioPergutna, GruposAgendas } from 'src/class/formulario';
import { newUsuario } from 'src/class/usuario';
import { FormularioTipo } from 'src/enum/formularioTipo.enum';
import { FormularioTipoPergunta } from 'src/enum/formularioTipoPergunta.enum';
import { campo } from 'src/interface/campo';
import { AgendaService } from 'src/services/component/agenda.service';
import { FormularioService } from 'src/services/component/formulario.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-criar-form',
  templateUrl: './criar-form.component.html',
  styleUrls: ['./criar-form.component.css']
})
export class CriarFormComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertFormularioModal', { static: false }) alertFormularioModal!: ElementRef;
  @ViewChild('novaPerguntaFormulario', { static: false }) novaPerguntaFormulario!: ElementRef;
  @ViewChild('editarPerguntaFormulario', { static: false }) editarPerguntaFormulario!: ElementRef;
  @ViewChild('agendasSelecionadas', {static: true}) agendasSelecionadas!: MatSelectionList;

  modalsMap = new Map<ElementRef, any>();

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  form: Formulario = new Formulario();
  empresaSigla: any | undefined;

  formularioTipo  = FormularioTipo;
  formularioTipoArray = Object.values(this.formularioTipo).map((enumFormularioTipo) => ({
    nome: enumFormularioTipo,
    nomeDesc: "",
    valor: enumFormularioTipo
  }));

  formularioPergunta: FormularioPergutna = new FormularioPergutna();

  formularioTipoPergunta  = FormularioTipoPergunta;
  formularioTipoPerguntaArray = Object.values(this.formularioTipoPergunta).map((enumTipoPergunta) => ({
    nome: enumTipoPergunta,
    nomeDesc: "",
    valor: enumTipoPergunta
  }));

  formularioTipoPerguntaSelecionados: any;

  formularioConfigInput: FormularioConfigInput = new FormularioConfigInput(null);

  agendas: any[] = [];

  editID = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private contadorIndexService: ContadorIndexService,
    private formularioService: FormularioService,
    private route: ActivatedRoute,
    private siglaService: SiglaService,
    private agendaService: AgendaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.agendaService.getAgendas(this.empresaSigla);
        });
      }
    });
  }

  async ngOnInit() {
    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        agendas.sort((a, b) => {
          const nomeA = a.nome.valor.toUpperCase();
          const nomeB = b.nome.valor.toUpperCase();
          if (nomeA < nomeB) {
            return -1;
          }
          if (nomeA > nomeB) {
            return 1;
          }
          return 0;
        });

        this.agendas = agendas;
      },
      error => {
        this.router.navigate(['/home']);
      }
    );

  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async criarFormulario(){
    this.form.titulo.status = this.validatorService.criarValidacao(this.form.titulo.valor, this.form.titulo.validacoesCustomizadas);
    this.form.descricao.status = this.validatorService.criarValidacao(this.form.descricao.valor, this.form.descricao.validacoesCustomizadas);
    this.form.tipoFormulario.status = this.validatorService.criarValidacao(this.form.tipoFormulario.valor, this.form.tipoFormulario.validacoesCustomizadas);

    if(
      this.form.titulo.status.invalid ||
      this.form.descricao.status.invalid ||
      this.form.tipoFormulario.status.invalid
    ){
      this.abrirModal(this.alertFormularioModal.nativeElement);
    }else{

      this.form.id = await this.contadorIndexService.contadorIndex();
      let new_formulario  = new Formulario();
      new_formulario = this.form;

      new_formulario.criado = new Date();
      new_formulario.atualizado = new Date();

      let agendas: GruposAgendas[] = [];

      for (let index = 0; index < this.agendasSelecionadas.selectedOptions.selected.length; index++) {
        const element = this.agendasSelecionadas.selectedOptions.selected[index];
        const rec = new GruposAgendas();

        rec.idAgenda = element.value.id
        rec.NomeAgenda = element.value.nome.valor

        agendas.push(rec)
      }

      new_formulario.agendas = agendas;

      const formulario = JSON.stringify(new_formulario);
      const res = await this.formularioService.criarFormulario(JSON.parse(formulario), this.empresaSigla)

      // this.showSuccess("Tipo criado com sucesso");
      this.form = new Formulario();
      this.router.navigate([`${this.empresaSigla}/formularios`]);
    }
  }

  abrirModal(event: any) {
    const modalElement = event;
    if (modalElement) {
        const modalInstance = new bootstrap.Modal(modalElement);
        this.modalsMap.set(event, modalInstance); // Armazenar a instância do modal no mapa
        modalInstance.show();
    } else {
        // console.log("Elemento do modal não encontrado");
    }
  }

  fecharModal(event: any){
    const modalInstance = this.modalsMap.get(event);
    if (modalInstance) {
        modalInstance.hide(); // Fechar o modal
    } else {
        // console.log("Instância do modal não encontrada");
    }
  }

  closeModalFormulario(event: any){
    const modalInstance = this.modalsMap.get(event);
    if (modalInstance) {
        modalInstance.hide(); // Fechar o modal
    } else {
        // console.log("Instância do modal não encontrada");
    }
  }

  openNovaPerguntaFormulario(){
    this.formularioPergunta = new FormularioPergutna();
    this.abrirModal(this.novaPerguntaFormulario.nativeElement);
  }


  adicionarPergunta() {
    this.validarCampo(this.formularioPergunta.pergunta);
    this.validarCampo(this.formularioPergunta.placeholder);
    this.validarCampo(this.formularioPergunta.tipoPergunta);

    if(this.formularioConfigInput){
      if(this.formularioConfigInput.tamanhoInput){
        this.validarCampo(this.formularioConfigInput.tamanhoInput);
      }
      if(this.formularioConfigInput.mascaraInput){
        this.validarCampo(this.formularioConfigInput.mascaraInput);
      }
      if(this.formularioConfigInput.alternativas){
        this.formularioConfigInput.alternativas.forEach(alternativa => {
            this.validarCampo(alternativa.label);
            this.validarCampo(alternativa.valor);
        });
      }
    }

    // Verifica se há campos inválidos
    if(this.temErros()) {
      this.showError("Alguns campos precisam ser preenchidos.");
    }else {
      const uniqueId = this.contadorIndexService.contadorIndex();
        const campoObj = this.criarCampoObjeto(uniqueId);

      this.form.adicionarCampo(`${uniqueId}`, campoObj);
      // console.log(this.formularioPergunta);

      this.showSuccess("Pergunta adicionada com sucesso.");
      this.formularioPergunta = new FormularioPergutna();
      this.formularioConfigInput = new FormularioConfigInput(null);

      this.closeModalFormulario(this.novaPerguntaFormulario.nativeElement);
      // console.log(this.form)
    }
  }

  async openEditarPerguntaFormulario(obj: campo){
    // console.log(obj)
    this.editID = obj.id;
    await this.changeTipoPergunta(obj.tipoPergunta);
    this.formularioPergunta.pergunta.valor = obj.title;
    this.formularioPergunta.placeholder.valor = obj.placeholder;
    this.formularioPergunta.tipoPergunta.valor = obj.tipoPergunta;
    this.formularioPergunta.obrigatorio.valor = obj.validacoesCustomizadas.includes('required') ? true : false;

    if (obj.opcoes && this.formularioConfigInput) {
      for (let index = 0; index < obj.opcoes.length; index++) {
        const op = obj.opcoes[index];

        // Verifica se já existe uma alternativa
        if (this.formularioConfigInput.alternativas && this.formularioConfigInput.alternativas?.length === 1 && index === 0) {
          // Se só há uma alternativa, você pode adicionar a nova opção à alternativa existente
          setTimeout(async () => {
            this.formularioConfigInput.alternativas![0].label.valor = op.label;
            this.formularioConfigInput.alternativas![0].valor.valor = op.value;
            this.changeDetectorRef.detectChanges();
          });
        } else {
          // Se não há alternativas ou há mais de uma, crie uma nova alternativa
          setTimeout(async () => {
            const uniqueId = await this.contadorIndexService.contadorIndex();
            const new_obj = await new Alternativas(uniqueId);
            new_obj.label.valor = op.label;
            new_obj.valor.valor = op.value;
            // Adicione a nova alternativa à lista
            this.formularioConfigInput.alternativas?.push(new_obj);
            this.changeDetectorRef.detectChanges();
          });
        }
        // this.changeDetectorRef.markForCheck();

      }
    }

    // console.log(this.formularioPergunta);
    // console.log(this.formularioConfigInput);
    this.abrirModal(this.editarPerguntaFormulario.nativeElement);
  }

  salvarPergunta(existingId: any) {
    this.validarCampo(this.formularioPergunta.pergunta);
    this.validarCampo(this.formularioPergunta.placeholder);
    this.validarCampo(this.formularioPergunta.tipoPergunta);

    if(this.formularioConfigInput){
        if(this.formularioConfigInput.tamanhoInput){
            this.validarCampo(this.formularioConfigInput.tamanhoInput);
        }
        if(this.formularioConfigInput.mascaraInput){
            this.validarCampo(this.formularioConfigInput.mascaraInput);
        }
        if(this.formularioConfigInput.alternativas){
            this.formularioConfigInput.alternativas.forEach(alternativa => {
                this.validarCampo(alternativa.label);
                this.validarCampo(alternativa.valor);
            });
        }
    }

    // Verifica se há campos inválidos
    if(this.temErros()) {
        this.showError("Alguns campos precisam ser preenchidos.");
    } else {
        const campoObj = this.criarCampoObjeto(existingId);

        // Substitui ou atualiza o campo existente no formulário com o ID fornecido
        this.form.adicionarCampo(`${existingId}`, campoObj);
        // console.log(this.formularioPergunta);

        this.showSuccess("Pergunta salva com sucesso.");
        this.formularioPergunta = new FormularioPergutna();
        this.formularioConfigInput = new FormularioConfigInput(null);
        this.editID = null;
        this.closeModalFormulario(this.editarPerguntaFormulario.nativeElement);
        // console.log(this.form);
    }
  }

  private validarCampo(campo: any){
    if(campo){
      campo.status = this.validatorService.criarValidacao(campo.valor, campo.validacoesCustomizadas);
    }
  }

  private temErros(): boolean {
    return [
      this.formularioPergunta.pergunta,
      this.formularioPergunta.placeholder,
      this.formularioPergunta.tipoPergunta,
      this.formularioConfigInput?.tamanhoInput,
      this.formularioConfigInput?.mascaraInput,
      ...(this.formularioConfigInput?.alternativas || []).flatMap(alternativa => [alternativa.label, alternativa.valor])
    ].some(campo => campo?.status?.invalid);
  }

  private criarCampoObjeto(uniqueId: string): campo {
    const tipoPergunta = this.formularioPergunta.tipoPergunta.valor;
    let campoObjeto: campo = {
        id: uniqueId,
        title: this.formularioPergunta.pergunta.valor,
        info: this.formularioPergunta.placeholder.valor,
        valor: '',
        placeholder: this.formularioPergunta.placeholder.valor,
        inputType: 'text',
        inputMode: 'text',
        mascara: '',
        maxlength: '',
        tipo: null,
        validacoesCustomizadas: [],
        tipoPergunta: this.formularioPergunta.tipoPergunta.valor,
        status: new FormControl()
    };

    if(this.formularioPergunta.obrigatorio.valor){
      campoObjeto.validacoesCustomizadas.push('required');
    }

    switch(tipoPergunta) {
      case FormularioTipoPergunta.TextoInput:
        campoObjeto.maxlength = this.formularioConfigInput.tamanhoInput?.valor || '';
        campoObjeto.tipoCampo = 'input';
        break;
      case FormularioTipoPergunta.TextoTextarea:
        campoObjeto.tipoCampo = 'textarea';
        campoObjeto.maxlength = this.formularioConfigInput.tamanhoInput?.valor || '';
        break;
      case FormularioTipoPergunta.TextoInputComMascara:
        campoObjeto.tipoCampo = 'input';
        campoObjeto.mascara = this.formularioConfigInput.mascaraInput?.valor || '';
        campoObjeto.maxlength = this.formularioConfigInput.tamanhoInput?.valor || '';
        break;
      case FormularioTipoPergunta.Numero:
        campoObjeto.tipoCampo = 'input';
        campoObjeto.inputType = 'number';
        campoObjeto.inputMode = '[0-9]*';
        campoObjeto.maxlength = this.formularioConfigInput.tamanhoInput?.valor || '10';
        break;
      case FormularioTipoPergunta.UmaAlternativaRadio:
        campoObjeto.tipoCampo = 'radio';
        campoObjeto.opcoes = this.criarOpcoesDasAlternativas();
        break;
      case FormularioTipoPergunta.UmaAlternativaSelect:
        campoObjeto.tipoCampo = 'select';
        campoObjeto.opcoes = this.criarOpcoesDasAlternativas();
        break;
      case FormularioTipoPergunta.VariasAlternativasCheckbox:
        campoObjeto.tipoCampo = 'checkbox';
        campoObjeto.opcoes = this.criarOpcoesDasAlternativas();
        break;
      case FormularioTipoPergunta.Data:
        campoObjeto.tipoCampo = 'datepicker';
        campoObjeto.inputMode = '[0-9]*';
        campoObjeto.mascara = '##/##/####';
        campoObjeto.maxlength = '10'
        break;
      case FormularioTipoPergunta.CPF:
        campoObjeto.tipoCampo = 'input';
        campoObjeto.inputMode = '[0-9]*';
        campoObjeto.mascara = '###.###.###-##';
        campoObjeto.maxlength = '14'
        campoObjeto.validacoesCustomizadas.push('cpf');
        break;
      case FormularioTipoPergunta.CNPJ:
        campoObjeto.tipoCampo = 'input';
        campoObjeto.inputMode = '[0-9]*';
        campoObjeto.mascara = '##.###.###/####-##';
        campoObjeto.maxlength = '18'
        campoObjeto.validacoesCustomizadas.push('cnpj');
        break;
      default:
        console.log("Tipo desconhecido");
    }

    return campoObjeto;
  }

  private criarOpcoesDasAlternativas(): { label: string; value: any }[] {
    if (this.formularioConfigInput && this.formularioConfigInput.alternativas) {
        return this.formularioConfigInput.alternativas.map(alternativa => {
            return {
                label: alternativa.label.valor,
                value: alternativa.valor.valor
            };
        });
    }
    return [];
  }

  deletarAlternativa(idAlternativa: any) {
    if (this.formularioConfigInput && this.formularioConfigInput.alternativas) {
        // Encontra o índice da alternativa que deve ser excluída
        const indiceParaExcluir = this.formularioConfigInput.alternativas.findIndex(alternativa => alternativa.id === idAlternativa);

        if (indiceParaExcluir !== -1) {
            // Exibir uma mensagem ou executar qualquer outra lógica necessária
            this.showError(`Alternativa com ID ${idAlternativa} deletada com sucesso!`);

            // Remove a alternativa do array
            this.formularioConfigInput.alternativas.splice(indiceParaExcluir, 1);
        } else {
            this.showError(`Alternativa com ID ${idAlternativa} não encontrada!`);
        }
    } else {
        this.showError("Configuração do formulário ou alternativas não encontradas.");
    }
  }

  adicionarAlternativa(param: any) {
    // console.log(param);
    // console.log(this.formularioConfigInput);

    // Verifica se o formulário já existe e se o tipo de pergunta é o esperado
    if (this.formularioConfigInput && this.formularioConfigInput.alternativas && this.formularioTipoPerguntaSelecionados === param) {
        // Adiciona uma nova alternativa à configuração existente
        const uniqueId = this.contadorIndexService.contadorIndex();
        const novaAlternativa = new Alternativas(uniqueId);
        this.formularioConfigInput.alternativas.push(novaAlternativa);
    } else {
        // Caso o formulário não exista ou o tipo de pergunta seja diferente, cria uma nova configuração
        const uniqueId = this.contadorIndexService.contadorIndex();
        this.formularioConfigInput = new FormularioConfigInput(param, uniqueId);
    }

    // console.log(this.formularioConfigInput.alternativas);
  }

  deletarPerguntaFormulario(idAlternativa: any) {
    if (this.formularioConfigInput && this.form.camposDinamicos) {
        // Converte camposDinamicos em um array de valores
        const alternativasArray = Object.values(this.form.camposDinamicos);

        // Encontra o índice da alternativa que deve ser excluída
        const indiceParaExcluir = alternativasArray.findIndex(alternativa => alternativa.id === idAlternativa);

        if (indiceParaExcluir !== -1) {
            // Obtém a chave correspondente ao índice encontrado
            const chaveParaExcluir = Object.keys(this.form.camposDinamicos)[indiceParaExcluir];

            // Exibe uma mensagem ou executa qualquer outra lógica necessária
            this.showError(`Alternativa com ID ${idAlternativa} deletada com sucesso!`);

            // Remove a alternativa do objeto usando a chave
            delete this.form.camposDinamicos[chaveParaExcluir];

            this.form.reordenarCampos();
        } else {
            this.showError(`Alternativa com ID ${idAlternativa} não encontrada!`);
        }
    } else {
        this.showError("Configuração do formulário ou alternativas não encontradas.");
    }
  }

  async changeTipoPergunta(param: any){
    if (param !== '') {
      // console.log(param);
      // console.log(this.formularioConfigInput);
      this.formularioTipoPerguntaSelecionados = param;
      let uniqueId;
      switch (param) {
        case FormularioTipoPergunta.TextoInput:
          // console.log("O tipo é TextoInput");
          this.formularioConfigInput = new FormularioConfigInput(FormularioTipoPergunta.TextoInput);
          // Object.assign(this.formularioConfigInput, obj)
          // this.formularioConfigInput = new FormularioConfigInput(FormularioTipoPergunta.TextoInput);
          break;
        case FormularioTipoPergunta.TextoTextarea:
          // console.log("O tipo é TextoTextarea");
          this.formularioConfigInput = new FormularioConfigInput(FormularioTipoPergunta.TextoTextarea);
          break;
        case FormularioTipoPergunta.Numero:
          // console.log("O tipo é Numero");
          this.formularioConfigInput = new FormularioConfigInput(FormularioTipoPergunta.Numero);
          break;
        case FormularioTipoPergunta.TextoInputComMascara:
          // console.log("O tipo é TextoInputComMascara");
          this.formularioConfigInput = new FormularioConfigInput(FormularioTipoPergunta.TextoInputComMascara);
          break;
        case FormularioTipoPergunta.UmaAlternativaRadio:
          // console.log("O tipo é UmaAlternativaRadio");
          uniqueId = this.contadorIndexService.contadorIndex();
          this.formularioConfigInput = new FormularioConfigInput(FormularioTipoPergunta.UmaAlternativaRadio, uniqueId);
          break;
        case FormularioTipoPergunta.UmaAlternativaSelect:
          // console.log("O tipo é UmaAlternativaSelect");
          uniqueId = this.contadorIndexService.contadorIndex();
          this.formularioConfigInput = new FormularioConfigInput(FormularioTipoPergunta.UmaAlternativaSelect, uniqueId);
          break;
        case FormularioTipoPergunta.VariasAlternativasCheckbox:
          // console.log("O tipo é VariasAlternativasCheckbox");
          uniqueId = this.contadorIndexService.contadorIndex();
          this.formularioConfigInput = new FormularioConfigInput(FormularioTipoPergunta.VariasAlternativasCheckbox, uniqueId);
          break;
        case FormularioTipoPergunta.Data:
          // console.log("O tipo é Data");
          break;
        case FormularioTipoPergunta.CNPJ:
          // console.log("O tipo é CNPJ");
          break;
        case FormularioTipoPergunta.CPF:
          // console.log("O tipo é CPF");
          break;
        default:
          this.formularioConfigInput = new FormularioConfigInput(null);
          this.formularioTipoPerguntaSelecionados = null;
          // console.log("Tipo desconhecido");
      }

      // console.log(this.formularioConfigInput);
    }else{
      this.formularioConfigInput = new FormularioConfigInput(null);
      this.formularioTipoPerguntaSelecionados = null;
    }
  }

  getKeysCamposDinamicos(obj: any): string[] {
    return Object.keys(obj);
  }

  existeCamposDinamicos(): boolean {
    return Object.keys(this.form.camposDinamicos).length > 0;
  }

  tamanhoCamposDinamicos(){
    return Object.keys(this.form.camposDinamicos).length;
  }

  selectAllAgendas(agendas: any[]): void {
    const time = setInterval(()=>{
      if(this.agendasSelecionadas && this.agendasSelecionadas.options){
        clearInterval(time);

        agendas.forEach((rec: any) => {
            const resourceId = rec.id; // Obtém o ID do recurso
            const option = this.agendasSelecionadas.options.toArray().find((opt: any) => opt.value.id === resourceId);
            if(option){
              option.selected = true; // Seleciona a opção com o ID correspondente
            }
        });

      }
    })
  }

  getSelectedAgendas() {
    for (let index = 0; index < this.agendasSelecionadas.selectedOptions.selected.length; index++) {
      const element = this.agendasSelecionadas.selectedOptions.selected[index];
      // console.log(element.value)
    }
    return this.agendasSelecionadas.selectedOptions.selected.map(option => option.value);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
