import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { Agenda } from 'src/class/agenda';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';

@Injectable({
  providedIn: 'root'
})

export class AgendaService {
  private agendas: Agenda[] = [];
  private agendasSubject = new BehaviorSubject<Agenda[]>(this.agendas);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService
  ) {
    this.agendas = [];
    // this.getAgendas();
  }

  async criarAgenda(agenda: Agenda, empresaSigla: any) {

    // console.log(agenda)

    agenda.agenda_fim.status = null;
    agenda.agenda_inicio.status = null;
    agenda.antecedenciaMaximaDias.status = null;
    agenda.antecedenciaMinimaHoras.status = null;
    agenda.duracaoAtendimento.status = null;
    agenda.instrucoes.status = null;
    agenda.link.status = null;
    agenda.linkImagem.status = null;
    agenda.nome.status = null;
    agenda.subNome.status = null;

    for (let index = 0; index < agenda.horarios.length; index++) {
      const obj_hr = agenda.horarios[index];
      obj_hr.dia.status = null;

      for (let index = 0; index < obj_hr.horariosDia.length; index++) {
        const hr = obj_hr.horariosDia[index];
        hr.hora_final.status = null
      }

    }

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: criarAgenda', conta);

      // Salve a nova agenda no Firestore do Firebase
      this.localhostService.postDadosId(`${conta.id}_agendas`, agenda).then(
        async (response: any) => {
          // console.log('Agenda criada com sucesso:', response);

          // Atualize a lista local de agendas
          this.agendas.push(agenda);
          this.agendasSubject.next(this.agendas);

          // Atualize o localStorage (opcional, dependendo dos requisitos)
          // this.updateLocalStorage();

          // Notifique o usuário sobre o sucesso
          this.showSuccess('Agenda criada com sucesso');

          // Nova notificação
          // 1 - empresaSigla: any,
          // 2 - titulo: string,
          // 3 - texto: string,
          // 4 - rotaSemParamNome: string,
          // 5 - rotaSemParamListar: string,
          // 6 - rotaComParamNome: string = "",
          // 7 - rotaComParamCaminho: string = "",
          // 8 - rotaComParamParam: string = "",
          // 9 - linkExternoNome: string = "",
          // 10 - linkExternoURL: string = "",
          // 11 - idReferencia: string,
          // 12 - tipo: string,
          // 13 - idResponsavel: string,
          // 14 - nomeResponsavel: string

          const usr = await this.userService.carregarUsuario(empresaSigla)

          this.notificacaoService.criarNotificacao(
            empresaSigla,
            `${agenda.nome.valor}`,
            `Agenda criada.`,
            'Listar Agendas',
            'agendas',
            'Editar Agenda',
            `agendas/agenda`,
            agenda.id,
            '',
            '',
            agenda.id,
            `Agenda`,
            `${usr.id}`,
            `${usr.displayName.valor}`
          )

          // Navegue para a página de agendas
          this.router.navigate([`${empresaSigla}/agendas`]);
        },
        (error: any) => {
          // console.error('Erro ao criar agenda:', error);
          // Trate o erro conforme necessário
        }
      );

    })
    .catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });



  }

  getAllAgendas() {
    return this.agendasSubject.asObservable().pipe(
      map(agendas => agendas)
    );
  }

  async getAgendas(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getAgendas', conta);
      //
      this.localhostService.getDados(`${conta.id}_agendas`).subscribe(
        (agendasFromServer: any[]) => {
          if (agendasFromServer) {
            // Mapeia os dados para a estrutura desejada
            const agendasArray: Agenda[] = agendasFromServer.map(agenda => plainToClass(Agenda, agenda));

            // console.log(agendasArray);

            this.agendas = agendasArray;
            this.agendasSubject.next(this.agendas);
            // this.updateLocalStorage();
          } else {
            this.agendas = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter agendas:', error);
        }
      );

    })
    .catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });

  }

  private updateLocalStorage() {
    localStorage.setItem("Agendas", JSON.stringify(this.agendas));
    DataService.set("Agendas", this.agendas);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
