import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Links } from 'src/class/links';
import { newUsuario } from 'src/class/usuario';
import { LinksService } from 'src/services/links.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { UsuariosService } from 'src/services/usuarios.services';
// import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-criar-controle-acesso-agenda',
  templateUrl: './criar-controle-acesso-agenda.component.html',
  styleUrls: ['./criar-controle-acesso-agenda.component.css']
})
export class CriarControleAcessoAgendaComponent {
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  selectedFile: File | null = null;
  uploadProgress: number | null = null;
  linksAgenda: Links[] = [];
  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private linksService: LinksService,
    private route: ActivatedRoute,
    private siglaService: SiglaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      }
    });
  }

  async ngOnInit() {
  try{
    const usr = JSON.parse(localStorage.getItem('user')!);
    // this.usr_id = usr.Id;
    // this.usr_tipo = usr.Tipo;
    // this.usr_token = usr.Token;
    this.usr_id = "1";
    this.usr_tipo = "M";
    this.usr_token = "usr.Token";
    if(this.usr_tipo != "M" && this.usr_tipo != "A"){
      this.router.navigate(['/home']);
    }
  }catch{
    this.router.navigate(['/home']);
  }
  // Agora você pode usar o ID como quiser

  this.linksAgenda = this.linksService.getLinksAgenda();

}

routerLink(rota: string){
  this.router.navigate([`${this.empresaSigla}/${rota}`]);
}

resetNewUsr(){
  this.usr_new = new newUsuario;
}

async newUsuario(){

  let imageUrl = "";

  // console.log('i');

  try {
    // imageUrl = await this.upload();
    imageUrl = "";
    // Aqui você pode usar a URL da imagem para prosseguir com a outra parte do código
    // console.log('URL da imagem:', imageUrl);
    this.usr_new.Foto = imageUrl;
  } catch (error) {
    // console.log('Erro ao fazer upload:', error);
  }

  // console.log('f');

  const newUrs = await this.usuarios.newUsuario(this.usr_new,this.usr_token);
  const response = JSON.parse(newUrs);

  if(response.Message){
    // console.log(response.Message);
    this.showError(response.Message)

  }else{
    const button = document.getElementById('btn-close-x-new');
    if (button) {
      button.click();
    }
    this.showSuccess(newUrs.replaceAll("\"", ""))

    this.resetNewUsr();
  }
}

onFileSelected(event: any): void {
  this.selectedFile = event.target.files[0];
}

async upload(){
  // async upload(): Promise<string> {
  //   if (this.selectedFile) {
  //     const filePath = `usuarios/${this.usr_new.Usuario}_${this.selectedFile.name}`;
  //     const fileRef = this.storage.ref(filePath);
  //     const task = this.storage.upload(filePath, this.selectedFile);

  //     task.percentageChanges().subscribe(progress => {
  //       this.uploadProgress = progress !== undefined ? progress : null;
  //     });

  //     await task.snapshotChanges().toPromise();

  //     const url = await fileRef.getDownloadURL().toPromise();
  //     console.log('URL da imagem:', url);

  //     return url;
  //   }

  //   throw new Error('Nenhum arquivo selecionado.');
  // }
}

private showSuccess(msg: string){
  this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
}

private showError(msg: string){
  this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
}

private showInfo(msg: string) {
  this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
}
}
