import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchUsuario'
})
export class SearchUsuarioPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }

    const searchValue = args.toLowerCase();

    return value.filter((val: any) => {
      const id = val.id?.toLowerCase().toString();
      const phoneNumber = (val.phoneNumber?.valor || '').toLowerCase().toString();
      const nome = (val.displayName?.valor || '').toLowerCase().toString();
      const email = (val.email?.valor || '').toLowerCase().toString();
      const criado = val.criado != "" ? this.formatarDataHora(val.criado) : val.criado;
      const atualizado = val.atualizado != "" ? this.formatarDataHora(val.atualizado) : val.atualizado;
      const ultimoAcesso = val.ultimoLogin != "" ? this.formatarDataHora(val.ultimoLogin) : val.ultimoLogin;

      return id.includes(searchValue) || phoneNumber.includes(searchValue) || nome.includes(searchValue) || nome.includes(searchValue) || email.includes(searchValue) || ultimoAcesso.includes(searchValue) || criado.includes(searchValue) || atualizado.includes(searchValue);
    });

  }

  formatarDataHora(dataHora: string): string {
    const data = new Date(dataHora);// 2023-10-24T18:12:17.221Z
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    const hora = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano}, ${hora}:${minutos}`;
  }

}
