import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { Formulario } from 'src/class/formulario';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';

@Injectable({
  providedIn: 'root'
})
export class FormularioService {

  private formularios: Formulario[] = [];
  private formulariosSubject = new BehaviorSubject<Formulario[]>(this.formularios);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService
  ) {
    this.formularios = [];
  }



  async criarFormulario(formulario: Formulario, empresaSigla: any) {
    // console.log("criarFormulario", formulario);
    formulario.titulo.status = null;
    formulario.descricao.status = null;
    formulario.tipoFormulario.status = null;

    if (formulario.camposDinamicos) {
      Object.values(formulario.camposDinamicos).forEach(cp => {
        cp.status = null;
      });
    }

    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarFormulario', conta);

      const response = await this.localhostService.postDadosId(`${conta.id}_formularios`, formulario);
      // console.log('Formulario criado com sucesso:', response);

      // Atualize a lista local de agendas
      this.formularios.push(formulario);
      this.formulariosSubject.next(this.formularios);

      // Notifique o usuário sobre o sucesso
      this.showSuccess('Formulario criado com sucesso');

      // Nova notificação
      // 1 - empresaSigla: any,
      // 2 - titulo: string,
      // 3 - texto: string,
      // 4 - rotaSemParamNome: string,
      // 5 - rotaSemParamListar: string,
      // 6 - rotaComParamNome: string = "",
      // 7 - rotaComParamCaminho: string = "",
      // 8 - rotaComParamParam: string = "",
      // 9 - linkExternoNome: string = "",
      // 10 - linkExternoURL: string = "",
      // 11 - idReferencia: string,
      // 12 - formulario: string,
      // 13 - idResponsavel: string,
      // 14 - nomeResponsavel: string

      const usr = await this.userService.carregarUsuario(empresaSigla)

      this.notificacaoService.criarNotificacao(
        empresaSigla,
        `${formulario.titulo.valor}`,
        `Formulario criado.`,
        'Listar Formulários',
        'formularios',
        'Editar Formulário',
        `formularios/formulario`,
        formulario.id?.toString(),
        '',
        '',
        formulario.id!.toString(),
        `Formulário`,
        `${usr.id}`,
        `${usr.displayName.valor}`
      )

      return true;
    } catch (error) {
      // console.error('Erro ao criar formulario:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllFormulario() {
    return this.formulariosSubject.asObservable().pipe(
      map(formularios => formularios)
    );
  }

  async getFormulario(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getFormulario', conta);

      this.localhostService.getDados(`${conta.id}_formularios`).subscribe(
        (formularioFromServer: any[]) => {
          if (formularioFromServer) {
            // Mapeia os dados para a estrutura desejada
            const formularioArray: Formulario[] = formularioFromServer.map(formulario => plainToClass(Formulario, formulario));

            // console.log(formularioArray);

            this.formularios = formularioArray;
            this.formulariosSubject.next(this.formularios);
            // this.updateLocalStorage();
          } else {
            this.formularios = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter formularios:', error);
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao obter formularios:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });


  }

  private updateLocalStorage() {
    localStorage.setItem("Formulario", JSON.stringify(this.formularios));
    DataService.set("Formulario", this.formularios);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
