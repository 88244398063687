export enum FormularioTipoPergunta {
  TextoInput = 'Texto (input)',
  TextoTextarea = 'Texto (textarea)',
  TextoInputComMascara = 'Texto (input com máscara)',
  UmaAlternativaRadio = 'Marcar uma alternativa (radio)',
  UmaAlternativaSelect = 'Selecionar uma alternativa (select)',
  VariasAlternativasCheckbox = 'Selecionar várias alternativas (checkbox)',
  Numero = 'Número',
  Data = 'Data',
  CNPJ = 'CNPJ',
  CPF = 'CPF',
}
