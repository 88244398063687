<div class="pb-4 min-width">

  <div class="container text-center p-0">

    <!-- 👇 Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Notificações</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- Breadcrumb 👆 -->

    <!-- 👇 Novo agendamento -->
    <!-- <div class="row p-0 m-0">
      <div class=" col-lg-3 mb-3" style=" cursor: pointer; ">
        <button [routerLink]="['/all_notificacoes/adicionar']" type="button" class="btn btn-sm p-0 m-0 btn-principal-primary">
          <span class="btn-principal-primary-nome">
            Novo tipo
          </span>
          <span class="btn-principal-primary-icon">
            <i class="bi bi-layers"></i>
          </span>
        </button>
      </div>
    </div> -->
    <!-- Novo agendamento 👆 -->

    <!-- 👇 Informações / Configurações / Filtro -->
    <div class="row p-0 m-0 mb-3">
      <div class="min-width col-12">
        <div class="p-0 m-0 box-gen-card">

          <!-- Informações / Configurações -->
          <div class="row p-0 m-0">

            <!-- Informações -->
            <div class="col-lg-3 col-md-3 col-sm-12 p-0 m-0">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100 box-gen-card-h3">
                <span *ngIf="all_notificacoes.length == 0">
                  Sem notificações
                </span>
                <span *ngIf="all_notificacoes.length > 0">
                  <span class="box-gen-card-h3-span">{{all_notificacoes.length}}</span> {{all_notificacoes.length == 1 ? "Notificação" : "Notificações"}}
                </span>
              </h3>
            </div>

            <!-- Configurações -->
            <div class="col-lg-9 col-md-9 col-sm-12 p-0 m-0">

              <div class="custom-button-group row">

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button type="button" class="custom-button" (click)="reload()" style=" background: transparent; ">
                    Atualizar <i class="bi bi-arrow-clockwise ag-pl-10"></i>
                  </button>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button mat-icon-button [matMenuTriggerFor]="modoButton" aria-label="modo" class="custom-button" style=" background: transparent; " [title]="modoView == true ? 'Tabela' : 'Card'">
                    {{modoView == true ? 'Tabela' : 'Card'}}
                    <i *ngIf="modoView" class="bi bi-table ag-pl-5"></i>
                    <i *ngIf="!modoView" class="bi bi-card-text ag-pl-5"></i>
                  </button>
                  <mat-menu #modoButton="matMenu">
                    <button mat-menu-item (click)="modoTabela()">
                      <i class="bi bi-table ag-pr-5"></i>
                      <span>Tabela</span>
                    </button>
                    <button mat-menu-item (click)="modoCard()">
                      <i class="bi bi-card-text ag-pr-5"></i>
                      <span>Card</span>
                    </button>
                  </mat-menu>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_'" aria-expanded="false" [attr.aria-controls]="'collapse_'" class="custom-button" style=" background: transparent; ">
                    Filtro <i class="bi bi-search ag-pl-5" title="expand" ></i>
                  </button>
                </div>

                <div class="custom-button-div col-lg-6 col-md-12 col-sm-12 col-12">
                  <button type="button" class="custom-button" (click)="marcarTodasNotificacoesComoLidas()" style=" background: transparent; " [disabled]="existeNotificacaoNaoLidaPorUsuarioAtual()" >
                    Marcar todas as notificações como lidas <i class="bi bi-eye ag-pl-5"></i>
                  </button>
                </div>
              </div>

            </div>
          </div>

          <!-- Filtro -->
          <div [id]="'collapse_'" class="accordion-collapse collapse col-lg-12 pt-2">
            <div class="p-0 m-0" >
              <div *ngIf="all_notificacoes.length > 0" style="position: relative;">
                <div class="form-floating w-100 ">
                  <input type="text" class="form-control input-text-padrao m-0 search-box-result input-text-padrao" id="filtrarNotificacaoSearch" [(ngModel)]="pesquisa" (keydown)="searchNotificacao()" (keyup)="searchNotificacao()" (keypress)="searchNotificacao()" placeholder="&nbsp;">
                  <label for="filtrarNotificacaoSearch" class="label-input-text-padrao ag-pt-18 ag-pb-18 ag-pl-12 ag-pr-12">Filtrar notificação ...</label>
                </div>
                <i *ngIf="pesquisa != ''" class="bi bi-x-circle-fill text-secondary reset-search-usr" (click)="resetSearch()"></i>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- Informações / Configurações / Filtro 👆 -->

    <!-- 👇 Modo Card -->
    <div class="row p-0 m-0" *ngIf="!modoView">
      <div class="min-width col-lg-12 pb-5 pt-1">

        <!-- Loading -->
        <div class="row" *ngIf="all_notificacoes.length == 0 && loading && modoViewNum == 12">
          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" >
            <div class="p-0 m-0 pulsate" >
            </div>
          </div>
        </div>

        <!-- Card -->
        <div class="row" *ngIf="all_notificacoes.length > 0 && !loading">

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" *ngFor="let ntf of displayedNotificacoes | searchNotificacao: pesquisa">

            <div class="card card-padrao-list p-0 m-0">

              <div class="card-body">
                <div class="row card-body-row">

                  <!-- Visivel inicialmente -->
                  <div class="col-lg-12 mb-1">
                    <div class="card-body-row-div">
                      <div class="card-padrao-list-box">

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <!-- <p style="font-size: 13px;padding: 0px 5px;margin: 0;">{{ ntf.message }}</p> -->
                          <div style="display: flex;align-items: flex-start;flex-direction: column;flex-wrap: nowrap;width: 100%;">
                            <div style=" display: flex; ">
                              <span class="background-cor-secundaria" style="min-width: auto;margin-bottom: 10px;padding: 0px 7px;display: flex;align-items: center;justify-content: space-between;font-size: 10px;border-radius: 6px;color: var(--cor-branco);font-weight: 600;height: 18px;text-transform: uppercase; margin-right: 10px;">
                                {{ntf.tipo}}
                              </span>

                              <ng-container *ngIf="isNotificacaoLidaPorUsuarioAtual(ntf)">
                                <!-- Exibir "Lida" para notificações existentes e lidas -->
                                <span style="min-width: auto;margin-bottom: 10px;padding: 0px 7px;display: flex;align-items: center;justify-content: space-between;background: var(--cor-sucesso);font-size: 10px;border-radius: 6px;color: var(--cor-branco);font-weight: 600;height: 18px;text-transform: uppercase;margin-right: 10px;">
                                  Lida
                                </span>
                              </ng-container>
                              <ng-container *ngIf="!isNotificacaoLidaPorUsuarioAtual(ntf)">
                                <!-- Exibir "Não lida" para notificações existentes, mas não lidas -->
                                <span style="min-width: auto;margin-bottom: 10px;padding: 0px 7px;display: flex;align-items: center;justify-content: space-between;background: var(--cor-danger);font-size: 10px;border-radius: 6px;color: var(--cor-branco);font-weight: 600;height: 18px;text-transform: uppercase;margin-right: 10px;">
                                  Não lida
                                </span>
                              </ng-container>

                            </div>
                            <span class="card-padrao-list-img-title ag-pl-5 ag-pb-2" style="font-weight: bold;" [innerHTML]="ntf.titulo | filtroDestaque: pesquisa"></span>
                            <span class="card-padrao-list-box-nobg-value ag-pb-3" style="font-weight: normal;" [innerHTML]="ntf.texto | filtroDestaque: pesquisa"></span>


                          </div>


                        </div>

                        <div class="d-flex justify-content-start flex-row align-items-center">
                          <div class="card-body-time w-100 p-0 m-0">

                              <span class="notification-item-lido-span w-100 m-0">
                                <span>
                                  <i class="bi bi-envelope"></i> {{ntf.dataCriacao | date:'dd/MM/yyyy, HH:mm:ss'}}
                                </span>
                                <ng-container *ngFor="let leitura of ntf.leitura">
                                  <ng-container *ngIf="(usuario != undefined) && leitura.idUsuario == usuario.id">
                                    <span>
                                      <i class="bi bi-envelope-open"></i> {{ leitura.dataLeitura | date:'dd/MM/yyyy, HH:mm:ss' }}
                                    </span>
                                  </ng-container>
                                </ng-container>
                              </span>

                              <!-- <i class="bi bi-circle-fill text-cor-sucesso ag-pr-5" *ngIf="ntf.status.valor"></i>
                              <i class="bi bi-circle-fill text-cor-danger ag-pr-5" *ngIf="!ntf.status.valor"></i>
                              {{ntf.status.valor ? "Ativo" : "Desativado"}} -->
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 mb-1 d-none">
                    <div class="card-padrao-list-box">
                      <!-- <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start" style=" min-width: 65px; ">Criado</span>
                        <span class="card-padrao-list-box-nobg-value" style=" font-size: 13px; " [innerHTML]="(ntf.criado == '' ? '--/--/----, --:--' : (ntf.criado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa"></span>
                      </div> -->

                      <!-- <div class="d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start" style=" min-width: 65px; ">Atualizado</span>
                        <span class="card-padrao-list-box-nobg-value" style=" font-size: 13px; " [innerHTML]="(ntf.atualizado == '' ? '--/--/----, --:--' : (ntf.atualizado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa"></span>
                      </div> -->
                    </div>
                  </div>

                  <!-- Informações ocultas -->
                  <div [id]="'collapse_'+ntf.id" class="accordion-collapse collapse col-12 mt-2">
                    <div class="row p-0 m-0 card-body-row-oculta">
                      <div class="col-lg-12 p-0 m-0 d-flex flex-column justify-content-start flex-wrap">
                        <div class="p-0 m-0 d-flex flex-column align-items-start" *ngIf="ntf.responsavel.idUsuario != ''">
                          <p class="ag-pl-5 m-0 p-0" style="text-align: left;"><span style="font-weight: bold;">Usuário Responsável:</span> {{ntf.responsavel.nomeUsuario}} - <span style=" font-style: italic; font-size: 10px; ">{{ntf.responsavel.idUsuario}}</span> </p>
                          <hr class="hr-padrao w-100 ag-mt-10 ag-mb-10">
                        </div>
                        <div class="d-flex justify-content-start flex-row align-items-center ">
                          <div style="display: flex;width: 100%;flex-wrap: wrap;">
                            <button *ngIf="ntf.rota.semParam.caminho != ''" type="button" class="btn btn-outline-primary m-0 p-0 ag-mt-5" (click)="routerLink(ntf.rota.semParam.caminho)" style="display: flex;justify-content: space-around;padding: .25rem .5rem !important;min-width: 120px;margin-right: 5px !important;align-items: center; height: 20px;" >
                              {{ntf.rota.semParam.nome}}
                            </button>

                            <button *ngIf="ntf.rota.comParam.caminho != ''" type="button" class="btn btn-outline-primary m-0 p-0 ag-mt-5" (click)="routerLink(ntf.rota.comParam.caminho, ntf.rota.comParam.param)" style="display: flex;justify-content: space-around;padding: .25rem .5rem !important;min-width: 120px;margin-right: 5px !important;align-items: center; height: 20px;" >
                              {{ntf.rota.comParam.nome}}
                            </button>

                            <button *ngIf="ntf.linkExterno.url != ''" type="button" class="btn btn-outline-primary m-0 p-0 ag-mt-5" (click)="abrirLinkEmNovaGuia(ntf.linkExterno.url)" style="display: flex;justify-content: space-around;padding: .25rem .5rem !important;min-width: 120px;margin-right: 5px !important;align-items: center; height: 20px;" >
                              {{ntf.linkExterno.nome}}
                            </button>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>

                </div>

                <div class="card-padrao-footer-div-menu">
                  <!-- <ng-container *ngFor="let leitura of ntf.leitura"> -->
                    <ng-container *ngIf="isNotLidaPorNenhumUsuario(ntf)">
                      <i class="bi bi-three-dots" title="menu" mat-icon-button [matMenuTriggerFor]="editarNotificacao_i"></i>
                      <mat-menu #editarNotificacao_i="matMenu">
                        <button mat-menu-item (click)="marcarComoLido(ntf)">
                          <i class="bi bi-eye ag-pr-10 text-cor-primaria"></i>
                          <span>Marcar como lido</span>
                        </button>

                      </mat-menu>

                    </ng-container>
                  <!-- </ng-container> -->
                  <i class="bi bi-chevron-expand" title="expand" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + ntf.id" aria-expanded="false" [attr.aria-controls]="'collapse_' + ntf.id"></i>
                </div>

              </div>
            </div>

          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedNotificacoes.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao mt-3">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="col-12 mt-3  ">
            <div class="box-gen-card" style=" padding: 7px 0px !important; ">
              <mat-paginator
              [length]="all_notificacoes.length"
              [pageSize]="maxSize"
              [pageSizeOptions]="[5, 10, 25, 50]"
              [showFirstLastButtons]="true"
              (page)="handlePage($event)"
            ></mat-paginator>
            </div>

          </div>

        </div>

      </div>
    </div>
    <!-- Modo Card -->

    <!-- 👇 Modo Tabela -->
    <div class="row p-0 m-0" *ngIf="modoView">

      <!-- Loading -->
      <div class="min-width col-lg-12 pb-5 pt-3" *ngIf="all_notificacoes.length == 0 && loading">
        <div class="p-0 m-0 pulsate box-gen-card">
        </div>
      </div>


      <div class="min-width col-lg-12 pb-5 pt-1" >
        <div class="p-0 m-0 box-gen-card mb-3" *ngIf="all_notificacoes.length > 0 && !loading">

          <!-- Tabela -->
          <div class="w-100 overflow-auto">
            <table id="excel-table" class="mb-1 mt-1 table table-responsive table-hover table-sm" [ngClass]="{'table-dark': !lightMode,'table-light': lightMode}" matSort (matSortChange)="sortData($event)">
              <thead style=" height: 40px; vertical-align: middle; " *ngIf="displayedNotificacoes.length > 0">
                  <tr>
                    <th class="text-cor-padrao ag-pl-15" mat-sort-header="id" style="min-width: 55px;border-right: 1px solid var(--border-cor);">ID</th>
                    <th class="text-cor-padrao text-align-start" style="min-width: 55px;border-right: 1px solid var(--border-cor);text-align: left;">Status</th>
                    <th class="text-cor-padrao" mat-sort-header="titulo" style="text-align: left;min-width: 180px;border-right: 1px solid var(--border-cor);">Título</th>
                    <th class="text-cor-padrao" mat-sort-header="mensagem" style="text-align: left;min-width: 180px;border-right: 1px solid var(--border-cor);">Mensagem</th>
                    <th class="text-cor-padrao" mat-sort-header="criado" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Criado</th>
                    <th class="text-cor-padrao" style="min-width: 100px;border-right: 1px solid var(--border-cor);text-align: left;">Leitura</th>
                    <th class="text-cor-padrao sticky-col" style=" text-align: center;" >&nbsp;</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="text-cor-padrao ag-table-tr" *ngFor="let ntf of displayedNotificacoes | searchNotificacao: pesquisa">
                    <td class="ag-table-td ag-pl-15" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="ntf.id | filtroDestaque: pesquisa">
                        <!-- {{ntf.id | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div >
                        <i class="bi bi-circle-fill text-cor-sucesso ag-pr-5" *ngIf="isNotificacaoLidaPorUsuarioAtual(ntf)"></i>
                        <i class="bi bi-circle-fill text-cor-danger ag-pr-5" *ngIf="!isNotificacaoLidaPorUsuarioAtual(ntf)"></i>
                        {{isNotificacaoLidaPorUsuarioAtual(ntf) ? "Lida" : "Não lida"}}
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="ntf.titulo | filtroDestaque: pesquisa">
                        <!-- {{ntf.placaVeiculo.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="ntf.texto | filtroDestaque: pesquisa">
                        <!-- {{ntf.placaVeiculo.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="(ntf.dataCriacao == '' ? '--/--/----, --:--' : (ntf.dataCriacao | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa">
                        <!-- {{ntf.fornecedor.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <ng-container *ngFor="let leitura of ntf.leitura">
                        <ng-container *ngIf="(usuario != undefined) && leitura.idUsuario == usuario.id">
                          <span>
                            {{ leitura.dataLeitura | date:'dd/MM/yyyy, HH:mm:ss' }}
                          </span>
                        </ng-container>
                      </ng-container>
                    </td>
                    <!-- <td class="ag-table-td">
                      <div >
                        {{ntf.hora_final.valor}}
                      </div>
                    </td> -->
                    <td class="ag-table-td sticky-col">
                      <ng-container *ngIf="isNotLidaPorNenhumUsuario(ntf)">
                        <button mat-icon-button [matMenuTriggerFor]="editarAgendamento" aria-label="modo" class="btn-outline-primary sticky-col-buttom">
                          <i class="bi bi-three-dots"></i>
                        </button>
                        <mat-menu #editarAgendamento="matMenu">
                          <button mat-menu-item (click)="marcarComoLido(ntf)">
                            <i class="bi bi-eye ag-pr-10 text-cor-primaria"></i>
                            <span>Marcar como lido</span>
                          </button>
                        </mat-menu>
                      </ng-container>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedNotificacoes.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="text-right row pagination-box-result" *ngIf="all_notificacoes.length > 0">
            <div class="col-12 p-0 m-0">
              <mat-paginator
                [length]="all_notificacoes.length"
                [pageSize]="maxSize"
                [pageSizeOptions]="[5, 10, 25, 50]"
                [showFirstLastButtons]="true"
                (page)="handlePage($event)"
              ></mat-paginator>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- Modo Tabela 👆 -->

  </div>
</div>
