<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100" style="background: var(--linear-gradient-0deg);border: var(--border-padrao);position: relative;">
      <!-- <div class="login100-pic js-tilt" data-tilt>
        <img src="images/img-01.png" alt="IMG">
      </div> -->

      <div style="position: absolute; top: -48px; width: 100%;z-index: 15;">
        <ngx-loading-bar [fixed]="false" ref="http" [color]="(loader.value$ | async) === 100 ? 'var(--cor-sucesso)' : 'var(--cor-primaria)'" class="bg-transition animate-bg" style="position: relative; top: 48px;"></ngx-loading-bar>
      </div>

      <form class="login100-form validate-form">
        <div style="display: flex;align-items: center;flex-wrap: wrap;padding: 0px 20px 0px;flex-direction: row;margin-bottom: 15px;justify-content: center;" *ngIf="retornoConta.length > 0">
          <img *ngIf="retornoConta[0].logoURL.valor.trim() != ''" [src]="retornoConta[0].logoURL.valor" [title]="retornoConta[0].fantasia.valor + ' | ' + retornoConta[0].razao.valor" style="width: 150px;margin-bottom: 20px;margin-top: 20px;"/>
          <span *ngIf="retornoConta[0].logoURL.valor.trim() == ''" style="font-family: 'Kanit', sans-serif;font-weight: bold;color: var(--text-cor-padrao);margin-bottom: 20px;margin-top: 18px;font-size: 25px;">{{retornoConta[0].fantasia.valor}}</span>
        </div>

        <div style="display: flex;align-items: center;flex-wrap: wrap;padding: 0px 20px 0px;flex-direction: row;margin-bottom: 15px;" *ngIf="retornoConta.length == 0">
          <span >
            <!-- <i class="bi bi-app-indicator" style=" position: relative; top: 2px; "></i> -->
            <svg
              width="45"
              height="45"
              viewBox="0 0 180 180"
              version="1.1"
              id="svg1"
              inkscape:version="1.3 (0e150ed6c4, 2023-07-21)"
              sodipodi:docname="logo-logwise.svg"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:svg="http://www.w3.org/2000/svg">
              <defs id="defs1" />
                <g
                  inkscape:label="Camada 1"
                  inkscape:groupmode="layer"
                  id="layer1">
                  <g
                    id="g1"
                    transform="translate(-3.1499377,-2.3223201)">
                    <path
                      id="path1"
                      style="fill:var(--lw-cor-p);fill-opacity:1;stroke-width:1.34008"
                      d="M 27.306309,54.662884 92.905506,16.790039 110.70327,27.065873 45.10312,64.938886 V 120.1336 l 41.998014,24.24667 -2.81e-4,20.55284 -59.7946,-34.52319 z"
                      sodipodi:nodetypes="ccccccccc" />
                    <path
                      id="path2"
                      style="fill:var(--lw-cor-p);fill-opacity:1;stroke-width:1.34008"
                      d="m 158.50174,130.40869 -65.596338,37.87302 0.01183,-75.938455 -42.11568,-24.114095 18.356081,-10.595195 42.100417,24.306073 2.4e-4,55.173132 5.70748,-3.29403 0.003,-55.176142 -42.101644,-24.306683 18.197234,-10.505466 42.10126,24.305285 4.4e-4,55.173476 5.52006,-3.18647 2.2e-4,-55.171906 -42.102145,-24.307631 17.817645,-10.286395 42.09784,24.30618 z"
                      sodipodi:nodetypes="ccccccccccccccccccc" />
                  </g>
                </g>
            </svg>
          </span>
          <span style="font-family: 'Kanit', sans-serif;font-weight: bold;color: var(--text-cor-padrao);margin-bottom: 20px;margin-top: 18px;font-size: 25px;">LOGWISE</span>

        </div>

        <div class="wrap-input100 validate-input">
          <input class="input100 input-text-padrao" style="border-radius: 25px !important;" type="text" name="usuario" placeholder="Usuário"  [(ngModel)]="usr.Nome" [ngClass]="_disabled ? 'disabled' : ''" [disabled]="_disabled">
          <span class="focus-input100"></span>
          <span class="symbol-input100">
            <i class="bi bi-person-fill" aria-hidden="true"></i>
          </span>
        </div>

        <div class="wrap-input100 validate-input">
          <input class="input100 input-text-padrao" style="border-radius: 25px !important;" type="password" name="pass" placeholder="Password" [(ngModel)]="usr.Senha" [ngClass]="_disabled ? 'disabled' : ''" [disabled]="_disabled">
          <span class="focus-input100"></span>
          <span class="symbol-input100">
            <i class="bi bi-lock-fill" aria-hidden="true"></i>
          </span>
        </div>

        <div class="container-login100-form-btn" *ngIf="!_disabled">
          <button class="login100-form-btn" (click)="fazerLoginEmailSenhaFirebasae()" [ngClass]="(!usr.Nome || !usr.Senha) ? 'disabled' : ''" [disabled]="!usr.Nome || !usr.Senha" >
            Login
            <i class="bi bi-box-arrow-in-right" aria-hidden="true"></i>
          </button>

          <hr class="hr-padrao w-100">

          <button class="login100-form-btn" (click)="fazerLoginComGoogle()">
            Fazer Login com Google
            <i class="bi bi-google" aria-hidden="true"></i>
          </button>

        </div>

        <div class="container-login100-form-btn" *ngIf="_disabled">
          <button class="login100-form-btn" [ngClass]="_disabled ? 'disabled' : ''" [disabled]="_disabled" >
            Login
            <i class="bi bi-box-arrow-in-right" aria-hidden="true"></i>
          </button>

          <hr class="hr-padrao w-100">

          <button class="login100-form-btn" [ngClass]="_disabled ? 'disabled' : ''" [disabled]="_disabled">
            Fazer Login com Google
            <i class="bi bi-google" aria-hidden="true"></i>
          </button>

        </div>

        <div style=" margin-top: 30px; " (click)="routerLink('recuperar-senha')" *ngIf="!_disabled">
          <p _ngcontent-jfx-c1="" style="display: flex;align-items: center;justify-content: flex-start;width: 100%;flex-wrap: wrap;color: var(--text-cor-padrao);cursor: pointer;">
            <i _ngcontent-jfx-c1="" aria-hidden="true" class="bi bi-envelope-fill" style=" padding-right: 10px; "></i>
            Recuperar Senha
          </p>
        </div>

        <!-- <div class="text-center p-t-136">
          <a class="txt2" href="#">
            Create your Account
            <i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
          </a>
        </div> -->
      </form>

      <div *ngIf="loading" style=" position: absolute; width: 100%; min-height: 500px; z-index: 10; border-radius: 5px; overflow: hidden; display: flex; flex-wrap: wrap; justify-content: center; flex-direction: column; padding: 0px !important; align-items: center;  opacity: 0.8;" class="pulsate">

      </div>
    </div>



    <div class="wrap-login100 text-center mt-2 text-cor-padrao" *ngIf="log" style="min-height: 100% !important;background: var(--linear-gradient-0deg);border: var(--border-padrao);">
      <div class="login100-form" style="min-height: 100% !important;">
        <span class="txt1" [innerHTML]="return">
          <!-- {{return}} -->
        </span>
        <span style=" position: absolute; display: flex; top: -22px; right: -22px; font-size: 20px; color: #ff4b4b; cursor: pointer;" (click)="fecharRetur()">
          <i class="bi bi-x-circle-fill"></i>
        </span>
      </div>
    </div>
  </div>
</div>
