import { FormControl, Validators } from "@angular/forms";
import { campo } from "../interface/campo";
import { ValidatorService } from "../services/validator.service";
import { ContadorIndexService } from "src/services/contador-index.service";

const idx = ContadorIndexService.createInstance();
const validatorService: ValidatorService  = new ValidatorService();

export class ConfiguracaoTelaAgendamento {

  id: any | null = null;

  nome: campo = {
    title: 'Nome da Empresa ou Negócio',
    info: 'Nome da Empresa ou Negócio',
    valor: '',
    placeholder: 'Nome da Empresa ou Negócio',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  sigla: campo = {
    title: 'Informe uma sigla ou nome para identificar seu Negócio ou Serviço',
    info: 'Informe uma sigla ou nome para identificar seu Negócio ou Serviço',
    valor: '',
    placeholder: 'Informe uma sigla ou nome para identificar seu Negócio ou Serviço',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  email: campo = {
    title: 'E-mail',
    info: 'E-mail',
    placeholder: 'Informe um endereço de e-mail válido',
    valor: '',
    inputType: 'text',
    inputMode: 'email',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required', 'email'],
    status: new FormControl()
  };

  telefone: campo = {
    title: 'Telefone',
    info: 'Telefone',
    placeholder: 'Informe o Telefone com DDD',
    valor: '',
    inputType: 'text',
    inputMode: 'tel',
    mascara: '',
    maxlength: '15',
    tipo: 'telefone',
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  whatsapp: campo = {
    title: 'Whatsapp',
    info: 'Whatsapp',
    placeholder: 'Informe o Whatsapp com DDD',
    valor: '',
    inputType: 'text',
    inputMode: 'tel',
    mascara: '',
    maxlength: '15',
    tipo: 'telefone',
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  site: campo = {
    title: 'Informe o seu site, se tiver. Inicie com HTTP:// ou HTTPS://',
    info: 'Informe o seu site, se tiver. Inicie com HTTP:// ou HTTPS://',
    valor: '',
    placeholder: 'Informe o seu site, se tiver. Inicie com HTTP:// ou HTTPS://',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  subTitulo: campo = {
    title: 'Mensagem personalizada aos seus clientes. É exibido em sua tela de agendamento',
    info: 'Mensagem personalizada aos seus clientes. É exibido em sua tela de agendamento',
    valor: '',
    placeholder: 'Mensagem personalizada aos seus clientes. É exibido em sua tela de agendamento',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  cep: campo = {
    title: 'CEP',
    info: 'CEP',
    valor: '',
    placeholder: 'Digite o CEP',
    inputType: 'text',
    inputMode: 'numeric',
    mascara: '#####-###',
    maxlength: '9',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  logradouro: campo = {
    title: 'Logradouro',
    info: 'Logradouro',
    valor: '',
    placeholder: 'Nome da rua, avenida, viela, travessa...',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  numero: campo = {
    title: 'Número',
    info: 'Número',
    valor: '',
    placeholder: 'Número do endereço, por exemplo, 123',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  complemento: campo = {
    title: 'Complemento',
    info: 'Complemento',
    valor: '',
    placeholder: 'Adicione informações adicionais, se houver, como "Apartamento 101" ou "Fundos"',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  bairro: campo = {
    title: 'Bairro',
    info: 'Bairro',
    valor: '',
    placeholder: 'Nome do bairro',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  localidade: campo = {
    title: 'Localidade',
    info: 'Localidade',
    valor: '',
    placeholder: 'Nome da cidade',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  uf: campo = {
    title: 'Uf',
    info: 'Uf',
    valor: '',
    placeholder: 'Digite o Estado no formato de sigla, ex.: SP',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  linkedin: campo = {
    title: 'Página no Linkedin',
    info: 'Página no Linkedin',
    valor: '',
    placeholder: 'Página no Linkedin',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  instagram: campo = {
    title: 'Página no Instagram',
    info: 'Página no Instagram',
    valor: '',
    placeholder: 'Página no Instagram',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  facebook: campo = {
    title: 'Página no Facebook',
    info: 'Página no Facebook',
    valor: '',
    placeholder: 'Página no Facebook',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  twitter: campo = {
    title: 'Página no X',
    info: 'Página no X',
    valor: '',
    placeholder: 'Página no X',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  youtube: campo = {
    title: 'Canal no Youtube',
    info: 'Canal no Youtube',
    valor: '',
    placeholder: 'Canal no Youtube',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  atualizado:                 Date = new Date();


  constructor() {}

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "nome":
        this.nome.valor = validatorService.aplicarMascara(this.nome.valor, mascara);
        break;
      case "sigla":
        this.sigla.valor = validatorService.aplicarMascara(this.sigla.valor, mascara);
        break;
      case "email":
        this.email.valor = validatorService.aplicarMascara(this.email.valor, mascara);
        break;
      case "telefone":
        if(this.telefone.tipo != ""){
          this.telefone.valor = validatorService.aplicarMascaraEspecial(this.telefone.valor, this.telefone.tipo);
        }{
          this.telefone.valor = validatorService.aplicarMascara(this.telefone.valor, mascara);
        }
        break;
      case "whatsapp":
        if(this.whatsapp.tipo != ""){
          this.whatsapp.valor = validatorService.aplicarMascaraEspecial(this.whatsapp.valor, this.whatsapp.tipo);
        }{
          this.whatsapp.valor = validatorService.aplicarMascara(this.whatsapp.valor, mascara);
        }
        break;
      case "site":
        this.site.valor = validatorService.aplicarMascara(this.site.valor, mascara);
        break;
      case "subTitulo":
        this.subTitulo.valor = validatorService.aplicarMascara(this.subTitulo.valor, mascara);
        break;
      case "cep":
        this.cep.valor = validatorService.aplicarMascara(this.cep.valor, mascara);
        break;
      case "logradouro":
        this.logradouro.valor = validatorService.aplicarMascara(this.logradouro.valor, mascara);
        break;
      case "numero":
        this.numero.valor = validatorService.aplicarMascara(this.numero.valor, mascara);
        break;
      case "complemento":
        this.complemento.valor = validatorService.aplicarMascara(this.complemento.valor, mascara);
      break;
      case "bairro":
        this.bairro.valor = validatorService.aplicarMascara(this.bairro.valor, mascara);
        break;
      case "localidade":
        this.localidade.valor = validatorService.aplicarMascara(this.localidade.valor, mascara);
        break;
      case "uf":
        this.uf.valor = validatorService.aplicarMascara(this.uf.valor, mascara);
        break;
      default:
        break;
    }
  }

  // Função para validar o campo com base nos validadores personalizados
  validarInput(tipo: string) {
    switch (tipo) {
      case "nome":
        this.nome.valor = this.nome.valor.trim();
        this.nome.status = validatorService.criarValidacao(this.nome.valor, this.nome.validacoesCustomizadas);
        break;
      case "sigla":
        this.sigla.valor = this.sigla.valor.trim();
        this.sigla.status = validatorService.criarValidacao(this.sigla.valor, this.sigla.validacoesCustomizadas);
        break;
      case "email":
        this.email.valor = this.email.valor.trim();
        this.email.status = validatorService.criarValidacao(this.email.valor, this.email.validacoesCustomizadas);
        break;
      case "telefone":
        this.telefone.valor = this.telefone.valor.trim();
        this.telefone.status = validatorService.criarValidacao(this.telefone.valor, this.telefone.validacoesCustomizadas);
        break;
      case "whatsapp":
        this.whatsapp.valor = this.whatsapp.valor.trim();
        this.whatsapp.status = validatorService.criarValidacao(this.whatsapp.valor, this.whatsapp.validacoesCustomizadas);
        break;
      case "site":
        this.site.valor = this.site.valor.trim();
        this.site.status = validatorService.criarValidacao(this.site.valor, this.site.validacoesCustomizadas);
        break;
      case "subTitulo":
        this.subTitulo.valor = this.subTitulo.valor.trim();
        this.subTitulo.status = validatorService.criarValidacao(this.subTitulo.valor, this.subTitulo.validacoesCustomizadas);
        break;
      case "cep":
        this.cep.valor = this.cep.valor.trim();
        this.cep.status = validatorService.criarValidacao(this.cep.valor, this.cep.validacoesCustomizadas);
        break;
      case "logradouro":
        this.logradouro.valor = this.logradouro.valor.trim();
        this.logradouro.status = validatorService.criarValidacao(this.logradouro.valor, this.logradouro.validacoesCustomizadas);
        break;
      case "numero":
        this.numero.valor = this.numero.valor.trim();
        this.numero.status = validatorService.criarValidacao(this.numero.valor, this.numero.validacoesCustomizadas);
        break;
      case "complemento":
        this.complemento.valor = this.complemento.valor.trim();
        this.complemento.status = validatorService.criarValidacao(this.complemento.valor, this.complemento.validacoesCustomizadas);
        break;
      case "bairro":
        this.bairro.valor = this.bairro.valor.trim();
        this.bairro.status = validatorService.criarValidacao(this.bairro.valor, this.bairro.validacoesCustomizadas);
        break;
      case "localidade":
        this.localidade.valor = this.localidade.valor.trim();
        this.localidade.status = validatorService.criarValidacao(this.localidade.valor, this.localidade.validacoesCustomizadas);
        break;
      case "uf":
        this.uf.valor = this.uf.valor.trim();
        this.uf.status = validatorService.criarValidacao(this.uf.valor, this.uf.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }

}
