<div class="pb-4 min-width">

  <div class="container text-center p-0">

    <!-- 👇 Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink(rotaStatus(checkin.status.valor))" class="text-padrao">Checkins <span style="text-transform: lowercase;">{{checkin.status.valor}}</span></a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Checkin {{checkin.id}}</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <div style=" display: flex; ">
          <button type="button" class="btn btn-outline-secondary mb-2 mr-2" (click)="routerLink(rotaStatus(checkin.status.valor))" style="display: flex;justify-content: space-around;margin-right: 10px;min-width: 155px !important;">
            <i class="bi bi-arrow-left" style="padding-right: 5px;"></i>
            Voltar {{checkin.status.valor}}
          </button>
          <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('checkin')" style="display: flex; justify-content: space-around;margin-right: 10px;">
            Kanban
            <i class="bi bi-arrow-right" style="padding-left: 5px;"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Breadcrumb 👆-->

    <!-- 👇 Novo Checkin -->
    <div class="row p-0 m-0">

      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Checkin - <span style="font-style: italic; font-size: 13px;">{{checkin.id}}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Dados do Motorista -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12 reset-padding-mobile">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Dados do Motorista </h3>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">

            <!-- checkin.nomeMotorista -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.nomeMotorista.valor"
                [label]="checkin.nomeMotorista.title"
                [tooltip]="checkin.nomeMotorista.info"
                [value]="checkin.nomeMotorista.valor"
                [isInvalid]="checkin.nomeMotorista.status?.invalid"
                [AgStatus]="checkin.nomeMotorista.status"
                [id]="'nomeMotorista'"
                [AgStatus]="checkin.nomeMotorista.status"
                AgPlaceholder="{{checkin.nomeMotorista.placeholder}}"
                AgInputType="{{checkin.nomeMotorista.inputType}}"
                AgMaxlength="{{checkin.nomeMotorista.maxlength}}"
                AgInputMode="{{checkin.nomeMotorista.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.nomeMotorista.mascara, 'nomeMotorista');"
                (change)="checkin.validarInput('nomeMotorista');"
              ></app-ag-input-field>
            </div>

            <!-- checkin.cpfMotorista -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.cpfMotorista.valor"
                [label]="checkin.cpfMotorista.title"
                [tooltip]="checkin.cpfMotorista.info"
                [value]="checkin.cpfMotorista.valor"
                [isInvalid]="checkin.cpfMotorista.status?.invalid"
                [AgStatus]="checkin.cpfMotorista.status"
                [id]="'cpfMotorista'"
                AgPlaceholder="{{checkin.cpfMotorista.placeholder}}"
                AgInputType="{{checkin.cpfMotorista.inputType}}"
                AgMaxlength="{{checkin.cpfMotorista.maxlength}}"
                AgInputMode="{{checkin.cpfMotorista.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.cpfMotorista.mascara, 'cpfMotorista');"
                (change)="checkin.validarInput('cpfMotorista');"
              ></app-ag-input-field>
            </div>

            <!-- checkin.placaVeiculo -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.placaVeiculo.valor"
                [label]="checkin.placaVeiculo.title"
                [tooltip]="checkin.placaVeiculo.info"
                [value]="checkin.placaVeiculo.valor"
                [isInvalid]="checkin.placaVeiculo.status?.invalid"
                [AgStatus]="checkin.placaVeiculo.status"
                [id]="'placaVeiculo'"
                AgPlaceholder="{{checkin.placaVeiculo.placeholder}}"
                AgInputType="{{checkin.placaVeiculo.inputType}}"
                AgMaxlength="{{checkin.placaVeiculo.maxlength}}"
                AgInputMode="{{checkin.placaVeiculo.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.placaVeiculo.mascara, 'placaVeiculo');"
                (change)="checkin.validarInput('placaVeiculo');"
              ></app-ag-input-field>
            </div>

            <!-- checkin.veiculo -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.veiculo.valor"
                [label]="checkin.veiculo.title"
                [tooltip]="checkin.veiculo.info"
                [value]="checkin.veiculo.valor"
                [isInvalid]="checkin.veiculo.status?.invalid"
                [AgStatus]="checkin.veiculo.status"
                [id]="'veiculo'"
                AgPlaceholder="{{checkin.veiculo.placeholder}}"
                AgInputType="{{checkin.veiculo.inputType}}"
                AgMaxlength="{{checkin.veiculo.maxlength}}"
                AgInputMode="{{checkin.veiculo.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.veiculo.mascara, 'veiculo');"
                (change)="checkin.validarInput('veiculo');"
              ></app-ag-input-field>
            </div>

            <!-- checkin.peso -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.peso.valor"
                [label]="checkin.peso.title"
                [tooltip]="checkin.peso.info"
                [value]="checkin.peso.valor"
                [isInvalid]="checkin.peso.status?.invalid"
                [AgStatus]="checkin.peso.status"
                [id]="'peso'"
                AgPlaceholder="{{checkin.peso.placeholder}}"
                AgInputType="{{checkin.peso.inputType}}"
                AgMaxlength="{{checkin.peso.maxlength}}"
                AgInputMode="{{checkin.peso.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.peso.mascara, 'peso');"
                (change)="checkin.validarInput('peso');"
              ></app-ag-input-field>
            </div>

            <!-- checkin.telefoneMotorista -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.telefoneMotorista.valor"
                [label]="checkin.telefoneMotorista.title"
                [tooltip]="checkin.telefoneMotorista.info"
                [value]="checkin.telefoneMotorista.valor"
                [isInvalid]="checkin.telefoneMotorista.status?.invalid"
                [AgStatus]="checkin.telefoneMotorista.status"
                [id]="'telefoneMotorista'"
                [AgDisabled]="true"
                AgPlaceholder="{{checkin.telefoneMotorista.placeholder}}"
                AgInputType="{{checkin.telefoneMotorista.inputType}}"
                AgMaxlength="{{checkin.telefoneMotorista.maxlength}}"
                AgInputMode="{{checkin.telefoneMotorista.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.telefoneMotorista.mascara, 'telefoneMotorista');"
                (change)="checkin.validarInput('telefoneMotorista');"
              ></app-ag-input-field>
            </div>

            <!-- checkin.emailMotorista -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.emailMotorista.valor"
                [label]="checkin.emailMotorista.title"
                [tooltip]="checkin.emailMotorista.info"
                [value]="checkin.emailMotorista.valor"
                [isInvalid]="checkin.emailMotorista.status?.invalid"
                [AgStatus]="checkin.emailMotorista.status"
                [id]="'emailMotorista'"
                [AgDisabled]="true"
                AgPlaceholder="{{checkin.emailMotorista.placeholder}}"
                AgInputType="{{checkin.emailMotorista.inputType}}"
                AgMaxlength="{{checkin.emailMotorista.maxlength}}"
                AgInputMode="{{checkin.emailMotorista.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.emailMotorista.mascara, 'emailMotorista');"
                (change)="checkin.validarInput('emailMotorista');"
              ></app-ag-input-field>
            </div>

          </div>

        </div>
      </div>

      <!-- Dados Complementares -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12 reset-padding-mobile">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Dados complementares </h3>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">

            <!-- checkin.notaFiscal -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.notaFiscal.valor"
                [label]="checkin.notaFiscal.title"
                [tooltip]="checkin.notaFiscal.info"
                [value]="checkin.notaFiscal.valor"
                [isInvalid]="checkin.notaFiscal.status?.invalid"
                [AgStatus]="checkin.notaFiscal.status"
                [id]="'notaFiscal'"
                [AgDisabled]="true"
                AgPlaceholder="{{checkin.notaFiscal.placeholder}}"
                AgInputType="{{checkin.notaFiscal.inputType}}"
                AgMaxlength="{{checkin.notaFiscal.maxlength}}"
                AgInputMode="{{checkin.notaFiscal.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.notaFiscal.mascara, 'notaFiscal');"
                (change)="checkin.validarInput('notaFiscal');"
              ></app-ag-input-field>
            </div>

            <!-- checkin.numeroPedido -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.numeroPedido.valor"
                [label]="checkin.numeroPedido.title"
                [tooltip]="checkin.numeroPedido.info"
                [value]="checkin.numeroPedido.valor"
                [isInvalid]="checkin.numeroPedido.status?.invalid"
                [AgStatus]="checkin.numeroPedido.status"
                [id]="'numeroPedido'"
                AgPlaceholder="{{checkin.numeroPedido.placeholder}}"
                AgInputType="{{checkin.numeroPedido.inputType}}"
                AgMaxlength="{{checkin.numeroPedido.maxlength}}"
                AgInputMode="{{checkin.numeroPedido.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.numeroPedido.mascara, 'numeroPedido');"
                (change)="checkin.validarInput('numeroPedido');"
              ></app-ag-input-field>
            </div>

            <!-- checkin.fornecedor -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="checkin.fornecedor.valor"
                [label]="checkin.fornecedor.title"
                [tooltip]="checkin.fornecedor.info"
                [value]="checkin.fornecedor.valor"
                [isInvalid]="checkin.fornecedor.status?.invalid"
                [AgStatus]="checkin.fornecedor.status"
                [id]="'fornecedor'"
                AgPlaceholder="{{checkin.fornecedor.placeholder}}"
                AgInputType="{{checkin.fornecedor.inputType}}"
                AgMaxlength="{{checkin.fornecedor.maxlength}}"
                AgInputMode="{{checkin.fornecedor.inputMode}}"
                (ngModelChange)="checkin.mascara(checkin.fornecedor.mascara, 'fornecedor');"
                (change)="checkin.validarInput('fornecedor');"
              ></app-ag-input-field>
            </div>

          </div>

    </div>

    <!-- Links Relacionados -->
    <!-- <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg  text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div> -->

      </div>

      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="salvarCheckin()">Salvar Checkin</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink(rotaStatus(checkin.status.valor))" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>

    </div>
    <!-- Novo Checkin 👆 -->


  </div>
</div>

<!-- alert Novo Checkin Modal -->
<div class="modal" id="alertNovoCheckinModal" #alertNovoCheckinModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="agendaSelecionada == null" class="border-alerta gen-card">
            <p class="text-cor-padrao gen-card-title">Atenção</p>
            <p class="text-cor-padrao gen-card-info">Selecione uma agenda</p>
          </div>

          <div *ngIf="checkin.nomeMotorista.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.nomeMotorista.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.nomeMotorista.info }}</p>
          </div>
          <div *ngIf="checkin.cpfMotorista.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.cpfMotorista.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.cpfMotorista.info }}</p>
          </div>
          <div *ngIf="checkin.placaVeiculo.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.placaVeiculo.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.placaVeiculo.info }}</p>
          </div>
          <div *ngIf="checkin.veiculo.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.veiculo.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.veiculo.info }}</p>
          </div>
          <div *ngIf="checkin.peso.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.peso.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.peso.info }}</p>
          </div>
          <div *ngIf="checkin.telefoneMotorista.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.telefoneMotorista.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.telefoneMotorista.info }}</p>
          </div>
          <div *ngIf="checkin.emailMotorista.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.emailMotorista.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.emailMotorista.info }}</p>
          </div>
          <div *ngIf="checkin.notaFiscal.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.notaFiscal.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.notaFiscal.info }}</p>
          </div>
          <div *ngIf="checkin.numeroPedido.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.numeroPedido.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.numeroPedido.info }}</p>
          </div>
          <div *ngIf="checkin.fornecedor.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ checkin.fornecedor.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ checkin.fornecedor.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>
