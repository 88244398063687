import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DataService } from '../data.service';
import { LocalhostService } from '../localhost.service';
import { ToastService } from '../toast.service';
import { Usuario } from 'src/class/usuario';
import { AngularFirestore,  } from '@angular/fire/compat/firestore';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private usuarios: Usuario[] = [];
  private usuariosSubject = new BehaviorSubject<Usuario[]>(this.usuarios);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private firestore: AngularFirestore,
    private conta: ContaService,
    private userService: AuthService
  ) {
    this.usuarios = [];
    // this.getUsuario();
  }

  async criarUsuario(usuario: Usuario, empresaSigla: any) {

    // console.log("criarUsuario",usuario);

    // Limpar o status formControll
    usuario.displayName.status = null;
    usuario.email.status = null;
    usuario.phoneNumber.status = null;
    usuario.photoURL.status = null;
    usuario.validadePlano.status = null;
    usuario.sigla.status = null;
    usuario.tipo.status = null;

    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarTipo', conta);

      usuario.sigla.valor = conta.sigla.valor;
      usuario.idConta = conta.id;

      const response = await this.localhostService.postDadosComId(`${conta.id}_usuarios`, usuario);
      // console.log('Tipo criado com sucesso:', response);

      // Atualize a lista local de agendas
      this.usuarios.push(usuario);
      this.usuariosSubject.next(this.usuarios);

      // Notifique o usuário sobre o sucesso
      this.showSuccess('Usuário criado com sucesso');

      // Nova notificação
      // 1 - empresaSigla: any,
      // 2 - titulo: string,
      // 3 - texto: string,
      // 4 - rotaSemParamNome: string,
      // 5 - rotaSemParamListar: string,
      // 6 - rotaComParamNome: string = "",
      // 7 - rotaComParamCaminho: string = "",
      // 8 - rotaComParamParam: string = "",
      // 9 - linkExternoNome: string = "",
      // 10 - linkExternoURL: string = "",
      // 11 - idReferencia: string,
      // 12 - tipo: string,
      // 13 - idResponsavel: string,
      // 14 - nomeResponsavel: string

      const usr = await this.userService.carregarUsuario(empresaSigla)

      this.notificacaoService.criarNotificacao(
        empresaSigla,
        `${usuario.displayName.valor}`,
        `Usuário criado.`,
        'Listar Usuários',
        'usuarios',
        'Editar Usuário',
        `usuario`,
        usuario.id?.toString(),
        '',
        '',
        usuario.id!.toString(),
        `Usuário`,
        `${usr.id}`,
        `${usr.displayName.valor}`
      )

      return true;
    } catch (error) {
      // console.error('Erro ao criar usuário:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllUsuario() {
    return this.usuariosSubject.asObservable().pipe(
      map(usuarios => usuarios)
    );
  }

  async getUsuario(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getTipo', conta);

      this.localhostService.getDados(`${conta.id}_usuarios`).subscribe(
        (usuarioFromServer: any[]) => {
          if (usuarioFromServer) {
            // Mapeia os dados para a estrutura desejada
            const usuarioArray: Usuario[] = usuarioFromServer.map(usuario => plainToClass(Usuario, usuario));

            // console.log(usuarioArray);

            this.usuarios = usuarioArray;
            this.usuariosSubject.next(this.usuarios);
            // this.updateLocalStorage();
          } else {
            this.usuarios = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter usuarios:', error);
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao obter tipos:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });


  }

  getUsuarioId(id: any): Observable<any[]> {
    return this.getAllUsuario().pipe(
      map(usuarios => {
        // Filtra as usuarios pela sigla
        const usuarioFiltrado = usuarios.filter(usuarios => usuarios.id === id);

        // Retorna os agendamentos filtrados
        return usuarioFiltrado;
      })
    );
  }

  private updateLocalStorage() {
    localStorage.setItem("Tipo", JSON.stringify(this.usuarios));
    DataService.set("Tipo", this.usuarios);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
