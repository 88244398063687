import { Component, OnDestroy, OnInit } from '@angular/core';
import { Usuario } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ToastService } from '../../../services/toast.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SiglaService } from 'src/services/siglaService.service';
import { Subject, takeUntil } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ContaService } from 'src/services/component/conta.service';

@Component({
  selector: 'app-usuario-recuperar-senha',
  templateUrl: './usuario-recuperar-senha.component.html',
  styleUrls: ['./usuario-recuperar-senha.component.css']
})
export class UsuarioRecuperarSenhaComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  usr = new Usuario();
  log = false;
  return: any;
  loading: boolean = false;
  loader = this.loadingBar.useRef('http');
  retornoConta: any = [];
  _disabled = false;

  email: string = "";

  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private siglaService: SiglaService,
    private loadingBar: LoadingBarService,
    private conta: ContaService,
  ) {
    // Alimenta o serviço com a empresaSigla
    this.route.params
    .pipe(takeUntil(this.destroy$))
    .subscribe(params => {
      this.empresaSigla = params['empresaSigla'];
      this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
    });
  }

  ngOnInit() {
    // console.log(this.usr)
    this.verificarSiglaEmpresa();

    // this.authService.usuarioLogado();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  verificarSiglaEmpresa() {

    if(this.empresaSigla) {
      this.loading = true;
      this.loader.start();

      this.conta.o_getSiglaConta('contas', this.empresaSigla)
      .pipe(takeUntil(this.destroy$))
      .subscribe((dados) => {
        // console.log('Dados contas:', dados);
        this.retornoConta = dados;
        this.loading = false;
        this.loader.complete();
      }, (erro) => {
        // console.log('Erro ao obter dados:', erro);
        this._disabled = true;
        this.loading = false;
        this.loader.complete();
        this.log = true;

        const ngxBarElement = document.querySelector('.ngx-bar');
        ngxBarElement?.classList.add('error-ngx-bar');
        this.return = `Sinto muito, não conseguimos encontrar a conta associada à sigla <strong>'${this.empresaSigla}'</strong>. Certifique-se de que o nome inserido na URL está correto.`;
      });
    }
  }

  async recuperarSenha(){
    this.log = false;

    try {
      const retorno = await this.authService.ForgotPassword(this.email)
      this.showSuccess(retorno)
    } catch (error) {
      this.log = true;
      this.return = error
    }


    // this.rec_pwd_load = true;
    // const usuario = await this.usuarios.recuperarSenha(this.email);
    // console.log(usuario)
    // if(usuario?.error?.Message){
    //   // this.rec_pwd_load = false;
    //   this.log = true;

    //   this.showError("E-mail não cadastrado")
    //   this.return = "E-mail não cadastrado"
    // }else{
    //   // this.rec_pwd_load = false;
    //   this.showSuccess(usuario.replaceAll("\"", ""))
    // }

  }

  fecharRetur(){
    this.log = false;
    this.return = ""
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
