import { Pipe, PipeTransform } from '@angular/core';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';

@Pipe({
  name: 'notificacaoBadge',
  pure: false // Defina pure como false para tornar o pipe assíncrono
})
export class NotificacaoBadgePipe implements PipeTransform {

  constructor(private notificacaoService: NotificacaoSistemaService,) {

  }

  transform(value: any, args?: any): any {
    const user = JSON.parse(localStorage.getItem('user')!);
    const unreadNotifications = this.notificacaoService.getNotificacoesNaoLidasUsuario(user?.uid);
    return unreadNotifications.length;
  }

}
