import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateWordService {

  constructor(private http: HttpClient) { }

  async translateWord(word: any, targetLanguage: any){
    const endpoint = `https://api.mymemory.translated.net/get?q=${encodeURIComponent(word)}&langpair=en|${targetLanguage}`;

    try {
      const response = await fetch(endpoint);
      const data = await response.json();

      if (response.ok) {
        const translation = data.responseData.translatedText;
        // console.log(`Tradução: ${translation}`);
        return translation;
      } else {
        // console.log('Erro na tradução:', data.responseStatus);
        return word;
      }
    } catch (error) {
      // console.log('Ocorreu um erro:', error);
      return word;
    }
  };
}
