import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Conta } from 'src/class/conta';
import { DataService } from '../data.service';
import { LocalhostService } from '../localhost.service';
import { NotificacaoBadgeService } from '../notificacao-badge.service';
import { ToastService } from '../toast.service';
import { AngularFirestore,  } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContaService {

  private contas: Conta[] = [];
  private contasSubject = new BehaviorSubject<Conta[]>(this.contas);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificationService: NotificacaoBadgeService,
    private toast: ToastService,
    private firestore: AngularFirestore
  ) {
    this.contas = [];
    // this.getConta();
  }

  criarConta(conta: Conta) {

    // console.log("criarConta",conta)

    conta.bairro.status = null;
    conta.cep.status = null;
    conta.cnpj.status = null;
    conta.complemento.status = null;
    conta.email.status = null;
    conta.facebook.status = null;
    conta.fantasia.status = null;
    conta.instagram.status = null;
    conta.linkedin.status = null;
    conta.localidade.status = null;
    conta.logoURL.status = null;
    conta.numero.status = null;
    conta.plano.status = null;
    conta.razao.status = null;
    conta.sigla.status = null;
    conta.site.status = null;
    conta.subTitulo.status = null;
    conta.telefone.status = null;
    conta.twitter.status = null;
    conta.uf.status = null;
    conta.validadePlano.status = null;
    conta.whatsapp.status = null;
    conta.youtube.status = null;
    // console.log("criarConta",conta);

    // Salve a nova agenda no Firestore do Firebase
    return this.localhostService.postDadosId('contas', conta).then(
      async (response: any) => {
        const res = await response
        // console.log('Serviço criado com sucesso:', res);

        // Atualize a lista local de agendas
        this.contas.push(conta);
        this.contasSubject.next(this.contas);

        // Atualize o localStorage (opcional, dependendo dos requisitos)
        // this.updateLocalStorage();

        // Notifique o usuário sobre o sucesso
        this.showSuccess('Serviço criado com sucesso');

        // Adicione uma notificação
        this.notificationService.addNotification(`${conta.id} - ${conta.razao.valor}, conta criada com sucesso`);

        // Navegue para a página de agendas
        // this.router.navigate(['/contas']);
        return true
      },
      (error: any) => {
        // console.error('Erro ao criar conta:', error);
        // Trate o erro conforme necessário
        return false
      }
    );
  }

  getAllConta() {
    return this.contasSubject.asObservable().pipe(
      map(contas => contas)
    );
  }

  getConta() {
    this.localhostService.getDados('contas').subscribe(
      (contaFromServer: any[]) => {
        if (contaFromServer) {
          // Mapeia os dados para a estrutura desejada
          const contaArray: Conta[] = contaFromServer.map(conta => plainToClass(Conta, conta));

          // console.log(contaArray);

          this.contas = contaArray;
          this.contasSubject.next(this.contas);
          // this.updateLocalStorage();
        } else {
          this.contas = [];
        }
      },
      (error) => {
        // console.error('Erro ao obter contas:', error);
      }
    );
  }

  getSiglaConta(sigla: string): Observable<any[]> {
    return this.getAllConta().pipe(
      map(contas => {
        // Filtra as contas pela sigla
        const contaFiltrada = contas.filter(contas => contas.sigla.valor === sigla);

        // Retorna os agendamentos filtrados
        return contaFiltrada;
      })
    );
  }

  o_getSiglaConta(colecao: string, sigla: string): Observable<any[]> {
    return new Observable<any[]>((observer) => {
      this.firestore.collection(colecao, ref => ref.where('sigla.valor', '==', sigla).limit(1)).get().toPromise()
        .then((snapshot) => {
          if (snapshot && !snapshot.empty) {
            // Retorna os dados do primeiro documento encontrado (assumindo que há apenas um)
            const doc = snapshot.docs[0];
            return doc.data();
          } else {
            throw new Error('Documento não encontrado');
          }
        })
        .then((dados) => {
          // Retorna os dados obtidos como um array para manter a consistência
          observer.next([dados]);
          observer.complete();
        })
        .catch((error) => {
          // console.log('Erro ao obter dados por sigla:', error);
          observer.error(error);
        });
    });
  }

  p_getSiglaConta(colecao: string, sigla: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection(colecao, ref => ref.where('sigla.valor', '==', sigla).limit(1)).get().toPromise()
        .then((snapshot) => {
          if (snapshot && !snapshot.empty) {
            // Retorna os dados do primeiro documento encontrado (assumindo que há apenas um)
            const doc = snapshot.docs[0];
            resolve(doc.data()); // Resolve a Promise com os dados obtidos
          } else {
            reject(new Error('Documento não encontrado')); // Rejeita a Promise se não houver dados
          }
        })
        .catch((error) => {
          reject(error); // Rejeita a Promise em caso de erro
        });
    });
  }

  private updateLocalStorage() {
    localStorage.setItem("Tipo", JSON.stringify(this.contas));
    DataService.set("Tipo", this.contas);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
