import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, catchError, takeUntil, throwError } from 'rxjs';
import { Conta } from 'src/class/conta';
import { Links } from 'src/class/links';
import { AuthService } from 'src/services/auth.service';
import { CepService } from 'src/services/cep.service';
import { ConfiguracaoTelaAgendamentoService } from 'src/services/component/ConfiguracaoTelaAgendamento.service';
import { ContaService } from 'src/services/component/conta.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { LinksService } from 'src/services/links.service';
import { LocalhostService } from 'src/services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';


declare var bootstrap: any;

@Component({
  selector: 'app-editar-conta',
  templateUrl: './editar-conta.component.html',
  styleUrls: ['./editar-conta.component.css']
})
export class EditarContaComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('configuracaoTelaAgendamento', { static: false }) configuracaoTelaAgendamento!: ElementRef;


  id: any;
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  rec_pwd_load = false;

  selectedFile: File | null = null;
  uploadProgress: number | null = null;

  showBtnMdl = false;

  cepBusca: string = "";

  // endereco: Endereco = new Endereco();
  // enderecos: Endereco[] = [];

  linksAgenda: Links[] = [];

  //

  conta: Conta = new Conta();
  verificarExisteDBconfigTelaAgendamento: any;
  empresaSigla: any | undefined;

  constructor(
    private route: ActivatedRoute,
    private usuarios: UsuariosService,
    private router: Router,
    private toast: ToastService,
    private authService: AuthService,
    private linksService: LinksService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private configuracaoTelaAgendamentoService: ConfiguracaoTelaAgendamentoService,
    private localhostService: LocalhostService,
    private siglaService: SiglaService,
    private contaService: ContaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      }
    });
  }

  async ngOnInit() {

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.contaService.getAllConta()
    .pipe(takeUntil(this.destroy$))
    .subscribe(contas => {

      for (let index = 0; index < contas.length; index++) {
        const ctd = contas[index];
        if(ctd.id == this.id){
          Object.assign(this.conta, ctd);
          // this.conta = Object.assign(new Conta(), ctd);
        }
      }

      if(this.conta.id == null){
        this.router.navigate([`${this.empresaSigla}/contas`]);
      }
    });

    // await this.contaService.p_getSiglaConta('contas', this.empresaSigla)
    // .then((conta) => {
    //   // Faça algo com os dados obtidos
    //   // console.log('Dados da conta: criarAgenda', conta);
    //   //`${conta.id}_agendas`
    //   this.conta = Object.assign(new Conta(), conta);
    // },
    // (error: any) => {
         // console.error('Erro ao atualizar dados:', error);
    //   // Trate o erro conforme necessário
    // }).catch((error) => {
    //   // Trate o erro
    //   // this.authService.fazerLogout(empresaSigla);
    //   // console.log('Erro ao obter dados da conta:', error);
    // });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async salvarConfiguracao(){

    const sigla = await this.verificarSigla(this.conta.sigla.valor)

    if(sigla){
      // console.log(this.conta)

      this.conta.cnpj.status = this.validatorService.criarValidacao(this.conta.cnpj.valor, this.conta.cnpj.validacoesCustomizadas);
      this.conta.razao.status = this.validatorService.criarValidacao(this.conta.razao.valor, this.conta.razao.validacoesCustomizadas);
      this.conta.fantasia.status = this.validatorService.criarValidacao(this.conta.fantasia.valor, this.conta.fantasia.validacoesCustomizadas);
      this.conta.sigla.status = this.validatorService.criarValidacao(this.conta.sigla.valor, this.conta.sigla.validacoesCustomizadas);
      this.conta.email.status = this.validatorService.criarValidacao(this.conta.email.valor, this.conta.email.validacoesCustomizadas);
      this.conta.telefone.status = this.validatorService.criarValidacao(this.conta.telefone.valor, this.conta.telefone.validacoesCustomizadas);
      this.conta.whatsapp.status = this.validatorService.criarValidacao(this.conta.whatsapp.valor, this.conta.whatsapp.validacoesCustomizadas);
      this.conta.site.status = this.validatorService.criarValidacao(this.conta.site.valor, this.conta.site.validacoesCustomizadas);
      this.conta.subTitulo.status = this.validatorService.criarValidacao(this.conta.subTitulo.valor, this.conta.subTitulo.validacoesCustomizadas);

      this.conta.cep.status = this.validatorService.criarValidacao(this.conta.cep.valor, this.conta.cep.validacoesCustomizadas);
      this.conta.logradouro.status = this.validatorService.criarValidacao(this.conta.logradouro.valor, this.conta.logradouro.validacoesCustomizadas);
      this.conta.numero.status = this.validatorService.criarValidacao(this.conta.numero.valor, this.conta.numero.validacoesCustomizadas);
      this.conta.complemento.status = this.validatorService.criarValidacao(this.conta.complemento.valor, this.conta.complemento.validacoesCustomizadas);
      this.conta.bairro.status = this.validatorService.criarValidacao(this.conta.bairro.valor, this.conta.bairro.validacoesCustomizadas);
      this.conta.localidade.status = this.validatorService.criarValidacao(this.conta.localidade.valor, this.conta.localidade.validacoesCustomizadas);
      this.conta.uf.status = this.validatorService.criarValidacao(this.conta.uf.valor, this.conta.uf.validacoesCustomizadas);


      if(
        this.conta.cnpj.status.invalid ||
        this.conta.razao.status.invalid ||
        this.conta.fantasia.status.invalid ||
        this.conta.sigla.status.invalid ||
        this.conta.email.status.invalid ||
        this.conta.telefone.status.invalid ||
        this.conta.whatsapp.status.invalid ||
        this.conta.site.status.invalid ||
        this.conta.subTitulo.status.invalid ||
        this.conta.logradouro.status.invalid ||
        this.conta.numero.status.invalid ||
        this.conta.complemento.status.invalid ||
        this.conta.bairro.status.invalid ||
        this.conta.localidade.status.invalid ||
        this.conta.uf.status.invalid
      ){
        this.abrirModal(this.configuracaoTelaAgendamento.nativeElement);
      }else{

        this.conta.atualizado = new Date();

        await this.contaService.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: criarAgenda', conta);
          //`${conta.id}_agendas`
          const docId = this.conta.id != null ? this.conta.id?.toString() : "";

          const plainObject = JSON.stringify(this.conta)
          const plainString = JSON.parse(plainObject)

          plainString.bairro.status = null;
          plainString.cep.status = null;
          plainString.cnpj.status = null;
          plainString.complemento.status = null;
          plainString.email.status = null;
          plainString.facebook.status = null;
          plainString.fantasia.status = null;
          plainString.instagram.status = null;
          plainString.linkedin.status = null;
          plainString.localidade.status = null;
          plainString.logoURL.status = null;
          plainString.numero.status = null;
          plainString.plano.status = null;
          plainString.razao.status = null;
          plainString.sigla.status = null;
          plainString.site.status = null;
          plainString.subTitulo.status = null;
          plainString.telefone.status = null;
          plainString.twitter.status = null;
          plainString.uf.status = null;
          plainString.validadePlano.status = null;
          plainString.whatsapp.status = null;
          plainString.youtube.status = null;

          this.localhostService.putDados('contas', docId!, plainString)
          .then((response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess("Configurações atualizadas com sucesso");
            this.router.navigate([`${this.empresaSigla}/home`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Erro ao atualizar as configurações \n" + error);
          });
        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // this.authService.fazerLogout(empresaSigla);
          // console.log('Erro ao obter dados da conta:', error);
        });

        // if(this.verificarExisteDBconfigTelaAgendamento.length > 0){
        //   console.log(this.verificarExisteDBconfigTelaAgendamento)

        //   const docId = this.conta.id != null ? this.conta.id?.toString() : "";

        //   const plainObject = JSON.stringify(this.conta)
        //   const plainString = JSON.parse(plainObject)

        //   this.localhostService.putDados('configuracaoTelaAgendamento', docId!, plainString)
        //   .then((response: any) => {
        //     console.log('Atualização bem-sucedida:', response);
        //     this.showSuccess("Configurações atualizadas com sucesso");
        //     this.router.navigate(['/home']);
        //   })
        //   .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
        //     this.showError("Erro ao atualizar as configurações \n" + error);
        //   });

        // }else{
        //   console.log(this.verificarExisteDBconfigTelaAgendamento)
        //   const configuracaoTelaAgendamento = JSON.stringify(this.conta);
        //   const res = await this.configuracaoTelaAgendamentoService.salvarConfiguracao(JSON.parse(configuracaoTelaAgendamento))
        //   console.log(res)
        // }


        // // Salve o array de volta no DataService
        // DataService.setItem("conta", this.conta);
        // localStorage.setItem("conta", JSON.stringify(this.conta))
        // this.showSuccess("Configurações atualizadas com sucesso");
        // this.router.navigate(['/home']);

      }
    }

  }

  buscarCep() {
    if(this.conta.cep.valor == undefined || this.conta.cep.valor.trim() == ""){
      this.showError("Por favor, informe um cep para realizar a busca")
    }else{
      this.cepService.buscarEnderecoPorCEP(this.conta.cep.valor)
      .pipe(
        catchError(async error => {
          try {
            // console.log('Ocorreu um erro ao buscar o endereço:', error);
            const trad = await this.translateWord.translateWord(error.message, 'pt');
            this.showError(trad)
            return throwError(trad)
          } catch (error2) {
            // console.log('Ocorreu um erro ao buscar o endereço:', error2);
            this.showError(error.message)
            return throwError(error.message)
          }
        })
      )
      .subscribe((resultado: any) => {
        if(resultado.erro){
          this.showError("O CEP não foi encontrado. Por favor, verifique o número digitado e tente novamente.")
        }else{
          this.conta.cep.valor = resultado.cep;
          this.conta.logradouro.valor = resultado.logradouro;
          this.conta.complemento.valor = resultado.complemento;
          this.conta.bairro.valor = resultado.bairro;
          this.conta.localidade.valor = resultado.localidade;
          this.conta.uf.valor = resultado.uf;

          this.conta.cep.status = this.validatorService.criarValidacao(this.conta.cep.valor, this.conta.cep.validacoesCustomizadas);
          this.conta.logradouro.status = this.validatorService.criarValidacao(this.conta.logradouro.valor, this.conta.logradouro.validacoesCustomizadas);
          this.conta.numero.status = this.validatorService.criarValidacao(this.conta.numero.valor, this.conta.numero.validacoesCustomizadas);
          this.conta.complemento.status = this.validatorService.criarValidacao(this.conta.complemento.valor, this.conta.complemento.validacoesCustomizadas);
          this.conta.bairro.status = this.validatorService.criarValidacao(this.conta.bairro.valor, this.conta.bairro.validacoesCustomizadas);
          this.conta.localidade.status = this.validatorService.criarValidacao(this.conta.localidade.valor, this.conta.localidade.validacoesCustomizadas);
          this.conta.uf.status = this.validatorService.criarValidacao(this.conta.uf.valor, this.conta.uf.validacoesCustomizadas);
        }

      });
    }
  }

  async verificarSigla(empresaSigla: any){
    if(empresaSigla == ''){
      // console.log(empresaSigla, false)
      this.showError("Por favor, informe um valor para a sigla da empresa.")
      this.conta.sigla.status = this.validatorService.criarValidacao(this.conta.sigla.valor, this.conta.sigla.validacoesCustomizadas);
      return false
    }
    else if(empresaSigla == this.empresaSigla){
      // console.log(empresaSigla, true)
      // this.showError("A sigla que você escolheu já está sendo utilizada. Por favor, escolha outra sigla.");
      return true;
    }else{
      return await this.contaService.p_getSiglaConta('contas', empresaSigla)
      .then((conta) => {
        // console.log('Conta:', conta, false);
        this.showError("A sigla que você escolheu já está sendo utilizada. Por favor, escolha outra sigla.");
        return false;
      },
      (error: any) => {
        // this.showSuccess("A sigla OK");
        // console.log('Erro ao criar conta:', error, true);
        // Trate o erro conforme necessário
        return true;
      }).catch((error) => {
        // Trate o erro
        // console.log('Erro ao obter dados da conta:', error, true);
        // this.showSuccess("A sigla OK");

        return true;
      });
    }
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private getFilePathFromUrl(fileUrl: string): string | null {
    // Verifique se a URL contém o parâmetro 'o' que indica o caminho do arquivo
    const pathParam = 'o/';
    const startIndex = fileUrl.indexOf(pathParam);

    if (startIndex !== -1) {
      const endIndex = fileUrl.indexOf('?');
      const filePath = fileUrl.substring(startIndex + pathParam.length, endIndex !== -1 ? endIndex : undefined);
      return decodeURIComponent(filePath);
    }

    return null;
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
