import { Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef, NgZone, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-ag-tempo-medio',
  templateUrl: './ag-tempo-medio.component.html',
  styleUrls: ['./ag-tempo-medio.component.css']
})
export class AgTempoMedioComponent implements OnChanges, OnDestroy {
  @Input() items: any[] = []; // Recebe os itens como entrada do componente
  tempoMedio: string = '00:00:00:00'; // Valor inicial do tempo médio

  private lastUpdate: number = 0;
  private intervalId: any; // Armazena o ID do intervalo

  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {
    // Atualiza o tempo médio a cada segundo
    this.ngZone.runOutsideAngular(() => {
      this.intervalId = setInterval(() => {
        this.ngZone.run(() => {
          this.lastUpdate = Date.now();
          this.atualizarTempoMedio(); // Atualiza o tempo médio
        });
      }, 1000);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.atualizarTempoMedio();
    }
  }

  atualizarTempoMedio(): void {
    const now = new Date();
    let totalDiffMs = 0;
    let count = 0;

    this.items.forEach(item => {
      // console.log(item)
      if (item.status.valor === 'Aguardando') {
        const date = new Date(item.statusAguardando.inicio);
        const diffMs = now.getTime() - date.getTime();
        totalDiffMs += diffMs;
        count++;
      }

      if (item.status.valor === 'Em andamento') {
        const date = new Date(item.statusEmAndamento.inicio);
        const diffMs = now.getTime() - date.getTime();
        totalDiffMs += diffMs;
        count++;
      }

      if (item.status.valor === 'Realizado') {
        const dateInicio = new Date(item.statusRealizado.inicio);
        const dateFim = new Date(item.statusRealizado.fim);
        const diffMs = dateFim.getTime() - dateInicio.getTime();
        totalDiffMs += diffMs;
        count++;
      }
    });

    if (count === 0) {
      this.tempoMedio = '00:00:00';
      return;
    }

    const averageDiffMs = totalDiffMs / count;

    const diffDays = Math.floor(averageDiffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((averageDiffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((averageDiffMs % (1000 * 60 * 60)) / (1000 * 60));
    const diffSeconds = Math.floor((averageDiffMs % (1000 * 60)) / 1000);

    const formattedDays = diffDays.toString().padStart(2, '0');
    const formattedHours = diffHours.toString().padStart(2, '0');
    const formattedMinutes = diffMinutes.toString().padStart(2, '0');
    const formattedSeconds = diffSeconds.toString().padStart(2, '0');

    this.tempoMedio = `${formattedDays == '00' ? '' : formattedDays+'D'} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    this.changeDetectorRef.markForCheck(); // Marca para detecção de mudanças
  }

  ngOnDestroy(): void {
    // Limpa o intervalo quando o componente for destruído
    if (this.intervalId) {
      clearInterval(this.intervalId);
      // console.log("ngOnDestroy AgTempoMedioComponent")
    }
  }
}
