import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { NotificacaoBadge } from 'src/interface/notificacao-badge';
import { DataService } from './data.service';
import { ContadorIndexService } from './contador-index.service';
import { LocalhostService } from './localhost.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoBadgeService {
  private notifications: NotificacaoBadge[] = [];
  private notificationsSubject = new BehaviorSubject<NotificacaoBadge[]>(this.notifications);

  constructor(
    private contadorIndexService: ContadorIndexService,
    private localhostService: LocalhostService
  ) {
    // const storedData = localStorage.getItem("NotificacaoBadge");
    // if (storedData) {
    //   this.notifications = JSON.parse(storedData);
    //   // console.log(this.notifications)
    //   this.notificationsSubject.next(this.notifications);
    // }
    this.notifications = [];
    this.getNotificacoes();

    // this.localhostService.getRooms2().subscribe(rooms => {
    //   this.teste = rooms;
    //   console.log(this.teste)
    // });
  }

  async addNotification(message: string) {
    const newNotification: NotificacaoBadge = {
      id: await this.contadorIndexService.contadorIndex(),
      message,
      read: false,
      createdDate: new Date(),
      readDate: null,
    };
    this.notifications.push(newNotification);
    this.notificationsSubject.next(this.notifications);

    // this.updateLocalStorage();
    this.postNotificationToServer(newNotification);
  }

  markAsRead(notification: NotificacaoBadge) {
    const notif = this.notifications.find(n => n.id === notification.id);

    if (notif) {
      notif.read = true;
      notif.readDate = new Date();
      this.notificationsSubject.next(this.notifications);
      const docId = notif.id.toString();
      this.localhostService.putDados('notificacoes', docId , notif)
        .then((response: any) => {
          // console.log('Atualização bem-sucedida:', response);
          this.getNotificacoes();
          // this.updateLocalStorage();
        })
        .catch((error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          this.getNotificacoes();
          // this.updateLocalStorage();
        });
    }
  }

  delete(notification: NotificacaoBadge) {
    const notif = this.notifications.find(n => n.id === notification.id);

    if (notif) {
      notif.read = true;
      notif.readDate = new Date();
      this.notificationsSubject.next(this.notifications);

      this.localhostService.deleteDados('notificacoes', { id: notif.id })
        .then((response: any) => {
          // console.log('Atualização bem-sucedida:', response);
          this.getNotificacoes();
          // this.updateLocalStorage();
        })
        .catch((error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          this.getNotificacoes();
          // this.updateLocalStorage();
        });
    }else{
      // console.error('Erro ao atualizar dados:');

    }
  }

  getAllNotifications() {
    return this.notificationsSubject.asObservable().pipe(
      map(notifications => notifications)
    );
  }

  getNotifications() {
    return this.notificationsSubject.asObservable().pipe(
      map(notifications => notifications.filter(notification => !notification.read))
    );
  }

  getNotificacoes() {
    this.localhostService.getDados('notificacoes').subscribe(
      (notificationsFromServer: any[]) => {

        if (notificationsFromServer) {
          // Mapeia os dados para a estrutura desejada
          const notificationsArray: NotificacaoBadge[] = notificationsFromServer.map(notification => ({
            createdDate: notification.createdDate.toDate(), // Converte para Date
            id: notification.id,
            readDate: notification.readDate ? notification.readDate.toDate() : null, // Converte para Date ou mantém null
            message: notification.message,
            read: notification.read
          }));

          // console.log(notificationsArray)

          this.notifications = notificationsArray;
          this.notificationsSubject.next(this.notifications);
          // this.updateLocalStorage();
        } else {
          this.notifications = [];
        }
      },
      (error) => {
        // console.error('Erro ao obter notificações:', error);
      }
    );
  }

  async getNotificacoesID() {
    this.localhostService.getDados('notificacoes').subscribe(
       (notificationsFromServer: NotificacaoBadge[]) => {
        // console.log(notificationsFromServer)

        if (notificationsFromServer) {
          this.notifications = notificationsFromServer;
          this.notificationsSubject.next(this.notifications);
          // this.updateLocalStorage();
        } else {
          this.notifications = [];
        }
      },
      (error) => {
        // console.error('Erro ao obter notificações:', error);
      }
    );
  }

  getUnreadNotifications() {
    return this.notifications.filter(notification => !notification.read);
  }

  getReadNotifications() {
    return this.notifications.filter(notification => notification.read);
  }

  private updateLocalStorage() {
    localStorage.setItem("NotificacaoBadge", JSON.stringify(this.notifications));
    DataService.set("NotificacaoBadge",this.notifications)
  }

  private postNotificationToServer(notification: NotificacaoBadge) {
    this.localhostService.postDadosId("notificacoes", notification)
      .then(response => {
        // console.log('Notificação enviada para o servidor com sucesso.', response);
      })
      .catch(error => {
        // console.error('Erro ao enviar notificação para o servidor:', error);
      });
  }
}
