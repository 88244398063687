import { FormControl } from "@angular/forms";
import { TipoRecurso } from "src/enum/tipo-recurso.enum";
import { campo } from "src/interface/campo";
import { ValidatorService } from "src/services/validator.service";

const validatorService: ValidatorService  = new ValidatorService();

export class Recurso {

  id: any | null = null;

  nome: campo = {
    title: 'Nome do Recurso',
    info: 'Nome do Recurso',
    valor: '',
    placeholder: 'Nome do Recurso',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  descricao: campo = {
    title: 'Descrição do Recurso',
    info: 'Descrição do Recurso',
    valor: '',
    placeholder: 'Descrição do Recurso',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  tipo: campo = {
    title: 'Tipo do Recurso',
    info: 'Tipo do Recurso',
    valor: '',
    placeholder: 'Tipo do Recurso',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  tag: any[] = [];

  status: campo = {
    title: 'Status do Recurso',
    info: 'Status do Recurso',
    valor: true,
  };

  criado: Date = new Date();
  atualizado: Date = new Date();

  tipoRecursoOptions: any;

  constructor() {
    this.tipoRecursoOptions = this.enumToOptions(TipoRecurso);
  }

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "nome":
        this.nome.valor = validatorService.aplicarMascara(this.nome.valor, mascara);
        break;
      case "descricao":
        this.descricao.valor = validatorService.aplicarMascara(this.descricao.valor, mascara);
        break;
      default:
        break;
    }
  }

  validarInput(tipo: string) {
    switch (tipo) {
      case "nome":
        this.nome.valor = this.nome.valor.trim();
        this.nome.status = validatorService.criarValidacao(this.nome.valor, this.nome.validacoesCustomizadas);
        break;
      case "descricao":
        this.descricao.valor = this.descricao.valor.trim();
        this.descricao.status = validatorService.criarValidacao(this.descricao.valor, this.descricao.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }

  enumToOptions(enumObj: any): { nome: string, value: string }[] {
    return Object.keys(enumObj)
      .filter(key => isNaN(Number(enumObj[key])))
      .map(key => ({ nome: enumObj[key], value: key }));
  }
}
