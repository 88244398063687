import { Pipe, PipeTransform } from '@angular/core';
import { StatusAgendamento } from 'src/enum/statusAgendamento.enum';
import { DataService } from 'src/services/data.service';

@Pipe({
  name: 'stepProgress'
})

export class StepProgressPipe implements PipeTransform {

  transform(status: StatusAgendamento): string {

    // console.log(status)
    const statusList = [
      StatusAgendamento.confirmadoCliente,
      StatusAgendamento.confirmadoAdm,
      StatusAgendamento.atendido,
      StatusAgendamento.naoAtendido
    ];

    const currentIndex = statusList.indexOf(status);

    let html = `
      <div class="wrapper-progressBarAgendamento">
        <ul class="progressBarAgendamento p-0 m-0">
    `;

    if(currentIndex === 0){
      html += `<li class="active"><span class="text-cor-sucesso">${statusList[0]}</span></li>`;
      html += `<li class=""><span>Pendente Administrador</span></li>`;
      html += `<li class=""><span>Pendente Finalização</span></li>`;
    }else if(currentIndex === 1){
      html += `<li class="active"><span class="text-cor-sucesso">${statusList[0]}</span></li>`;
      html += `<li class="active"><span class="text-cor-sucesso">${statusList[1]}</span></li>`;
      html += `<li class=""><span>Pendente Finalização</span></li>`;
    }else if(statusList[currentIndex] == statusList[2]){
      html += `<li class="active"><span class="text-cor-sucesso">${statusList[0]}</span></li>`;
      html += `<li class="active"><span class="text-cor-sucesso">${statusList[1]}</span></li>`;
      html += `<li class="active"><span class="text-cor-sucesso">${statusList[currentIndex]}</span></li>`;
    }else if(statusList[currentIndex] == statusList[3]){
      html += `<li class="active"><span class="text-cor-sucesso">${statusList[0]}</span></li>`;
      html += `<li class="active"><span class="text-cor-sucesso">${statusList[1]}</span></li>`;
      html += `<li class="activeDanger"><span class="text-cor-danger">${statusList[currentIndex]}</span></li>`;
    }

    html += `
        </ul>
      </div>
    `;

    return html;
  }

}
