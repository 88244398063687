<div class="pb-4">

  <div class="container text-center min-width p-0">

    <!-- Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('slots')" class="text-padrao">Slots</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{id}}</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('slots')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>


    <!-- Slot -->
    <div class="row p-0 m-0">
      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Slot - <span style="font-style: italic; font-size: 13px;">{{id}}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Slot Dados -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">
          <div class="row p-0 m-0">
            <!-- slot.nome -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="slot.nome.valor"
                  [label]="slot.nome.title"
                  [tooltip]="slot.nome.info"
                  [value]="slot.nome.valor"
                  [isInvalid]="slot.nome.status?.invalid"
                  [AgStatus]="slot.nome.status"
                  [id]="'slot_nome'"
                  AgPlaceholder="{{slot.nome.placeholder}}"
                  AgInputType="{{slot.nome.inputType}}"
                  AgMaxlength="{{slot.nome.maxlength}}"
                  AgInputMode="{{slot.nome.inputMode}}"
                  (ngModelChange)="slot.mascara(slot.nome.mascara, 'nome');"
                  (change)="slot.validarInput('nome');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- slot.descricao -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-textarea-field class="w-100"
                  [(ngModel)]="slot.descricao.valor"
                  [label]="slot.descricao.title"
                  [tooltip]="slot.descricao.info"
                  [value]="slot.descricao.valor"
                  [isInvalid]="slot.descricao.status?.invalid"
                  [AgStatus]="slot.descricao.status"
                  [id]="'slot_descricao'"
                  AgPlaceholder="{{slot.descricao.placeholder}}"
                  AgInputType="{{slot.descricao.inputType}}"
                  AgMaxlength="{{slot.descricao.maxlength}}"
                  AgInputMode="{{slot.descricao.inputMode}}"
                  (ngModelChange)="slot.mascara(slot.descricao.mascara, 'descricao');"
                  (change)="slot.validarInput('descricao');"
                  >
                </app-ag-textarea-field>
              </div>
            </div>

            <!-- slot.duracaoSlot -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="slot.duracaoSlot.valor"
                  [label]="slot.duracaoSlot.title"
                  [tooltip]="slot.duracaoSlot.info"
                  [value]="slot.duracaoSlot.valor"
                  [isInvalid]="slot.duracaoSlot.status?.invalid"
                  [AgStatus]="slot.duracaoSlot.status"
                  [id]="'slot_duracaoSlot'"
                  AgPlaceholder="{{slot.duracaoSlot.placeholder}}"
                  AgInputType="{{slot.duracaoSlot.inputType}}"
                  AgMaxlength="{{slot.duracaoSlot.maxlength}}"
                  AgInputMode="{{slot.duracaoSlot.inputMode}}"
                  (ngModelChange)="slot.mascara(slot.duracaoSlot.mascara, 'duracaoSlot');"
                  (change)="slot.validarInput('duracaoSlot');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- slot.status -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="slot.status.valor"
                [label]="slot.status.title"
                [tooltip]="slot.status.info"
                [value]="slot.status.valor"
                [id]="'slot_status'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>
        </div>
      </div>

      <!-- GrupoAgenda -->
      <div class="row p-0 m-0">
        <div class="row p-0 m-0">
          <div class="min-width col-lg mb-3">
            <div class="box-gen-card">
              <div class="row p-0 m-0">
                <div class="col-lg-12 reset-padding-mobile">
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100 mt-2 mb-2">
                    Agendas Disponíveis
                  </h3>
                </div>

                <div class="col-lg-12 reset-padding-mobile">

                  <!-- <h3 class="p-0 m-0 text-cor-padrao text-start w-100 ag-pl-10">Recursos Disponíveis</h3> -->
                  <mat-selection-list #resources>
                    <mat-list-option *ngFor="let agenda of agendas" [value]="agenda" class="p-0 m-0" style="border-top: var(--border-padrao)">
                      {{agenda.nome.valor}} - <span style=" font-size: 13px; font-style: italic; ">{{agenda.subNome.valor}}</span>
                      <!-- <div style="display: flex;flex-diagendation: column;flex-wrap: wrap;align-items: flex-start;justify-content: center;">
                        <span>{{agenda.descricao.valor}}</span>
                      </div> -->
                    </mat-list-option>
                  </mat-selection-list>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row p-0 m-0">
        <div class="row p-0 m-0">
          <div class="min-width col-lg mb-3">
            <div class="box-gen-card">
              <div class="row p-0 m-0">
                <div class="col-lg-12 reset-padding-mobile">
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Campos fixos </h3>
                      <p class="p-0 m-0 text-cor-padrao text-start w-100">Defina as regras de slots para os campos fixos.</p>
                    </div>
                    <div (click)="openModalCampoFixo()" class="ag-icon-click">
                      <i class="bi bi-plus-circle-fill"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="slot.regraCampoFixo.length > 0" style="height: 10px;">

              </div>
              <!--  -->

              <div class="row d-flex flex-wrap flex-column justify-content-center p-0 m-0 ">
                <div *ngFor="let cpFixo of slot.regraCampoFixo" class="row col-12 m-0 reset-padding-mobile">
                  <div class="row col-lg-12 p-2 m-0 ag-mt-5 text-cor-padrao" style="border: var(--border-padrao);position: relative;border-radius: 5px;">

                    <div class="col-lg-3 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2">
                      <p class="p-0 m-0">
                        {{cpFixo.nomeCampo.title}}:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{cpFixo.nomeCampo.valor}}</span>
                      </p>
                    </div>

                    <div class="col-lg-3 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2">
                      <p class="p-0 m-0">
                        {{cpFixo.regraCampo.title}}:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{cpFixo.regraCampo.valor}}</span>
                      </p>
                    </div>

                    <div class="col-lg-3 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2">
                      <p class="p-0 m-0">
                        {{cpFixo.regraValor1.title}}: <span style="font-weight: bold;">{{cpFixo.regraValor1.valor}}</span>
                      </p>
                      <p class="p-0 m-0" *ngIf="cpFixo.regraValor2.valor != ''">
                        {{cpFixo.regraValor2.title}}: <span style="font-weight: bold;">{{cpFixo.regraValor2.valor}}</span>
                      </p>
                    </div>

                    <div class="col-lg-3 text-left d-flex flex-row flex-wrap justify-content-between align-items-center pb-2 pt-2">

                      <button type="button" class="btn btn-outline-primary-s" (click)="openModalEditarCampoFixo(cpFixo)" style="display: flex;justify-content: space-around;align-items: center;" >
                        <i class="bi bi-pencil-fill"></i> Editar
                      </button>

                      <button type="button" class="btn btn-outline-danger" (click)="deletarRegra(cpFixo.id)" style="display: flex;justify-content: space-around;align-items: center;" >
                        <i class="bi bi-trash3-fill"></i> Excluir
                      </button>

                    </div>

                  </div>
                </div>
              </div>
              <!--  -->
            </div>
          </div>
        </div>
      </div>

      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <!-- <div class="w-100 mb-3" style="text-align: left; display: flex; align-items: center; ">
            <mat-checkbox [(ngModel)]="agenda.status.valor" [color]="'radial-primary'">
              {{agenda.status.title}}
            </mat-checkbox>
            <span mat-raised-button #status="matTooltip" [matTooltip]="agenda.status.info" matTooltipPosition="right" (click)="status.show()" class="tooltip-input-icon">
              <i class="bi bi-info-circle-fill"></i>
            </span>
          </div> -->

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="atualizarSlot()">Atualizar Slot</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('slots')" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<!-- alert Slot Modal -->
<div class="modal" id="alertSlotModal" #alertSlotModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="slot.nome.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ slot.nome.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ slot.nome.info }}</p>
          </div>

          <div *ngIf="slot.descricao.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ slot.descricao.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ slot.descricao.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>

<!-- Adicionar Regra Campo Fixo Modal -->
<div class="modal" id="campoFixoModal" #campoFixoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Adicionar Campo fixo</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" (click)="fecharModalRegra(campoFixoModal)" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >

        <div class="ag-pt-10 ag-pl-5 ag-pr-5">
          <div class="row p-0 m-0">

            <div class="col-lg-6 mb-3">
              <app-ag-ng-select [(ngModel)]="formCamposSelecionados"
                   [label]="'Selecione um campo'"
                   [id]="'formCampos'"
                   [tooltip]="'Selecione um campo'"
                   [AgPlaceholder]="'Selecione um campo'"
                   [AgOptions]="formCampos.campos"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   AgSelectType="checkbox"
                   [AgAppendTo]="'#campoFixoModal'">
              </app-ag-ng-select>
            </div>

            <div class="col-lg-6 mb-3">
              <app-ag-ng-select [(ngModel)]="tipoSlotSelecionados"
                   [label]="'Selecione uma regra'"
                   [id]="'formCampos'"
                   [tooltip]="'Selecione uma regra'"
                   [AgPlaceholder]="'Selecione uma regra'"
                   [AgOptions]="tipoSlotArray"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   AgSelectType="checkbox"
                   [AgAppendTo]="'#campoFixoModal'">
              </app-ag-ng-select>
            </div>

            <div class="col-lg-6 mb-3" *ngIf="formCamposSelecionados?.length > 0 && tipoSlotSelecionados?.length > 0" [ngClass]="{'col-lg-12': tipoSlotSelecionados != tipoSlot.estaEntre && tipoSlotSelecionados != tipoSlot.naoEstaEntre}">
              <app-ag-input-field class="w-100"
                  [(ngModel)]="objRegras.regraValor1.valor"
                  [label]="objRegras.regraValor1.title"
                  [tooltip]="objRegras.regraValor1.info"
                  [value]="objRegras.regraValor1.valor"
                  [isInvalid]="objRegras.regraValor1.status?.invalid"
                  [AgStatus]="objRegras.regraValor1.status"
                  [id]="'objRegras_regraValor1'"
                  AgPlaceholder="{{objRegras.regraValor1.placeholder}}"
                  AgInputType="{{objRegras.regraValor1.inputType}}"
                  AgMaxlength="{{objRegras.regraValor1.maxlength}}"
                  AgInputMode="{{objRegras.regraValor1.inputMode}}"
                  (ngModelChange)="objRegras.mascara(objRegras.regraValor1.mascara, 'regraValor1');"
                  (change)="objRegras.validarInput('regraValor1');"
                  >
              </app-ag-input-field>
            </div>

            <div class="col-lg-6 mb-3" *ngIf="formCamposSelecionados?.length > 0 && tipoSlotSelecionados?.length > 0 && (tipoSlotSelecionados == tipoSlot.estaEntre || tipoSlotSelecionados == tipoSlot.naoEstaEntre)">
              <app-ag-input-field class="w-100"
                  [(ngModel)]="objRegras.regraValor2.valor"
                  [label]="objRegras.regraValor2.title"
                  [tooltip]="objRegras.regraValor2.info"
                  [value]="objRegras.regraValor2.valor"
                  [isInvalid]="objRegras.regraValor2.status?.invalid"
                  [AgStatus]="objRegras.regraValor2.status"
                  [id]="'objRegras_regraValor2'"
                  AgPlaceholder="{{objRegras.regraValor2.placeholder}}"
                  AgInputType="{{objRegras.regraValor2.inputType}}"
                  AgMaxlength="{{objRegras.regraValor2.maxlength}}"
                  AgInputMode="{{objRegras.regraValor2.inputMode}}"
                  (ngModelChange)="objRegras.mascara(objRegras.regraValor1.mascara, 'regraValor2');"
                  (change)="objRegras.validarInput('regraValor2');"
                  >
              </app-ag-input-field>
            </div>

          </div>
        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" (click)="fecharModalRegra(campoFixoModal)">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addRegra()">Adicionar Regra</button>
      </div>

    </div>
  </div>
</div>

<!-- Editar Regra Campo Fixo Modal -->
<div class="modal" id="campoFixoModalEditar" #campoFixoModalEditar data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Editar Campo fixo</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" (click)="fecharModalRegra(campoFixoModalEditar)" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >

        <div class="ag-pt-10 ag-pl-5 ag-pr-5">
          <div class="row p-0 m-0">

            <div class="col-lg-6 mb-3">
              <app-ag-ng-select [(ngModel)]="formCamposSelecionados"
                   [label]="'Selecione um campo'"
                   [id]="'formCampos'"
                   [tooltip]="'Selecione um campo'"
                   [AgPlaceholder]="'Selecione um campo'"
                   [AgOptions]="formCampos.campos"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   AgSelectType="checkbox"
                   [AgAppendTo]="'#campoFixoModalEditar'">
              </app-ag-ng-select>
            </div>

            <div class="col-lg-6 mb-3">
              <app-ag-ng-select [(ngModel)]="tipoSlotSelecionados"
                   [label]="'Selecione uma regra'"
                   [id]="'formCampos'"
                   [tooltip]="'Selecione uma regra'"
                   [AgPlaceholder]="'Selecione uma regra'"
                   [AgOptions]="tipoSlotArray"
                   [AgMultiple]="false"
                   [AgCloseOnSelect]="true"
                   AgSelectType="checkbox"
                   [AgAppendTo]="'#campoFixoModalEditar'">
              </app-ag-ng-select>
            </div>

            <div class="col-lg-6 mb-3" *ngIf="formCamposSelecionados?.length > 0 && tipoSlotSelecionados?.length > 0" [ngClass]="{'col-lg-12': tipoSlotSelecionados != tipoSlot.estaEntre && tipoSlotSelecionados != tipoSlot.naoEstaEntre}">
              <app-ag-input-field class="w-100"
                  [(ngModel)]="objRegras.regraValor1.valor"
                  [label]="objRegras.regraValor1.title"
                  [tooltip]="objRegras.regraValor1.info"
                  [value]="objRegras.regraValor1.valor"
                  [isInvalid]="objRegras.regraValor1.status?.invalid"
                  [AgStatus]="objRegras.regraValor1.status"
                  [id]="'objRegras_regraValor1'"
                  AgPlaceholder="{{objRegras.regraValor1.placeholder}}"
                  AgInputType="{{objRegras.regraValor1.inputType}}"
                  AgMaxlength="{{objRegras.regraValor1.maxlength}}"
                  AgInputMode="{{objRegras.regraValor1.inputMode}}"
                  (ngModelChange)="objRegras.mascara(objRegras.regraValor1.mascara, 'regraValor1');"
                  (change)="objRegras.validarInput('regraValor1');"
                  >
              </app-ag-input-field>
            </div>

            <div class="col-lg-6 mb-3" *ngIf="formCamposSelecionados?.length > 0 && tipoSlotSelecionados?.length > 0 && (tipoSlotSelecionados == tipoSlot.estaEntre || tipoSlotSelecionados == tipoSlot.naoEstaEntre)">
              <app-ag-input-field class="w-100"
                  [(ngModel)]="objRegras.regraValor2.valor"
                  [label]="objRegras.regraValor2.title"
                  [tooltip]="objRegras.regraValor2.info"
                  [value]="objRegras.regraValor2.valor"
                  [isInvalid]="objRegras.regraValor2.status?.invalid"
                  [AgStatus]="objRegras.regraValor2.status"
                  [id]="'objRegras_regraValor2'"
                  AgPlaceholder="{{objRegras.regraValor2.placeholder}}"
                  AgInputType="{{objRegras.regraValor2.inputType}}"
                  AgMaxlength="{{objRegras.regraValor2.maxlength}}"
                  AgInputMode="{{objRegras.regraValor2.inputMode}}"
                  (ngModelChange)="objRegras.mascara(objRegras.regraValor1.mascara, 'regraValor2');"
                  (change)="objRegras.validarInput('regraValor2');"
                  >
              </app-ag-input-field>
            </div>

          </div>
        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" (click)="fecharModalRegra(campoFixoModalEditar)">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="atualizarRegra(objRegras.id)">Atualizar Regra</button>
      </div>

    </div>
  </div>
</div>
