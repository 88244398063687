import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  private chaveDeApi = 'EAAT3kJnGZAQIBO60y1ieMV2Uc47rfXxbD62r21z8diZAwEwqs7wNVZCzLngse1XS8jgVFUfdYJP4gYEMLqHdvWS6wF0wOBDMhCAZChdADk0p7HxT71VZB8uAZABE3NAAQFOJxBx13gJab1pg4DlKHHdIiRdYbh1uj833ZBZBlGCZAS18fWa8zZBLtpAbaAfXjz';

  constructor(private http: HttpClient) { }

  async enviarMensagem(corpo: any) {
    const url = 'https://graph.facebook.com/v17.0/150394868168057/messages';
    const headers = {
        'Authorization': `Bearer ${this.chaveDeApi}`,
        'Content-Type': 'application/json'
    };
    const body = {
        messaging_product: corpo.messaging_product,
        to: corpo.to,
        type: corpo.type,
        template: corpo.template,
        metadata: corpo.metadata
    };

    try {
      const response = await this.http.post<any>(url, body, { headers }).toPromise();
      // console.log(response);
      return response;
    } catch (error) {
        // console.error(error);
        return error;
    }
  }
}
