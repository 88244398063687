<div class="w-100 mb-1" style="text-align: left;">

  <div style=" display: flex; align-items: center; ">
    <label for="{{id}}" class="form-label label-input-text-padrao" style="display: flex; align-items: center; flex-wrap: nowrap;margin-bottom: 5px;">
      {{label}}
    </label>
    <span mat-raised-button #idTooltip="matTooltip" [matTooltip]="tooltip" (click)="idTooltip.show()" matTooltipPosition="right" class="tooltip-input-icon" style="margin-bottom: 5px;">
      <i class="bi bi-info-circle-fill"></i>
    </span>
    <div *ngIf="isInvalid">
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.required">Obrigatório</p>
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.cnpjInvalido">CNPJ Inválido</p>
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.cpfInvalido">CPF Inválido</p>
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.email">E-mail Inválido</p>

    </div>
  </div>

  <input class="form-control input-text-padrao m-0"
    [placeholder]="AgPlaceholder"
    [id]="id"
    [(ngModel)]="innerValue"
    (ngModelChange)="onValueChange($event)"
    [ngClass]="{'border-danger': isInvalid, 'input-disabled': AgDisabled}"
    [attr.type]="AgInputType ? AgInputType : 'text'"
    [attr.maxlength]="AgMaxlength"
    [attr.inputmode]="AgInputMode ? AgInputMode : 'text'"
    [disabled]="AgDisabled">

  <div class="char-counter text-padrao" style="text-align: right;margin-top: 0px;font-size: .7rem;">
    <span *ngIf="AgMaxlength; else onlyCount" [ngClass]="{'error-message': (innerValue?.length == AgMaxlength)}">
      {{ innerValue?.length || 0 }}/{{ AgMaxlength }}
    </span>
    <ng-template #onlyCount>
      {{ innerValue?.length || 0 }}
    </ng-template>
  </div>

</div>

