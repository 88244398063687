import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Links } from 'src/class/links';
import { Servico } from 'src/class/servico';
import { objUsuario } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { CepService } from 'src/services/cep.service';
import { ContaService } from 'src/services/component/conta.service';
import { ServicoService } from 'src/services/component/servico.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { DataService } from 'src/services/data.service';
import { LinksService } from 'src/services/links.service';
import { LocalhostService } from 'src/services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-editar-servico',
  templateUrl: './editar-servico.component.html',
  styleUrls: ['./editar-servico.component.css']
})
export class EditarServicoComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('alertServicoModal', { static: false }) alertServicoModal!: ElementRef;

  id: any;
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  rec_pwd_load = false;

  selectedFile: File | null = null;
  uploadProgress: number | null = null;

  showBtnMdl = false;

  servico: Servico = new Servico();
  servicos: Servico[] = [];

  linksAgenda: Links[] = [];
  empresaSigla: any | undefined;

  constructor(
    private route: ActivatedRoute,
    private usuarios: UsuariosService,
    private router: Router,
    private toast: ToastService,
    private authService: AuthService,
    private linksService: LinksService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private servicoService: ServicoService,
    private localhostService: LocalhostService,
    private siglaService: SiglaService,
    private conta: ContaService
  ){
    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      // }
    // });
  }

  async ngOnInit() {

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.servicoService.getAllServico()
    .pipe(takeUntil(this.destroy$))
    .subscribe(servico => {
      this.servicos = servico;

      for (let index = 0; index < this.servicos.length; index++) {
        const agd = this.servicos[index];
        if(agd.id == this.id){
          Object.assign(this.servico, agd);
        }
      }

      if(this.servico.id == null){
        this.router.navigate([`${this.empresaSigla}/agendas/servicos`]);
      }
    });

    // let servico = DataService.get("Servico");
    // console.log(servico)
    // if(servico != undefined){
    //   this.servicos = servico;
    // }

    // for (let index = 0; index < this.servicos.length; index++) {
    //   const agd = this.servicos[index];
    //   if(agd.id == this.id){
    //     Object.assign(this.servico, agd);
    //   }
    // }

    // if(this.servico.id == null){
    //   this.router.navigate([`${this.empresaSigla}/agendas/servicos`]);
    // }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async salvarServico(){
    this.servico.nome.status = this.validatorService.criarValidacao(this.servico.nome.valor, this.servico.nome.validacoesCustomizadas);
    this.servico.valor.status = this.validatorService.criarValidacao(this.servico.valor.valor, this.servico.valor.validacoesCustomizadas);
    this.servico.linkPagamento.status = this.validatorService.criarValidacao(this.servico.linkPagamento.valor, this.servico.linkPagamento.validacoesCustomizadas);
    this.servico.duracaoAtendimento.status = this.validatorService.criarValidacao(this.servico.duracaoAtendimento.valor, this.servico.duracaoAtendimento.validacoesCustomizadas);
    this.servico.numeroPessoasPorHorario.status = this.validatorService.criarValidacao(this.servico.numeroPessoasPorHorario.valor, this.servico.numeroPessoasPorHorario.validacoesCustomizadas);
    this.servico.instrucoesConfirmacao.status = this.validatorService.criarValidacao(this.servico.instrucoesConfirmacao.valor, this.servico.instrucoesConfirmacao.validacoesCustomizadas);

    if(this.servico.nome.status.invalid ||
      this.servico.valor.status.invalid ||
      this.servico.linkPagamento.status.invalid ||
      this.servico.duracaoAtendimento.status.invalid ||
      this.servico.numeroPessoasPorHorario.status.invalid ||
      this.servico.instrucoesConfirmacao.status.invalid
    ){
      this.abrirModal(this.alertServicoModal.nativeElement);
  }else{
      // console.log(this.servico)

      // Obtenha o array existente do DataService
      let servico = this.servicos;

      // Encontre o índice do item correspondente em servico com base no ID
      const index = servico.findIndex((item: Servico) => item.id === this.servico.id);

      if (index !== -1) {
        this.servico.atualizado = new Date();
        // Atualize o item existente com as alterações de this.servico
        servico[index] = this.servico;

        const docId = servico[index].id != null ? servico[index].id?.toString() : "";

        const plainObject = JSON.stringify(servico[index])
        const plainString = JSON.parse(plainObject)

        plainString.duracaoAtendimento.status = null;
        plainString.instrucoesConfirmacao.status = null;
        plainString.linkPagamento.status = null;
        plainString.nome.status = null;
        plainString.numeroPessoasPorHorario.status = null;
        plainString.valor.status = null;

        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: atualizarStatusServico', conta);
          this.localhostService.putDados(`${conta.id}_servicos`, docId!, plainString)
          .then((response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess("Serviço atualizado com sucesso");
            this.router.navigate([`${this.empresaSigla}/agendas/servicos`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Serviço não encontrado para atualização \n" + error);
          });

        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // console.log('Erro ao obter dados da conta:', error);
        });

        // Salve o array de volta no DataService
        // DataService.setItem("Servico", servico);

        // this.showSuccess("Serviço atualizado com sucesso");
        // this.router.navigate([`${this.empresaSigla}/agendas/servicos`]);
      } else {
        // O item não foi encontrado no array, você pode tratar isso conforme necessário
        this.showError("Serviço não encontrado para atualização");
      }

    }
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
