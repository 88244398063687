import { Pipe, PipeTransform, ChangeDetectorRef, NgZone } from '@angular/core';

@Pipe({
  name: 'tempoDecorrido',
  pure: false // Tornamos o pipe impuro para garantir a atualização frequente
})
export class TempoDecorridoPipe implements PipeTransform {

  private lastUpdate: number = 0;

  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {
    // Rodar o intervalo fora da zona Angular para evitar sobrecarga de detecção de mudanças
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.ngZone.run(() => {  // Força a detecção de mudanças dentro da zona Angular
          this.lastUpdate = Date.now();
          this.changeDetectorRef.markForCheck(); // Marca para detecção de mudanças
          // this.changeDetectorRef.detectChanges(); // Marca para detecção de mudanças
        });
      }, 1000);
    });
  }

  transform(isoDate: string): string {
    const now = new Date();
    const givenDate = new Date(isoDate);

    const diffMs = now.getTime() - givenDate.getTime(); // diferença em milissegundos
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24)); // dias
    const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // horas
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // minutos
    const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000); // segundos

    const formattedDays = diffDays.toString().padStart(2, '0');
    const formattedHours = diffHours.toString().padStart(2, '0');
    const formattedMinutes = diffMinutes.toString().padStart(2, '0');
    const formattedSeconds = diffSeconds.toString().padStart(2, '0');

    return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
}
