import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Agendamento } from 'src/class/agendamento';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { SiglaService } from '../siglaService.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';


@Injectable({
  providedIn: 'root'
})

export class AgendamentoService {
  private agendamentos: Agendamento[] = [];
  private agendamentosSubject = new BehaviorSubject<Agendamento[]>(this.agendamentos);

  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService,
    private notificacaoService: NotificacaoSistemaService,
    private siglaService: SiglaService
  ) {
    this.agendamentos = [];
    // this.getAgendamentos();

    this.siglaService.empresaSigla$.subscribe(empresaSigla => {
      this.empresaSigla = empresaSigla
    });

    this.rotinaAgendamentosPassados();
  }

  async criarAgendamento(agendamento: Agendamento, empresaSigla: any) {
    // console.log("criarAgendamento",agendamento)

    agendamento.cpfMotorista.status = null;
    agendamento.dataAtendimento.status = null;
    agendamento.duracaoAtendimento.status = null;
    agendamento.emailMotorista.status = null;
    agendamento.fornecedor.status = null;
    agendamento.hora_final.status = null;
    agendamento.hora_inicial.status = null;
    agendamento.nomeMotorista.status = null;
    agendamento.notaFiscal.status = null;
    agendamento.numeroPedido.status = null;
    agendamento.observacao.status = null;
    agendamento.placaVeiculo.status = null;
    agendamento.veiculo.status = null;
    agendamento.peso.status = null;
    agendamento.telefoneMotorista.status = null;
    agendamento.observacaoCliente.status = null;
    agendamento.statusExecucao.status = null;
    agendamento.status.status = null;

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: criarAgenda', conta);
      //`${conta.id}_agendamentos`
      // Salve a nova agenda no Firestore do Firebase
      this.localhostService.postDadosId(`${conta.id}_agendamentos`, agendamento).then(
        async (response: any) => {
          // console.log('Agendamento criado com sucesso:', response);

          // Atualize a lista local de agendamentos
          this.agendamentos.push(agendamento);
          this.agendamentosSubject.next(this.agendamentos);

          // Atualize o localStorage (opcional, dependendo dos requisitos)
          // this.updateLocalStorage();

          // Notifique o usuário sobre o sucesso
          this.showSuccess(`Agendamento NF ${agendamento.notaFiscal.valor}, foi criado.`);

          // Nova notificação
          // 1 - empresaSigla: any,
          // 2 - titulo: string,
          // 3 - texto: string,
          // 4 - rotaSemParamNome: string,
          // 5 - rotaSemParamListar: string,
          // 6 - rotaComParamNome: string = "",
          // 7 - rotaComParamCaminho: string = "",
          // 8 - rotaComParamParam: string = "",
          // 9 - linkExternoNome: string = "",
          // 10 - linkExternoURL: string = "",
          // 11 - idReferencia: string,
          // 12 - tipo: string,
          // 13 - idResponsavel: string,
          // 14 - nomeResponsavel: string

          const usr = await this.userService.carregarUsuario(this.empresaSigla)

          this.notificacaoService.criarNotificacao(
            this.empresaSigla,
            `NF ${agendamento.notaFiscal.valor}`,
            `O agendamento do dia ${agendamento.dataAtendimento.valor}, das ${agendamento.hora_inicial.valor} às ${agendamento.hora_final.valor}, foi criado.`,
            'Listar Agendamentos',
            'agendamentos',
            'Editar Agendamento',
            `agendamentos/agendamento`,
            agendamento.id!.toString(),
            '',
            '',
            agendamento.agendaId!.toString(),
            `Agendamento`,
            `${usr.id}`,
            `${usr.displayName.valor}`
          )

          // Navegue para a página de agendamentos
          this.router.navigate([`${empresaSigla}/agendamentos`]);
        },
        (error: any) => {
          // console.error('Erro ao criar agendamento:', error);
          // Trate o erro conforme necessário
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao criar agenda:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // this.authService.fazerLogout(empresaSigla);
      // console.log('Erro ao obter dados da conta:', error);
    });

  }

  async getAgendamentos(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getAgendamentos', conta);

      this.localhostService.getDados(`${conta.id}_agendamentos`).subscribe(
        (agendamentosFromServer: any[]) => {
          if (agendamentosFromServer) {
            // Mapeia os dados para a estrutura desejada
            const agendamentosArray: Agendamento[] = agendamentosFromServer.map(agendamento => plainToClass(Agendamento, agendamento));

            // console.log(agendamentosArray);

            this.agendamentos = agendamentosArray;
            this.agendamentosSubject.next(this.agendamentos);
            // this.updateLocalStorage();
          } else {
            this.agendamentos = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter agendamentos:', error);
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao obter agendamentos:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });


  }

  getAllAgendamentos() {
    return this.agendamentosSubject.asObservable().pipe(
      map(agendamentos => agendamentos)
    );
  }

  getStatusAgendamento(status: string): Observable<Agendamento[]> {
    return this.getAllAgendamentos().pipe(
      map(agendamentos => {
        // Filtra os agendamentos pelo status desejado
        const agendamentosFiltrados = agendamentos.filter(agendamento => agendamento.status.valor === status);

        // Retorna os agendamentos filtrados
        return agendamentosFiltrados;
      })
    );
  }

  getAgendamentosDia(): Observable<Agendamento[]> {
    return this.getAllAgendamentos().pipe(
      map(agendamentos => {
        const dataAtualApenas = new Date();
        return agendamentos.filter(agt => {
          const dataDoObjetoString = agt.dataAtendimento.valor;
          const partesData = dataDoObjetoString.split("/");

          return (
            parseInt(partesData[2]) === dataAtualApenas.getFullYear() &&
            parseInt(partesData[1]) === dataAtualApenas.getMonth() + 1 &&
            parseInt(partesData[0]) === dataAtualApenas.getDate()
          );
        });
      })
    );
  }

  getStatusAgendamentoDia(status: string): Observable<Agendamento[]> {
    return this.getAgendamentosDia().pipe(
      map(agendamentos => {
        // Filtra os agendamentos pelo status desejado
        const agendamentosFiltrados = agendamentos.filter(agendamento => agendamento.status.valor === status);

        // Retorna os agendamentos filtrados
        return agendamentosFiltrados;
      })
    );
  }

  getAgendamentosPassados(): Observable<Agendamento[]> {
    return this.getAllAgendamentos().pipe(
      map(agendamentos => {
        const dataAtualApenas = new Date();
        dataAtualApenas.setHours(0, 0, 0, 0);
        return agendamentos.filter(agt => {
          const dataDoObjetoString = agt.dataAtendimento.valor;
          const partesData = dataDoObjetoString.split("/");

          // Convertendo a data do agendamento para um objeto Date
          const dataAgendamento = new Date(
            parseInt(partesData[2]),
            parseInt(partesData[1]) - 1, // meses em JavaScript vão de 0 a 11
            parseInt(partesData[0])
          );

          // Verificar se a data do agendamento é anterior à data atual
          return dataAgendamento < dataAtualApenas;
        });
      })
    );
  }

  getStatusAgendamentosPassados(status: string): Observable<Agendamento[]> {
    return this.getAgendamentosPassados().pipe(
      map(agendamentos => {
        // Filtra os agendamentos pelo status desejado
        const agendamentosFiltrados = agendamentos.filter(agendamento => agendamento.status.valor === status);

        // Retorna os agendamentos filtrados
        return agendamentosFiltrados;
      })
    );
  }

  getAgendamentosFuturos(): Observable<Agendamento[]> {
    return this.getAllAgendamentos().pipe(
      map(agendamentos => {
        const dataAtualApenas = new Date();
        dataAtualApenas.setHours(0, 0, 0, 0);
        return agendamentos.filter(agt => {
          const dataDoObjetoString = agt.dataAtendimento.valor;
          const partesData = dataDoObjetoString.split("/");

          // Convertendo a data do agendamento para um objeto Date
          const dataAgendamento = new Date(
            parseInt(partesData[2]),
            parseInt(partesData[1]) - 1, // meses em JavaScript vão de 0 a 11
            parseInt(partesData[0])
          );

          // Verificar se a data do agendamento é após a data atual
          return dataAgendamento > dataAtualApenas;
        });
      })
    );
  }

  getStatusAgendamentosFuturos(status: string): Observable<Agendamento[]> {
    return this.getAgendamentosFuturos().pipe(
      map(agendamentos => {
        // Filtra os agendamentos pelo status desejado
        const agendamentosFiltrados = agendamentos.filter(agendamento => agendamento.status.valor === status);

        // Retorna os agendamentos filtrados
        return agendamentosFiltrados;
      })
    );
  }


  async p_getAgendamentos(empresaSigla: any): Promise<any[]> {
    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getAgendamentos', conta);

      return new Promise<any[]>((resolve, reject) => {
        this.localhostService.getDados(`${conta.id}_agendamentos`).subscribe(
          (agendamentosFromServer: any[]) => {
            resolve(agendamentosFromServer);
          },
          (error) => {
            reject(error);
          }
        );
      });

    } catch (error) {
      // console.error('Erro ao obter agendamentos:', error);
      // Trate o erro conforme necessário
      return [];
    }
  }

  async p_getAgendamentoID(empresaSigla: any, id: any): Promise<any[]> {
    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getAgendamentos', conta);

      return this.localhostService.getDadosId(`${conta.id}_agendamentos`, id)
        .then((agendamento) => {

          return agendamento
        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
          return [];
        })

    } catch (error) {
      // console.error('Erro ao obter agendamentos:', error);
      // Trate o erro conforme necessário
      return [];
    }
  }

  rotinaAgendamentosPassados(): void {
    this.getAllAgendamentos().subscribe(agendamentos => {
        const dataAtualApenas = new Date();
        dataAtualApenas.setHours(0, 0, 0, 0);
        const agendamentosPassados = agendamentos.filter(agt => {
            const dataDoObjetoString = agt.dataAtendimento.valor;
            const partesData = dataDoObjetoString.split("/");

            // Convertendo a data do agendamento para um objeto Date
            const dataAgendamento = new Date(
                parseInt(partesData[2]),
                parseInt(partesData[1]) - 1, // meses em JavaScript vão de 0 a 11
                parseInt(partesData[0])
            );

            // Verificar se a data do agendamento é anterior à data atual
            // console.log(dataAgendamento , dataAtualApenas)
            return dataAgendamento < dataAtualApenas;
        });

        // console.log('Agendamentos passados:', agendamentosPassados);

        for (let index = 0; index < agendamentosPassados.length; index++) {
          const agendamento = agendamentosPassados[index];

          if((agendamento.status.valor == "Confirmado Cliente" || agendamento.status.valor == "Confirmado Administrador") && agendamento.statusExecucao.valor == ""){
            this.atualizarAgendamentosPassados(agendamento, agendamento.statusAgendamentoOptions[3].nome, agendamento.statusExecucaoOptions[1].nome, "Reprovação automática do sistema")
          }else if(agendamento.status.valor == "Não Atendido" && agendamento.statusExecucao.valor == ""){
            this.atualizarAgendamentosPassados(agendamento, agendamento.status.valor, agendamento.statusExecucaoOptions[1].nome, agendamento.observacao.valor)
          }else if(agendamento.status.valor == "Atendido" && agendamento.statusExecucao.valor == ""){
            this.atualizarAgendamentosPassados(agendamento, agendamento.status.valor, agendamento.statusExecucaoOptions[0].nome)
          }

        }

    });
  }

  async atualizarAgendamentosPassados(agendamento: Agendamento, status: any, statusExecucao: any, motivo: any = ""){
    try{
      const docId = agendamento.id != null ? agendamento.id?.toString() : "";

      agendamento.atualizado = new Date();
      agendamento.status.valor = status;
      agendamento.statusExecucao.valor = statusExecucao;
      agendamento.observacao.valor = motivo;

      const plainObject = JSON.stringify(agendamento)
      const plainString = JSON.parse(plainObject)

      plainString.cpfMotorista.status = null;
      plainString.dataAtendimento.status = null;
      plainString.duracaoAtendimento.status = null;
      plainString.emailMotorista.status = null;
      plainString.fornecedor.status = null;
      plainString.hora_final.status = null;
      plainString.hora_inicial.status = null;
      plainString.nomeMotorista.status = null;
      plainString.notaFiscal.status = null;
      plainString.numeroPedido.status = null;
      plainString.observacao.status = null;
      plainString.placaVeiculo.status = null;
      plainString.telefoneMotorista.status = null;
      plainString.status.status = null;
      plainString.statusExecucao.status = null;

      if(this.empresaSigla != ""){
        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: criarAgenda', conta);

          this.localhostService.putDados(`${conta.id}_agendamentos`, docId!, plainString)
          .then((response: any) => {
            // console.log('Atualização bem-sucedida:', agendamento);
            // this.showSuccess("Agendamento atualizado com sucesso");
            // this.router.navigate([`${this.empresaSigla}/agendamentos`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            // this.showError("Agendamento não encontrado para atualização \n" + error);
          });

        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // this.authService.fazerLogout(this.empresaSigla);
          // console.log('Erro ao obter dados da conta:', error);
        });
      }
    }catch(e: any){
      // console.log(e)
    }
  }

  private updateLocalStorage() {
    localStorage.setItem("Agendamentos", JSON.stringify(this.agendamentos));
    DataService.set("Agendamentos", this.agendamentos);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}

