import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelogioService {
  private relogioSubject: Subject<Date> = new Subject<Date>();

  iniciarRelogio(): void {
    setInterval(() => this.relogioSubject.next(new Date()), 1000);
  }

  obterRelogioObservable(): Observable<Date> {
    return this.relogioSubject.asObservable();
  }
}
