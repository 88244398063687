import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class TokenValidationService {
  private jwtHelper: JwtHelperService;

  constructor(
    private router: Router,
    public toast: ToastService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  validateToken(token: string): boolean {
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }

    // return true;

    // Token inválido ou expirado, redireciona para a página de login
    const empresaSigla = localStorage.getItem("empresaSigla");

    this.router.navigate([`${empresaSigla}/login`])
    // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.");
    return false;
  }

  navigateLogin(){
    // Token inválido ou expirado, redireciona para a página de login
    const empresaSigla = localStorage.getItem("empresaSigla");

    this.router.navigate([`${empresaSigla}/login`])
    // this.showError("Desculpe, mas sua sessão expirou. Por favor, faça login novamente.");
    return false;
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
