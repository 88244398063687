import { Checkbox } from "src/interface/checkbox";

export class FormCamposObrigatorios {
  title: string = "Selecione os obrigatórios";
  info: string = "Escolha quais campos devem ser obrigatórios no formulário de agendamento.";
  nome: string = 'Marcar Todos';
  check: boolean = false;
  cor: string = 'radial-danger';
  campos: Checkbox[];

  constructor() {
    this.campos = [
      {
        nome: 'Placa do Veículo',
        check: false,
        cor: 'radial-danger',
        visivel: true
      },{
        nome: 'Nome do Motorista',
        check: false,
        cor: 'radial-danger',
        visivel: true
      },{
        nome: 'CPF',
        check: false,
        cor: 'radial-danger',
        visivel: true
      },{
        nome: 'Telefone',
        check: true,
        cor: 'radial-danger',
        visivel: false
      },{
        nome: 'E-mail',
        check: true,
        cor: 'radial-danger',
        visivel: false
      },{
        nome: 'Número da Nota Fiscal',
        check: true,
        cor: 'radial-danger',
        visivel: false
      },{
        nome: 'Número do Pedido',
        check: false,
        cor: 'radial-danger',
        visivel: true
      },{
        nome: 'Fornecedor',
        check: false,
        cor: 'radial-danger',
        visivel: true
      },{
        nome: 'Peso',
        check: false,
        cor: 'radial-danger',
        visivel: true
      },{
        nome: 'Veículo',
        check: false,
        cor: 'radial-danger',
        visivel: true
      }
    ];

    this.campos = this.campos.map(campo => Object.assign({}, campo));
  }
}

