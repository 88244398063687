<div class="pb-4">

  <div class="container text-center min-width p-0">

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('agendas')" class="text-padrao">Agendas</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('agendas/controle-acesso')" class="text-padrao">Controle de Acesso</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('agendas/controle-acesso')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>

    <div class="row p-0 m-0">

      <div class="row p-0 m-0">

        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <h2 class="p-0 m-0 text-cor-padrao text-start w-100">
              Novo Controle
            </h2>
          </div>
        </div>

      </div>

      <div class="min-width col-lg-12 pb-5">
        <div class="p-0 m-0 box-gen-card" style="width: 100%;overflow: auto;flex-direction: column;">

          <div class="form-floating w-100 mb-3" style="text-align: left; ">
            <input type="text" class="form-control input-text-padrao m-0" id="usr_new_nome" placeholder="Usuario" [(ngModel)]="usr_new.Usuario">
            <label for="usr_new_nome" class="label-input-text-padrao" >Usuario</label>
          </div>

          <div class="form-floating w-100 mb-3" style="text-align: left; ">
            <input type="text" class="form-control input-text-padrao m-0" id="usr_new_senha" placeholder="Senha" [(ngModel)]="usr_new.Senha">
            <label for="usr_new_senha" class="label-input-text-padrao" >Senha</label>
          </div>

          <div class="form-floating w-100 mb-3" style="text-align: left; ">
            <input type="text" class="form-control input-text-padrao m-0" id="usr_new_nome" placeholder="Nome" [(ngModel)]="usr_new.Nome">
            <label for="usr_new_nome" class="label-input-text-padrao" >Nome</label>
          </div>

          <div class="form-floating w-100 mb-3" style="text-align: left; ">
            <input type="text" class="form-control input-text-padrao m-0" id="usr_new_email" placeholder="Email" [(ngModel)]="usr_new.Email">
            <label for="usr_new_email" class="label-input-text-padrao" >Email</label>
          </div>

          <div class="form-floating w-100 mb-3" style="text-align: left; ">
            <select id="usr_new_tipo" class="form-control input-text-padrao m-0" placeholder="Selecione" [(ngModel)]="usr_new.Tipo">
              <option value="">Selecione...</option>
              <option value="S">Simples</option>
              <option value="A">Administrador</option>
              <option value="M">Master</option>
              <option value="D">Desativado</option>
            </select>
            <label for="usr_new_tipo" class="label-input-text-padrao" >Tipo</label>
          </div>

          <div class="form-floating w-100 mb-3" style="text-align: left; ">
            <input type="file" class="form-control input-text-padrao m-0" id="usr_new_foto" placeholder="Foto"  (change)="onFileSelected($event)" style=" padding: 20px 25px; font-size: 13px; ">
            <!-- <label for="usr_new_foto" class="label-input-text-padrao" >Foto</label> -->
          </div>

          <hr class="hr-padrao">

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-outline-primary w-50" style="color: #fff; margin-right: 5px; " (click)="newUsuario()">Criar Usuário</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('agendas/controle-acesso')">Cancelar</button>
          </div>
        </div>
      </div>


      <!-- <input type="file" (change)="onFileSelected($event)">
      <button (click)="upload()">Upload</button> -->

    </div>

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg  text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" *ngIf="card.tipo != 'agenda-controle-acesso'" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div>

  </div>
</div>


