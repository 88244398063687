
<div class="pb-4 min-width">

  <div class="container text-center p-0">

    <!-- 👇 Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Grupos Usuários</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- Breadcrumb 👆 -->

    <!-- 👇 Novo agendamento -->
    <div class="row p-0 m-0">
      <div class=" col-lg-4 mb-3" style=" cursor: pointer; ">
        <button (click)="routerLink('grupos-usuarios/adicionar')" type="button" class="btn btn-sm p-0 m-0 btn-principal-primary">
          <span class="btn-principal-primary-nome">
            Novo Grupo Usuário
          </span>
          <span class="btn-principal-primary-icon">
            <i class="bi bi-shield-lock"></i>
          </span>
        </button>
      </div>
    </div>
    <!-- Novo agendamento 👆 -->

    <!-- 👇 Informações / Configurações / Filtro -->
    <div class="row p-0 m-0 mb-3">
      <div class="min-width col-12">
        <div class="p-0 m-0 box-gen-card">

          <!-- Informações / Configurações -->
          <div class="row p-0 m-0">

            <!-- Informações -->
            <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100 box-gen-card-h3">
                <span *ngIf="grupos.length == 0">
                  Nenhum grupo
                </span>
                <span *ngIf="grupos.length > 0">
                  <span class="box-gen-card-h3-span">{{grupos.length}}</span> {{grupos.length == 1 ? "Grupo disponível" : "Grupos disponíveis"}}
                </span>
              </h3>
            </div>

            <!-- Configurações -->
            <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0">

              <div class="d-flex justify-content-between align-items-center flex-nowrap">
                <button type="button" class="btn btn-outline-primary" (click)="reload()" style=" background: transparent; ">
                  Atualizar <i class="bi bi-arrow-clockwise"></i>
                </button>
                <button mat-icon-button [matMenuTriggerFor]="modoButton" aria-label="modo" class="btn btn-outline-primary" style=" background: transparent; " [title]="modoView == true ? 'Tabela' : 'Card'">
                  {{modoView == true ? 'Tabela' : 'Card'}}
                  <i *ngIf="modoView" class="bi bi-table ag-pl-10"></i>
                  <i *ngIf="!modoView" class="bi bi-card-text ag-pl-10"></i>
                </button>
                <mat-menu #modoButton="matMenu">
                  <button mat-menu-item (click)="modoTabela()">
                    <i class="bi bi-table ag-pr-10"></i>
                    <span>Tabela</span>
                  </button>
                  <button mat-menu-item (click)="modoCard()">
                    <i class="bi bi-card-text ag-pr-10"></i>
                    <span>Card</span>
                  </button>
                </mat-menu>

                <button [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_'" aria-expanded="false" [attr.aria-controls]="'collapse_'" class="btn btn-outline-primary mb-1 mt-1" style=" background: transparent; ">
                  Filtro <i class="bi bi-search" title="expand" ></i>
                </button>

              </div>
            </div>
          </div>

          <!-- Filtro -->
          <div [id]="'collapse_'" class="accordion-collapse collapse col-lg-12 pt-2">
            <div class="p-0 m-0" >
              <div *ngIf="grupos.length > 0" style="position: relative;">
                <div class="form-floating w-100 ">
                  <input type="text" class="form-control input-text-padrao m-0 search-box-result input-text-padrao" id="filtrarGrupoUsuarioSearch" [(ngModel)]="pesquisa" (keydown)="searchGrupoUsuario()" (keyup)="searchGrupoUsuario()" (keypress)="searchGrupoUsuario()" placeholder="&nbsp;">
                  <label for="filtrarGrupoUsuarioSearch" class="label-input-text-padrao ag-pt-18 ag-pb-18 ag-pl-12 ag-pr-12">Filtrar grupo ...</label>
                </div>
                <i *ngIf="pesquisa != ''" class="bi bi-x-circle-fill text-secondary reset-search-usr" (click)="resetSearch()"></i>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- Informações / Configurações / Filtro 👆 -->

    <!-- 👇 Modo Card -->
    <div class="row p-0 m-0" *ngIf="!modoView">
      <div class="min-width col-lg-12 pb-5 pt-1">

        <!-- Loading -->
        <div class="row" *ngIf="grupos.length == 0 && loading && modoViewNum == 12">
          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" >
            <div class="p-0 m-0 pulsate" >
            </div>
          </div>
        </div>

        <!-- Card -->
        <div class="row" *ngIf="grupos.length > 0 && !loading">

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" *ngFor="let grp of displayedGrupoUsuario | SearchGrupoUsuario: pesquisa">

            <div class="card card-padrao-list p-0 m-0">

              <div class="card-body">
                <div class="row card-body-row">

                  <!-- Visivel inicialmente -->
                  <div class="col-lg-6 mb-1">
                    <div class="card-body-row-div">
                      <div class="card-padrao-list-box">

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="card-padrao-list-img-title p-0 m-0" [innerHTML]="grp.nome.valor | filtroDestaque: pesquisa">
                            <!-- {{grp.nomeMotorista.valor}} -->
                          </span>
                        </div>

                        <div class="d-flex justify-content-start flex-row align-items-center">
                          <div class="card-body-time p-0 m-0">
                            <span>
                              <i class="bi bi-circle-fill text-cor-sucesso ag-pr-5" *ngIf="grp.status.valor"></i>
                              <i class="bi bi-circle-fill text-cor-danger ag-pr-5" *ngIf="!grp.status.valor"></i>
                              {{grp.status.valor ? "Ativo" : "Desativado"}}
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 mb-1 d-none">
                    <div class="card-padrao-list-box">
                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start" style=" min-width: 65px; ">Criado</span>
                        <span class="card-padrao-list-box-nobg-value" style=" font-size: 13px; " [innerHTML]="(grp.criado == '' ? '--/--/----, --:--' : (grp.criado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa"></span>
                      </div>

                      <div class="d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start" style=" min-width: 65px; ">Atualizado</span>
                        <span class="card-padrao-list-box-nobg-value" style=" font-size: 13px; " [innerHTML]="(grp.atualizado == '' ? '--/--/----, --:--' : (grp.atualizado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa"></span>
                      </div>
                    </div>
                  </div>


                  <!-- Informações ocultas -->
                  <div [id]="'collapse_'+grp.id" class="accordion-collapse collapse col-12 mt-2">
                    <div class="row p-0 m-0 card-body-row-oculta">
                      <div class="col-lg-6 p-0 m-0 d-flex flex-column justify-content-start flex-wrap">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center ">
                          <span class="m-0 p-0 ag-mr-10 text-start">
                            {{grp.descricao.title}}
                          </span>
                          <span class="card-padrao-list-box-nobg-value" [innerHTML]="grp.descricao.valor | filtroDestaque: pesquisa">
                            <!-- {{grp.descricao.valor}} -->
                          </span>
                        </div>
                      </div>

                      <div class="col-lg-3 p-0 m-0 d-flex flex-column justify-content-start flex-wrap">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start" style=" min-width: 65px; ">Criado</span>
                          <span class="card-padrao-list-box-nobg-value" style=" font-size: 13px; " [innerHTML]="(grp.criado == '' ? '--/--/----, --:--' : (grp.criado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa"></span>
                        </div>
                      </div>

                      <div class="col-lg-3 p-0 m-0 d-flex flex-column justify-content-start flex-wrap">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start" style=" min-width: 65px; ">Atualizado</span>
                          <span class="card-padrao-list-box-nobg-value" style=" font-size: 13px; " [innerHTML]="(grp.atualizado == '' ? '--/--/----, --:--' : (grp.atualizado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa"></span>
                        </div>
                      </div>


                    </div>
                  </div>

                </div>

                <div class="card-padrao-footer-div-menu">
                  <i class="bi bi-three-dots" title="menu" mat-icon-button [matMenuTriggerFor]="editarGrupoUsuario_i"></i>
                  <mat-menu #editarGrupoUsuario_i="matMenu">
                    <button mat-menu-item (click)="routerLink('grupos-usuarios/grupo', grp.id)">
                      <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                      <span>Editar</span>
                    </button>
                    <button mat-menu-item (click)="atualizarStatusGrupoUsuario(grp.id)">
                      <i class="bi bi-dash-circle ag-pr-10" [ngClass]="{'text-cor-danger': grp.status.valor, 'text-cor-sucesso': !grp.status.valor}"></i>
                      <span>{{grp.status.valor ? "Desativar" : "Ativar"}}</span>
                    </button>
                    <button mat-menu-item (click)="deletarGrupoUsuario(grp.id)">
                      <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                      <span>Deletar</span>
                    </button>
                  </mat-menu>
                  <i class="bi bi-chevron-expand" title="expand" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + grp.id" aria-expanded="false" [attr.aria-controls]="'collapse_' + grp.id"></i>

                </div>
              </div>
            </div>

          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedGrupoUsuario.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao mt-3">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="col-12 mt-3  ">
            <div class="box-gen-card" style=" padding: 7px 0px !important; ">
              <mat-paginator
              [length]="grupos.length"
              [pageSize]="maxSize"
              [pageSizeOptions]="[5, 10, 25, 50]"
              [showFirstLastButtons]="true"
              (page)="handlePage($event)"
            ></mat-paginator>
            </div>

          </div>

        </div>

      </div>
    </div>
    <!-- Modo Card -->

    <!-- 👇 Modo Tabela -->
    <div class="row p-0 m-0" *ngIf="modoView">

      <!-- Loading -->
      <div class="min-width col-lg-12 pb-5 pt-3" *ngIf="grupos.length == 0 && loading">
        <div class="p-0 m-0 pulsate box-gen-card">
        </div>
      </div>


      <div class="min-width col-lg-12 pb-5 pt-1" >
        <div class="p-0 m-0 box-gen-card mb-3" *ngIf="grupos.length > 0 && !loading">

          <!-- Tabela -->
          <div class="w-100 overflow-auto">
            <table id="excel-table" class="mb-1 mt-1 table table-responsive table-hover table-sm" [ngClass]="{'table-dark': !lightMode,'table-light': lightMode}" matSort (matSortChange)="sortData($event)">
              <thead style=" height: 40px; vertical-align: middle; " *ngIf="displayedGrupoUsuario.length > 0">
                  <tr>
                    <th class="text-cor-padrao ag-pl-15" mat-sort-header="id" style="min-width: 55px;border-right: 1px solid var(--border-cor);">ID</th>
                    <th class="text-cor-padrao text-align-start" mat-sort-header="status" style="min-width: 55px;border-right: 1px solid var(--border-cor);">Status</th>
                    <th class="text-cor-padrao" mat-sort-header="nome" style="text-align: left;min-width: 180px;border-right: 1px solid var(--border-cor);">Nome</th>
                    <th class="text-cor-padrao" mat-sort-header="descricao" style="text-align: left;min-width: 180px;border-right: 1px solid var(--border-cor);">Descrição</th>
                    <th class="text-cor-padrao" mat-sort-header="criado" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Criado</th>
                    <th class="text-cor-padrao" mat-sort-header="atualizado" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Atualizado</th>
                    <!-- <th class="text-cor-padrao" mat-sort-header="uf" >Hora Final</th> -->
                    <th class="text-cor-padrao sticky-col" style=" text-align: center;" >&nbsp;</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="text-cor-padrao ag-table-tr" *ngFor="let grp of displayedGrupoUsuario | SearchGrupoUsuario: pesquisa">
                    <td class="ag-table-td ag-pl-15" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="grp.id | filtroDestaque: pesquisa">
                        <!-- {{grp.id | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div >
                        <i class="bi bi-circle-fill text-cor-sucesso ag-pr-5" *ngIf="grp.status.valor"></i>
                        <i class="bi bi-circle-fill text-cor-danger ag-pr-5" *ngIf="!grp.status.valor"></i>
                        {{grp.status.valor ? "Ativo" : "Desativado"}}
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="grp.nome.valor | filtroDestaque: pesquisa">
                        <!-- {{grp.placaVeiculo.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="grp.descricao.valor | filtroDestaque: pesquisa">
                        <!-- {{grp.placaVeiculo.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="(grp.criado == '' ? '--/--/----, --:--' : (grp.criado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa">
                        <!-- {{grp.fornecedor.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="(grp.atualizado == '' ? '--/--/----, --:--' : (grp.atualizado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa">
                        <!-- {{grp.notaFiscal.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <!-- <td class="ag-table-td">
                      <div >
                        {{grp.hora_final.valor}}
                      </div>
                    </td> -->
                    <td class="ag-table-td sticky-col">
                      <button mat-icon-button [matMenuTriggerFor]="editarAgendamento" aria-label="modo" class="btn-outline-primary sticky-col-buttom">
                        <i class="bi bi-three-dots"></i>
                      </button>
                      <mat-menu #editarAgendamento="matMenu">
                        <button mat-menu-item (click)="routerLink('grupos-usuarios/grupo', grp.id)">
                          <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                          <span>Editar</span>
                        </button>
                        <button mat-menu-item (click)="atualizarStatusGrupoUsuario(grp.id)">
                          <i class="bi bi-dash-circle ag-pr-10" [ngClass]="{'text-cor-danger': grp.status.valor, 'text-cor-sucesso': !grp.status.valor}"></i>
                          <span>{{grp.status.valor ? "Desativar" : "Ativar"}}</span>
                        </button>
                        <button mat-menu-item (click)="deletarGrupoUsuario(grp.id)">
                          <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                          <span>Deletar</span>
                        </button>
                      </mat-menu>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedGrupoUsuario.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="text-right row pagination-box-result" *ngIf="grupos.length > 0">
            <div class="col-12 p-0 m-0">
              <mat-paginator
                [length]="grupos.length"
                [pageSize]="maxSize"
                [pageSizeOptions]="[5, 10, 25, 50]"
                [showFirstLastButtons]="true"
                (page)="handlePage($event)"
              ></mat-paginator>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- Modo Tabela 👆 -->

    <!-- 👇 Links Relacionados -->
    <!-- <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg  text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" *ngIf="card.grupo != 'agenda-enderecos'" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div> -->
    <!-- Links Relacionados 👆 -->


  </div>
</div>
