import { FormControl } from "@angular/forms";
import { TipoUsuario } from "src/enum/tipoUsuario.enum";
import { campo } from "src/interface/campo";
import { ValidatorService } from "src/services/validator.service";

const validatorService: ValidatorService  = new ValidatorService();

export class Usuario {

  id: any | null = null;

  displayName: campo = {
    title: 'Nome do Usuário',
    info: 'Nome do Usuário',
    valor: '',
    placeholder: 'Nome do Usuário',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  email: campo = {
    title: 'E-mail do Usuário',
    info: 'E-mail do Usuário',
    valor: '',
    placeholder: 'Informe um endereço de e-mail válido',
    inputType: 'text',
    inputMode: 'email',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required', 'email'],
    status: new FormControl()
  };

  phoneNumber: campo = {
    title: 'Telefone do Usuário',
    info: 'Telefone do Usuário',
    valor: '',
    placeholder: 'Informe o Telefone com DDD',
    inputType: 'text',
    inputMode: 'tel',
    mascara: '',
    maxlength: '15',
    tipo: 'telefone',
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  photoURL: campo = {
    title: 'Foto do Usuário',
    info: 'Foto do Usuário',
    valor: '',
    placeholder: 'URL da Foto do Usuário',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  validadePlano: campo = {
    title: 'Validade Plano',
    info: 'Validade Plano',
    valor: '',
    placeholder: 'Validade Plano',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  sigla: campo = {
    title: 'Sigla da Conta',
    info: 'Sigla única que identifica esta conta',
    valor: '',
    placeholder: 'Sigla da Conta',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '100',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  status: campo = {
    title: 'Status',
    info: 'Status',
    valor: true,
  };

  tipo: campo = {
    title: 'Tipo de Usuário',
    info: 'Tipo de Usuário',
    placeholder: 'Tipo de Usuário',
    valor: '',
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  tipoOptions: any;

  grupo: GruposUsuarios[] = [];
  grupoAgenda: GruposUsuariosAgenda[] = [];

  idConta: any | null = null;

  providerId: any[] = [];

  criado: Date = new Date();
  atualizado: Date = new Date();
  ultimoLogin: Date = new Date();


  constructor(){
    this.tipoOptions = this.enumToOptions(TipoUsuario);

  }

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "displayName":
        this.displayName.valor = validatorService.aplicarMascara(this.displayName.valor, mascara);
        break;
      case "email":
        this.email.valor = validatorService.aplicarMascara(this.email.valor, mascara);
        break;
      case "phoneNumber":
        if(this.phoneNumber.tipo != ""){
          this.phoneNumber.valor = validatorService.aplicarMascaraEspecial(this.phoneNumber.valor, this.phoneNumber.tipo);
        }{
          this.phoneNumber.valor = validatorService.aplicarMascara(this.phoneNumber.valor, mascara);
        }
        break;
      case "photoURL":
        this.photoURL.valor = validatorService.aplicarMascara(this.photoURL.valor, mascara);
        break;
      default:
        break;
    }
  }

  validarInput(tipo: string) {
    switch (tipo) {
      case "displayName":
        this.displayName.valor = this.displayName.valor.trim();
        this.displayName.status = validatorService.criarValidacao(this.displayName.valor, this.displayName.validacoesCustomizadas);
        break;
      case "email":
        this.email.valor = this.email.valor.trim();
        this.email.status = validatorService.criarValidacao(this.email.valor, this.email.validacoesCustomizadas);
        break;
      case "phoneNumber":
        this.phoneNumber.valor = this.phoneNumber.valor.trim();
        this.phoneNumber.status = validatorService.criarValidacao(this.phoneNumber.valor, this.phoneNumber.validacoesCustomizadas);
        break;
      case "photoURL":
        this.photoURL.valor = this.photoURL.valor.trim();
        this.photoURL.status = validatorService.criarValidacao(this.photoURL.valor, this.photoURL.validacoesCustomizadas);
        break;
      case "tipo":
        this.tipo.valor = this.tipo.valor.trim();
        this.tipo.status = validatorService.criarValidacao(this.tipo.valor, this.tipo.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }

  enumToOptions(enumObj: any): { nome: string, value: string }[] {
    return Object.keys(enumObj)
      .filter(key => isNaN(Number(enumObj[key])))
      .map(key => ({ nome: enumObj[key], value: key }));
  }

}

export class GruposUsuarios{
  idGrupo?: string;
  NomeGrupo?: string;
}

export class GruposUsuariosAgenda{
  idGrupoAgenda?: string;
  NomeGrupoAgenda?: string;
}


export class UsuarioEmailSenha{
  Nome?:       string;
  Senha?:      string;
}

export class objUsuario {
  Id?:         string;
  Usuario?:    string;
  Nome?:       string;
  Email?:      string;
  Tipo?:       string;
  Criado?:     string;
  Atualizado?: string;
  Foto:        string = "";
}

export class newUsuario {
  Usuario:     string = "";
  Senha:       string = "";
  Tipo:        string = "";
  Nome:        string = "";
  Email:       string = "";
  Foto:        string = "";
}

