import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalLinkService } from 'src/services/modal-link.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from 'src/services/toast.service';

@Component({
  selector: 'app-modal-link',
  templateUrl: './modal-link.component.html',
  styleUrls: ['./modal-link.component.css']
})
export class ModalLinkComponent {
  @Input() titulo!: string;
  @Input() link!: string;

  constructor(
    public modalRef: BsModalRef,
    private modalLinkService: ModalLinkService,
    private clipboard: Clipboard,
    private toast: ToastService
  ) {}

  confirmAction(confirmed: boolean) {
    return new Promise<void>((resolve) => {
      this.modalRef.hide();
      // this.modalLinkService.setConfirmation(confirmed); // Informa o resultado para o serviço
      // if (confirmed) {
      //   console.log('Ação confirmada!');
      // } else {
      //   console.log('Ação cancelada.');
      // }
      resolve();
    });
  }

  copyToClipboard(text: string): void {
    try{
      this.clipboard.copy(text);
      this.showSuccess(`Link copiado`)
    }catch(error){
      this.showError(`Erro ao copiar o link`)
    }
  }

  abrirLinkEmNovaGuia(link: string): void {
    window.open(link, '_blank');
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
