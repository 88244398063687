import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  inputValue: string = '';

  constructor(){}

  ngOnInit(): void {

  }

  // Função para construir a URL com base no valor do input
  buildURL(): string {
    // Supondo que você tenha uma URL base e o valor do input é um parâmetro
    const baseURL = window.location.href;
    localStorage.setItem('empresaSigla', this.inputValue)
    return `${baseURL}${this.inputValue}/login`;
  }
}
