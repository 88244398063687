import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NotificacaoBadge } from 'src/interface/notificacao-badge';
import { Leitura, NotificacaoInt } from 'src/interface/notificacao-int';
import { AuthService } from 'src/services/auth.service';
import { ContaService } from 'src/services/component/conta.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { UsuarioService } from 'src/services/component/usuario.service';
import { SiglaService } from 'src/services/siglaService.service';

@Component({
  selector: 'app-notificacao-badge',
  templateUrl: './notificacao-badge.component.html',
  styleUrls: ['./notificacao-badge.component.css']
})
export class NotificacaoBadgeComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();

  all_notifications: NotificacaoBadge[] = [];
  notifications: NotificacaoBadge[] = [];

  all_notificacoes: NotificacaoInt[] = [];
  notificacoes: NotificacaoInt[] = [];

  empresaSigla: any | undefined;
  usuario: any;

  constructor(
    private notificacaoService: NotificacaoSistemaService,
    private router: Router,
    private siglaService: SiglaService,
    private usuarioService: UsuarioService,
    private conta: ContaService,
    private userService: AuthService
  ) {

    this.siglaService.empresaSigla$.subscribe(async empresaSigla => {
      this.empresaSigla = empresaSigla;

      if(this.empresaSigla && this.empresaSigla != ""){
        this.carregarNotificacoes();
        this.usuario = await this.userService.carregarUsuario(this.empresaSigla)
        // console.log(this.usuario)
      }
    });
  }

  async ngOnInit() {

  }

  async carregarNotificacoes(){
    const user = JSON.parse(localStorage.getItem('user')!);

    if(user){
      this.notificacaoService.getNotificacoes(this.empresaSigla).pipe(takeUntil(this.destroy$)).subscribe(all_notificacoes => {
        this.all_notificacoes = all_notificacoes
        // console.log(all_notificacoes)
        const user = JSON.parse(localStorage.getItem('user')!);

        // console.log("getNotificacoesNaoLidasUsuario", this.notificacaoService.getNotificacoesNaoLidasUsuario(user?.uid))
        this.notificacoes = this.notificacaoService.getNotificacoesNaoLidasUsuario(user?.uid);
      });
    }else{
      // console.log("ELSE user",user)
      this.destroy$.next();
      this.destroy$.complete();
    }
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  marcarComoLido(notificacao: NotificacaoInt) {

    const leituraUsuario: Leitura = {
      idUsuario: this.usuario.id,
      nomeUsuario: this.usuario.displayName.valor,
      dataLeitura: new Date().toISOString(),
      visivel: true
    };

    this.notificacaoService.addUsuarioLeitura(notificacao, leituraUsuario, this.empresaSigla);

    // console.log(this.notifications);
    // console.log(this.all_notifications);
  }

  filterNotifications() {
    this.all_notifications = this.notifications.filter(notification => notification.read == true);
    this.notifications = this.notifications.filter(notification => notification.read == false);
  }

  reordenar(){
    // Ordenar this.all_notifications por readDate (maior para menor)
    this.all_notifications.sort((a, b) => {
      const dateA = a.readDate ? new Date(a.readDate) : null;
      const dateB = b.readDate ? new Date(b.readDate) : null;

      if (dateA instanceof Date && dateB instanceof Date) {
        return dateB.getTime() - dateA.getTime();
      } else if (dateA instanceof Date) {
        return -1;
      } else if (dateB instanceof Date) {
        return 1;
      } else {
        return 0;
      }
    });

    // Ordenar this.notifications por createdDate (maior para menor)
    this.notifications.sort((a, b) => {
      const dateA = a.createdDate ? new Date(a.createdDate) : null;
      const dateB = b.createdDate ? new Date(b.createdDate) : null;

      if (dateA instanceof Date && dateB instanceof Date) {
        return dateB.getTime() - dateA.getTime();
      } else if (dateA instanceof Date) {
        return -1;
      } else if (dateB instanceof Date) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
