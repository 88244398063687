import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FeriadosService {

  constructor(private http: HttpClient) {}


  async consultarFeriados(ano: string, state: string = ""): Promise<any> {
    const token = "4905|8dxjf0KWeZ2IErA5k9QnqQ6YgUJJu2Fw";
    let url = `https://api.invertexto.com/v1/holidays/${ano}?token=${token}`;

    if(state !== ""){
      url = `https://api.invertexto.com/v1/holidays/${ano}?token=${token}&state=${state}`;
    }

    const response = await this.http.get<any>(url).toPromise();
    return response;
  }

}
