import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouletteService {
  private countSubjects: Map<string, BehaviorSubject<number>> = new Map();

  startRoulette(key: string, targetValue: number): void {
    const increment = 1;
    const interval = 60;

    let currentValue = 0;

    if (!this.countSubjects.has(key)) {
      this.countSubjects.set(key, new BehaviorSubject<number>(0));
    }

    const currentValueSubject = this.countSubjects.get(key);

    const updateValue = () => {
      if (currentValueSubject && currentValue < targetValue) {
        currentValue += increment;
        currentValueSubject.next(currentValue);
        setTimeout(updateValue, interval);
      }
    };

    updateValue();
  }

  reset(key: string): void {
    const currentValueSubject = this.countSubjects.get(key);
    if (currentValueSubject) {
      currentValueSubject.next(0);
    }
  }

  getValueObservable(key: string): Observable<number> | undefined {
    const currentValueSubject = this.countSubjects.get(key);

    if (currentValueSubject) {
      return currentValueSubject.asObservable();
    } else {
      // console.warn(`Value subject not found for key: ${key}`);
      return undefined;
    }
  }
}
