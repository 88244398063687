import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Recurso } from 'src/class/recurso';
import { newUsuario } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { CepService } from 'src/services/cep.service';
import { ContaService } from 'src/services/component/conta.service';
import { RecursoService } from 'src/services/component/recurso.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { DataService } from 'src/services/data.service';
import { LocalhostService } from 'src/services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-editar-recurso',
  templateUrl: './editar-recurso.component.html',
  styleUrls: ['./editar-recurso.component.css']
})
export class EditarRecursoComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertRecursoModal', { static: false }) alertRecursoModal!: ElementRef;

  id: any;
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  recurso: Recurso = new Recurso();
  recursos: Recurso[] = [];
  empresaSigla: any | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private recursoService: RecursoService,
    private localhostService: LocalhostService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private authService: AuthService
  ){
    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      // }
    // });
  }

  async ngOnInit() {
    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    this.recursoService.getAllRecurso()
    .pipe(takeUntil(this.destroy$))
    .subscribe(recurso => {
      this.recursos = recurso;

      for (let index = 0; index < this.recursos.length; index++) {
        const tpd = this.recursos[index];
        if(tpd.id == this.id){
          Object.assign(this.recurso, tpd);
        }
      }

      if(this.recurso.id == null){
        this.router.navigate([`${this.empresaSigla}/recursos`]);
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async salvarRecurso(){
    this.recurso.nome.status = this.validatorService.criarValidacao(this.recurso.nome.valor, this.recurso.nome.validacoesCustomizadas);
    this.recurso.descricao.status = this.validatorService.criarValidacao(this.recurso.descricao.valor, this.recurso.descricao.validacoesCustomizadas);

    if(this.recurso.nome.status.invalid ||
      this.recurso.descricao.status.invalid
    ){
      this.abrirModal(this.alertRecursoModal.nativeElement);
    }else{

      // console.log(this.recurso)

      // Obtenha o array existente do DataService
      let recurso = this.recursos;

      // Encontre o índice do item correspondente em recurso com base no ID
      const index = recurso.findIndex((item: Recurso) => item.id === this.recurso.id);

      if (index !== -1) {
        this.recurso.atualizado = new Date();
        // Atualize o item existente com as alterações de this.recurso
        recurso[index] = this.recurso;

        const docId = recurso[index].id != null ? recurso[index].id?.toString() : "";

        const plainObject = JSON.stringify(recurso[index])
        const plainString = JSON.parse(plainObject)

        plainString.descricao.status = null;
        plainString.nome.status = null;

        // await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        // .then((conta) => {
        //   // Faça algo com os dados obtidos
        //   console.log('Dados da conta: atualizarStatusServico', conta);
          this.localhostService.putDados(`recursos`, docId!, plainString)
          .then((response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess("Recurso atualizado com sucesso");
            this.router.navigate([`${this.empresaSigla}/recursos`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Serviço não encontrado para atualização \n" + error);
          });

        // },
        // (error: any) => {
             // console.error('Erro ao atualizar dados:', error);
        //   // Trate o erro conforme necessário
        // }).catch((error) => {
        //   // Trate o erro
        //   console.log('Erro ao obter dados da conta:', error);
        // });

        // Salve o array de volta no DataService
        // DataService.setItem("Recurso", recurso);
        // localStorage.setItem("Recurso", JSON.stringify(recurso))
        // this.showSuccess("Recurso atualizado com sucesso");
        // this.router.navigate([`${this.empresaSigla}/recursos`]);

      } else {
        // O item não foi encontrado no array, você pode tratar isso conforme necessário
        this.showError("Recurso não encontrado para atualização");
      }
    }
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
