import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchEndereco'
})
export class SearchEnderecoPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    // console.log(value.data, args)
    if (!args) {
      return value;
    }

    const searchValue = args.toLowerCase();

    return  value.filter((val: any) => {
      const id = val.id?.toString();
      const cep = val.cep.valor?.toLowerCase().toString();
      const logradouro = val.logradouro.valor?.toLowerCase().toString();
      const numero = val.numero.valor?.toLowerCase().toString();
      const bairro = val.bairro.valor?.toLowerCase().toString();
      const localidade = val.localidade.valor?.toLowerCase().toString();
      const uf = val.uf.valor?.toLowerCase().toString();

      return  id.includes(searchValue)||
              cep.includes(searchValue) ||
              logradouro.includes(searchValue) ||
              numero.includes(searchValue) ||
              bairro.includes(searchValue) ||
              localidade.includes(searchValue) ||
              uf.includes(searchValue)
    });

  }

}
