import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Checkin, Comentario } from 'src/class/checkin';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';
import { StatusKanban } from 'src/enum/statusKanban.enum';
import { SiglaService } from '../siglaService.service';
import { ContadorIndexService } from '../contador-index.service';

@Injectable({
  providedIn: 'root'
})
export class CheckinService {

  private checkins: Checkin[] = [];
  private checkinsSubject = new BehaviorSubject<Checkin[]>(this.checkins);

  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService,
    private siglaService: SiglaService,
    private contadorIndexService: ContadorIndexService
  ) {
    this.checkins = [];

    this.siglaService.empresaSigla$.subscribe(empresaSigla => {
      this.empresaSigla = empresaSigla
    });

    this.rotinaCheckinsNaoRealizados();
  }

  async criarCheckin(checkin: Checkin, empresaSigla: any) {
    // console.log("criarCheckin", checkin);

    checkin.nomeMotorista.status = null;
    checkin.cpfMotorista.status = null;
    checkin.placaVeiculo.status = null;
    checkin.veiculo.status = null;
    checkin.peso.status = null;
    checkin.telefoneMotorista.status = null;
    checkin.emailMotorista.status = null;
    checkin.notaFiscal.status = null;
    checkin.numeroPedido.status = null;
    checkin.fornecedor.status = null;
    checkin.status.status = null;

    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarCheckin', conta);

      const response = await this.localhostService.postDadosId(`${conta.id}_checkins`, checkin);
      // console.log('Checkin criado com sucesso:', response);

      // Atualize a lista local
      this.checkins.push(checkin);
      this.checkinsSubject.next(this.checkins);

      // Notifique o usuário sobre o sucesso
      this.showSuccess('Checkin criado com sucesso');

      const usr = await this.userService.carregarUsuario(empresaSigla)

      this.notificacaoService.criarNotificacao(
        empresaSigla,                   // 1 - empresaSigla: any,
        `${checkin.notaFiscal.valor}`,  // 2 - titulo: string,
        `Checkin criado.`,              // 3 - texto: string,
        'Listar Checkins',              // 4 - rotaSemParamNome: string,
        'checkin',                      // 5 - rotaSemParamListar: string,
        'Editar Checkin',               // 6 - rotaComParamNome: string = "",
        `checkin`,                      // 7 - rotaComParamCaminho: string = "",
        checkin.id?.toString(),         // 8 - rotaComParamParam: string = "",
        '',                             // 9 - linkExternoNome: string = "",
        '',                             // 10 - linkExternoURL: string = "",
        checkin.id!.toString(),         // 11 - idReferencia: string,
        `Checkin`,                      // 12 - tipo: string,
        `${usr.id}`,                    // 13 - idResponsavel: string,
        `${usr.displayName.valor}`      // 14 - nomeResponsavel: string
      )

      return true;
    } catch (error) {
      // console.error('Erro ao criar checkin:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllCheckin() {
    return this.checkinsSubject.asObservable().pipe(
      map(checkins => checkins)
    );
  }

  async getCheckin(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getCheckin', conta);

      this.localhostService.getDados(`${conta.id}_checkins`).subscribe(
        (checkinFromServer: any[]) => {
          if (checkinFromServer) {
            // Mapeia os dados para a estrutura desejada
            const checkinArray: Checkin[] = checkinFromServer.map(checkin => plainToClass(Checkin, checkin));

            // console.log(checkinArray);

            this.checkins = checkinArray;
            this.checkinsSubject.next(this.checkins);
            // this.updateLocalStorage();
          } else {
            this.checkins = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter checkins:', error);
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao obter checkins:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });


  }

  getStatusCheckin(status: string): Observable<Checkin[]> {
    return this.getAllCheckin().pipe(
      map(checkins => {
        // Filtra os checkins pelo status desejado
        const checkinsFiltrados = checkins.filter(agendamento => agendamento.status.valor === status);

        // Retorna os checkins filtrados
        return checkinsFiltrados;
      })
    );
  }

  getCheckinsDia(): Observable<Checkin[]> {
    return this.getAllCheckin().pipe(
      map(checkins => {
        const dataAtualApenas = new Date();
        const anoAtual = dataAtualApenas.getFullYear();
        const mesAtual = dataAtualApenas.getMonth(); // Mês é zero-indexado
        const diaAtual = dataAtualApenas.getDate();

        return checkins.filter(checkin => {
          // Assumindo que checkin.criado é uma string no formato ISO 8601
          const dataDoObjeto = new Date(checkin.criado);
          const anoObjeto = dataDoObjeto.getFullYear();
          const mesObjeto = dataDoObjeto.getMonth(); // Mês é zero-indexado
          const diaObjeto = dataDoObjeto.getDate();

          return (
            anoObjeto === anoAtual &&
            mesObjeto === mesAtual &&
            diaObjeto === diaAtual
          );
        });
      })
    );
  }

  getStatusCheckinDia(status: string): Observable<Checkin[]> {
    return this.getCheckinsDia().pipe(
      map(checkins => {
        // Filtra os checkins pelo status desejado
        const checkinsFiltrados = checkins.filter(agendamento => agendamento.status.valor === status);

        // Retorna os checkins filtrados
        return checkinsFiltrados;
      })
    );
  }

  getRealizadoCheckinsDia(status: string): Observable<Checkin[]> {
    return this.getAllCheckin().pipe(
      map(checkins => {
        const dataAtualApenas = new Date();
        const anoAtual = dataAtualApenas.getFullYear();
        const mesAtual = dataAtualApenas.getMonth(); // Mês é zero-indexado
        const diaAtual = dataAtualApenas.getDate();

        return checkins.filter(checkin => {
          // Assumindo que checkin.criado é uma string no formato ISO 8601
          const dataDoObjeto = new Date(checkin.statusRealizado.inicio);
          const anoObjeto = dataDoObjeto.getFullYear();
          const mesObjeto = dataDoObjeto.getMonth(); // Mês é zero-indexado
          const diaObjeto = dataDoObjeto.getDate();

          return (
            anoObjeto === anoAtual &&
            mesObjeto === mesAtual &&
            diaObjeto === diaAtual && checkin.status.valor == status
          );
        });
      })
    );
  }

  getCheckinsPassados(): Observable<Checkin[]> {
    return this.getAllCheckin().pipe(
      map(checkins => {
        const dataAtualApenas = new Date();
        dataAtualApenas.setHours(0, 0, 0, 0); // Zera a parte da hora para comparar apenas a data

        return checkins.filter(checkin => {
          // Supondo que checkin.criado seja uma string no formato ISO 8601
          const dataCheckin = new Date(checkin.criado);
          dataCheckin.setHours(0, 0, 0, 0); // Zera a parte da hora para comparar apenas a data

          // Verificar se a data do check-in é anterior à data atual
          return dataCheckin < dataAtualApenas;
        });
      })
    );
  }

  getStatusCheckinsPassados(status: string): Observable<Checkin[]> {
    return this.getCheckinsPassados().pipe(
      map(checkins => {
        // Filtra os checkins pelo status desejado
        const checkinsFiltrados = checkins.filter(agendamento => agendamento.status.valor === status);

        // Retorna os checkins filtrados
        return checkinsFiltrados;
      })
    );
  }

  getCheckinsFuturos(): Observable<Checkin[]> {
    return this.getAllCheckin().pipe(
      map(checkins => {
        const dataAtualApenas = new Date();
        dataAtualApenas.setHours(0, 0, 0, 0); // Zera a parte da hora para comparar apenas a data

        return checkins.filter(checkin => {
          // Supondo que checkin.criado seja uma string no formato ISO 8601
          const dataCheckin = new Date(checkin.criado);
          dataCheckin.setHours(0, 0, 0, 0); // Zera a parte da hora para comparar apenas a data

          // Verificar se a data do check-in é após a data atual
          return dataCheckin > dataAtualApenas;
        });
      })
    );
  }


  getStatusCheckinsFuturos(status: string): Observable<Checkin[]> {
    return this.getCheckinsFuturos().pipe(
      map(checkins => {
        // Filtra os checkins pelo status desejado
        const checkinsFiltrados = checkins.filter(agendamento => agendamento.status.valor === status);

        // Retorna os checkins filtrados
        return checkinsFiltrados;
      })
    );
  }

  rotinaCheckinsNaoRealizados(): void {
    this.getAllCheckin().subscribe(checkins => {
        const dataAtual = new Date();

        const checkinsAguardando = checkins.filter(checkin => {
            const dataInicio = new Date(checkin.statusAguardando.inicio);
            const diferencaEmMilissegundos = dataAtual.getTime() - dataInicio.getTime();
            const diferencaEmHoras = diferencaEmMilissegundos / (1000 * 60 * 60);

            // Verificar se o check-in está aguardando ou em andamento e se passaram mais de 48 horas desde o início
            return (
                checkin.status.valor === StatusKanban.aguardando &&
                checkin.statusAguardando.fim === '' &&
                diferencaEmHoras > 48
            );
        });

        const checkinsEmAndamento = checkins.filter(checkin => {
          const dataInicio = new Date(checkin.statusEmAndamento.inicio);
          const diferencaEmMilissegundos = dataAtual.getTime() - dataInicio.getTime();
          const diferencaEmHoras = diferencaEmMilissegundos / (1000 * 60 * 60);

          // Verificar se o check-in está aguardando ou em andamento e se passaram mais de 48 horas desde o início
          return (
              checkin.status.valor === StatusKanban.emAndamento &&
              checkin.statusEmAndamento.fim === '' &&
              diferencaEmHoras > 48
          );
        });

        // console.log("checkinsAguardando > ",checkinsAguardando)

        // console.log("checkinsEmAndamento > ",checkinsEmAndamento)

        for (let index = 0; index < checkinsAguardando.length; index++) {
          const checkin = checkinsAguardando[index];
          this.atualizarCheckinStatusSemAcao(checkin, StatusKanban.naoRealizado, "Reprovação automática do sistema.")
          // console.log("atualizarCheckinStatusSemAcao checkinsAguardando > ",checkinsAguardando)
        }

        for (let index = 0; index < checkinsEmAndamento.length; index++) {
          const checkin = checkinsEmAndamento[index];
          this.atualizarCheckinStatusSemAcao(checkin, StatusKanban.naoRealizado, "Reprovação automática do sistema.")
          // console.log("atualizarCheckinStatusSemAcao checkinsEmAndamento > ",checkinsEmAndamento)
        }

    });
  }

  async atualizarCheckinStatusSemAcao(checkin: Checkin, status: any, observacao: any = ""){
    try{
      const docId = checkin.id != null ? checkin.id?.toString() : "";

      checkin.atualizado = new Date();
      checkin.status.valor = status;

      checkin.statusNaoRealizado.inicio = new Date();
      checkin.statusNaoRealizado.fim = new Date();

      let cmt = new Comentario();

      cmt.id = this.contadorIndexService.contadorIndex();
      cmt.observacao.valor = observacao;
      cmt.timestamp = new Date();
      cmt.responsavel.nomeUsuario = "Sistema";

      cmt.observacao.status = null;

      checkin.statusNaoRealizado.comentarios.push(cmt);

      // checkin.statusExecucao.valor = statusExecucao;
      // checkin.observacao.valor = motivo;

      const plainObject = JSON.stringify(checkin)
      const plainString = JSON.parse(plainObject)

      plainString.nomeMotorista.status = null;
      plainString.cpfMotorista.status = null;
      plainString.placaVeiculo.status = null;
      plainString.veiculo.status = null;
      plainString.peso.status = null;
      plainString.telefoneMotorista.status = null;
      plainString.emailMotorista.status = null;
      plainString.notaFiscal.status = null;
      plainString.numeroPedido.status = null;
      plainString.fornecedor.status = null;
      plainString.status.status = null;

      if(this.empresaSigla != ""){
        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: criarAgenda', conta);

          this.localhostService.putDados(`${conta.id}_checkins`, docId!, plainString)
          .then((response: any) => {
            // console.log('Atualização bem-sucedida:', checkin);
            // this.showSuccess("Agendamento atualizado com sucesso");
            // this.router.navigate([`${this.empresaSigla}/agendamentos`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            // this.showError("Agendamento não encontrado para atualização \n" + error);
          });

        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // this.authService.fazerLogout(this.empresaSigla);
          // console.log('Erro ao obter dados da conta:', error);
        });
      }
    }catch(e: any){
      // console.log(e)
    }
  }

  private updateLocalStorage() {
    localStorage.setItem("Checkin", JSON.stringify(this.checkins));
    DataService.set("Checkin", this.checkins);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
