import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteLoadingBarControlService {
  private errorSubject = new Subject<string>();

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationError) {
        this.errorSubject.next(event.error.message); // Pode personalizar a mensagem de erro aqui
      }
    });
  }

  getErrorObservable() {
    return this.errorSubject.asObservable();
  }
}
