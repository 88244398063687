import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription, async, takeUntil } from 'rxjs';
import { Usuario, objUsuario } from 'src/class/usuario';
import { SearchUsuarioPipe } from 'src/pipes/search-usuario.pipe';
import { AuthService } from 'src/services/auth.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ThemeService } from 'src/services/theme.service';
import { ToastService } from 'src/services/toast.service';
import { UsuarioService } from 'src/services/component/usuario.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';
import { ContaService } from 'src/services/component/conta.service';
import { LocalhostService } from 'src/services/localhost.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnDestroy  {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginationControls', { read: ElementRef }) paginationControlsRef!: ElementRef;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj = new objUsuario;

  usr_arr: any[] = [];
  agenda_arr: any[] = [];
  tps_arr: any[] = [];

  pesquisa="";
  pag = 1;
  contador = 5;
  pageSize = 5;
  pageIndex = 0;
  totalizador = 0;
  maxSize = 5;
  visualizando = this.contador;
  lightMode: any;
  modoView = false;
  modoViewNum = 12;

  private subscription: Subscription;

  empresaSigla: any | undefined;

  usuarios: Usuario[] = [];
  displayedUsuarios: Usuario[] = [];
  loading: boolean = true;
  sortedData: Usuario[] = [];

  loader = this.loadingBar.useRef('http');

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private loadingBar: LoadingBarService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    private toast: ToastService,
    private userService: AuthService,
    private searchUsr: SearchUsuarioPipe,
    private localhostService: LocalhostService,
    private themeService: ThemeService,
    private route: ActivatedRoute,
    private siglaService: SiglaService,
    private conta: ContaService,
    private notificacaoService: NotificacaoSistemaService
  ) {
    this.subscription = this.themeService.lightMode$.subscribe(lightMode => {
      this.lightMode = lightMode;
    });

    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.usuarioService.getUsuario(this.empresaSigla)
        });
      // }
    // });
  }


  ngOnInit() {
    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    setTimeout(() => {

      for (let index = 1; index < 2; index++) {
        const element = {
          "Id": index.toString(),
          "Titulo": `Agenda ${index}`,
          "SubTitulo": `Item ${index}`,
          "Descricao": `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus sapien vulputate, ultricies quam placerat, dignissim enim. Sed tincidunt risus id ipsum consequat faucibus.`,
          "Info": `A item ${index}`,
          "Criado": "31/08/2023 17:00",
          "Valido": "08/09/2023 17:00",
          "Foto": "https://picsum.photos/1080/720",
        };


        this.agenda_arr.push(element)

      }
    }, 1000);

    setTimeout(() => {

      for (let index = 1; index < 10; index++) {
        const element = new objUsuario;;

        element.Id = index.toString();
        element.Usuario = `Usuário ${index}`;
        element.Nome = `Nome ${index}`;
        element.Email = `email${index}@gmail.com`;
        element.Tipo = "A";
        element.Criado = "01/01/2023 14:22";
        element.Atualizado = "01/09/2023 15:22";
        element.Foto = "https://picsum.photos/1080/720";

        this.usr_arr.push(element)

      }
      // this.loading = false;
    }, 1000);

    // this.getTotalTiposUsuarios();
    // this.getUsuario();

    this.usuarioService.getAllUsuario()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (usuarios: Usuario[]) => {
        // console.log(usuarios)
        // Faça algo com as notificações atualizadas
        this.usuarios = usuarios;

        this.loading = false;

        this.displayedUsuarios = this.usuarios.slice(
          this.pageIndex * this.pageSize,
          (this.pageIndex + 1) * this.pageSize
        );

        this.route.queryParams.subscribe((params: any) => {
          // O parâmetro de consulta 'search' estará em params.search
          this.pesquisa = params.search ? params.search : '';
          // console.log(params)
          // Agora você pode usar o valor da pesquisa em sua função searchAgenda
          if(this.pesquisa != ''){
            this.searchUsuario();
          }
        });

      },
      error => {
        // console.error('Erro ao listar usuários:', error);
        this.router.navigate(['/home']);
      }
    );

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  searchUsuario(){
    const retorno = this.searchUsr.transform(this.usuarios, this.pesquisa);

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.displayedUsuarios = retorno;

    if(this.pesquisa == ""){
      this.displayedUsuarios = this.usuarios.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      this.paginator.length = this.usuarios.length;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { search: null },
        queryParamsHandling: 'merge',
      });

    }else{

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ['search']: this.pesquisa },
        queryParamsHandling: 'merge',
      });

      this.displayedUsuarios = retorno.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      if (this.paginator) {
        this.paginator.length = retorno.length;
      }
    }
  }

  modoTabela(){
    this.modoView = true;
    localStorage.setItem("modoView", "true");
  }


  modoCard(){
    this.modoView = false;
    this.modoViewNum = 12;
    localStorage.setItem("modoViewNum", "12");
    localStorage.setItem("modoView", "false");
  }

  // async getTotalTiposUsuarios(){
  //   let totalTipos = await this.usuarioService.getTotalTiposUsuarios(this.usr_token);
  //   totalTipos.sort((a: any, b:any) => {
  //     const order = ['M', 'A', 'S', 'D', 'Total'];
  //     return order.indexOf(a.Tipo) - order.indexOf(b.Tipo);
  //   });
  //   // console.log(totalTipos)
  //   totalTipos = totalTipos.filter((item: { Tipo: string; }) => item.Tipo !== "Total");
  //   this.tps_arr = totalTipos;
  // }

  reload(){
    try {
      this.usuarios = [];
      this.loading = true;
      // this.getTotalTiposUsuarios();
      // this.getUsuario();


    this.loader.start()

      setTimeout(async () => {
        let usuarios = await this.usuarioService.getUsuario(this.empresaSigla);
        // console.log(usuarios)

        if(usuarios != undefined){
          this.usuarios = usuarios;
        }else{
          setTimeout(() => {
            this.loading = false;
            this.loader.complete()

          }, 500);
        }

        this.showInfo("Usuários atualizados com sucesso!!")
        this.loading = false;
        this.loader.complete()


      }, 1000);


    } catch (error) {
      this.showError("Ocorreu um erro na atualização da agenda")
      this.loader.complete()
    }

  }

  searchUsrReturn(){
    const ts = this.searchUsr.transform(this.usr_arr, this.pesquisa);
    if(this.pesquisa == ""){
      // this.pesquisaLength = 0;
    }else{
      // this.pesquisaLength = ts.length;
    }
  }

  resetSearch(){
    this.displayedUsuarios = this.usuarios.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
    this.paginator.length = this.usuarios.length;

    // Use o serviço Router para navegar para a mesma rota sem o parâmetro de consulta
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: null },
      queryParamsHandling: 'merge',
    });
    this.pesquisa = "";
    // this.pesquisaLength = 0;
  }

  pags(pags: any) {
    const target = pags as HTMLTextAreaElement;
    this.contador = parseInt(target.value)
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }
  }

  handlePageChange(event: any) {
    this.pag = event;
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }

    this.ajusteMaxSize();
  }

  handlePage(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    // Calcule o índice de início e fim dos itens a serem exibidos na página atual
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    // Atualize os itens a serem exibidos na tabela
    this.displayedUsuarios = this.usuarios.slice(startIndex, endIndex);

    // console.log(this.displayedUsuarios)
  }

  sortData(sort: Sort) {
    const data = this.usuarios.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      this.usuarios = this.sortedData
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'id':
          return this.compare(a.id!, b.id!, isAsc);
        case 'status':
          return this.compare(a.status.valor, b.status.valor, isAsc);
        // case 'nome':
        //   return this.compare(a.nome.valor, b.nome.valor, isAsc);
        // case 'descricao':
        //   return this.compare(a.descricao.valor, b.descricao.valor, isAsc);
        // case 'criado':
        //   return this.compare(a.criado.toString(), b.criado.toString(), isAsc);
        // case 'atualizado':
        //   return this.compare(a.atualizado.toString(), b.atualizado.toString(), isAsc);
        default:
          return 0;
      }
    });

    // console.log(this.sortedData)
    this.usuarios = this.sortedData

    this.displayedUsuarios = this.usuarios.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ajusteMaxSize(){
    if(this.paginationControlsRef != undefined){
      // Obtém o elemento de paginação
      const paginationElement: HTMLElement = this.paginationControlsRef.nativeElement;

      // Calcula o número máximo de itens visíveis com base no tamanho da tela
      const availableWidth: number = paginationElement.offsetWidth;
      const itemWidth: number = 55; // Largura estimada de cada item da paginação
      const maxSize: number = Math.floor(availableWidth / itemWidth) - 1;

      // Define o maxSize calculado
      this.maxSize = maxSize;
    }
  }

  atualizarStatusUsuario(id: any){

    const index = this.usuarios.findIndex((item: Usuario) => item.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja alterar o status do usuário?';
      let mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                        <p class="ag-mb-5">
                          <span>${this.usuarios[index].displayName.valor}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-danger">Status atual: </span>
                          <span>${this.usuarios[index].status.valor ? "Ativo" : "Desativado"}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-sucesso">Novo status: </span>
                          <span>${!this.usuarios[index].status.valor ? "Ativo" : "Desativado"}</span>
                        </p>
                      </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Atualize o item existente
          this.usuarios[index].status.valor = !this.usuarios[index].status.valor;

          const docId = this.usuarios[index].id != null ? this.usuarios[index].id?.toString() : "";
          const plainObject = JSON.stringify(this.usuarios[index])
          const plainString = JSON.parse(plainObject)

          // Limpar o status formControll
          plainString.displayName.status = null;
          plainString.email.status = null;
          plainString.phoneNumber.status = null;
          plainString.photoURL.status = null;
          plainString.validadePlano.status = null;
          plainString.sigla.status = null;

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: atualizarStatusServico', conta);
            this.localhostService.putDados(`${conta.id}_usuarios`, docId!, plainString)
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              this.showSuccess(`Status do usuário ${this.usuarios[index].displayName.valor} alterado para ${this.usuarios[index].status.valor ? "ativado" : "desativado"}`);

              // Nova notificação
              // 1 - empresaSigla: any,
              // 2 - titulo: string,
              // 3 - texto: string,
              // 4 - rotaSemParamNome: string,
              // 5 - rotaSemParamListar: string,
              // 6 - rotaComParamNome: string = "",
              // 7 - rotaComParamCaminho: string = "",
              // 8 - rotaComParamParam: string = "",
              // 9 - linkExternoNome: string = "",
              // 10 - linkExternoURL: string = "",
              // 11 - idReferencia: string,
              // 12 - tipo: string,
              // 13 - idResponsavel: string,
              // 14 - nomeResponsavel: string

              const usr = await this.userService.carregarUsuario(this.empresaSigla)

              this.notificacaoService.criarNotificacao(
                this.empresaSigla,
                `${plainString.displayName.valor}`,
                `O status do usuário foi atualizado para ${this.usuarios[index].status.valor ? "ativado" : "desativado"}.`,
                'Listar Usuários',
                'usuarios',
                'Editar Usuário',
                `usuario`,
                plainString.id?.toString(),
                '',
                '',
                plainString.id!.toString(),
                `Usuário`,
                `${usr.id}`,
                `${usr.displayName.valor}`
              )
            })
            .catch((error: any) => {
              // console.error('Erro ao atualizar dados:', error);
              this.showError("Usuário não encontrado para atualização \n" + error);
            });

          },
          (error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // console.log('Erro ao obter dados da conta:', error);
          });

          // DataService.setItem("Tipo", this.tipos);
          // localStorage.setItem("Tipo", JSON.stringify(this.tipos))
          // this.showSuccess(`Status do tipo ${this.tipos[index].status.valor ? "ativado" : "desativado"}`);
        } else {
          // console.log('NÃO');
        }
      });


    } else {
      // O item não foi encontrado no array, você pode tratar isso conforme necessário
      this.showError("Usuário não encontrada para atualização");
    }
  }

  deletarUsuario(id: any){
    const index = this.usuarios.findIndex(usr => usr.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja excluir o Usuário?';
      let mensagem = ``;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Encontrou um item com o ID correspondente, exclua-o

          const docId = this.usuarios[index].id != null ? this.usuarios[index].id?.toString() : "";

          const usuario = this.usuarios[index];

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: deletarTipo', conta);
            this.localhostService.deleteDados(`${conta.id}_usuarios`, { id: docId })
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              this.showSuccess(`Usuário deletado com sucesso`);

              // Nova notificação
              // 1 - empresaSigla: any,
              // 2 - titulo: string,
              // 3 - texto: string,
              // 4 - rotaSemParamNome: string,
              // 5 - rotaSemParamListar: string,
              // 6 - rotaComParamNome: string = "",
              // 7 - rotaComParamCaminho: string = "",
              // 8 - rotaComParamParam: string = "",
              // 9 - linkExternoNome: string = "",
              // 10 - linkExternoURL: string = "",
              // 11 - idReferencia: string,
              // 12 - tipo: string,
              // 13 - idResponsavel: string,
              // 14 - nomeResponsavel: string

              const usr = await this.userService.carregarUsuario(this.empresaSigla)

              this.notificacaoService.criarNotificacao(
                this.empresaSigla,
                `${usuario.displayName.valor}`,
                `O usuário foi deletado.`,
                'Listar Usuários',
                'usuarios',
                '',
                ``,
                ``,
                '',
                '',
                ``,
                `Usuário`,
                `${usr.id}`,
                `${usr.displayName.valor}`
              )

              this.displayedUsuarios = this.usuarios.slice(
                this.pageIndex * this.pageSize,
                (this.pageIndex + 1) * this.pageSize
              );
            })
            .catch((error: any) => {
              // console.error('Erro ao deletar dados:', error);
              this.showError("Usuário não encontrada para exclusão \n" + error);
            });
          },
          (error: any) => {
            // console.error('Erro ao deletar dados:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // console.log('Erro ao obter dados da conta:', error);
          });

        } else {
          // console.log('NÃO');
        }
      });

    } else {
      // Não encontrou um item com o ID correspondente
      this.showError("Tipo não encontrado para exclusão");
    }
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
