import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ag-multiple-checkbox',
  templateUrl: './ag-multiple-checkbox.component.html',
  styleUrls: ['./ag-multiple-checkbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgMultipleCheckboxComponent),
      multi: true,
    },
  ],
})
export class AgMultipleCheckboxComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() tooltip: string = '';
  @Input() id: string = '';
  @Input() isInvalid: boolean = false;
  @Input() AgStatus: any;
  @Input() options: any[] = []; // Lista de opções para os checkboxes
  @Input() ngModelChange: any;

  private _innerValue: any[] = [];

  get innerValue(): any[] {
    return this._innerValue;
  }

  set innerValue(value: any[]) {
    this._innerValue = value;
    this.onChange(this._innerValue);
  }

  constructor() {}

  writeValue(value: any) {
    this.innerValue = Array.isArray(value) ? value : [];
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  onCheckboxChange(option: any, isChecked: boolean) {
    if (isChecked) {
      if (!this.innerValue.includes(option.value)) {
        this.innerValue.push(option.value);
      }
    } else {
      const index = this.innerValue.indexOf(option.value);
      if (index > -1) {
        this.innerValue.splice(index, 1);
      }
    }
    this.onChange(this.innerValue);
  }

  isChecked(option: any): boolean {
    return this.innerValue.includes(option.value);
  }
}
