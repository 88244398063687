import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  private static emitters: {
    [nomeEvento: string]: any
  } = {}

  static get(nomeEvento: string): any {
    return this.emitters[nomeEvento];
  }

  static getItem(nomeEvento: string): any {
      return this.emitters[nomeEvento];
  }

  static setItem(nomeEvento: string, valor: any) {
      this.emitters[nomeEvento] = valor;
  }

  static get2(nomeEvento: string, id: any): any {
    return this.emitters[nomeEvento][id];
  }

  static set(nomeEvento: string, valor: any) {
      this.emitters[nomeEvento] = valor;
  }

}
