import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _lightMode = new BehaviorSubject<boolean>(false);

  get lightMode$() {
    return this._lightMode.asObservable();
  }

  get lightMode() {
    return this._lightMode.value;
  }

  set lightMode(value: boolean) {
    this._lightMode.next(value);
  }

  constructor(private router: Router) {}

  getEmpresaSigla(route: ActivatedRouteSnapshot): string | null {
    return route.paramMap.get('empresaSigla');
  }

  getEmpresaSiglaObservable(): Observable<string | null> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const snapshot = this.router.routerState.snapshot.root;
        return this.getEmpresaSigla(snapshot);
      })
    );
  }
}
