import { Component } from '@angular/core';
import { CustomStyles } from 'src/interface/custom-styles';

@Component({
  selector: 'app-configuracao-sistema',
  templateUrl: './configuracao-sistema.component.html',
  styleUrls: ['./configuracao-sistema.component.css']
})
export class ConfiguracaoSistemaComponent {

  modoEstilo: string = 'dark'; // Inicialmente definido como "dark"

  constructor() {}

  aplicarEstilo(){
    console.log(this.modoEstilo)
  }
}
