import { ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Agenda } from 'src/class/agenda';
import { Links } from 'src/class/links';
import { objUsuario } from 'src/class/usuario';
import { SearchAgendaPipe } from 'src/pipes/search-agenda.pipe';
import { DataService } from 'src/services/data.service';
import { LinksService } from 'src/services/links.service';
import { ThemeService } from 'src/services/theme.service';
import { ToastService } from 'src/services/toast.service';

// Calendario
import { CalendarOptions, DatesSetArg, EventContentArg } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ptbr from '@fullcalendar/core/locales/pt-br';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { FeriadosService } from '../../../../services/feriados.service';
import { CalendarioService } from 'src/services/calendario.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';
import { AgendaService } from 'src/services/component/agenda.service';
import { LocalhostService } from '../../../../services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ContaService } from 'src/services/component/conta.service';
import { AuthService } from 'src/services/auth.service';
import { ModalLinkService } from 'src/services/modal-link.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';

declare var bootstrap: any;

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit,OnChanges,OnDestroy  {
  @ViewChild('calendarioModal', { static: false }) calendarioModal!: ElementRef;
  @ViewChild('fullcalendar') fullcalendar!: FullCalendarComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";
  usr_obj = new objUsuario;

  pesquisa="";
  pag = 1;
  contador = 5;
  pageSize = 5;
  pageIndex = 0;
  totalizador = 0;
  maxSize = 5;
  visualizando = this.contador;
  lightMode: any;
  modoView = false;
  modoViewNum = 12;

  private subscription: Subscription;

  linksAgenda: Links[] = [];

  agendas: Agenda[] = [];
  displayedAgendas: Agenda[] = [];
  loading: boolean = true;
  sortedData: Agenda[] = [];
  eventsList: any[] = [];

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth', // Define a visualização de lista semanal
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    locale: ptbr, // Define o idioma para pt-BR
    events: this.eventsList,
    eventClick: this.handleEventClick.bind(this), // Associe um manipulador de eventos para quando um evento for clicado,
    eventDisplay: 'block', // Exibe os eventos em blocos
    // eventOverlap: false, // Não permita a sobreposição de eventos
    dayMaxEventRows: 2, // Define o número máximo de linhas de eventos por dia
    // eventContent: this.customEventContent.bind(this),
    // datesSet: this.customDatesSet.bind(this), // Personalize o tamanho das células no modo mês
    // eventContent: function (info) {
    //   const event = info.event;
    //   const description = event.extendedProps['description'] || ''; // Obtenha a descrição do evento

    //   return {
    //     html: `
    //       <div class="event-title">${event.title}</div>
    //       <div class="event-description">${description}</div>
    //       `
    //     };
    // }
  };

  loader = this.loadingBar.useRef('http');

  empresaSigla: any | undefined;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService,
    private linksService: LinksService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private paginatorIntl: MatPaginatorIntl,
    private searchAgendaPipe: SearchAgendaPipe,
    private feriadosService: FeriadosService,
    private calendarioService: CalendarioService,
    private loadingBar: LoadingBarService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    private agendaService: AgendaService,
    private localhostService: LocalhostService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private userService: AuthService,
    private modalLinkService: ModalLinkService,
    private notificacaoService: NotificacaoSistemaService
  ) {
    this.subscription = this.themeService.lightMode$.subscribe(lightMode => {
      this.lightMode = lightMode;
    });
    this.paginatorIntl.itemsPerPageLabel = 'Itens por página:';
    this.paginatorIntl.nextPageLabel = 'Próxima página';
    this.paginatorIntl.previousPageLabel = 'Página anterior';
    this.paginatorIntl.firstPageLabel = 'Primeira página';
    this.paginatorIntl.lastPageLabel = 'Última página';

    // Personalize a indexação para começar em 1
    this.paginatorIntl.getRangeLabel = this.getPortugueseRangeLabel;

    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.agendaService.getAgendas(this.empresaSigla);
        });
      // }
    // });

   }

  ngOnInit() {


    // console.log(this.agendas)

    this.getCache();

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.changeDetectorRef.detectChanges();

    this.route.queryParams.subscribe((params: any) => {
      // O parâmetro de consulta 'search' estará em params.search
      this.pesquisa = params.search ? params.search : '';
      // console.log(params)
      // Agora você pode usar o valor da pesquisa em sua função searchAgenda
      if(this.pesquisa != ''){
        this.searchAgenda();
      }
    });
  }

  ngOnChanges() {
    // Recarregar os eventos quando eventsList muda
    if (this.fullcalendar) {
      const calendarApi = this.fullcalendar.getApi();
      calendarApi.removeAllEventSources();
      calendarApi.addEventSource(this.eventsList);
      // console.log(this.calendarOptions)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  getCache(){
    // console.log(this.agendas)

    let modoView = localStorage.getItem("modoView");

    let modoViewNum = localStorage.getItem("modoViewNum");

    if(modoView){
      this.modoView = modoView == 'true' ? true : false
    }

    if(modoViewNum){
      this.modoViewNum = parseInt(modoViewNum);
    }

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        // console.log(agendas)

        // Faça algo com as notificações atualizadas
        // if(agendas.length > 0){
          this.agendas = agendas;
          this.displayedAgendas = this.agendas.slice(
            this.pageIndex * this.pageSize,
            (this.pageIndex + 1) * this.pageSize
          );
          this.loading = false;
          if(this.pesquisa != ''){
            setTimeout(() => {
              this.searchAgenda();
              // if (this.filtro && this.filtro.nativeElement) {
              //   console.log(this.filtro)
              //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
              // }
            }, 500);
          }
        // }
      },
      error => {
        // console.error('Erro ao listar as Agendas:', error);
      }
    );

    // let agenda = this.agendaService.getAgendas();

    // if(agenda != undefined){
    //   this.agendas = agenda;
    //   this.loading = false;
    // }else{
    //   // this.loading = false;
    //   let agendaCache = localStorage.getItem("Agenda")
    //   if(agendaCache != undefined){
    //     this.agendas = JSON.parse(agendaCache);
    //     DataService.set("Agenda", JSON.parse(agendaCache));
    //     this.loading = false;
    //   }else{
    //     setTimeout(() => {
    //       this.loading = false;
    //     }, 500);
    //   }
    // }

    // console.log(this.agendas)

  }

  async getFeriados(){
    const feriados = await this.feriadosService.consultarFeriados("2023", "SP");

    // const eventosFiltrados = this.eventsList.filter(evento => evento.holiday !== true);
    this.eventsList = this.eventsList.filter(evento => evento.holiday !== true);

    for (let index = 0; index < feriados.length; index++) {
      const feriado = feriados[index];

      const newEvent = {
        title: feriado.name,
        date: feriado.date,
        holiday: true,
        backgroundColor: 'var(--cor-sucesso)',
        description: feriado.level
      };

      this.eventsList.push(newEvent);
    }
    // console.log(feriados)
    // console.log(this.eventsList)

    this.calendarOptions.events = this.eventsList

    this.reloadCalendar();
    this.changeDetectorRef.detectChanges();
    // console.log(this.calendarOptions.events = this.eventsList)


  }

  removerFeriados(){
    this.eventsList = this.eventsList.filter(evento => evento.holiday !== true);
    this.calendarOptions.events = this.eventsList

    this.reloadCalendar();
    this.changeDetectorRef.detectChanges();

  }

  // Função para atualizar a lista de eventos
  updateEvents() {

    this.calendarOptions.events = this.eventsList;
    // this.reloadCalendar();

  }

  // Manipulador de eventos quando um evento é clicado
  handleEventClick(info: any) {
    // Aqui, você pode adicionar lógica para editar ou remover o evento
    // Por exemplo, exibir um modal para editar o evento
    console.log(info.event);
  }

  // Função para adicionar um novo evento à lista
  addEvent() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
    const minute = now.getMinutes().toString().padStart(2, '0');
    const second = now.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`;


    const newEvent = {
      title: 'Novo Evento',
      start: formattedDate,
      end: formattedDate,
      backgroundColor: 'var(--cor-primaria)',
    };

    this.eventsList.push(newEvent);
    // this.updateEvents();
  }

  // Função para editar um evento existente
  editEvent(event: any) {
    // Implemente a lógica para editar o evento aqui
    // Por exemplo, exibir um modal de edição
    console.log('Editar evento:', event);
  }

  // Função para excluir um evento existente
  deleteEvent(event: any) {
    const index = this.eventsList.indexOf(event);
    if (index !== -1) {
      this.eventsList.splice(index, 1);
      this.updateEvents();
    }
  }

  // Função para recarregar o calendário
  reloadCalendar() {
    if (this.fullcalendar) {
      this.fullcalendar.getApi().refetchEvents();
    }
  }

  customEventContent(arg: EventContentArg) {
    // Defina um tamanho fixo para as caixas de eventos
    const eventElement = document.createElement('div');
    // eventElement.style.width = '100px'; // Defina o tamanho desejado em pixels
    eventElement.style.height = '30px'; // Defina o tamanho desejado em pixels
    eventElement.style.display = 'flex';
    eventElement.style.alignItems = 'center';
    eventElement.style.paddingLeft = '5px';

    // Crie o conteúdo do evento (por exemplo, o título)
    const eventTitle = document.createElement('div');
    eventTitle.innerText = arg.event.title;

    // Adicione o conteúdo ao elemento do evento
    eventElement.appendChild(eventTitle);

    return { domNodes: [eventElement] };
  }

  customDatesSet(info: DatesSetArg) {
    if (info.view.type === 'dayGridMonth') {
      // Defina o tamanho desejado para as células do dia no modo mês
      const dayCellElements = document.querySelectorAll('.fc-daygrid-day');
      dayCellElements.forEach((element:any) => {
        element.style.maxHeight = '100px'; // Defina o tamanho máximo desejado em pixels
      });
    }
  }

  // Personalize a função getRangeLabel para começar em 1
  getPortugueseRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }

  searchAgenda() {

    const retorno = this.searchAgendaPipe.transform(this.agendas, this.pesquisa);

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.displayedAgendas = retorno;

    if(this.pesquisa == ""){
      this.displayedAgendas = this.agendas.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      this.paginator.length = this.agendas.length;

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { search: null },
        queryParamsHandling: 'merge',
      });

    }else{

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ['search']: this.pesquisa },
        queryParamsHandling: 'merge',
      });

      this.displayedAgendas = retorno.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      if (this.paginator) {
        this.paginator.length = retorno.length;
      }
    }

  }

  sortData(sort: Sort) {
    const data = this.agendas.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      this.agendas = this.sortedData
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id!, b.id!, isAsc);
        case 'status':
          return this.compare(a.status.valor, b.status.valor, isAsc);
        case 'nome':
          return this.compare(a.nome.valor, b.nome.valor, isAsc);
        case 'confirmacaoAdmin':
          return this.compare(a.confirmacaoAdmin.valor, b.confirmacaoAdmin.valor, isAsc);
        case 'notificacoes':
          return this.compare(a.notificacoes.valor, b.notificacoes.valor, isAsc);
        case 'agenda_inicio':
          return this.compare(a.agenda_inicio.valor, b.agenda_inicio.valor, isAsc);
        case 'agenda_fim':
          return this.compare(a.agenda_fim.valor, b.agenda_fim.valor, isAsc);
        default:
          return 0;
      }
    });

    // console.log(this.sortedData)
    this.agendas = this.sortedData

    this.displayedAgendas = this.agendas.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  async openCalendario(id: any){

    this.loader.start()
    this.abrirModal(this.calendarioModal.nativeElement);
    this.fullcalendar.getApi().setOption('height', 'auto');
    this.fullcalendar.getApi().updateSize();

    let agenda: any = {};

    for (let index = 0; index < this.agendas.length; index++) {
      const agd = this.agendas[index];
      if(agd.id == id){
        Object.assign(agenda, agd);
      }
    }
    // console.log(agenda)

    const dataString_dataInicial = agenda.agenda_inicio.valor;
    const partesData_dataInicial = dataString_dataInicial.split("/");

    const dataString_dataFinal = agenda.agenda_fim.valor;
    const partesData_dataFinal = dataString_dataFinal.split("/");

    const dataInicial = new Date(partesData_dataInicial[2], partesData_dataInicial[1] - 1, partesData_dataInicial[0]);
    const dataFinal = new Date(partesData_dataFinal[2], partesData_dataFinal[1] - 1, partesData_dataFinal[0], 23, 59, 59);

    // console.log(dataInicial, dataFinal)

    const duracao = agenda.duracaoAtendimento.valor;
    const horario = agenda.horarios;

    const nomeAgenda = agenda.nome.valor;

    const ret = await this.calendarioService.gerarEventos(dataInicial,dataFinal,duracao,horario,nomeAgenda)

    // console.log(ret)

    this.calendarOptions.events = ret;

    this.loader.complete()

  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  reload(){
    try {
      this.agendas = [];
      this.loading = true;
      // this.getTotalTiposUsuarios();
      // this.getUsuario();
      this.loader.start()
      setTimeout(async () => {
        let agenda = await this.agendaService.getAgendas(this.empresaSigla);
        // console.log(agenda)

        if(agenda != undefined){
          this.agendas = agenda;
        }else{
          setTimeout(() => {
            this.loading = false;
            this.loader.complete()

          }, 500);
        }

        this.showInfo("Agendas atualizadas com sucesso!!")
        this.loading = false;
        this.loader.complete()


      }, 1000);


    } catch (error) {
      this.showError("Ocorreu um erro na atualização da agenda")
      this.loader.complete()

    }

  }

  resetSearch(){
    this.pesquisa = "";
    this.displayedAgendas = this.agendas.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
    this.paginator.length = this.agendas.length;

    // Use o serviço Router para navegar para a mesma rota sem o parâmetro de consulta
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: null },
      queryParamsHandling: 'merge',
    });
  }

  pags(pags: any) {
    const target = pags as HTMLTextAreaElement;
    this.contador = parseInt(target.value)
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }
  }

  handlePage(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    // Calcule o índice de início e fim dos itens a serem exibidos na página atual
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    // Atualize os itens a serem exibidos na tabela
    this.displayedAgendas = this.agendas.slice(startIndex, endIndex);

    // console.log(this.displayedAgendas)
  }

  modoTabela(){
    this.modoView = true;
    localStorage.setItem("modoView", "true");

    this.changeDetectorRef.detectChanges();
  }

  modoCard(){
    this.modoView = false;
    this.modoViewNum = 12;
    localStorage.setItem("modoViewNum", "12");
    localStorage.setItem("modoView", "false");
  }

  formatBR(instrucoes: string): string {
    return instrucoes.replace(/\n/g, '<br>');
  }

  deletarAgeda(id: any){
    const index = this.agendas.findIndex(agenda => agenda.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja excluir a agenda?';
      let mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                        <p class="ag-mb-5">
                          <span>${this.agendas[index].nome.valor}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span>
                            <i class="bi bi-calendar-event"></i> ${this.agendas[index].agenda_inicio.valor}
                          </span>
                          <span class="ag-pl-5 ag-pr-5">
                            -
                          </span>
                          <span>
                            <i class="bi bi-calendar-event"></i> ${this.agendas[index].agenda_fim.valor}
                          </span>
                          <span class="ag-pl-5 ag-pr-5">
                            -
                          </span>
                          <span>
                            <i class="bi bi-stopwatch"></i> ${this.agendas[index].duracaoAtendimento.valor}
                          </span>
                        </p>
                        <p class="ag-mb-5">
                          <span>
                            ${this.agendas[index].status.valor? "<i class='bi bi-circle-fill text-cor-sucesso ag-pr-5'></i>" : "<i class='bi bi-circle-fill text-cor-danger ag-pr-5'></i>"}
                            ${this.agendas[index].status.valor ? "Ativa" : "Desativada"}
                          </span>
                        </p>
                      </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Encontrou um item com o ID correspondente, exclua-o
          const docId = this.agendas[index].id != null ? this.agendas[index].id?.toString() : "";

          const agenda = this.agendas[index];

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: deletarAgeda', conta);
            this.localhostService.deleteDados(`${conta.id}_agendas`, { id: docId })
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              // Salve o array de volta no DataService
              // DataService.set("Agenda", this.agendas)
              // localStorage.setItem("Agenda", JSON.stringify(this.agendas))
              this.showSuccess(`Agenda deletada.`);

              // Nova notificação
              // empresaSigla: any,
              // titulo: string,
              // texto: string,
              // rotaSemParamNome: string,
              // rotaSemParamListar: string,
              // rotaComParamNome: string = "",
              // rotaComParamCaminho: string = "",
              // rotaComParamParam: string = "",
              // linkExternoNome: string = "",
              // linkExternoURL: string = "",
              // idReferencia: string,
              // tipo: string,
              // 13 - idResponsavel: string,
              // 14 - nomeResponsavel: string

              const usr = await this.userService.carregarUsuario(this.empresaSigla);

              this.notificacaoService.criarNotificacao(
                this.empresaSigla,
                `${agenda.nome.valor}`,
                `A agenda foi deletada.`,
                'Listar Agendas',
                'agendas',
                ``,
                ``,
                ``,
                ``,
                ``,
                ``,
                `Agenda`,
                `${usr.id}`,
                `${usr.displayName.valor}`
              )

              this.displayedAgendas = this.agendas.slice(
                this.pageIndex * this.pageSize,
                (this.pageIndex + 1) * this.pageSize
              );
            })
            .catch((error: any) => {
              // console.error('Erro ao deletar dados:', error);
              this.showError("Agenda não encontrada para exclusão \n" + error);
            });

            // this.agendas.splice(index, 1);
            // localStorage.setItem("Agenda", JSON.stringify(this.agendas))

            // this.showSuccess("Agenda deletada com sucesso");
            // this.displayedAgendas = this.agendas.slice(
            //   this.pageIndex * this.pageSize,
            //   (this.pageIndex + 1) * this.pageSize
            // );

          },
          (error: any) => {
            // console.error('Erro ao criar agenda:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // console.log('Erro ao obter dados da conta:', error);
          });


        } else {
          // console.log('NÃO');
        }
      });

    } else {
      // Não encontrou um item com o ID correspondente
      this.showError("Agenda não encontrada para exclusão");
    }
  }

  atualizarStatusAgenda(id: any){

    const index = this.agendas.findIndex((item: Agenda) => item.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja alterar o status da agenda?';
      let mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                        <p class="ag-mb-5">
                          <span>${this.agendas[index].nome.valor}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span>
                            <i class="bi bi-calendar-event"></i> ${this.agendas[index].agenda_inicio.valor}
                          </span>
                          <span class="ag-pl-5 ag-pr-5">
                            -
                          </span>
                          <span>
                            <i class="bi bi-calendar-event"></i> ${this.agendas[index].agenda_fim.valor}
                          </span>
                          <span class="ag-pl-5 ag-pr-5">
                            -
                          </span>
                          <span>
                            <i class="bi bi-stopwatch"></i> ${this.agendas[index].duracaoAtendimento.valor}
                          </span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-danger">Status atual: </span>
                          <span>${this.agendas[index].status.valor ? "Ativa" : "Desativada"}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-sucesso">Novo status: </span>
                          <span>${!this.agendas[index].status.valor ? "Ativa" : "Desativada"}</span>
                        </p>
                      </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Atualize o item existente

          this.agendas[index].status.valor = !this.agendas[index].status.valor;

          const docId = this.agendas[index].id != null ? this.agendas[index].id?.toString() : "";
          const plainObject = JSON.stringify(this.agendas[index])
          const plainString = JSON.parse(plainObject)

          plainString.agenda_fim.status = null;
          plainString.agenda_inicio.status = null;
          plainString.antecedenciaMaximaDias.status = null;
          plainString.antecedenciaMinimaHoras.status = null;
          plainString.duracaoAtendimento.status = null;
          plainString.instrucoes.status = null;
          plainString.link.status = null;
          plainString.linkImagem.status = null;
          plainString.nome.status = null;
          plainString.subNome.status = null;

          for (let index = 0; index < plainString.horarios.length; index++) {
            const obj_hr = plainString.horarios[index];
            obj_hr.dia.status = null;
            for (let index = 0; index < obj_hr.horariosDia.length; index++) {
              const hr = obj_hr.horariosDia[index];
              hr.hora_final.status = null
            }
          }

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: criarAgenda', conta);

            this.localhostService.putDados(`${conta.id}_agendas`, docId , plainString)
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              // Salve o array de volta no DataService
              // DataService.set("Agenda", this.agendas)
              // localStorage.setItem("Agenda", JSON.stringify(this.agendas))
              this.showSuccess(`O status da agenda ${this.agendas[index].nome.valor}, foi atualizado para ${this.agendas[index].status.valor ? "ativado" : "desativado"}.`);

              // Nova notificação
              // empresaSigla: any,
              // titulo: string,
              // texto: string,
              // rotaSemParamNome: string,
              // rotaSemParamListar: string,
              // rotaComParamNome: string = "",
              // rotaComParamCaminho: string = "",
              // rotaComParamParam: string = "",
              // linkExternoNome: string = "",
              // linkExternoURL: string = "",
              // idReferencia: string,
              // tipo: string,
              // 13 - idResponsavel: string,
              // 14 - nomeResponsavel: string

              const usr = await this.userService.carregarUsuario(this.empresaSigla)

              this.notificacaoService.criarNotificacao(
                this.empresaSigla,
                `${this.agendas[index].nome.valor}`,
                `O status da agenda foi atualizado para ${this.agendas[index].status.valor ? "ativado" : "desativado"}.`,
                'Listar Agendas',
                'agendas',
                'Editar Agenda',
                `agendas/agenda`,
                this.agendas[index].id,
                '',
                '',
                `${this.agendas[index].id}`,
                `Agenda`,
                `${usr.id}`,
                `${usr.displayName.valor}`
              )
            })
            .catch((error: any) => {
              // console.error('Erro ao atualizar dados:', error);
              this.showError("Agenda não encontrada para atualização \n" + error);
            });
          },
          (error: any) => {
            // console.error('Erro ao criar agenda:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // console.log('Erro ao obter dados da conta:', error);
          });

        } else {
          // console.log('NÃO');
        }
      });


    } else {
      // O item não foi encontrado no array, você pode tratar isso conforme necessário
      this.showError("Agenda não encontrada para atualização");
    }
  }

  openModalLink(titulo: any, link: any){
    // const linkCompleto = `https://logwise-agendamento-dev-9cf97.web.app/${this.empresaSigla}/agendamento/${link}`;
    this.modalLinkService.openModal(titulo, link).then((confirmation: boolean) => {});
  }

  openModalLinkID(titulo: any, link: any){
    const linkCompleto = `https://logwise-agendamento-dev-9cf97.web.app/${this.empresaSigla}/agendamento/${link}`;
    this.modalLinkService.openModal(titulo, linkCompleto).then((confirmation: boolean) => {});
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}



