<div class="pb-4">

  <div class="container text-center min-width p-0">

    <!-- 👇 Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('agendas')" class="text-padrao">Agendas</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Agenda {{agenda.id}}</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('agendas')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>
    <!-- Breadcrumb 👆-->

    <!-- 👇 Editar Agenda -->
    <div class="row p-0 m-0">

      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Agenda - <span style="font-style: italic; font-size: 13px;">{{agenda.id}}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="loading">
        <div [class]="'col-lg-12 mb-2'" >
          <div class="p-0 m-0 pulsate" >
          </div>
        </div>
      </div>

      <!-- Dados da Agenda -->
      <div class="min-width col-lg-12 pb-3" *ngIf="!loading">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12 reset-padding-mobile">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Configurações Gerais </h3>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">

            <!-- agenda.nome -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                [(ngModel)]="agenda.nome.valor"
                [label]="agenda.nome.title"
                [tooltip]="agenda.nome.info"
                [value]="agenda.nome.valor"
                [isInvalid]="agenda.nome.status?.invalid"
                [AgStatus]="agenda.nome.status"
                [id]="'nome_agenda'"
                AgPlaceholder="{{agenda.nome.placeholder}}"
                AgInputType="{{agenda.nome.inputType}}"
                AgMaxlength="{{agenda.nome.maxlength}}"
                AgInputMode="{{agenda.nome.inputMode}}"
                (ngModelChange)="agenda.mascara(agenda.nome.mascara, 'nome');"
                (change)="agenda.validarInput('nome');"
                >
              </app-ag-input-field>
            </div>

            <!-- agenda.subNome -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                    [(ngModel)]="agenda.subNome.valor"
                    [label]="agenda.subNome.title"
                    [tooltip]="agenda.subNome.info"
                    [value]="agenda.subNome.valor"
                    [isInvalid]="agenda.subNome.status?.invalid"
                    [AgStatus]="agenda.subNome.status"
                    [id]="'subnome_agenda'"
                    AgPlaceholder="{{agenda.subNome.placeholder}}"
                    AgInputType="{{agenda.subNome.inputType}}"
                    AgMaxlength="{{agenda.subNome.maxlength}}"
                    AgInputMode="{{agenda.subNome.inputMode}}"
                    (ngModelChange)="agenda.mascara(agenda.subNome.mascara, 'subNome');"
                    (change)="agenda.validarInput('subNome');"
                    >
              </app-ag-input-field>
            </div>

            <!-- agenda.link -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                    [(ngModel)]="agenda.link.valor"
                    [label]="agenda.link.title"
                    [tooltip]="agenda.link.info"
                    [value]="agenda.link.valor"
                    [isInvalid]="agenda.link.status?.invalid"
                    [AgStatus]="agenda.link.status"
                    [id]="'link_agenda'"
                    AgPlaceholder="{{agenda.link.placeholder}}"
                    AgInputType="{{agenda.link.inputType}}"
                    AgMaxlength="{{agenda.link.maxlength}}"
                    AgInputMode="{{agenda.link.inputMode}}"
                    (ngModelChange)="agenda.mascara(agenda.link.mascara, 'link');"
                    (change)="agenda.validarInput('link');"
                    [AgDisabled]="true"
                    >
              </app-ag-input-field>
            </div>

            <!-- agenda.instrucoes -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-textarea-field
                    [(ngModel)]="agenda.instrucoes.valor"
                    [label]="agenda.instrucoes.title"
                    [tooltip]="agenda.instrucoes.info"
                    [value]="agenda.instrucoes.valor"
                    [isInvalid]="agenda.instrucoes.status?.invalid"
                    [AgStatus]="agenda.instrucoes.status"
                    [id]="'instrucos_agenda'"
                    AgPlaceholder="{{agenda.instrucoes.placeholder}}"
                    AgInputType="{{agenda.instrucoes.inputType}}"
                    AgMaxlength="{{agenda.instrucoes.maxlength}}"
                    AgInputMode="{{agenda.instrucoes.inputMode}}"
                    (ngModelChange)="agenda.mascara(agenda.instrucoes.mascara, 'instrucoes');"
                    (change)="agenda.validarInput('instrucoes');"
                    >
              </app-ag-textarea-field>
            </div>

            <!-- agenda.linkImagem -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-input-field
                    [(ngModel)]="agenda.linkImagem.valor"
                    [label]="agenda.linkImagem.title"
                    [tooltip]="agenda.linkImagem.info"
                    [value]="agenda.linkImagem.valor"
                    [isInvalid]="agenda.linkImagem.status?.invalid"
                    [AgStatus]="agenda.linkImagem.status"
                    [id]="'linkImagem_agenda'"
                    AgPlaceholder="{{agenda.linkImagem.placeholder}}"
                    AgInputType="{{agenda.linkImagem.inputType}}"
                    AgMaxlength="{{agenda.linkImagem.maxlength}}"
                    AgInputMode="{{agenda.linkImagem.inputMode}}"
                    (ngModelChange)="agenda.mascara(agenda.linkImagem.mascara, 'linkImagem');"
                    (change)="agenda.validarInput('linkImagem');"
                    >
              </app-ag-input-field>
            </div>

            <!-- agenda.agenda_inicio -->
            <div class="col-lg-4 reset-padding-mobile">
              <app-ag-input-field-datepicker
                [(ngModel)]="agenda.agenda_inicio.valor"
                [label]="agenda.agenda_inicio.title"
                [tooltip]="agenda.agenda_inicio.info"
                [value]="agenda.agenda_inicio.valor"
                [isInvalid]="agenda.agenda_inicio.status?.invalid"
                [AgStatus]="agenda.agenda_inicio.status"
                [id]="'agenda_inicio_agenda'"
                AgPlaceholder="{{agenda.agenda_inicio.placeholder}}"
                AgInputType="{{agenda.agenda_inicio.inputType}}"
                AgMaxlength="{{agenda.agenda_inicio.maxlength}}"
                AgInputMode="{{agenda.agenda_inicio.inputMode}}"
                (ngModelChange)="agenda.mascara(agenda.agenda_inicio.mascara, 'agenda_inicio');"
                (change)="agenda.validarInput('agenda_inicio');"
                >
              </app-ag-input-field-datepicker>

            </div>

            <!-- agenda.agenda_fim -->
            <div class="col-lg-4 reset-padding-mobile">
              <app-ag-input-field-datepicker
                [(ngModel)]="agenda.agenda_fim.valor"
                [label]="agenda.agenda_fim.title"
                [tooltip]="agenda.agenda_fim.info"
                [value]="agenda.agenda_fim.valor"
                [isInvalid]="agenda.agenda_fim.status?.invalid"
                [AgStatus]="agenda.agenda_fim.status"
                [id]="'agenda_fim_agenda'"
                AgPlaceholder="{{agenda.agenda_fim.placeholder}}"
                AgInputType="{{agenda.agenda_fim.inputType}}"
                AgMaxlength="{{agenda.agenda_fim.maxlength}}"
                AgInputMode="{{agenda.agenda_fim.inputMode}}"
                (ngModelChange)="agenda.mascara(agenda.agenda_fim.mascara, 'agenda_fim');"
                (change)="agenda.validarInput('agenda_fim');"
                >
              </app-ag-input-field-datepicker>
            </div>

            <!-- agenda.duracaoAtendimento -->
            <div class="col-lg-4 reset-padding-mobile">
              <app-ag-input-field
                    [(ngModel)]="agenda.duracaoAtendimento.valor"
                    [label]="agenda.duracaoAtendimento.title"
                    [tooltip]="agenda.duracaoAtendimento.info"
                    [value]="agenda.duracaoAtendimento.valor"
                    [isInvalid]="agenda.duracaoAtendimento.status?.invalid"
                    [AgStatus]="agenda.duracaoAtendimento.status"
                    [id]="'duracao_atendimento_agenda'"
                    AgPlaceholder="{{agenda.duracaoAtendimento.placeholder}}"
                    AgInputType="{{agenda.duracaoAtendimento.inputType}}"
                    AgMaxlength="{{agenda.duracaoAtendimento.maxlength}}"
                    AgInputMode="{{agenda.duracaoAtendimento.inputMode}}"
                    (ngModelChange)="agenda.mascara(agenda.duracaoAtendimento.mascara, 'duracaoAtendimento');"
                    (change)="agenda.validarInput('duracaoAtendimento');"
                    >
              </app-ag-input-field>
            </div>

            <!-- agenda.antecedenciaMinimaHoras -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                    [(ngModel)]="agenda.antecedenciaMinimaHoras.valor"
                    [label]="agenda.antecedenciaMinimaHoras.title"
                    [tooltip]="agenda.antecedenciaMinimaHoras.info"
                    [value]="agenda.antecedenciaMinimaHoras.valor"
                    [isInvalid]="agenda.antecedenciaMinimaHoras.status?.invalid"
                    [AgStatus]="agenda.antecedenciaMinimaHoras.status"
                    [id]="'antecedencia_minima_agenda'"
                    AgPlaceholder="{{agenda.antecedenciaMinimaHoras.placeholder}}"
                    AgInputType="{{agenda.antecedenciaMinimaHoras.inputType}}"
                    AgMaxlength="{{agenda.antecedenciaMinimaHoras.maxlength}}"
                    AgInputMode="{{agenda.antecedenciaMinimaHoras.inputMode}}"
                    (ngModelChange)="agenda.mascara(agenda.antecedenciaMinimaHoras.mascara, 'antecedenciaMinimaHoras');"
                    (change)="agenda.validarInput('antecedenciaMinimaHoras');"
                    >
              </app-ag-input-field>
            </div>

            <!-- agenda.antecedenciaMaximaDias -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-input-field
                    [(ngModel)]="agenda.antecedenciaMaximaDias.valor"
                    [label]="agenda.antecedenciaMaximaDias.title"
                    [tooltip]="agenda.antecedenciaMaximaDias.info"
                    [value]="agenda.antecedenciaMaximaDias.valor"
                    [isInvalid]="agenda.antecedenciaMaximaDias.status?.invalid"
                    [AgStatus]="agenda.antecedenciaMaximaDias.status"
                    [id]="'antecedencia_maxima_agenda'"
                    AgPlaceholder="{{agenda.antecedenciaMaximaDias.placeholder}}"
                    AgInputType="{{agenda.antecedenciaMaximaDias.inputType}}"
                    AgMaxlength="{{agenda.antecedenciaMaximaDias.maxlength}}"
                    AgInputMode="{{agenda.antecedenciaMaximaDias.inputMode}}"
                    (ngModelChange)="agenda.mascara(agenda.antecedenciaMaximaDias.mascara, 'antecedenciaMaximaDias');"
                    (change)="agenda.validarInput('antecedenciaMaximaDias');"
                    >
              </app-ag-input-field>
            </div>

            <!-- agenda.confirmacaoAdmin -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="agenda.confirmacaoAdmin.valor"
                [label]="agenda.confirmacaoAdmin.title"
                [tooltip]="agenda.confirmacaoAdmin.info"
                [value]="agenda.confirmacaoAdmin.valor"
                [id]="'confirmacao_admin_agenda'"
                >
              </app-ag-mat-checkbox>
            </div>

            <!-- agenda.notificacoes -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="agenda.notificacoes.valor"
                [label]="agenda.notificacoes.title"
                [tooltip]="agenda.notificacoes.info"
                [value]="agenda.notificacoes.valor"
                [id]="'notificacoes_agenda'"
                >
              </app-ag-mat-checkbox>
            </div>

            <!-- agenda.bloquearFeriadosNacionais -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="agenda.bloquearFeriadosNacionais.valor"
                [label]="agenda.bloquearFeriadosNacionais.title"
                [tooltip]="agenda.bloquearFeriadosNacionais.info"
                [value]="agenda.bloquearFeriadosNacionais.valor"
                [id]="'bloquear_feriados_nacionais_agenda'"
                >
              </app-ag-mat-checkbox>
            </div>

            <!-- agenda.bloquearFeriadosEstaduais -->
            <div class="col-lg-6 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="agenda.bloquearFeriadosEstaduais.valor"
                [label]="agenda.bloquearFeriadosEstaduais.title"
                [tooltip]="agenda.bloquearFeriadosEstaduais.info"
                [value]="agenda.bloquearFeriadosEstaduais.valor"
                [id]="'bloquear_feriados_estaduais_agenda'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>

        </div>
      </div>

      <!-- Formulário -->
      <div class="min-width col-lg-12 pb-3" *ngIf="!loading">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12 reset-padding-mobile">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Dados do Cliente </h3>
              <p class="p-0 m-0 text-cor-padrao text-start w-100">Escolha quais campos devem ser incluídos no formulário de agendamento.</p>
              <p class="p-0 m-0 text-cor-padrao text-start w-100">Os campos obrigatórios mínimos necessários para o agendamento são: <strong>Telefone</strong>, <strong>E-mail</strong> e <strong>Número da Nota Fiscal</strong>.</p>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">
            <div class="col-lg-6 mb-3 reset-padding-mobile">

              <app-ag-ng-select [(ngModel)]="formCamposSelecionados"
                   [label]="agenda.formCampos.title"
                   [id]="'formCamposSelecionados'"
                   [tooltip]="agenda.formCampos.info"
                   [AgPlaceholder]="agenda.formCampos.title"
                   [AgMultiple]="true"
                   [AgCloseOnSelect]="false"
                   [AgOptions]="getCamposVisiveis()"
                   (ngModelChange)="selectAllCampos($event)"
                   (change)="selectAllCampos($event)"
                   AgSelectType="checkbox">
              </app-ag-ng-select>

            </div>

            <div class="col-lg-6 mb-3 reset-padding-mobile">

              <app-ag-ng-select [(ngModel)]="formCamposObrigatoriosSelecionados"
                   [label]="agenda.formCamposObrigatorios.title"
                   [id]="'formCamposObrigatoriosSelecionados'"
                   [tooltip]="agenda.formCamposObrigatorios.info"
                   [AgPlaceholder]="agenda.formCamposObrigatorios.title"
                   [AgMultiple]="true"
                   [AgCloseOnSelect]="false"
                   [AgOptions]="getCamposObrigatoriosVisiveis()"
                   (ngModelChange)="selectAllCamposObrigatorios($event)"
                   (change)="selectAllCamposObrigatorios($event)"
                   AgSelectType="checkbox">
              </app-ag-ng-select>

            </div>
          </div>


        </div>
      </div>

      <!-- Endereço -->
      <!-- <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0" >
            <div class="col-lg-12 reset-padding-mobile">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Endereço de Atendimento </h3>
                  <p class="p-0 m-0 text-cor-padrao text-start w-100" *ngIf="agenda.enderecos.length == 0">Escolha um endereço</p>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#addEndereco" (click)="checkEndereco()" class="ag-icon-click">
                  <i class="bi bi-plus-circle-fill"></i>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="agenda.enderecos.length > 0" class="row">
            <div *ngFor="let end of agenda.enderecos; let i = index" class="col-lg-6 reset-padding-mobile">
              <div class="box-gen-card-list ">
                <p class="p-0 m-0"><span class="ag-pr-15">CEP:</span> {{ end.cep.valor }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Logradouro:</span> {{ end.logradouro.valor }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Número:</span> {{ end.numero.valor }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Bairro:</span> {{ end.bairro.valor }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Cidade:</span> {{ end.localidade.valor }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Estado:</span> {{ end.uf.valor }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Complemento:</span> {{ end.complemento.valor }}</p>
              </div>
            </div>
          </div>

        </div>
      </div> -->

      <!-- Serviço -->
      <div class="min-width col-lg-12 pb-3" *ngIf="!loading">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0" >
            <div class="col-lg-12 reset-padding-mobile">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Serviços </h3>
                <p class="p-0 m-0 text-cor-padrao text-start w-100" *ngIf="agenda.servicos.length == 0">Escolha um serviço</p>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#addServico" (click)="checkServico()" class="ag-icon-click">
                  <i class="bi bi-plus-circle-fill"></i>
                </div>
              </div>
            </div>
          </div>


          <div *ngIf="agenda.servicos.length > 0"  class="row">
            <div *ngFor="let serv of agenda.servicos; let i = index" class="col-lg-6 reset-padding-mobile">
              <div class="box-gen-card-list">
                <p class="p-0 m-0"><span class="ag-pr-15">Nome:</span> {{ serv.nome.valor }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Valor:</span> {{ serv.valor.valor }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Mostrar valor do serviço para os clientes:</span> {{ serv.mostrarValorParaClientes.valor ? "Sim" : "Não" }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Link de pagamento:</span> {{ serv.linkPagamento.valor }}</p>
                <p class="p-0 m-0"><span class="ag-pr-15">Duração do atendimento:</span> {{ serv.duracaoAtendimento.valor }}</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- Horários de Atendimento -->
      <div class="min-width col-lg-12 pb-3" *ngIf="!loading">
        <div class="p-0 m-0 box-gen-card">

          <div class="row p-0 m-0" >
            <div class="col-lg-12 reset-padding-mobile">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Horários de Atendimento </h3>
                  <p class="p-0 m-0 text-cor-padrao text-start w-100">Configure os horários de atendimento. </p>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#addHorario" class="ag-icon-click">
                  <i class="bi bi-lightning-fill"></i>
                </div>
              </div>
            </div>
          </div>

          <hr class="hr-padrao">

          <!-- horario.dia -->
          <div class="row p-0 m-0">
            <div *ngFor="let horarios of agenda.horarios; let i = index" class="col-lg-12 hover-border-secundaria box-gen-card-list m-0 ag-mb-15">

              <div class="d-flex align-items-center justify-content-between">
                <div class="w-100 accordion-button" style="background-color: var(--linear-gradient-0deg);cursor: pointer;padding: 5px 15px 4px 0px;box-shadow: none;" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + horarios.id" [attr.aria-expanded]="false" [attr.aria-controls]="'collapse_' + horarios.id">
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500;">
                    <i class="bi bi-clock"></i>
                    {{horarios.dia.title}}
                    <span class="ag-tag-label">{{horarios.horariosDia.length == 0 ? "Sem horários" : horarios.horariosDia.length == 1 ? horarios.horariosDia.length + " Horário" : horarios.horariosDia.length + " Horários"}}</span>
                  </h3>


                </div>
                <div data-bs-toggle="modal" data-bs-target="#addHorarioUnico" (click)="selectHorario(horarios.id)" class="ag-icon-click">
                  <i class="bi bi-plus-circle-fill"></i>
                </div>
              </div>

              <div [id]="'collapse_'+horarios.id" class="accordion-collapse collapse show">
                <div class="d-flex flex-wrap flex-column justify-content-center">
                  <div *ngFor="let hr of horarios.horariosDia" class="row">
                    <div class="row col-lg-12 p-0 m-0 ag-pt-10 ag-mt-5 text-cor-padrao" style="border-top: var(--border-padrao);position: relative;">
                      <!-- hora_inicial -->
                      <div class="col-lg-3 text-left d-flex flex-column flex-wrap justify-content-center">
                        <app-ag-input-field
                          [(ngModel)]="hr.hora_inicial.valor"
                          [label]="hr.hora_inicial.title"
                          [tooltip]="hr.hora_inicial.info"
                          [value]="hr.hora_inicial.valor"
                          [isInvalid]="hr.hora_inicial.status?.invalid"
                          [AgStatus]="hr.hora_inicial.status"
                          [id]="'hora_inicial_'+hr.id"
                          AgPlaceholder="{{hr.hora_inicial.placeholder}}"
                          AgInputType="{{hr.hora_inicial.inputType}}"
                          AgMaxlength="{{hr.hora_inicial.maxlength}}"
                          AgInputMode="{{hr.hora_inicial.inputMode}}"
                          (ngModelChange)="agenda.mascara(hr.hora_inicial.mascara, 'hora_inicial');"
                          (change)="agenda.validarInput('hora_inicial');"
                          >
                        </app-ag-input-field>
                      </div>

                      <!-- hora_final -->
                      <div class="col-lg-3 text-left d-flex flex-column flex-wrap justify-content-center">
                        <app-ag-input-field
                          [(ngModel)]="hr.hora_final.valor"
                          [label]="hr.hora_final.title"
                          [tooltip]="hr.hora_final.info"
                          [value]="hr.hora_final.valor"
                          [isInvalid]="hr.hora_final.status?.invalid"
                          [AgStatus]="hr.hora_final.status"
                          [id]="'hora_final_'+hr.id"
                          AgPlaceholder="{{hr.hora_final.placeholder}}"
                          AgInputType="{{hr.hora_final.inputType}}"
                          AgMaxlength="{{hr.hora_final.maxlength}}"
                          AgInputMode="{{hr.hora_final.inputMode}}"
                          (ngModelChange)="agenda.mascara(hr.hora_final.mascara, 'hora_final');"
                          (change)="agenda.validarInput('hora_final');"
                          >
                        </app-ag-input-field>
                      </div>

                      <!-- num_pes_horario -->
                      <div class="col-lg-3 text-left d-flex flex-column flex-wrap justify-content-center">
                        <app-ag-input-field
                          [(ngModel)]="hr.num_pes_horario.valor"
                          [label]="hr.num_pes_horario.title"
                          [tooltip]="hr.num_pes_horario.info"
                          [value]="hr.num_pes_horario.valor"
                          [isInvalid]="hr.num_pes_horario.status?.invalid"
                          [AgStatus]="hr.num_pes_horario.status"
                          [id]="'num_pes_horario_'+hr.id"
                          AgPlaceholder="{{hr.num_pes_horario.placeholder}}"
                          AgInputType="{{hr.num_pes_horario.inputType}}"
                          AgMaxlength="{{hr.num_pes_horario.maxlength}}"
                          AgInputMode="{{hr.num_pes_horario.inputMode}}"
                          (ngModelChange)="agenda.mascara(hr.num_pes_horario.mascara, 'num_pes_horario');"
                          (change)="agenda.validarInput('num_pes_horario');"
                          >
                        </app-ag-input-field>
                      </div>

                      <!-- Ação / Excluir horário -->
                      <div class="col-lg-3 text-left d-flex flex-column flex-wrap justify-content-center">

                        <div class="form-label label-input-text-padrao d-flex flex-wrap justify-content-center h-100 align-items-center flex-column">
                          <button type="button" class="btn btn-outline-danger" style="display: flex;justify-content: space-around;align-items: center;" (click)="deletarHorario(hr.id)">
                            <i class="bi bi-trash3-fill"></i> Excluir
                          </button>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3" *ngIf="!loading">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <app-ag-mat-checkbox
                [(ngModel)]="agenda.status.valor"
                [label]="agenda.status.title"
                [tooltip]="agenda.status.info"
                [value]="agenda.status.valor"
                [id]="'ativa_agenda'"
                >
          </app-ag-mat-checkbox>

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="salvarAgenda()">Salvar</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('agendas')" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>

    </div>
    <!-- Editar Agenda 👆 -->

    <!-- 👇 Links Relacionados -->
    <div class="row p-0 m-0" *ngIf="!loading">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg  text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div>
    <!-- Links Relacionados 👆 -->

  </div>
</div>

<!-- Adicionar Listar Endereço Modal -->
<!-- <div class="modal" id="addEndereco" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">


      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Endereço</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>


      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;" *ngIf="listaEndereco.length == 0">
          <h4 class="modal-title p-3 m-0">Nenhum endereço disponível</h4>
        </div>

        <div *ngIf="listaEndereco.length > 0" style=" padding: 15px; ">
          <div *ngFor="let end of listaEndereco; let i = index;" class="gen-card mt-3">
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">CEP:</span> {{ end.cep.valor }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Logradouro:</span> {{ end.logradouro.valor }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Número:</span> {{ end.numero.valor }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Complemento:</span> {{ end.complemento.valor }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Bairro:</span> {{ end.bairro.valor }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Cidade:</span> {{ end.localidade.valor }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Estado:</span> {{ end.uf.valor }}</p>

            <div class="w-100 m-0 p-0 pt-2" style="text-align: left; display: flex; align-items: center; ">
              <mat-slide-toggle [(ngModel)]="isChecked[i]" (change)="vincularEndereco($event, end.id)"> Vincular endereço </mat-slide-toggle>
              <span mat-raised-button #vincularEnderecoT="matTooltip" matTooltip="Vincular endereço" matTooltipPosition="right" (click)="vincularEnderecoT.show()" class="tooltip-input-icon">
                <i class="bi bi-info-circle-fill"></i>
              </span>
            </div>
          </div>
        </div>
      </div>


      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#newEndereco">Novo Endereço</button>
      </div>

    </div>
  </div>
</div> -->

<!-- Modal Novo Endereço -->
<!-- <div class="modal" id="newEndereco" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl modal-dialog-scrollable">
    <div class="modal-content">


      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Novo Endereço</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>


      <div class="modal-body p-0 m-0" style=" padding: 5px !important; ">

        <div class="row p-0 m-0">

          <div class="col-lg-12 reset-padding-mobile">
            <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
              <app-ag-input-field class="w-100"
                [(ngModel)]="endereco.cep.valor"
                [label]="endereco.cep.title"
                [tooltip]="endereco.cep.info"
                [value]="endereco.cep.valor"
                [isInvalid]="endereco.cep.status?.invalid"
                [AgStatus]="endereco.cep.status"
                [id]="'cep_busca'"
                AgPlaceholder="{{endereco.cep.placeholder}}"
                AgInputType="{{endereco.cep.inputType}}"
                AgMaxlength="{{endereco.cep.maxlength}}"
                AgInputMode="{{endereco.cep.inputMode}}"
                (ngModelChange)="endereco.mascara(endereco.cep.mascara, 'cep');"
                (change)="endereco.validarInput('cep');"
                >
              </app-ag-input-field>
              <button type="button" class="btn btn-secondary" (click)="buscarCep()" style="height: 45.5px;position: relative;top: 4px;border-radius: 0px 5px 5px 0px !important;">Buscar</button>
            </div>
          </div>


          <div class="col-lg-12 reset-padding-mobile">
            <app-ag-input-field
              [(ngModel)]="endereco.logradouro.valor"
              [label]="endereco.logradouro.title"
              [tooltip]="endereco.logradouro.info"
              [value]="endereco.logradouro.valor"
              [isInvalid]="endereco.logradouro.status?.invalid"
              [AgStatus]="endereco.logradouro.status"
              [id]="'new_endereco_logradouro'"
              AgPlaceholder="{{endereco.logradouro.placeholder}}"
              AgInputType="{{endereco.logradouro.inputType}}"
              AgMaxlength="{{endereco.logradouro.maxlength}}"
              AgInputMode="{{endereco.logradouro.inputMode}}"
              (ngModelChange)="endereco.mascara(endereco.logradouro.mascara, 'logradouro');"
              (change)="endereco.validarInput('logradouro');"
              >
            </app-ag-input-field>
          </div>


          <div class="col-lg-6 reset-padding-mobile">
            <app-ag-input-field
              [(ngModel)]="endereco.numero.valor"
              [label]="endereco.numero.title"
              [tooltip]="endereco.numero.info"
              [value]="endereco.numero.valor"
              [isInvalid]="endereco.numero.status?.invalid"
              [AgStatus]="endereco.numero.status"
              [id]="'new_endereco_numero'"
              AgPlaceholder="{{endereco.numero.placeholder}}"
              AgInputType="{{endereco.numero.inputType}}"
              AgMaxlength="{{endereco.numero.maxlength}}"
              AgInputMode="{{endereco.numero.inputMode}}"
              (ngModelChange)="endereco.mascara(endereco.numero.mascara, 'numero');"
              (change)="endereco.validarInput('numero');"
              >
            </app-ag-input-field>
          </div>


          <div class="col-lg-6 reset-padding-mobile">
            <app-ag-input-field
              [(ngModel)]="endereco.bairro.valor"
              [label]="endereco.bairro.title"
              [tooltip]="endereco.bairro.info"
              [value]="endereco.bairro.valor"
              [isInvalid]="endereco.bairro.status?.invalid"
              [AgStatus]="endereco.bairro.status"
              [id]="'new_endereco_bairro'"
              AgPlaceholder="{{endereco.bairro.placeholder}}"
              AgInputType="{{endereco.bairro.inputType}}"
              AgMaxlength="{{endereco.bairro.maxlength}}"
              AgInputMode="{{endereco.bairro.inputMode}}"
              (ngModelChange)="endereco.mascara(endereco.bairro.mascara, 'bairro');"
              (change)="endereco.validarInput('bairro');"
              >
            </app-ag-input-field>
          </div>


          <div class="col-lg-6 reset-padding-mobile">
            <app-ag-input-field
              [(ngModel)]="endereco.localidade.valor"
              [label]="endereco.localidade.title"
              [tooltip]="endereco.localidade.info"
              [value]="endereco.localidade.valor"
              [isInvalid]="endereco.localidade.status?.invalid"
              [AgStatus]="endereco.localidade.status"
              [id]="'new_endereco_localidade'"
              AgPlaceholder="{{endereco.localidade.placeholder}}"
              AgInputType="{{endereco.localidade.inputType}}"
              AgMaxlength="{{endereco.localidade.maxlength}}"
              AgInputMode="{{endereco.localidade.inputMode}}"
              (ngModelChange)="endereco.mascara(endereco.localidade.mascara, 'localidade');"
              (change)="endereco.validarInput('localidade');"
              >
            </app-ag-input-field>
          </div>


          <div class="col-lg-6 reset-padding-mobile">
            <app-ag-input-field
              [(ngModel)]="endereco.uf.valor"
              [label]="endereco.uf.title"
              [tooltip]="endereco.uf.info"
              [value]="endereco.uf.valor"
              [isInvalid]="endereco.uf.status?.invalid"
              [AgStatus]="endereco.uf.status"
              [id]="'new_endereco_uf'"
              AgPlaceholder="{{endereco.uf.placeholder}}"
              AgInputType="{{endereco.uf.inputType}}"
              AgMaxlength="{{endereco.uf.maxlength}}"
              AgInputMode="{{endereco.uf.inputMode}}"
              (ngModelChange)="endereco.mascara(endereco.uf.mascara, 'uf');"
              (change)="endereco.validarInput('uf');"
              >
            </app-ag-input-field>
          </div>


          <div class="col-lg-12 reset-padding-mobile">
            <app-ag-textarea-field
              [(ngModel)]="endereco.complemento.valor"
              [label]="endereco.complemento.title"
              [tooltip]="endereco.complemento.info"
              [value]="endereco.complemento.valor"
              [isInvalid]="endereco.complemento.status?.invalid"
              [AgStatus]="endereco.complemento.status"
              [id]="'new_endereco_complemento'"
              AgPlaceholder="{{endereco.complemento.placeholder}}"
              AgInputType="{{endereco.complemento.inputType}}"
              AgMaxlength="{{endereco.complemento.maxlength}}"
              AgInputMode="{{endereco.complemento.inputMode}}"
              (ngModelChange)="endereco.mascara(endereco.complemento.mascara, 'complemento');"
              (change)="endereco.validarInput('complemento');"
              >
            </app-ag-textarea-field>
          </div>

        </div>

      </div>


      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#addEndereco">Voltar</button>
        <button type="button" class="btn btn-primary text-cor-branco" data-bs-dismiss="modal" (click)="addEndereco()">Adicionar Endereço</button>
      </div>

    </div>
  </div>
</div> -->

<!-- Adicionar Listar Serviço Modal -->
<div class="modal" id="addServico" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Serviço</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;" *ngIf="listaServico.length == 0">
          <h4 class="modal-title p-3 m-0">Nenhum serviço disponível</h4>
        </div>

        <div *ngIf="listaServico.length > 0" style=" padding: 15px; ">
          <div *ngFor="let serv of listaServico; let i = index" class="gen-card mt-3">

            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Nome:</span> {{ serv.nome.valor }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Valor:</span> {{ serv.valor.valor }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Mostrar valor do serviço para os clientes:</span> {{ serv.mostrarValorParaClientes.valor ? "Sim" : "Não"  }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Link de pagamento:</span> {{ serv.linkPagamento.valor }}</p>
            <p class="p-0 m-0"><span style=" padding-right: 5px; ">Duração do atendimento:</span> {{ serv.duracaoAtendimento.valor }}</p>
            <!-- <p class="p-0 m-0"><span>Número de pessoas que podem agendar um mesmo horário</span>: {{ serv.numeroPessoasPorHorario }}</p> -->
            <!-- <p class="p-0 m-0"><span>Instruções</span>: {{ serv.instrucoesConfirmacao }}</p> -->
            <!-- <p class="p-0 m-0"><span>Vincular serviço em todas as agendas</span>: {{ serv.vincularEmTodasAgendas }}</p> -->

            <div class="w-100 m-0 p-0 pt-2" style="text-align: left; display: flex; align-items: center; ">
              <mat-slide-toggle [(ngModel)]="isChecked[i]" (change)="vincularServico($event, serv.id)"> Vincular serviço </mat-slide-toggle>
              <span mat-raised-button #vincularServicoT="matTooltip" matTooltip="Vincular serviço" matTooltipPosition="right" (click)="vincularServicoT.show()" class="tooltip-input-icon">
                <i class="bi bi-info-circle-fill"></i>
              </span>
            </div>
          </div>
        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#newServico">Novo Serviço</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>

<!-- Novo Serviço Modal -->
<div class="modal" id="newServico" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Novo Serviço</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" style="padding: 5px !important;">

        <div class="w-100 mb-0 mt-2" style="text-align: left; ">

          <div class="row p-0 m-0">
            <!-- servico.nome -->
            <div class="col-lg-12">
              <app-ag-input-field
                [(ngModel)]="servico.nome.valor"
                [label]="servico.nome.title"
                [tooltip]="servico.nome.info"
                [value]="servico.nome.valor"
                [isInvalid]="servico.nome.status?.invalid"
                [AgStatus]="servico.nome.status"
                [id]="'new_servico_nome'"
                AgPlaceholder="{{servico.nome.placeholder}}"
                AgInputType="{{servico.nome.inputType}}"
                AgMaxlength="{{servico.nome.maxlength}}"
                AgInputMode="{{servico.nome.inputMode}}"
                (ngModelChange)="servico.mascara(servico.nome.mascara, 'nome');"
                (change)="servico.validarInput('nome');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.valor -->
            <div class="col-lg-12">
              <app-ag-input-field
                [(ngModel)]="servico.valor.valor"
                [label]="servico.valor.title"
                [tooltip]="servico.valor.info"
                [value]="servico.valor.valor"
                [isInvalid]="servico.valor.status?.invalid"
                [AgStatus]="servico.valor.status"
                [id]="'new_servico_valor'"
                AgPlaceholder="{{servico.valor.placeholder}}"
                AgInputType="{{servico.valor.inputType}}"
                AgMaxlength="{{servico.valor.maxlength}}"
                AgInputMode="{{servico.valor.inputMode}}"
                (ngModelChange)="servico.mascara(servico.valor.mascara, 'valor');"
                (change)="servico.validarInput('valor');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.linkPagamento -->
            <div class="col-lg-12">
              <app-ag-input-field
                [(ngModel)]="servico.linkPagamento.valor"
                [label]="servico.linkPagamento.title"
                [tooltip]="servico.linkPagamento.info"
                [value]="servico.linkPagamento.valor"
                [isInvalid]="servico.linkPagamento.status?.invalid"
                [AgStatus]="servico.linkPagamento.status"
                [id]="'new_servico_linkPagamento'"
                AgPlaceholder="{{servico.linkPagamento.placeholder}}"
                AgInputType="{{servico.linkPagamento.inputType}}"
                AgMaxlength="{{servico.linkPagamento.maxlength}}"
                AgInputMode="{{servico.linkPagamento.inputMode}}"
                (ngModelChange)="servico.mascara(servico.linkPagamento.mascara, 'linkPagamento');"
                (change)="servico.validarInput('linkPagamento');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.duracaoAtendimento -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="servico.duracaoAtendimento.valor"
                [label]="servico.duracaoAtendimento.title"
                [tooltip]="servico.duracaoAtendimento.info"
                [value]="servico.duracaoAtendimento.valor"
                [isInvalid]="servico.duracaoAtendimento.status?.invalid"
                [AgStatus]="servico.duracaoAtendimento.status"
                [id]="'new_servico_duracaoAtendimento'"
                AgPlaceholder="{{servico.duracaoAtendimento.placeholder}}"
                AgInputType="{{servico.duracaoAtendimento.inputType}}"
                AgMaxlength="{{servico.duracaoAtendimento.maxlength}}"
                AgInputMode="{{servico.duracaoAtendimento.inputMode}}"
                (ngModelChange)="servico.mascara(servico.duracaoAtendimento.mascara, 'duracaoAtendimento');"
                (change)="servico.validarInput('duracaoAtendimento');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.numeroPessoasPorHorario -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="servico.numeroPessoasPorHorario.valor"
                [label]="servico.numeroPessoasPorHorario.title"
                [tooltip]="servico.numeroPessoasPorHorario.info"
                [value]="servico.numeroPessoasPorHorario.valor"
                [isInvalid]="servico.numeroPessoasPorHorario.status?.invalid"
                [AgStatus]="servico.numeroPessoasPorHorario.status"
                [id]="'new_servico_numeroPessoasPorHorario'"
                AgPlaceholder="{{servico.numeroPessoasPorHorario.placeholder}}"
                AgInputType="{{servico.numeroPessoasPorHorario.inputType}}"
                AgMaxlength="{{servico.numeroPessoasPorHorario.maxlength}}"
                AgInputMode="{{servico.numeroPessoasPorHorario.inputMode}}"
                (ngModelChange)="servico.mascara(servico.numeroPessoasPorHorario.mascara, 'numeroPessoasPorHorario');"
                (change)="servico.validarInput('numeroPessoasPorHorario');"
                >
              </app-ag-input-field>
            </div>

            <!-- servico.instrucoesConfirmacao -->
            <div class="col-lg-12">
              <app-ag-textarea-field
                [(ngModel)]="servico.instrucoesConfirmacao.valor"
                [label]="servico.instrucoesConfirmacao.title"
                [tooltip]="servico.instrucoesConfirmacao.info"
                [value]="servico.instrucoesConfirmacao.valor"
                [isInvalid]="servico.instrucoesConfirmacao.status?.invalid"
                [AgStatus]="servico.instrucoesConfirmacao.status"
                [id]="'new_servico_instrucoesConfirmacao'"
                AgPlaceholder="{{servico.instrucoesConfirmacao.placeholder}}"
                AgInputType="{{servico.instrucoesConfirmacao.inputType}}"
                AgMaxlength="{{servico.instrucoesConfirmacao.maxlength}}"
                AgInputMode="{{servico.instrucoesConfirmacao.inputMode}}"
                (ngModelChange)="servico.mascara(servico.instrucoesConfirmacao.mascara, 'instrucoesConfirmacao');"
                (change)="servico.validarInput('instrucoesConfirmacao');"
                >
              </app-ag-textarea-field>
            </div>

            <!-- servico.mostrarValorParaClientes -->
            <div class="col-lg-6">
              <app-ag-mat-checkbox
                [(ngModel)]="servico.mostrarValorParaClientes.valor"
                [label]="servico.mostrarValorParaClientes.title"
                [tooltip]="servico.mostrarValorParaClientes.info"
                [value]="servico.mostrarValorParaClientes.valor"
                [id]="'new_servico_mostrar_valor_para_cliente'"
                >
              </app-ag-mat-checkbox>
            </div>

            <!-- servico.vincularEmTodasAgendas -->
            <div class="col-lg-6">
              <app-ag-mat-checkbox
                [(ngModel)]="servico.vincularEmTodasAgendas.valor"
                [label]="servico.vincularEmTodasAgendas.title"
                [tooltip]="servico.vincularEmTodasAgendas.info"
                [value]="servico.vincularEmTodasAgendas.valor"
                [id]="'new_servico_vincular_em_todas_agendas'"
                >
              </app-ag-mat-checkbox>
            </div>

            <!-- servico.status -->
            <div class="col-lg-6">
              <app-ag-mat-checkbox
                [(ngModel)]="servico.status.valor"
                [label]="servico.status.title"
                [tooltip]="servico.status.info"
                [value]="servico.status.valor"
                [id]="'new_servico_status'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#addServico">Voltar</button>
        <button type="button" class="btn btn-primary text-cor-branco" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#addServico" (click)="addServico()">Adicionar Serviço</button>
      </div>

    </div>
  </div>
</div>

<!-- Adicionar Horário Modal -->
<div class="modal" id="addHorario" #addHorarioModal  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Horário</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >

        <div class="ag-pt-10 ag-pb-0 ag-pl-10 ag-pr-10">
          <app-ag-ng-select [label]="'Selecione os dias da semana'"
            [(ngModel)]="diasSelecionados"
            [id]="'diasSelecionados'"
            [tooltip]="'Dias da semana...'"
            [AgPlaceholder]="'Dias da semana...'"
            [AgMultiple]="true"
            [AgCloseOnSelect]="closeOnSelect"
            [AgOptions]="diasSemanaArray"
            (ngModelChange)="onDiasSelecionadosChange()"
            (change)="onDiasSelecionadosChange()"
            AgSelectType="diasSemana"
            [AgAppendTo]="'#addHorario'">
          </app-ag-ng-select>
        </div>

        <hr class="hr-padrao">

        <div class="ag-pt-0 ag-pb-15 ag-pl-15 ag-pr-15">
          <!-- diaDaSemanaHorario.hora_inicial -->
          <app-ag-input-field
            [(ngModel)]="diaDaSemanaHorario.hora_inicial.valor"
            [label]="diaDaSemanaHorario.hora_inicial.title"
            [tooltip]="diaDaSemanaHorario.hora_inicial.info"
            [value]="diaDaSemanaHorario.hora_inicial.valor"
            [isInvalid]="diaDaSemanaHorario.hora_inicial.status?.invalid"
            [AgStatus]="diaDaSemanaHorario.hora_inicial.status"
            [id]="'hora_inicial'"
            AgPlaceholder="{{diaDaSemanaHorario.hora_inicial.placeholder}}"
            AgInputType="{{diaDaSemanaHorario.hora_inicial.inputType}}"
            AgMaxlength="{{diaDaSemanaHorario.hora_inicial.maxlength}}"
            AgInputMode="{{diaDaSemanaHorario.hora_inicial.inputMode}}"
            (ngModelChange)="agenda.mascara(diaDaSemanaHorario.hora_inicial.mascara, 'hora_inicial');"
            (change)="agenda.validarInput('hora_inicial');"
            >
          </app-ag-input-field>

           <!-- diaDaSemanaHorario.hora_final -->
           <app-ag-input-field
            [(ngModel)]="diaDaSemanaHorario.hora_final.valor"
            [label]="diaDaSemanaHorario.hora_final.title"
            [tooltip]="diaDaSemanaHorario.hora_final.info"
            [value]="diaDaSemanaHorario.hora_final.valor"
            [isInvalid]="diaDaSemanaHorario.hora_final.status?.invalid"
            [AgStatus]="diaDaSemanaHorario.hora_final.status"
            [id]="'hora_final'"
            AgPlaceholder="{{diaDaSemanaHorario.hora_final.placeholder}}"
            AgInputType="{{diaDaSemanaHorario.hora_final.inputType}}"
            AgMaxlength="{{diaDaSemanaHorario.hora_final.maxlength}}"
            AgInputMode="{{diaDaSemanaHorario.hora_final.inputMode}}"
            (ngModelChange)="agenda.mascara(diaDaSemanaHorario.hora_final.mascara, 'hora_final');"
            (change)="agenda.validarInput('hora_final');"
            >
          </app-ag-input-field>

           <!-- diaDaSemanaHorario.num_pes_horario -->
           <app-ag-input-field
            [(ngModel)]="diaDaSemanaHorario.num_pes_horario.valor"
            [label]="diaDaSemanaHorario.num_pes_horario.title"
            [tooltip]="diaDaSemanaHorario.num_pes_horario.info"
            [value]="diaDaSemanaHorario.num_pes_horario.valor"
            [isInvalid]="diaDaSemanaHorario.num_pes_horario.status?.invalid"
            [AgStatus]="diaDaSemanaHorario.num_pes_horario.status"
            [id]="'num_pes_horario'"
            AgPlaceholder="{{diaDaSemanaHorario.num_pes_horario.placeholder}}"
            AgInputType="{{diaDaSemanaHorario.num_pes_horario.inputType}}"
            AgMaxlength="{{diaDaSemanaHorario.num_pes_horario.maxlength}}"
            AgInputMode="{{diaDaSemanaHorario.num_pes_horario.inputMode}}"
            (ngModelChange)="agenda.mascara(diaDaSemanaHorario.num_pes_horario.mascara, 'num_pes_horario');"
            (change)="agenda.validarInput('num_pes_horario');"
            >
          </app-ag-input-field>

        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="adicionarHorarios()">Salvar</button>
      </div>

    </div>
  </div>
</div>

<!-- Adicionar Horário Único Modal -->
<div class="modal" id="addHorarioUnico" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">{{addHorarioUnico.dia.title}}</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >

        <div style=" padding: 10px 15px !important; ">
          <!-- addHorarios.hora_inicial -->
          <app-ag-input-field
            [(ngModel)]="addHorarios.hora_inicial.valor"
            [label]="addHorarios.hora_inicial.title"
            [tooltip]="addHorarios.hora_inicial.info"
            [value]="addHorarios.hora_inicial.valor"
            [isInvalid]="addHorarios.hora_inicial.status?.invalid"
            [AgStatus]="addHorarios.hora_inicial.status"
            [id]="'hora_inicial'"
            AgPlaceholder="{{addHorarios.hora_inicial.placeholder}}"
            AgInputType="{{addHorarios.hora_inicial.inputType}}"
            AgMaxlength="{{addHorarios.hora_inicial.maxlength}}"
            AgInputMode="{{addHorarios.hora_inicial.inputMode}}"
            (ngModelChange)="agenda.mascara(addHorarios.hora_inicial.mascara, 'hora_inicial');"
            (change)="agenda.validarInput('hora_inicial');"
            >
          </app-ag-input-field>

          <!-- addHorarios.hora_final -->
          <app-ag-input-field
            [(ngModel)]="addHorarios.hora_final.valor"
            [label]="addHorarios.hora_final.title"
            [tooltip]="addHorarios.hora_final.info"
            [value]="addHorarios.hora_final.valor"
            [isInvalid]="addHorarios.hora_final.status?.invalid"
            [AgStatus]="addHorarios.hora_final.status"
            [id]="'hora_final'"
            AgPlaceholder="{{addHorarios.hora_final.placeholder}}"
            AgInputType="{{addHorarios.hora_final.inputType}}"
            AgMaxlength="{{addHorarios.hora_final.maxlength}}"
            AgInputMode="{{addHorarios.hora_final.inputMode}}"
            (ngModelChange)="agenda.mascara(addHorarios.hora_final.mascara, 'hora_final');"
            (change)="agenda.validarInput('hora_final');"
            >
          </app-ag-input-field>

          <!-- addHorarios.num_pes_horario -->
          <app-ag-input-field
            [(ngModel)]="addHorarios.num_pes_horario.valor"
            [label]="addHorarios.num_pes_horario.title"
            [tooltip]="addHorarios.num_pes_horario.info"
            [value]="addHorarios.num_pes_horario.valor"
            [isInvalid]="addHorarios.num_pes_horario.status?.invalid"
            [AgStatus]="addHorarios.num_pes_horario.status"
            [id]="'num_pes_horario'"
            AgPlaceholder="{{addHorarios.num_pes_horario.placeholder}}"
            AgInputType="{{addHorarios.num_pes_horario.inputType}}"
            AgMaxlength="{{addHorarios.num_pes_horario.maxlength}}"
            AgInputMode="{{addHorarios.num_pes_horario.inputMode}}"
            (ngModelChange)="agenda.mascara(addHorarios.num_pes_horario.mascara, 'num_pes_horario');"
            (change)="agenda.validarInput('num_pes_horario');"
            >
          </app-ag-input-field>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="adicionarHorario()">Adicionar</button>
      </div>

    </div>
  </div>
</div>

<!-- alert Modal -->
<div class="modal" id="alertModal" #alertModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>



      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="agenda.nome.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agenda.nome.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agenda.nome.info }}</p>
          </div>

          <div *ngIf="agenda.duracaoAtendimento.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agenda.duracaoAtendimento.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agenda.duracaoAtendimento.info }}</p>
          </div>

          <div *ngIf="agenda.antecedenciaMinimaHoras.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ agenda.antecedenciaMinimaHoras.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agenda.antecedenciaMinimaHoras.info }}</p>
          </div>

          <div *ngIf="agenda.antecedenciaMaximaDias.status?.invalid" class="border-danger gen-card">
            <p style="padding: 0;margin: 0;font-weight: 500;"  class="text-cor-padrao">{{ agenda.antecedenciaMaximaDias.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ agenda.antecedenciaMaximaDias.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>

<!-- alert Endereço Modal -->
<!-- <div class="modal" id="alertEnderecoModal" #alertEnderecoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">


      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="endereco.cep.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ endereco.cep.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ endereco.cep.info }}</p>
          </div>

          <div *ngIf="endereco.logradouro.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ endereco.logradouro.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ endereco.logradouro.info }}</p>
          </div>

          <div *ngIf="endereco.numero.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ endereco.numero.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ endereco.numero.info }}</p>
          </div>

          <div *ngIf="endereco.complemento.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ endereco.complemento.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ endereco.complemento.info }}</p>
          </div>

          <div *ngIf="endereco.bairro.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ endereco.bairro.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ endereco.bairro.info }}</p>
          </div>

          <div *ngIf="endereco.localidade.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ endereco.localidade.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ endereco.localidade.info }}</p>
          </div>

          <div *ngIf="endereco.uf.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ endereco.uf.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ endereco.uf.info }}</p>
          </div>

        </div>

      </div>


      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#newEndereco">Ok</button>

      </div>

    </div>
  </div>
</div> -->

<!-- alert Serviço Modal -->
<div class="modal" id="alertServicoModal" #alertServicoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="servico.nome.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.nome.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.nome.info }}</p>
          </div>

          <div *ngIf="servico.valor.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.valor.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.valor.info }}</p>
          </div>

          <div *ngIf="servico.mostrarValorParaClientes.status" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.mostrarValorParaClientes.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.mostrarValorParaClientes.info }}</p>
          </div>

          <div *ngIf="servico.linkPagamento.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.linkPagamento.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.linkPagamento.info }}</p>
          </div>

          <div *ngIf="servico.duracaoAtendimento.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.duracaoAtendimento.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.duracaoAtendimento.info }}</p>
          </div>

          <div *ngIf="servico.numeroPessoasPorHorario.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.numeroPessoasPorHorario.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.numeroPessoasPorHorario.info }}</p>
          </div>

          <div *ngIf="servico.instrucoesConfirmacao.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.instrucoesConfirmacao.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.instrucoesConfirmacao.info }}</p>
          </div>

          <div *ngIf="servico.vincularEmTodasAgendas?.status" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ servico.vincularEmTodasAgendas.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ servico.vincularEmTodasAgendas.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#newServico">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>
