import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subject } from 'rxjs';
import { takeUntil, timeout } from 'rxjs/operators';
import { Conta } from 'src/class/conta';
import { UsuarioEmailSenha } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { ContaService } from 'src/services/component/conta.service';
import { SiglaService } from 'src/services/siglaService.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  usr = new UsuarioEmailSenha();
  log = false;
  return: any;
  loading: boolean = false;
  loader = this.loadingBar.useRef('http');
  retornoConta: any = [];
  _disabled = false;

  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loadingBar: LoadingBarService,
    private route: ActivatedRoute,
    private conta: ContaService,
    private siglaService: SiglaService
  ) {
    // Alimenta o serviço com a empresaSigla
    this.route.params
    .pipe(takeUntil(this.destroy$))
    .subscribe(params => {
      this.empresaSigla = params['empresaSigla'];
      this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
    });
  }

  ngOnInit() {
    // console.log(this.usr)
    this.verificarSiglaEmpresa();
    // console.log(this.empresaSigla)
    this.authService.usuarioLogado(this.empresaSigla);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.loader.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  verificarSiglaEmpresa() {

    if(this.empresaSigla) {
      this.loading = true;
      this.loader.start();

      this.conta.o_getSiglaConta('contas', this.empresaSigla)
      .pipe(takeUntil(this.destroy$))
      .subscribe((dados) => {
        // console.log('Dados contas:', dados);
        this.retornoConta = dados;
        this.loading = false;
        this.loader.complete();
      }, (erro) => {
        // console.log('Erro ao obter dados:', erro);
        this._disabled = true;
        this.loading = false;
        this.loader.complete();
        this.log = true;

        const ngxBarElement = document.querySelector('.ngx-bar');
        ngxBarElement?.classList.add('error-ngx-bar');
        this.return = `Sinto muito, não conseguimos encontrar a conta associada à sigla <strong>'${this.empresaSigla}'</strong>. Certifique-se de que o nome inserido na URL está correto.`;
      });

      // // Define um limite de tempo de 30 segundos
      // const timeout = setTimeout(() => {
      //   // Se a chamada não for concluída, emita um erro
      //   // console.log('Tempo limite excedido ao buscar a conta.');
      //   this._disabled = true;
      //   this.loading = false;
      //   this.loader.complete();
      //   this.log = true;

      //   const ngxBarElement = document.querySelector('.ngx-bar');
      //   ngxBarElement?.classList.add('error-ngx-bar');
      //   this.return = `Sinto muito, não conseguimos encontrar a conta associada à sigla <strong>'${this.empresaSigla}'</strong>. Certifique-se de que o nome inserido na URL está correto.`;
      // }, 30000); // 30 segundos

      // this.conta.getSiglaConta(this.empresaSigla)
      // .subscribe(
      //   (conta: Conta[]) => {
      //     console.log(conta);
      //     if (conta.length > 0) {
      //       this.retornoConta = conta;
      //       this.loading = false;
      //       this.loader.complete();

      //       // Cancela o timeout
      //       clearTimeout(timeout);
      //     }
      //   }
      // );
    }
  }

  async fazerLoginEmailSenhaFirebasae(): Promise<void> {
    this.loading = true;
    this.loader.start();
    try {
      await this.authService.fazerLoginComEmailSenha(this.usr, this.empresaSigla);
      // Faça algo com a resposta
      this.log = true;
      this.return = "Logado com sucesso!!";
      this.loading = false;
      this.loader.complete();


    } catch (error: any) {
      // Trate o erro
      // console.error(error);
      this.log = true;
      this.return = "Erro durante o login por e-mail/senha.";
      this.loading = false;
      this.loader.complete();

    }
  }

  async fazerLoginComGoogle(){
    let response: any;
    this.log = false;
    this.loading = true;
    this.loader.start();

    try {
      //const response = await this.authService.fazerLoginComGoogle();
      // console.log('LoginComponent');
      let empresaSigla = '';

      this.route.params.subscribe(async params => {
        empresaSigla = params['empresaSigla'];

        await this.authService.GoogleAuth(empresaSigla).then((response) => {
          // console.log('complete:', response);
          this.loader.complete();

        }).catch((error: any) => {
          // console.error(error.message);
          this.log = true;
          this.return = error.message;
          this.loader.complete();
          this.loading = false;
          // this.authService.mostrarMenuEmitter.emit(false);
        });

      });

    } catch (error: any) {
      // trate o erro
      response = error;
      // console.log(response);
      this.log = true;
      this.loading = false;
      this.loader.complete();

      if(this.return = response.error.Message == undefined){
        this.return = "Sinto muito, mas o servidor não está respondendo no momento. Por favor, tente novamente mais tarde."
      }else{
        this.return = response.error.Message
      }
    }
  }

  fecharRetur(){
    this.log = false;
    this.return = ""
  }

}
