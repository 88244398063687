import { GrupoAgendaService } from './../../../../services/component/grupo-agenda.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
// import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { GrupoAgenda } from 'src/class/grupo-agenda';
import { GrupoUsuario } from 'src/class/grupo-usuario';
import { Usuario, objUsuario, GruposUsuarios, GruposUsuariosAgenda } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { ContaService } from 'src/services/component/conta.service';
import { GrupoUsuarioService } from 'src/services/component/grupo-usuario.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { UsuarioService } from 'src/services/component/usuario.service';
import { LocalhostService } from 'src/services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('gruposDeUsuarios', {static: true}) gruposDeUsuarios!: MatSelectionList;
  @ViewChild('gruposDeAgendas', {static: true}) gruposDeAgendas!: MatSelectionList;

  id: any;
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_edt = new objUsuario;

  rec_pwd_load = false;

  selectedFile: File | null = null;
  uploadProgress: number | null = null;

  showBtnMdl = false;
  empresaSigla: any | undefined;
  usuario: Usuario = new Usuario();
  usuarios: Usuario[] = [];

  grupos: any[] = [];
  gruposAgendas: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private usuariosService: UsuariosService,
    private usuarioService: UsuarioService,
    private router: Router,
    private toast: ToastService,
    private userService: AuthService,
    private siglaService: SiglaService,
    private validatorService: ValidatorService,
    private conta: ContaService,
    private localhostService: LocalhostService,
    private grupoUsuarioService: GrupoUsuarioService,
    private grupoAgendaService: GrupoAgendaService,
    private notificacaoService: NotificacaoSistemaService
  ){
    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.grupoUsuarioService.getGrupoUsuario(this.empresaSigla);
          this.grupoAgendaService.getGrupoAgenda(this.empresaSigla);
        });
      // }
    // });
  }

  async ngOnInit() {

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }



    this.route.params
    .pipe(takeUntil(this.destroy$))
    .subscribe(params => {
        this.id = params['id'];
        this.carregarUsuario();
    });



    // this.id = this.route.snapshot.paramMap.get('id');

    // this.usuarioService.getAllUsuario()
    // .pipe(takeUntil(this.destroy$))
    // .subscribe(usr => {
    //   this.usuarios = usr;

    //   for (let index = 0; index < this.usuarios.length; index++) {
    //     const usr = this.usuarios[index];
    //     if(usr.id == this.id){
    //       Object.assign(this.usuario, usr);
    //       console.log(this.usuario)
    //     }
    //   }

    //   if(this.usuario.id == null){
    //     this.router.navigate([`${this.empresaSigla}/usuarios`]);
    //   }
    // });


    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    // await this.getUsuario()


    setTimeout(() => {

      for (let index = 1; index < 10; index++) {
        const element = new objUsuario;

        element.Id = index.toString();
        element.Usuario = `Usuário ${index}`;
        element.Nome = `Nome ${index}`;
        element.Email = `email${index}@gmail.com`;
        element.Tipo = "A";
        element.Criado = "01/01/2023 14:22";
        element.Atualizado = "01/09/2023 15:22";
        element.Foto = "https://picsum.photos/1080/720";

        this.usr_obj.push(element)

      }

      for (let index = 0; index < this.usr_obj.length; index++) {
        const usr = this.usr_obj[index];
        if(usr.Id == this.id){
          Object.assign(this.usr_edt, usr);
          // console.log(this.usr_edt)
        }
      }

    }, 1000);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  carregarUsuario(): void {
    this.usuarioService.getAllUsuario()
        .pipe(takeUntil(this.destroy$))
        .subscribe(usuarios => {
            this.usuarios = usuarios;

            const usuarioEncontrado = this.usuarios.find(usr => usr.id === this.id);

            if (usuarioEncontrado) {
              Object.assign(this.usuario, usuarioEncontrado);
              // console.log(this.usuario)


              this.grupoUsuarioService.getAllGrupoUsuario()
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (grupos: GrupoUsuario[]) => {
                  // console.log(grupos)
                  // Faça algo com as notificações atualizadas
                  if(grupos.length > 0){
                    this.grupos = grupos;

                      // setTimeout(() => {
                      let rec: any[] = [];

                      // console.log(this.grupo.recursos)

                      for (let index1 = 0; index1 < this.usuario.grupo.length; index1++) {
                        const element = this.usuario.grupo[index1];

                        for (let index = 0; index < this.grupos.length; index++) {
                          const recId = this.grupos[index];

                          if(element.idGrupo == recId.id){
                            rec.push(element);
                            break;
                          }
                        }
                      }

                      // console.log(rec)

                      this.selectAllResources(rec);

                    // }, 1000);
                  }

                },
                error => {
                  // console.error('Erro ao listar os GrupoUsuario:', error);
                  this.router.navigate(['/usuarios']);
                }
              );

              this.grupoAgendaService.getAllGrupoAgenda()
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (gruposAgendas: GrupoAgenda[]) => {
                  // console.log(gruposAgendas)
                  // Faça algo com as notificações atualizadas
                  if(gruposAgendas.length > 0){
                    this.gruposAgendas = gruposAgendas;

                      // setTimeout(() => {
                      let rec: any[] = [];

                      // console.log(this.grupo.recursos)

                      for (let index1 = 0; index1 < this.usuario.grupoAgenda.length; index1++) {
                        const element = this.usuario.grupoAgenda[index1];

                        for (let index = 0; index < this.gruposAgendas.length; index++) {
                          const recId = this.gruposAgendas[index];

                          if(element.idGrupoAgenda == recId.id){
                            rec.push(element);
                            break;
                          }
                        }
                      }

                      // console.log(rec)

                      this.selectAllAgendas(rec);

                    // }, 1000);
                  }

                },
                error => {
                  // console.error('Erro ao listar os GrupoUsuario:', error);
                  this.router.navigate(['/usuarios']);
                }
              );

            } else {
                this.router.navigate([`${this.empresaSigla}/usuarios`]);
            }
        });
    }

  async getUsuario(){
    const usuario = await this.usuariosService.getUsuarioId(this.id,this.usr_token);
    // console.log(usuarios)
    this.usr_obj = usuario;

    for (let index = 0; index < this.usr_obj.length; index++) {
      const usr = this.usr_obj[index];
      if(usr.Id == this.id){
        Object.assign(this.usr_edt, usr);
        // console.log(this.usr_edt)
      }
    }
  }

  async updateUsuario(){

    let imageUrl = "";

    // console.log('i');

    try {
      // imageUrl = await this.upload();
      imageUrl = "";
      // Aqui você pode usar a URL da imagem para prosseguir com a outra parte do código
      // console.log('URL da imagem:', imageUrl);
      this.usr_edt.Foto = imageUrl;
    } catch (error) {
      // console.log('Erro ao fazer upload:', error);
    }

    // console.log('f');

    const update = await this.usuariosService.updateUsuario(this.usr_edt,this.usr_token);
    const response = JSON.parse(update);

    if(response.Message){
      // console.log(response.Message);
      this.showError(response.Message)
    }else{
      const button = document.getElementById('btn-close-x');
      if (button) {
        button.click();
      }
      this.showSuccess(update.replaceAll("\"", ""))

      if(this.usr_edt.Id == this.usr_id && (this.usr_edt.Tipo != "M" && this.usr_edt.Tipo != "A")){
        this.userService.fazerLogout();
      }

    }
  }

  async delUsuario(){
    const delUsr = await this.usuariosService.deleteUsuario(this.usr_edt,this.usr_token);
    const response = JSON.parse(delUsr);

    if(response.Message){
      // console.log(response.Message);
      this.showError(response.Message)

    }else{
      const button1 = document.getElementById('btn-close-x-del');

      if (button1) {
        button1.click();
      }

      this.showSuccess(delUsr.replaceAll("\"", ""));

      this.router.navigate(['/usuarios']);

      if(this.usr_edt.Id == this.usr_id){
        this.userService.fazerLogout();
      }
    }

  }

  async recuperarSenha(){
    this.rec_pwd_load = true;
    const usuario = await this.usuariosService.recuperarSenha(this.usr_edt.Email);
    // console.log(usuario)
    if(usuario.Message){
      this.rec_pwd_load = false;
      this.showError(usuario.Message)
    }else if(usuario.error?.Message){
      this.rec_pwd_load = false;
      this.showError(usuario.error?.Message)
    }else{
      this.rec_pwd_load = false;
      this.showSuccess(usuario.replaceAll("\"", ""))
    }

  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile)
  }

  async upload(){
  // async upload(): Promise<string> {

    //   if (this.selectedFile) {
    //     const filePath = `usuarios/${this.usr_edt.Usuario}_${this.selectedFile.name}`;
    //     const fileRef = this.storage.ref(filePath);
    //     const task = this.storage.upload(filePath, this.selectedFile);

    //     task.percentageChanges().subscribe(progress => {
    //       this.uploadProgress = progress !== undefined ? progress : null;
    //     });

    //     await task.snapshotChanges().toPromise();

    //     const url = await fileRef.getDownloadURL().toPromise();
    //     console.log('URL da imagem:', url);

    //     return url;
    //   }

    //   throw new Error('Nenhum arquivo selecionado.');
    // }
  }

  deleteFileByUrl(fileUrl: string){
    // deleteFileByUrl(fileUrl: string): void {
    //   // Obtenha o caminho do arquivo a partir da URL
    //   const filePath = this.getFilePathFromUrl(fileUrl);

    //   this.fileInput.nativeElement.value = '';
    //   this.selectedFile = null;

    //   if (filePath) {
    //     const fileRef = this.storage.ref(filePath);
    //     fileRef.delete().toPromise()
    //       .then(() => {
    //         console.log('Arquivo excluído com sucesso!');
    //         this.showSuccess('Arquivo excluído com sucesso!')
    //         this.usr_edt.Foto = "";

    //         this.updateUsuario();
    //         const button = document.getElementById('btn-close-x-foto');
    //         if (button) {
    //           button.click();
    //         }
    //       })
    //       .catch((error) => {
    //         this.showError('Erro ao excluir o arquivo: '+ error)

            // console.error('Erro ao excluir o arquivo:', error);
    //       });
    //   } else {
    //     this.showError('URL inválida do arquivo.')

        // console.error('URL inválida do arquivo.');
    //   }
    // }
  }

  getSelectedResources() {
    for (let index = 0; index < this.gruposDeUsuarios.selectedOptions.selected.length; index++) {
      const element = this.gruposDeUsuarios.selectedOptions.selected[index];
      // console.log(element.value)
    }
    return this.gruposDeUsuarios.selectedOptions.selected.map(option => option.value);
  }

  selectAllResources(recursos: any[]): void {
    const time = setInterval(()=>{
      if(this.gruposDeUsuarios && this.gruposDeUsuarios.options){
        clearInterval(time);

        recursos.forEach((rec: any) => {
            const resourceId = rec.idGrupo; // Obtém o ID do recurso
            const option = this.gruposDeUsuarios.options.toArray().find((opt: any) => opt.value.id === resourceId);
            if(option){
              option.selected = true; // Seleciona a opção com o ID correspondente
            }
        });

      }
    })
  }

  getSelectedAgendas() {
    for (let index = 0; index < this.gruposDeAgendas.selectedOptions.selected.length; index++) {
      const element = this.gruposDeAgendas.selectedOptions.selected[index];
      // console.log(element.value)
    }
    return this.gruposDeAgendas.selectedOptions.selected.map(option => option.value);
  }

  selectAllAgendas(recursos: any[]): void {
    const time = setInterval(()=>{
      if(this.gruposDeAgendas && this.gruposDeAgendas.options){
        clearInterval(time);

        recursos.forEach((rec: any) => {
            const resourceId = rec.idGrupoAgenda; // Obtém o ID do recurso
            const option = this.gruposDeAgendas.options.toArray().find((opt: any) => opt.value.id === resourceId);
            if(option){
              option.selected = true; // Seleciona a opção com o ID correspondente
            }
        });

      }
    })
  }

  async salvarUsuario(){

    this.usuario.displayName.status = this.validatorService.criarValidacao(this.usuario.displayName.valor, this.usuario.displayName.validacoesCustomizadas);
    this.usuario.email.status = this.validatorService.criarValidacao(this.usuario.email.valor, this.usuario.email.validacoesCustomizadas);
    this.usuario.tipo.status = this.validatorService.criarValidacao(this.usuario.tipo.valor, this.usuario.tipo.validacoesCustomizadas);

    // const resources_qtd = this.getSelectedResources();

    // if(resources_qtd.length == 0){
    //   this.showError("Por favor, selecione pelo menos 1 grupo de usuário")
    // }else
    if(this.usuario.displayName.status.invalid ||
      this.usuario.email.status.invalid ||
      this.usuario.tipo.status.invalid
    ){
      // this.abrirModal(this.alertTipoModal.nativeElement);
    }else{

      // console.log(this.usuario)

      // Obtenha o array existente do DataService
      let usuario = this.usuarios;

      // Encontre o índice do item correspondente em tipo com base no ID
      const index = usuario.findIndex((item: Usuario) => item.id === this.usuario.id);

      if (index !== -1) {
        this.usuario.atualizado = new Date();
        // Atualize o item existente com as alterações de this.tipo
        usuario[index] = this.usuario;

        const docId = usuario[index].id != null ? usuario[index].id?.toString() : "";

        let grupo: GruposUsuarios[] = [];

        // console.log(this.resources.selectedOptions)
        for (let index = 0; index < this.gruposDeUsuarios.selectedOptions.selected.length; index++) {
          const element = this.gruposDeUsuarios.selectedOptions.selected[index];
          // console.log(element.value)
          const rec = new GruposUsuarios();

          rec.idGrupo = element.value.id
          rec.NomeGrupo = element.value.nome.valor

          grupo.push(rec)
        }

        usuario[index].grupo = grupo;

        let agendas: GruposUsuariosAgenda[] = [];

        // console.log(this.resources.selectedOptions)
        for (let index = 0; index < this.gruposDeAgendas.selectedOptions.selected.length; index++) {
          const element = this.gruposDeAgendas.selectedOptions.selected[index];
          // console.log(element.value)
          const rec = new GruposUsuariosAgenda();

          rec.idGrupoAgenda = element.value.id
          rec.NomeGrupoAgenda = element.value.nome.valor

          agendas.push(rec)
        }

        usuario[index].grupoAgenda = agendas;

        const plainObject = JSON.stringify(usuario[index])
        const plainString = JSON.parse(plainObject)

        // Limpar o status formControll
        plainString.displayName.status = null;
        plainString.email.status = null;
        plainString.phoneNumber.status = null;
        plainString.photoURL.status = null;
        plainString.validadePlano.status = null;
        plainString.sigla.status = null;
        plainString.status.status = null;
        plainString.tipo.status = null;

        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: atualizarStatusServico', conta);
          this.localhostService.putDados(`${conta.id}_usuarios`, docId!, plainString)
          .then(async (response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess("Usuário atualizado com sucesso");

            // Nova notificação
            // 1 - empresaSigla: any,
            // 2 - titulo: string,
            // 3 - texto: string,
            // 4 - rotaSemParamNome: string,
            // 5 - rotaSemParamListar: string,
            // 6 - rotaComParamNome: string = "",
            // 7 - rotaComParamCaminho: string = "",
            // 8 - rotaComParamParam: string = "",
            // 9 - linkExternoNome: string = "",
            // 10 - linkExternoURL: string = "",
            // 11 - idReferencia: string,
            // 12 - tipo: string,
            // 13 - idResponsavel: string,
            // 14 - nomeResponsavel: string

            const usr = await this.userService.carregarUsuario(this.empresaSigla)

            this.notificacaoService.criarNotificacao(
              this.empresaSigla,
              `${plainString.displayName.valor}`,
              `O usuário foi atualizado.`,
              'Listar Usuários',
              'usuarios',
              'Editar Usuário',
              `usuario`,
              plainString.id?.toString(),
              '',
              '',
              plainString.id!.toString(),
              `Usuário`,
              `${usr.id}`,
              `${usr.displayName.valor}`
            )

            this.router.navigate([`${this.empresaSigla}/usuarios`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Usuário não encontrado para atualização \n" + error);
          });

        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // console.log('Erro ao obter dados da conta:', error);
        });

        // Salve o array de volta no DataService
        // DataService.setItem("Tipo", tipo);
        // localStorage.setItem("Tipo", JSON.stringify(tipo))
        // this.showSuccess("Tipo atualizado com sucesso");
        // this.router.navigate([`${this.empresaSigla}/tipos`]);

      } else {
        // O item não foi encontrado no array, você pode tratar isso conforme necessário
        this.showError("Usuário não encontrado para atualização");
      }
    }

  }


  private getFilePathFromUrl(fileUrl: string): string | null {
    // Verifique se a URL contém o parâmetro 'o' que indica o caminho do arquivo
    const pathParam = 'o/';
    const startIndex = fileUrl.indexOf(pathParam);

    if (startIndex !== -1) {
      const endIndex = fileUrl.indexOf('?');
      const filePath = fileUrl.substring(startIndex + pathParam.length, endIndex !== -1 ? endIndex : undefined);
      return decodeURIComponent(filePath);
    }

    return null;
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
