import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

export const environment = {
  production: false,
  firebaseConfig: {
    // apiKey: "AIzaSyCIAMo2-LZ61h3oHoNnyFHlzHD2AYk41dw",
    // authDomain: "paperoff-agenda-dev.firebaseapp.com",
    // projectId: "paperoff-agenda-dev",
    // storageBucket: "paperoff-agenda-dev.appspot.com",
    // messagingSenderId: "935540085469",
    // appId: "1:935540085469:web:0c053eb982af31578bc134"

    apiKey: "AIzaSyAjPuVRPnuH3vywiapV0czyQ06m7ZrOiKE",
    authDomain: "logwise-dev-9cf97.firebaseapp.com",
    projectId: "logwise-dev-9cf97",
    storageBucket: "logwise-dev-9cf97.appspot.com",
    messagingSenderId: "604927279990",
    appId: "1:604927279990:web:04bdbf2d1897ccc864f057"
  }
};

export const app = initializeApp(environment.firebaseConfig);
export const db = getFirestore(app);
