import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Leitura, NotificacaoInt } from 'src/interface/notificacao-int';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
// import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class NotificacaoSistemaService {

  private notificacoes: NotificacaoInt[] = [];
  private notificacoesSubject = new BehaviorSubject<NotificacaoInt[]>(this.notificacoes);

  constructor(
    private localhostService: LocalhostService,
    private conta: ContaService
  ) { }

  async criarNotificacao(
    empresaSigla: any,
    titulo: string,
    texto: string,
    rotaSemParamNome: string,
    rotaSemParamListar: string,
    rotaComParamNome: string = "",
    rotaComParamCaminho: string = "",
    rotaComParamParam: string = "",
    linkExternoNome: string = "",
    linkExternoURL: string = "",
    idReferencia: string,
    tipo: string,
    idResponsavel: string,
    nomeResponsavel: string,
  ) {
    try {
      const novaNotificacao: NotificacaoInt = {
        id: '',
        titulo: titulo,
        texto: texto,
        rota: {
          semParam:{
              nome: rotaSemParamNome,
            caminho: rotaSemParamListar,
          },
          comParam: {
            nome: rotaComParamNome,
            caminho: rotaComParamCaminho,
            param: rotaComParamParam
          }
        },
        linkExterno: {
          nome: linkExternoNome,
          url: linkExternoURL,
        },
        idReferencia: idReferencia,
        tipo: tipo,
        dataCriacao: new Date().toISOString(),
        leitura: [],
        responsavel: {
          idUsuario: idResponsavel,
          nomeUsuario: nomeResponsavel,
        }
      };

      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      const response = await this.localhostService.postDadosId(`${conta.id}_notificacoes`, novaNotificacao);

      this.notificacoes.push(novaNotificacao);
      this.notificacoesSubject.next(this.notificacoes);

      return true;
    } catch (error) {
      // console.error('Erro ao criar notificação:', error);
      return false;
    }
  }

  getNotificacoes(empresaSigla: any): Observable<NotificacaoInt[]> {
    // console.log(empresaSigla)
    return new Observable<NotificacaoInt[]>(observer => {
      this.conta.p_getSiglaConta('contas', empresaSigla).then(conta => {
        this.localhostService.getDados(`${conta.id}_notificacoes`).subscribe((notificacaos: any[]) => {
          if (notificacaos) {

            const notificacaosArray: NotificacaoInt[] = notificacaos.map(notificacao => ({
              id: notificacao.id,
              titulo: notificacao.titulo,
              texto: notificacao.texto,
              rota: {
                semParam: {
                  nome: notificacao.rota.semParam.nome,
                  caminho: notificacao.rota.semParam.caminho,
                },
                comParam: {
                  nome: notificacao.rota.comParam.nome,
                  caminho: notificacao.rota.comParam.caminho,
                  param: notificacao.rota.comParam.param
                }
              },
              linkExterno: {
                nome: notificacao.linkExterno.nome,
                url: notificacao.linkExterno.url,
              },
              idReferencia: notificacao.idReferencia,
              tipo: notificacao.tipo,
              dataCriacao: notificacao.dataCriacao,
              leitura: notificacao.leitura ? notificacao.leitura.map((leitura: any) => ({
                idUsuario: leitura.idUsuario,
                nomeUsuario: leitura.nomeUsuario,
                dataLeitura: leitura.dataLeitura,
                visivel: leitura.visivel
              })) : [],
              responsavel: {
                idUsuario: notificacao.responsavel.idUsuario,
                nomeUsuario: notificacao.responsavel.nomeUsuario,
              }
            }));

            // console.log(notificacaosArray);

            this.notificacoes = notificacaosArray;
            this.notificacoesSubject.next(this.notificacoes);
            observer.next(notificacaosArray);

          } else {
            this.notificacoes = [];
            observer.next([]);
          }
        }, error => {
          // console.error('Erro ao obter notificações:', error);
          observer.error(error);
        });
      }).catch(error => {
        // console.error('Erro ao criar notificação:', error);
        observer.error(error);
      });
    });
  }

  async getNotificacoesOLD(empresaSigla: any) {

    try{
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

     return this.localhostService.getDados(`${conta.id}_notificacoes`)
      .subscribe((notificacaos: any[]) => {

        if (notificacaos) {
          // Mapeia os dados para a estrutura desejada
          const notificacaosArray: NotificacaoInt[] = notificacaos.map(notificacao => ({
            id: notificacao.id,
            titulo: notificacao.titulo,
            texto: notificacao.texto,
            rota: {
              semParam: {
                nome: notificacao.rota.semParam.nome,
                caminho: notificacao.rota.semParam.caminho,
              },
              comParam: {
                nome: notificacao.rota.comParam.nome,
                caminho: notificacao.rota.comParam.caminho,
                param: notificacao.rota.comParam.param
              }
            },
            linkExterno: {
              nome: notificacao.linkExterno.nome,
              url: notificacao.linkExterno.url,
            },
            idReferencia: notificacao.idReferencia,
            tipo: notificacao.tipo,
            dataCriacao: notificacao.dataCriacao,
            leitura: notificacao.leitura ? notificacao.leitura.map((leitura: any) => ({
              idUsuario: leitura.idUsuario,
              nomeUsuario: leitura.nomeUsuario,
              dataLeitura: leitura.dataLeitura,
              visivel: leitura.visivel
            })) : [],
            responsavel: {
              idUsuario: notificacao.responsavel.idUsuario,
              nomeUsuario: notificacao.responsavel.nomeUsuario,
            }
          }));

          console.log(notificacaosArray)

          this.notificacoes = notificacaosArray;
          this.notificacoesSubject.next(this.notificacoes);
          // this.updateLocalStorage();
        } else {
          this.notificacoes = [];
        }
      },
      (error) => {
        // console.error('Erro ao obter notificações:', error);
      });
    }catch (error) {
      // console.error('Erro ao criar notificação:', error);
      this.notificacoes = [];
      return this.notificacoes;
    }

  }

  getAllNotificacoes() {
    return this.notificacoesSubject.asObservable().pipe(
      map(notificacao => notificacao)
    );
  }

  async addUsuarioLeitura(notificacao: NotificacaoInt, leituraUsuario: Leitura, empresaSigla: any) {

    try{
      // this.notificacoesSubject.next(this.notificacoes);

      const docId = notificacao.id.toString();
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      if(notificacao.leitura.length == 0){
        notificacao.leitura.push(leituraUsuario);
      }else{
        let usuarioExistente = notificacao.leitura.find(leitura => leitura.idUsuario === leituraUsuario.idUsuario);
        // console.log("usuarioExistente",usuarioExistente)
        if(!usuarioExistente){
          notificacao.leitura.push(leituraUsuario);
        }
      }

      this.localhostService.putDados(`${conta.id}_notificacoes`, docId , notificacao)
        .then((response: any) => {
          // console.log('Atualização bem-sucedida:', response);
          this.getNotificacoes(empresaSigla);
          // this.updateLocalStorage();
        })
        .catch((error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          this.getNotificacoes(empresaSigla);
          // this.updateLocalStorage();
        });
      return true;
    } catch (error) {
      // console.error('Erro ao criar notificação:', error);
      return false;
    }

  }

  getNotificacoesNaoLidasUsuario(idUsuario: any) {
    // Filtra as notificações que possuem o id do usuário
    // const notificacoesUsuario = this.notificacoes.filter(
    //   (notificacao) => notificacao.leitura?.some((leitura) => leitura.idUsuario === idUsuario)
    // );

    // Retorna apenas as notificações não lidas pelo usuário
    return this.notificacoes.filter((notificacao) => {
      const leituraUsuario = notificacao.leitura?.find((leitura) => leitura.idUsuario === idUsuario);
      return !leituraUsuario;
    });
  }

}
