import { FormControl, Validators } from "@angular/forms";
import { campo } from "../interface/campo";
import { ValidatorService } from "src/services/validator.service";

const validatorService: ValidatorService  = new ValidatorService();
export class Servico {

  id: any | null = null;

  nome: campo = {
    title: 'Nome do Serviço',
    info: 'Nome do Serviço',
    valor: '',
    placeholder: 'Nome do serviço',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  valor: campo = {
    title: 'Valor do Serviço',
    info: 'Valor do Serviço',
    valor: '',
    placeholder: 'Valor do Serviço',
    inputType: 'text',
    inputMode: 'numeric',
    mascara: '',
    maxlength: '17',
    tipo: 'real',
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  mostrarValorParaClientes: campo = {
    title: 'Mostrar valor do serviço para os clientes',
    info: 'Mostrar valor do serviço para os clientes',
    valor: false,
  };

  linkPagamento: campo = {
    title: 'Link de pagamento para o cliente',
    info: 'Link de pagamento para o cliente',
    valor: '',
    placeholder: 'Link de pagamento',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  duracaoAtendimento: campo = {
    title: 'Duração do atendimento',
    info: 'Duração do atendimento',
    valor: '00:00',
    placeholder: 'Duração',
    inputType: 'time',
    inputMode: 'numeric',
    mascara: '##:##',
    maxlength: '5',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  numeroPessoasPorHorario: campo = {
    title: 'Limite de agendamentos simultâneos',
    info: 'Limite de agendamentos simultâneos',
    valor: '',
    placeholder: 'Limite de agendamentos',
    inputType: 'number',
    inputMode: 'numeric',
    mascara: '#####',
    maxlength: '5',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  instrucoesConfirmacao: campo = {
    title: 'Instruções exibidas na tela de confirmação do agendamento e no e-mail de confirmação',
    info: 'Instruções exibidas na tela de confirmação do agendamento e no e-mail de confirmação',
    valor: '',
    placeholder: 'Instruções',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  vincularEmTodasAgendas: campo = {
    title: 'Vincular serviço em todas as agendas',
    info: 'Vincular serviço em todas as agendas',
    valor: false,
  };

  criado: Date = new Date();

  atualizado: Date = new Date();

  vinculoAgenda: any[] = [];

  status: campo = {
    title: 'Serviço Ativo',
    info: 'Serviço Ativo',
    valor: true,
  };

  constructor() {}

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "nome":
        this.nome.valor = validatorService.aplicarMascara(this.nome.valor, mascara);
        break;
      case "valor":
        if(this.valor.tipo != ""){
          this.valor.valor = validatorService.aplicarMascaraEspecial(this.valor.valor, this.valor.tipo);
        }{
          this.valor.valor = validatorService.aplicarMascara(this.valor.valor, mascara);
        }
        break;
      case "mostrarValorParaClientes":
        this.mostrarValorParaClientes.valor = validatorService.aplicarMascara(this.mostrarValorParaClientes.valor, mascara);
        break;
      case "linkPagamento":
        this.linkPagamento.valor = validatorService.aplicarMascara(this.linkPagamento.valor, mascara);
      break;
      case "duracaoAtendimento":
        this.duracaoAtendimento.valor = validatorService.aplicarMascara(this.duracaoAtendimento.valor, mascara);
        break;
      case "numeroPessoasPorHorario":
        this.numeroPessoasPorHorario.valor = validatorService.aplicarMascara(this.numeroPessoasPorHorario.valor, mascara);
        break;
      case "instrucoesConfirmacao":
        this.instrucoesConfirmacao.valor = validatorService.aplicarMascara(this.instrucoesConfirmacao.valor, mascara);
        break;
      case "vincularEmTodasAgendas":
        this.vincularEmTodasAgendas.valor = validatorService.aplicarMascara(this.vincularEmTodasAgendas.valor, mascara);
        break;
      default:
        break;
    }
  }

  // Função para validar o campo com base nos validadores personalizados
  // Validadores
  // validacoesCustomizadas: [Validators.required, Validators.email, Validators.minLength(8), Validators.maxLength(10), Validators.pattern('[0-9]{11}'), Validators.min(5), Validators.max(10000)]
  validarInput(tipo: string) {
    switch (tipo) {
      case "nome":
        this.nome.valor = this.nome.valor.trim();
        this.nome.status = validatorService.criarValidacao(this.nome.valor, this.nome.validacoesCustomizadas);
        break;
      case "valor":
        this.valor.valor = this.valor.valor.trim();
        this.valor.status = validatorService.criarValidacao(this.valor.valor, this.valor.validacoesCustomizadas);
        break;
      case "mostrarValorParaClientes":
        this.mostrarValorParaClientes.valor = this.mostrarValorParaClientes.valor.trim();
        this.mostrarValorParaClientes.status = validatorService.criarValidacao(this.mostrarValorParaClientes.valor, this.mostrarValorParaClientes.validacoesCustomizadas);
        break;
      case "linkPagamento":
        this.linkPagamento.valor = this.linkPagamento.valor.trim();
        this.linkPagamento.status = validatorService.criarValidacao(this.linkPagamento.valor, this.linkPagamento.validacoesCustomizadas);
        break;
      case "duracaoAtendimento":
        this.duracaoAtendimento.valor = this.duracaoAtendimento.valor.trim();
        this.duracaoAtendimento.status = validatorService.criarValidacao(this.duracaoAtendimento.valor, this.duracaoAtendimento.validacoesCustomizadas);
        break;
      case "numeroPessoasPorHorario":
        this.numeroPessoasPorHorario.valor = this.numeroPessoasPorHorario.valor.trim();
        this.numeroPessoasPorHorario.status = validatorService.criarValidacao(this.numeroPessoasPorHorario.valor, this.numeroPessoasPorHorario.validacoesCustomizadas);
        break;
      case "instrucoesConfirmacao":
        this.instrucoesConfirmacao.valor = this.instrucoesConfirmacao.valor.trim();
        this.instrucoesConfirmacao.status = validatorService.criarValidacao(this.instrucoesConfirmacao.valor, this.instrucoesConfirmacao.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }


}
