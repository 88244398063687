
<div class="pb-4">

  <div class="container text-center min-width p-0">

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('agendas')" class="text-padrao"> Agendas</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Regras</li>
          </ol>
        </nav>
      </div>
    </div>

    <!-- Cards após Loading -->
    <div class="row p-0 m-0">
      <div class=" col-lg-4 mb-3" style=" cursor: pointer; ">
        <button type="button" (click)="routerLink('agendas/regras/adicionar')" class="btn btn-sm p-0 m-0 btn-principal-primary">
          <span class="btn-principal-primary-nome">
            Nova Regra
          </span>
          <span class="btn-principal-primary-icon">
            <i class="bi bi-filter"></i>
          </span>
        </button>
      </div>
    </div>

    <div class="row p-0 m-0">

      <div class="min-width col-12">
        <div class="row p-0 m-0 box-gen-card box-gen-card-top">
          <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0 mb-2">
            <h3 class="p-0 m-0 text-cor-padrao text-start w-100" *ngIf="!modoView">
              <span *ngIf="agenda_arr.length == 0">
                Nenhuma regra cadastrada
              </span>
              <span *ngIf="agenda_arr.length > 0">
                <span class="box-gen-card-h3-span">{{agenda_arr.length}}</span> {{agenda_arr.length == 1 ? "Regra disponível" : "Regras disponíveis"}}
              </span>
            </h3>
            <h3 class="p-0 m-0 text-cor-padrao text-start w-100" *ngIf="modoView">
              <span *ngIf="usr_arr.length == 0">
                Nenhuma regra cadastrada
              </span>
              <span *ngIf="usr_arr.length > 0">
                <span class="box-gen-card-h3-span">{{usr_arr.length}}</span> {{usr_arr.length == 1 ? "Regra disponível" : "Regras disponíveis"}}
              </span>
            </h3>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0">
            <div class="d-flex justify-content-between align-items-center flex-nowrap">
              <button type="button" class="btn btn-outline-primary" style="color: #fff; margin-right: 5px;" (click)="reload()">
                Atualizar <i class="bi bi-arrow-clockwise"></i>
              </button>

              <button type="button" class="btn btn-outline-primary" style="color: #fff; margin-right: 5px;" (click)="modo()">
                Modo <i class="bi bi-gear"></i>
              </button>
            </div>
          </div>
        </div>


      </div>
    </div>

    <div class="row p-0 m-0" *ngIf="!modoView">
      <div class="min-width col-lg-12 pb-5 pt-3">

        <div class="row" *ngIf="agenda_arr.length == 0">

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" >
            <div class="p-0 m-0 pulsate" >
            </div>
          </div>

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'">
            <div class="p-0 m-0 pulsate" >
            </div>
          </div>

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'">
            <div class="p-0 m-0 pulsate" >
            </div>
          </div>

        </div>

        <div class="row" *ngIf="agenda_arr.length > 0">

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" *ngFor="let agenda of agenda_arr">
            <div class="card card-padrao">
              <div [style]="{ 'width': '100%', 'height': '145px', 'background-image': 'url(' + agenda.Foto + ')', 'background-size': 'cover', 'background-position': 'center center', 'background-repeat': 'no-repeat', 'border-radius': '26px 26px 0px 0px', 'position': 'relative', 'background-color': '#00000094' }">
                <div class="card-padrao-img-sombra">
                  <span class="card-padrao-img-title">{{agenda.Titulo}}</span>
                </div>
              </div>
              <div class="card-body card-body-pd">
                <div class="card-body-time">
                  <span><i class="bi bi-clock"></i> {{agenda.Criado}}</span>
                  <span><i class="bi bi-clock-history"></i> {{agenda.Valido}}</span>
                </div>
                <div>

                  <ul class="list-group card-body-ul">
                    <li class="list-group-item" style="text-align: left;border: none;padding: 5px 5px 5px 5px;background: var(--linear-gradient-0deg);color: var(--text-cor-padrao);"><span style=" font-size: 15px; font-weight: 500; ">{{agenda.SubTitulo}}</span></li>
                    <li class="list-group-item" style="text-align: left;border: none;padding: 5px 5px 5px 5px;background: var(--linear-gradient-0deg);color: var(--text-cor-padrao);">{{agenda.Descricao}}</li>
                    <li class="list-group-item" style="text-align: left;border: none;padding: 5px 5px 5px 5px;background: var(--linear-gradient-0deg);color: var(--text-cor-padrao);">{{agenda.Info}}</li>
                  </ul>

                </div>

                <div class="mt-2 card-padrao-footer">
                  <button type="button" class="btn btn-outline-primary w-100" style="color: #fff; margin-right: 5px;">
                    Editar <i class="bi bi-pencil-square"></i>
                  </button>
                  <button type="button" class="btn btn-outline-secondary w-100" style="color: #fff; margin-right: 5px;">
                    Desativar <i class="bi bi-dash-circle"></i>
                  </button>
                  <button type="button" class="btn btn-outline-danger w-100" tabindex="0">
                    Deletar <i class="bi bi-trash3"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="row p-0 m-0" *ngIf="modoView">

      <div class="min-width col-lg-12 pb-5 pt-3" *ngIf="usr_arr.length == 0">
        <div class="p-0 m-0 pulsate box-gen-card" style="min-height: 50vh;width: 100%;overflow: auto;flex-direction: column;">
        </div>
      </div>

      <div class="min-width col-lg-12 pb-5 pt-3" *ngIf="usr_arr.length > 0">
        <div class="p-0 m-0 box-gen-card" style="width: 100%;overflow: auto;flex-direction: column;">

          <div *ngIf="usr_arr.length > 0" style="position: relative;">

            <div class="form-floating mb-3 w-100 ">
              <input type="text" class="form-control input-text-padrao m-0 search-box-result input-text-padrao" id="cpfInputSearch" [(ngModel)]="pesquisa" (keydown)="searchUsrReturn()" (keyup)="searchUsrReturn()" (keypress)="searchUsrReturn()" placeholder="&nbsp;">
              <label for="cpfInputSearch" class="label-input-text-padrao ag-pt-18 ag-pb-18 ag-pl-12 ag-pr-12">Pesquisar ...</label>
            </div>

            <i *ngIf="pesquisa != ''" class="bi bi-x-circle-fill text-secondary reset-search-usr" (click)="resetSearch()"></i>

          </div>

          <div *ngIf="usr_arr.length > 0" class="row mb-3 d-flex align-items-center text-cor-padrao p-0 m-0" style=" margin-bottom: 1rem !important; ">
            <div class="col-6 p-0 m-0">
              <select class="form-select form-select-sm select-box-result" (change)="pags($event.target)">
                <option value="5" selected>5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
            <div class="col-6 pagination-info-box-result">
              <span *ngIf="pesquisa == ''"> {{visualizando > usr_arr.length ? usr_arr.length : visualizando }} de {{usr_arr.length}} </span>
              <span *ngIf="pesquisa != ''"> {{pesquisaLength}} de {{usr_arr.length}}</span>
            </div>
          </div>

          <div class="w-100 overflow-auto">
            <table id="excel-table" class="table table-responsive table-hover table-sm" [ngClass]="{'table-dark': !lightMode,'table-light': lightMode}">
              <thead style=" height: 40px; vertical-align: middle; ">
                  <tr>
                    <th class="text-cor-padrao">&nbsp;</th>
                    <th class="text-cor-padrao">ID</th>
                    <th class="text-cor-padrao">Usuário</th>
                    <th class="text-cor-padrao">Nome</th>
                    <th class="text-cor-padrao">E-mail</th>
                    <th class="text-cor-padrao">Tipo</th>
                    <th class="text-cor-padrao">Criado</th>
                    <th class="text-cor-padrao">Atualizado</th>
                    <th class="text-cor-padrao">Editar</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="text-cor-padrao ag-table-tr" *ngFor="let usr of usr_arr | searchUsuario: pesquisa | paginate: { itemsPerPage: contador, currentPage: pag }">
                    <td style="font-weight: 300;font-size: 15px;">
                      <div style=" display: flex; justify-content: center; ">
                        <div style="min-height: 30px; width: 30px;border-radius: 15px;border: none; background-color: var(--background);background-attachment: inherit; background-origin: initial; background-clip: inherit; background-position: center center; background-size: cover; background-repeat: no-repeat;" [ngStyle]="{'background-image': usr.Foto != '' ? 'url('+usr.Foto+')' : ''}">
                        </div>
                      </div>
                    </td>
                    <td style="font-weight: 300;font-size: 15px;">
                      <div >
                        {{usr.Id}}
                      </div>
                    </td>
                    <td style="font-weight: 300;font-size: 15px;">
                      <div >
                        {{usr.Usuario}}
                      </div>
                    </td>
                    <td style="font-weight: 300;font-size: 15px;">
                      <div >
                        {{usr.Nome}}
                      </div>
                    </td>
                    <td style="font-weight: 300;font-size: 15px;">
                      <div >
                        {{usr.Email}}
                      </div>
                    </td>
                    <td style="font-weight: 300;font-size: 15px;">
                      <div >
                        <span >{{usr.Tipo == 'A' ? 'Administrador' : usr.Tipo == 'M' ? 'Master' : usr.Tipo == 'S' ? 'Simples' : usr.Tipo == 'D' ? 'Desativado' : usr.Tipo }}</span>
                      </div>
                    </td>
                    <td style="font-weight: 300;font-size: 15px;">
                      <div >
                        {{usr.Criado}}
                      </div>
                    </td>
                    <td style="font-weight: 300;font-size: 15px;">
                      <div >
                        {{usr.Atualizado}}
                      </div>
                    </td>
                    <td style="font-weight: 300;font-size: 15px;">
                      <button type="button" class="btn btn-outline-primary" (click)="routerLink('agendas/regra', usr.Id)" style="border: none !important;font-size: 16px !important;padding: 4px 4px !important;margin: 0 !important;">
                        <i class="bi bi-pencil-square"></i>
                      </button>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <div class="text-right row pagination-box-result" *ngIf="usr_arr.length > 0">
            <div class="col-12 p-0 m-0">
              <pagination-controls class="info-pagination-box-result" #paginationControls (pageChange)="handlePageChange($event)" previousLabel="" nextLabel="" [maxSize]="maxSize"></pagination-controls>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg  text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" (click)="routerLink(card.rota!.toString())" *ngIf="card.tipo != 'agenda-regras'" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div>

  </div>
</div>
