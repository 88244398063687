import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { Recurso } from 'src/class/recurso';
import { NotificacaoSistemaService } from './notificacao-sistema.service';

@Injectable({
  providedIn: 'root'
})
export class RecursoService {

  private recursos: Recurso[] = [];
  private recursosSubject = new BehaviorSubject<Recurso[]>(this.recursos);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService
  ) {
    this.recursos = [];
    // this.getRecurso();
  }



  async criarRecurso(recurso: Recurso, empresaSigla: any) {
    // console.log("criarRecurso", recurso);
    recurso.nome.status = null;
    recurso.descricao.status = null;
    recurso.tipo.status = null;

    try {
      // const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarRecurso', conta);

      const response = await this.localhostService.postDadosId(`recursos`, recurso);
      // console.log('Recurso criado com sucesso:', response);

      // Atualize a lista local de agendas
      this.recursos.push(recurso);
      this.recursosSubject.next(this.recursos);

      // Notifique o usuário sobre o sucesso
      this.showSuccess(`${recurso.nome.valor}, recurso criado com sucesso`);

      // Nova notificação
      // 1 - empresaSigla: any,
      // 2 - titulo: string,
      // 3 - texto: string,
      // 4 - rotaSemParamNome: string,
      // 5 - rotaSemParamListar: string,
      // 6 - rotaComParamNome: string = "",
      // 7 - rotaComParamCaminho: string = "",
      // 8 - rotaComParamParam: string = "",
      // 9 - linkExternoNome: string = "",
      // 10 - linkExternoURL: string = "",
      // 11 - idReferencia: string,
      // 12 - tipo: string,
      // 13 - idResponsavel: string,
      // 14 - nomeResponsavel: string

      // const usr = await this.userService.carregarUsuario(empresaSigla)

      // this.notificacaoService.criarNotificacao(
      //   empresaSigla,
      //   `${recurso.nome.valor}`,
      //   `Recurso criado.`,
      //   'Listar Recursos',
      //   'recursos',
      //   'Editar Recurso',
      //   `recursos/recurso`,
      //   recurso.id?.toString(),
      //   '',
      //   '',
      //   recurso.id!.toString(),
      //   `Recurso`,
      //   `${usr.id}`,
      //   `${usr.displayName.valor}`
      // )

      return true;
    } catch (error) {
      // console.error('Erro ao criar recurso:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllRecurso() {
    return this.recursosSubject.asObservable().pipe(
      map(recursos => recursos)
    );
  }

  async getRecurso(empresaSigla: any) {

    // await this.conta.p_getSiglaConta('contas', empresaSigla)
    // .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getRecurso', conta);

      this.localhostService.getDados(`recursos`).subscribe(
        (recursoFromServer: any[]) => {
          if (recursoFromServer) {
            // Mapeia os dados para a estrutura desejada
            const recursoArray: Recurso[] = recursoFromServer.map(recurso => plainToClass(Recurso, recurso));

            // console.log(recursoArray);

            this.recursos = recursoArray;
            this.recursosSubject.next(this.recursos);
            // this.updateLocalStorage();
          } else {
            this.recursos = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter recursos:', error);
        }
      );
    // },
    // (error: any) => {
         // console.error('Erro ao obter recursos:', error);
    //   // Trate o erro conforme necessário
    // }).catch((error) => {
    //   // Trate o erro
    //   // console.log('Erro ao obter dados da conta:', error);
    // });


  }

  private updateLocalStorage() {
    localStorage.setItem("Recurso", JSON.stringify(this.recursos));
    DataService.set("Recurso", this.recursos);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
