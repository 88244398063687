import { Injectable } from '@angular/core';
import { AgendamentoService } from './component/agendamento.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  constructor(private agendamentoService: AgendamentoService) { }

  async gerarEventos(dataInicial: Date, dataFinal: Date, duracaoAtendimento: string, horariosDisponiveis: any[], nomeAgenda: string) {
    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // 1 dia em milissegundos
    const eventos = [];

    const duracaoAtendimentoEmMinutos = this.converterDuracaoParaMinutos(duracaoAtendimento);

    while (dataInicial <= dataFinal) {
        const diaDaSemana = dataInicial.getDay(); // 0 (Domingo) a 6 (Sábado)

        // Verificar os horários disponíveis para o dia da semana atual
        const horariosDoDia = horariosDisponiveis[diaDaSemana].horariosDia;

        if (horariosDoDia.length > 0) {
            for (let index = 0; index < horariosDoDia.length; index++) {
                const horarioDisponivel = horariosDoDia[index];

                const horaInicialDisponivel = new Date(dataInicial);
                const horaFinalDisponivel = new Date(dataInicial);

                const [horaIni, minIni] = horarioDisponivel.hora_inicial.valor.split(':');
                const [horaFim, minFim] = horarioDisponivel.hora_final.valor.split(':');

                horaInicialDisponivel.setHours(parseInt(horaIni), parseInt(minIni), 0, 0);
                horaFinalDisponivel.setHours(parseInt(horaFim), parseInt(minFim), 0, 0);

                if (duracaoAtendimentoEmMinutos > 0) {
                  while (
                      horaInicialDisponivel.getTime() + duracaoAtendimentoEmMinutos * 60 * 1000 <= horaFinalDisponivel.getTime()
                  ) {
                      const startDateTime = new Date(horaInicialDisponivel.getTime());
                      const endDateTime = new Date(horaInicialDisponivel.getTime() + duracaoAtendimentoEmMinutos * 60 * 1000);
                      const hr_start = startDateTime.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
                      const hr_end = endDateTime.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

                      let bgColor = "var(--cor-info)";
                      let txtColor = "var(--cor-branco)";

                      const agora = new Date();
                      agora.setHours(0, 0, 0, 0);

                      if (startDateTime < agora) {
                        bgColor = "var(--cor-danger)";
                        txtColor = "var(--cor-branco)";
                      }

                      const evento = {
                          start: startDateTime.toISOString(),
                          end: endDateTime.toISOString(),
                          title: `${nomeAgenda}`,
                          description: `${index + 1}º Horário`,
                          backgroundColor: bgColor,
                          textColor: txtColor,
                      };

                      eventos.push(evento);

                      horaInicialDisponivel.setTime(horaInicialDisponivel.getTime() + duracaoAtendimentoEmMinutos * 60 * 1000);
                  }
              }else {

                const startDateTime = new Date(horaInicialDisponivel.getTime());
                const endDateTime = new Date(horaFinalDisponivel.getTime());

                let bgColor = "var(--cor-info)";
                let txtColor = "var(--cor-branco)";

                const agora = new Date();
                agora.setHours(0, 0, 0, 0);

                if (startDateTime < agora) {
                  bgColor = "var(--cor-danger)";
                  txtColor = "var(--cor-branco)";
                }

                const evento = {
                    start: startDateTime.toISOString(),
                    end: endDateTime.toISOString(),
                    title: `${nomeAgenda}`,
                    description: `${index + 1}º Horário`,
                    backgroundColor: bgColor,
                    textColor: txtColor,
                };

                eventos.push(evento);
              }
            }
        }

        // Avance para o próximo dia
        dataInicial.setTime(dataInicial.getTime() + umDiaEmMilissegundos);
    }

    return await eventos;
  }

  async gerarEventosAgendamento(dataInicial: Date, dataFinal: Date, duracaoAtendimento: string, horariosDisponiveis: any[], nomeAgenda: string, agendaSelecionada: any, empresaSigla: string) {
    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // 1 dia em milissegundos
    const eventos = [];

    const duracaoAtendimentoEmMinutos = this.converterDuracaoParaMinutos(duracaoAtendimento);

    while (dataInicial <= dataFinal) {
        const diaDaSemana = dataInicial.getDay(); // 0 (Domingo) a 6 (Sábado)

        // Verificar os horários disponíveis para o dia da semana atual
        const horariosDoDia = horariosDisponiveis[diaDaSemana].horariosDia;

        if (horariosDoDia.length > 0) {
            for (let index = 0; index < horariosDoDia.length; index++) {
                const horarioDisponivel = horariosDoDia[index];

                const horaInicialDisponivel = new Date(dataInicial);
                const horaFinalDisponivel = new Date(dataInicial);

                const [horaIni, minIni] = horarioDisponivel.hora_inicial.valor.split(':');
                const [horaFim, minFim] = horarioDisponivel.hora_final.valor.split(':');

                horaInicialDisponivel.setHours(parseInt(horaIni), parseInt(minIni), 0, 0);
                horaFinalDisponivel.setHours(parseInt(horaFim), parseInt(minFim), 0, 0);

                if (duracaoAtendimentoEmMinutos > 0) {
                  while (
                      horaInicialDisponivel.getTime() + duracaoAtendimentoEmMinutos * 60 * 1000 <= horaFinalDisponivel.getTime()
                  ) {
                      const startDateTime = new Date(horaInicialDisponivel.getTime());
                      const endDateTime = new Date(horaInicialDisponivel.getTime() + duracaoAtendimentoEmMinutos * 60 * 1000);
                      const hr_start = startDateTime.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
                      const hr_end = endDateTime.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

                      let bgColor = "var(--cor-secundaria)";
                      let txtColor = "var(--cor-branco)";

                      const agora = new Date();
                      agora.setHours(0, 0, 0, 0);

                      if (startDateTime < agora) {
                        bgColor = "var(--input-background)";
                        txtColor = "var(--text-cor-padrao)";
                      }

                      let encontrouHorario = false;

                      let diaSemana = '';

                      // while (!encontrouHorario) {
                        const semana = endDateTime.getDay();

                        switch (semana) {
                          case 0:
                            diaSemana = 'Domingo';
                            break;
                          case 1:
                            diaSemana = 'Segunda-feira';
                            break;
                          case 2:
                            diaSemana = 'Terça-feira';
                            break;
                          case 3:
                            diaSemana = 'Quarta-feira';
                            break;
                          case 4:
                            diaSemana = 'Quinta-feira';
                            break;
                          case 5:
                            diaSemana = 'Sexta-feira';
                            break;
                          case 6:
                            diaSemana = 'Sábado';
                            break;
                          default:
                            // console.log('Dia inválido');
                        }
                      // }

                // console.log(horarioDisponivel.num_pes_horario.valor)

                      for (let index = 0; index < horarioDisponivel.num_pes_horario.valor; index++) {
                        const evento = {
                          start: startDateTime.toISOString(),
                          end: endDateTime.toISOString(),
                          dia: diaSemana,
                          title: `- ${nomeAgenda}`,
                          description: `${index + 1}º Horário`,
                          backgroundColor: bgColor,
                          textColor: txtColor,
                          idAgendamento: '',
                          color: bgColor
                        };

                        eventos.push(evento);

                      }



                      horaInicialDisponivel.setTime(horaInicialDisponivel.getTime() + duracaoAtendimentoEmMinutos * 60 * 1000);
                  }
              }else {

                const startDateTime = new Date(horaInicialDisponivel.getTime());
                const endDateTime = new Date(horaFinalDisponivel.getTime());

                let bgColor = "var(--cor-secundaria)";
                let txtColor = "var(--cor-branco)";

                const agora = new Date();
                agora.setHours(0, 0, 0, 0);

                if (startDateTime < agora) {
                  bgColor = "var(--input-background)";
                  txtColor = "var(--text-cor-padrao)";
                }

                let encontrouHorario = false;

                let diaSemana = '';

                // while (!encontrouHorario) {
                  const semana = endDateTime.getDay();

                  switch (semana) {
                    case 0:
                      diaSemana = 'Domingo';
                      break;
                    case 1:
                      diaSemana = 'Segunda-feira';
                      break;
                    case 2:
                      diaSemana = 'Terça-feira';
                      break;
                    case 3:
                      diaSemana = 'Quarta-feira';
                      break;
                    case 4:
                      diaSemana = 'Quinta-feira';
                      break;
                    case 5:
                      diaSemana = 'Sexta-feira';
                      break;
                    case 6:
                      diaSemana = 'Sábado';
                      break;
                    default:
                      // console.log('Dia inválido');
                  }
                // }

                // console.log(horarioDisponivel.num_pes_horario.valor)

                for (let index = 0; index < horarioDisponivel.num_pes_horario.valor; index++) {
                  const evento = {
                    start: startDateTime.toISOString(),
                    end: endDateTime.toISOString(),
                    dia: diaSemana,
                    title: `- ${nomeAgenda}`,
                    description: `${index + 1}º Horário`,
                    backgroundColor: bgColor,
                    textColor: txtColor,
                    idAgendamento: '',
                    color: bgColor
                  };

                  eventos.push(evento);

                }
              }
            }
        }

        // Avance para o próximo dia
        dataInicial.setTime(dataInicial.getTime() + umDiaEmMilissegundos);
    }

    const agendamentos = await this.agendamentoService.p_getAgendamentos(empresaSigla);

    for (const agendamento of agendamentos) {
      if (agendaSelecionada === agendamento.agendaId) {
        const dataISOString = await this.criarISODateTime(agendamento.dataAtendimento.valor, agendamento.hora_inicial.valor);

        for (const event of eventos) {
          const comp1 = new Date(event.start).getTime();
          const comp2 = new Date(dataISOString).getTime();

          if (comp1 === comp2 && event.idAgendamento == "") {
            event.idAgendamento = agendamento.id;
            event.title = `- NF ${agendamento.notaFiscal.valor}`;

            // event.backgroundColor = "var(--cor-sucesso)";
            // event.color = "var(--cor-sucesso)";

            const status = agendamento.status.valor;
            if (status === 'Confirmado Cliente') {
              event.backgroundColor = "var(--cor-alerta)";
              event.color = "var(--cor-alerta)";
              event.textColor = "var(--cor-black)";
            } else if (status === 'Confirmado Administrador') {
              event.backgroundColor = "var(--cor-info)";
              event.color = "var(--cor-info)";
              event.textColor = "var(--cor-branco)";
            } else if (status === 'Atendido') {
              event.backgroundColor = "var(--cor-sucesso)";
              event.color = "var(--cor-sucesso)";
              event.textColor = "var(--cor-branco)";
            } else if (status === 'Não Atendido') {
              event.backgroundColor = "var(--cor-danger)";
              event.color = "var(--cor-danger)";
              event.textColor = "var(--cor-branco)";
            }
            break;
          }
        }
      }
    }

    return await eventos;
  }

  async criarISODateTime(dataString: any, horaString: any){
    const dataStr = dataString;
    const horaStr = horaString;

    const partesData = dataStr.split("/");
    const dia = parseInt(partesData[0], 10);
    const mes = parseInt(partesData[1], 10) - 1;
    const ano = parseInt(partesData[2], 10);

    const partesHora = horaStr.split(":");
    const hora = parseInt(partesHora[0], 10);
    const minuto = parseInt(partesHora[1], 10);

    const dataHora = new Date(ano, mes, dia, hora, minuto);

    const currentDate = dataHora;
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours() + 3).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');

    const isoDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    // console.log("Data e Hora:", dataHora);
    // console.log(isoDateTime);
    return isoDateTime
  }


  // Função para converter duração no formato "00:00" para minutos
  converterDuracaoParaMinutos(duracao: string): number {
    const [horas, minutos] = duracao.split(":");
    return parseInt(horas) * 60 + parseInt(minutos);
  }

}
