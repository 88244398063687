import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() {}

  // Mapeamento de nomes de validadores para funções reais
  validatorMapping: { [key: string]: ValidatorFn } = {
    'required': Validators.required,
    'email': Validators.email,
    'cpf': this.validarCPF,
    'cnpj': this.validarCNPJ
  };

  criarFormControlComValidadores(valorInicial: string, validadores: any[]) {
    return new FormControl(valorInicial, validadores);
  }

  obrigatorio(nome: string) {
    return new FormControl(nome, [Validators.required]);
  }

  criarValidacao(nome: string, validacoesCustomizadas: any[] = []) {
    let validadores: ValidatorFn[] = [];

    validacoesCustomizadas.forEach(validador => {
      const validatorFunction = this.validatorMapping[validador];
      if (validatorFunction) {
        validadores.push(validatorFunction);
      } else {
        // console.error(`Validador personalizado '${validador}' não encontrado.`);
      }
    });

    return new FormControl(nome, validadores);
  }

  aplicarMascara(valor: string, mascara: string) {

    if(mascara != ""){

      try{
        valor = valor.replace(/^\s+|\s+$/g, '');
      }catch{
        if(valor){
          const date = new Date(valor)

          if (date instanceof Date && !isNaN(date.getTime())) {
            valor = date.toLocaleDateString();
            valor = valor.replace(/^\s+|\s+$/g, '');
          }
        }

      }

      if (/^\s+$/.test(valor)) {
        return valor.replace(/^\s+|\s+$/g, '');
      }

      if(/^\s*$/.test(mascara)) {
        return valor.replace(/^\s+|\s+$/g, '');
      }

      let valorFormatado = '';
      let mascaraIndex = 0;

      const separadores = ['.', '/', '-', ':', ','];

      valor = valor.replace(/\D/g, "");

      for (let valorIndex = 0; valorIndex < valor.length && valorIndex < mascara.length; valorIndex++) {
        const charMascara = mascara.charAt(mascaraIndex);

        if (separadores.includes(charMascara)) {
          // console.log(valorFormatado, charMascara, valor.charAt(valorFormatado.length))
          if(separadores.includes(valor.charAt(valorFormatado.length))){
            valorFormatado += charMascara;
          }else{
            const index = valorFormatado.replace(/\D/g, "")
            const tm = index.length
            valorFormatado += charMascara + valor.charAt(tm);
            mascaraIndex++;

          }
          // console.log(valorFormatado)
        } else {
          valorFormatado += valor.charAt(valorIndex);
        }

        mascaraIndex++;
      }

      valorFormatado = valorFormatado.replace(/^\s+|\s+$/g, '')
      // console.log(valorFormatado)
      return valorFormatado;
    }
    return valor;
  }

  aplicarMascaraEspecial(valor: string, tipo: string){

    switch (tipo) {
      case 'real':
        if(valor != ""){
          let novoValor;
          novoValor = parseInt(valor.replace(/[\D]+/g, '')); //Remove letras
          novoValor = novoValor + '';
          novoValor = novoValor.replace(/([0-9]{2})$/g, ",$1");

          if(novoValor.length > 6){
            novoValor = novoValor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
          }
          if(novoValor.length > 10){
            novoValor = novoValor.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2,$3");
          }
          if(novoValor.length > 14){
            novoValor = novoValor.replace(/([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2.$3,$4");
          }
          if(novoValor.length > 18){
            novoValor = novoValor.replace(/([0-9]{3}).([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2.$3.$4,$5");
          }
          if(novoValor.length > 22){
            novoValor = novoValor.replace(/([0-9]{3}).([0-9]{3}).([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2.$3.$4.$5,$6");
          }

          valor = novoValor;
        }
        break;
      case 'telefone':
        if(valor != ""){
          let novoValor;

          novoValor = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito
          novoValor = novoValor.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
          novoValor = novoValor.replace(/(\d)(\d{4})$/, "$1-$2"); //(00) 00000-0000

          valor = novoValor;
        }
        break;
        default:
          break;
    }

    return valor;
  }

  validarCPF(control: AbstractControl): { [key: string]: any } | null {
    let cpf = control.value;
    if (!cpf) {
      return null; // Se o campo estiver vazio, não faz a validação
    }

    // Remova qualquer caractere que não seja dígito.
    cpf = cpf.replace(/\D/g, '');

    let soma1 = 0;
    let soma2 = 0;
    let vlr1 = 10;
    let vlr2 = 11;

    for (let i = 0; i < 9; i++) {
        soma1 += parseInt(cpf.charAt(i)) * vlr1;
        vlr1--;
    }

    for (let i = 0; i < 10; i++) {
        soma2 += parseInt(cpf.charAt(i)) * vlr2;
        vlr2--;
    }

    const resto1 = (soma1 * 10) % 11;
    const digito1 = (resto1 === 10 || resto1 === 11) ? 0 : resto1;

    const resto2 = (soma2 * 10) % 11;
    const digito2 = (resto2 === 10 || resto2 === 11) ? 0 : resto2;

    // console.log((digito1 === parseInt(cpf.charAt(9)) && digito2 === parseInt(cpf.charAt(10))));

    const cpfValido = (digito1 === parseInt(cpf.charAt(9)) && digito2 === parseInt(cpf.charAt(10)));
    // console.log(cpfValido);

    if (cpfValido) { // Substitua 'validacaoCPF' pela lógica de validação real
      return null; // CPF válido
    } else {
      return { 'cpfInvalido': true }; // CPF inválido
    }
  }

  validarCNPJ(control: AbstractControl): { [key: string]: any } | null {
    let cnpj = control.value;

    if (!cnpj) {
      return null; // Se o campo estiver vazio, não faz a validação
    }

    // Remova qualquer caractere que não seja dígito.
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj.length !== 14) {
      return { 'cnpjInvalido': true }; // CNPJ inválido
    }

    // Elimina CNPJs invalidos conhecidos
    const cnpjsInvalidosConhecidos = [
      "00000000000000",
      "11111111111111",
      "22222222222222",
      "33333333333333",
      "44444444444444",
      "55555555555555",
      "66666666666666",
      "77777777777777",
      "88888888888888",
      "99999999999999"
    ];

    if (cnpjsInvalidosConhecidos.includes(cnpj)) {
      return { 'cnpjInvalido': true }; // CNPJ inválido
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    const resultado1 = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado1 !== parseInt(digitos.charAt(0))) {
      return { 'cnpjInvalido': true }; // CNPJ inválido
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    const resultado2 = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado2 !== parseInt(digitos.charAt(1))) {
      return { 'cnpjInvalido': true }; // CNPJ inválido
    }

    return null; // CNPJ válido
  }

  validateInputPattern(valor: string, pattern: string) {
    // console.log("valor ", valor)
    try{
      const regex = new RegExp(pattern, 'g');
      // console.log(regex)
      const matches = valor.match(regex);
      const newValue = matches ? matches.join('') : '';
      // console.log("newValue ", newValue)
      return newValue;
    }catch(e){
      // console.log(e)
      // console.log("valor ", valor)
      return valor;
    }
  }

}
