import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Agenda } from 'src/class/agenda';
import { Checkin } from 'src/class/checkin';
import { Links } from 'src/class/links';
import { AuthService } from 'src/services/auth.service';
import { AgendaService } from 'src/services/component/agenda.service';
import { CheckinService } from 'src/services/component/checkin.service';
import { ContaService } from 'src/services/component/conta.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { LinksService } from 'src/services/links.service';
import { LocalhostService } from 'src/services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { ValidatorService } from 'src/services/validator.service';
import { StatusKanban } from '../../../../enum/statusKanban.enum';

declare var bootstrap: any;

@Component({
  selector: 'app-editar-checkin',
  templateUrl: './editar-checkin.component.html',
  styleUrls: ['./editar-checkin.component.css']
})
export class EditarCheckinComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertNovoCheckinModal', { static: false }) alertNovoCheckinModal!: ElementRef;

  id: any;
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  rec_pwd_load = false;

  selectedFile: File | null = null;
  uploadProgress: number | null = null;

  showBtnMdl = false;

  checkin: Checkin = new Checkin();
  checkins: Checkin[] = [];

  linksAgenda: Links[] = [];

  agendaSelecionada: number | null = null;

  agendas: Agenda[] = [];
  empresaSigla: any | undefined;

  statusKanban = StatusKanban;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private userService: AuthService,
    private linksService: LinksService,
    private validatorService: ValidatorService,
    private localhostService: LocalhostService,
    private checkinService: CheckinService,
    private agendaService: AgendaService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private notificacaoService: NotificacaoSistemaService
  ){
    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.checkinService.getCheckin(this.empresaSigla);
          this.agendaService.getAgendas(this.empresaSigla);
        });
      // }
    // });
  }

  async ngOnInit() {

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        // console.log(agendas)
        // Faça algo com as notificações atualizadas
        // if(agendas.length > 0){
          this.agendas = agendas;
        // }
      },
      error => {
        // console.error('Erro ao listar as Agendas:', error);
      }
    );

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.checkinService.getAllCheckin()
    .pipe(takeUntil(this.destroy$))
    .subscribe(checkins => {
      if(checkins.length > 0){
        this.checkins = checkins;

        for (let index = 0; index < this.checkins.length; index++) {
          const agd = this.checkins[index];
          if(agd.id == this.id){
            Object.assign(this.checkin, agd);
          }
        }

        if(this.checkin.id == null){
          this.router.navigate([`${this.empresaSigla}/checkin`]);
        }
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  rotaStatus(status: any){
    let rota = 'home'

    switch (status) {
      case this.statusKanban.aguardando:
        rota = 'checkin/aguardando'
        break;
      case this.statusKanban.emAndamento:
        rota = 'checkin/andamento'
        break;
      case this.statusKanban.realizado:
        rota = 'checkin/realizados'
        break;
      default:
        break;
    }

    return rota
  }

  async salvarCheckin(){

    this.checkin.nomeMotorista.status = this.validatorService.criarValidacao(this.checkin.nomeMotorista.valor, this.checkin.nomeMotorista.validacoesCustomizadas);
    this.checkin.cpfMotorista.status = this.validatorService.criarValidacao(this.checkin.cpfMotorista.valor, this.checkin.cpfMotorista.validacoesCustomizadas);
    this.checkin.placaVeiculo.status = this.validatorService.criarValidacao(this.checkin.placaVeiculo.valor, this.checkin.placaVeiculo.validacoesCustomizadas);
    this.checkin.veiculo.status = this.validatorService.criarValidacao(this.checkin.veiculo.valor, this.checkin.veiculo.validacoesCustomizadas);
    this.checkin.peso.status = this.validatorService.criarValidacao(this.checkin.peso.valor, this.checkin.peso.validacoesCustomizadas);
    this.checkin.telefoneMotorista.status = this.validatorService.criarValidacao(this.checkin.telefoneMotorista.valor, this.checkin.telefoneMotorista.validacoesCustomizadas);
    this.checkin.emailMotorista.status = this.validatorService.criarValidacao(this.checkin.emailMotorista.valor, this.checkin.emailMotorista.validacoesCustomizadas);
    this.checkin.notaFiscal.status = this.validatorService.criarValidacao(this.checkin.notaFiscal.valor, this.checkin.notaFiscal.validacoesCustomizadas);
    this.checkin.numeroPedido.status = this.validatorService.criarValidacao(this.checkin.numeroPedido.valor, this.checkin.numeroPedido.validacoesCustomizadas);
    this.checkin.fornecedor.status = this.validatorService.criarValidacao(this.checkin.fornecedor.valor, this.checkin.fornecedor.validacoesCustomizadas);
    this.checkin.status.status = this.validatorService.criarValidacao(this.checkin.status.valor, this.checkin.status.validacoesCustomizadas);

    if(
      this.checkin.nomeMotorista.status.invalid ||
      this.checkin.cpfMotorista.status.invalid ||
      this.checkin.placaVeiculo.status.invalid ||
      this.checkin.veiculo.status.invalid ||
      this.checkin.peso.status.invalid ||
      this.checkin.telefoneMotorista.status.invalid ||
      this.checkin.emailMotorista.status.invalid ||
      this.checkin.notaFiscal.status.invalid ||
      this.checkin.numeroPedido.status.invalid ||
      this.checkin.fornecedor.status.invalid
    ){
      this.abrirModal(this.alertNovoCheckinModal.nativeElement);

  }else{
      // console.log(this.checkin)

      // Obtenha o array existente do DataService
      let checkin = this.checkins;

      // Encontre o índice do item correspondente em checkin com base no ID
      const index = checkin.findIndex((item: Checkin) => item.id === this.checkin.id);

      if (index !== -1) {
        this.checkin.atualizado = new Date();
        // Atualize o item existente com as alterações de this.checkin
        checkin[index] = this.checkin;

        const docId = checkin[index].id != null ? checkin[index].id?.toString() : "";

        const plainObject = JSON.stringify(checkin[index])
        const plainString = JSON.parse(plainObject)

        plainString.nomeMotorista.status = null;
        plainString.cpfMotorista.status = null;
        plainString.placaVeiculo.status = null;
        plainString.veiculo.status = null;
        plainString.peso.status = null;
        plainString.telefoneMotorista.status = null;
        plainString.emailMotorista.status = null;
        plainString.notaFiscal.status = null;
        plainString.numeroPedido.status = null;
        plainString.fornecedor.status = null;
        plainString.status.status = null;

        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: salvarCheckin', conta);

          this.localhostService.putDados(`${conta.id}_checkins`, docId!, plainString)
          .then(async (response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess("Checkin atualizado com sucesso");

            // Nova notificação
            // 1 - empresaSigla: any,
            // 2 - titulo: string,
            // 3 - texto: string,
            // 4 - rotaSemParamNome: string,
            // 5 - rotaSemParamListar: string,
            // 6 - rotaComParamNome: string = "",
            // 7 - rotaComParamCaminho: string = "",
            // 8 - rotaComParamParam: string = "",
            // 9 - linkExternoNome: string = "",
            // 10 - linkExternoURL: string = "",
            // 11 - idReferencia: string,
            // 12 - tipo: string,
            // 13 - idResponsavel: string,
            // 14 - nomeResponsavel: string

            const usr = await this.userService.carregarUsuario(this.empresaSigla)

            this.notificacaoService.criarNotificacao(
              this.empresaSigla,
              `NF ${plainString.notaFiscal.valor}`,
              `Checkin atualizado.`,
              'Listar Checkins',
              'checkin/realizados',
              'Editar Checkin',
              `checkin`,
              plainString.id!.toString(),
              '',
              '',
              plainString.atendimentoId!.toString(),
              `Checkin`,
              `${usr.id}`,
              `${usr.displayName.valor}`
            )
            this.router.navigate([`${this.empresaSigla}/agendamentos`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Checkin não encontrado para atualização \n" + error);
          });

        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // this.authService.fazerLogout(this.empresaSigla);
          // console.log('Erro ao obter dados da conta:', error);
        });

        // // Salve o array de volta no DataService
        // DataService.setItem("Checkin", agendamento);

        // this.showSuccess("Checkin atualizado com sucesso");
        // this.router.navigate([`${this.empresaSigla}/agendamentos`]);
      } else {
        // O item não foi encontrado no array, você pode tratar isso conforme necessário
        this.showError("Checkin não encontrado para atualização");
      }

    }
  }

  tempo(inicial: any, duraco: any){
    // Hora Inicial no formato "hh:mm"
    const horaInicial = inicial;

    // Duração de Atendimento no formato "hh:mm"
    const duracaoAtendimento = duraco;

    // Dividir as horas e minutos da Hora Inicial
    const [hiHoras, hiMinutos] = horaInicial.split(":").map(Number);

    // Dividir as horas e minutos da Duração de Atendimento
    const [duracaoHoras, duracaoMinutos] = duracaoAtendimento.split(":").map(Number);

    // Adicionar a duração ao horário inicial
    let horasFinais = hiHoras + duracaoHoras;
    let minutosFinais = hiMinutos + duracaoMinutos;

    // Verificar e corrigir se os minutos ultrapassarem 60
    if (minutosFinais >= 60) {
      horasFinais += Math.floor(minutosFinais / 60);
      minutosFinais %= 60;
    }

    // Formatar o Hora Final com 2 dígitos para horas e minutos
    const horaFinal = `${horasFinais.toString().padStart(2, "0")}:${minutosFinais.toString().padStart(2, "0")}`;

    // console.log("Hora Final:", horaFinal);

    return horaFinal;
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
