import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-ag-tempo-decorrido',
  templateUrl: './ag-tempo-decorrido.component.html',
  styleUrls: ['./ag-tempo-decorrido.component.css']
})
export class AgTempoDecorridoComponent implements OnInit, OnDestroy {
  @Input() inicio: string = '';
  tempoDecorrido: string = '';
  private subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(
      interval(1000).pipe(
        map(() => this.calcularTempoDecorrido())
      ).subscribe(tempo => this.tempoDecorrido = tempo)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    // console.log("ngOnDestroy AgTempoDecorridoComponent")
  }

  calcularTempoDecorrido(): string {
    // console.log(this.inicio)
    const now = new Date();
    const givenDate = new Date(this.inicio);
    const diffMs = now.getTime() - givenDate.getTime(); // diferença em milissegundos
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24)); // dias
    const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // horas
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // minutos
    const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000); // segundos

    const formattedDays = diffDays.toString().padStart(2, '0');
    const formattedHours = diffHours.toString().padStart(2, '0');
    const formattedMinutes = diffMinutes.toString().padStart(2, '0');
    const formattedSeconds = diffSeconds.toString().padStart(2, '0');

    return `${formattedDays == '00' ? '' : formattedDays+'D'} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
}
