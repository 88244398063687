import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Links } from 'src/class/links';
import { objUsuario } from 'src/class/usuario';
import { LinksService } from 'src/services/links.service';
import { ThemeService } from 'src/services/theme.service';
import { ToastService } from 'src/services/toast.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';
import { LocalhostService } from 'src/services/localhost.service';
import { WhatsappService } from 'src/services/whatsapp.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ContaService } from 'src/services/component/conta.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { AuthService } from 'src/services/auth.service';
import { Checkin, Comentario } from 'src/class/checkin';
import { CheckinService } from 'src/services/component/checkin.service';
import { StatusKanban } from 'src/enum/statusKanban.enum';
import { ValidatorService } from 'src/services/validator.service';
import { ContadorIndexService } from 'src/services/contador-index.service';

declare var bootstrap: any;

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.css']
})
export class CheckinComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('filtro', { static: false }) filtro!: ElementRef;
  @ViewChild('naoRealizadoModal', { static: false }) naoRealizadoModal!: ElementRef;


  modalsMap = new Map<ElementRef, any>();

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj = new objUsuario;

  lightMode: any;

  private subscription: Subscription;

  linksAgenda: Links[] = [];

  statusCheckin = StatusKanban;

  checkins: Checkin[] = [];
  checkins_aguardando: Checkin[] = [];
  checkins_em_andamento: Checkin[] = [];
  checkins_realizados: Checkin[] = [];
  displayedCheckins: Checkin[] = [];
  loading: boolean = true;
  sortedData: Checkin[] = [];

  loader = this.loadingBar.useRef('http');

  status = '';
  classificado = '';
  dataCheckin = '';
  empresaSigla: any | undefined;

  naoRealizadoObj: Checkin = new Checkin();
  comentario: Comentario = new Comentario();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService,
    private linksService: LinksService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private loadingBar: LoadingBarService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    private notificacaoService: NotificacaoSistemaService,
    private localhostService: LocalhostService,
    private whatsapp: WhatsappService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private userService: AuthService,
    private checkinService: CheckinService,
    private validatorService: ValidatorService,
    private contadorIndexService: ContadorIndexService
  ){
    this.subscription = this.themeService.lightMode$.subscribe(lightMode => {
      this.lightMode = lightMode;
    });
    // Alimenta o serviço com a empresaSigla
    this.route.params
    .pipe(takeUntil(this.destroy$))
    .subscribe(params => {
      this.empresaSigla = params['empresaSigla'];
      this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
      this.checkinService.getCheckin(this.empresaSigla);
    });
  }

  ngOnInit() {
    this.getCache();
    this.linksAgenda = this.linksService.getLinksAgenda();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  getCache(){

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    this.dataCheckin = 'Hoje';

    this.updateCheckins();
  }

  updateCheckins() {
    this.checkins_aguardando = [];
    this.checkins_em_andamento = [];
    this.checkins_realizados = [];
    this.getCheckinAguardando();
    this.getCheckinEmAndamento();
    this.getCheckinRealizado();
  }

  getCheckinAguardando(){
    this.checkinService.getStatusCheckin(this.statusCheckin.aguardando)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (checkins: Checkin[]) => {
        // console.log(checkins)
        if(checkins.length > 0){
          this.checkins_aguardando = checkins;
          // this.ordenadoPor('Maior Data/Horário');
          this.loading = false;
        }else{
          setTimeout(() => {
            this.loading = false;
          }, 5000);
        }
      },
      error => {
        // console.error('Erro ao listar os Agendamentos:', error);
      }
    );
  }

  getCheckinEmAndamento(){
    this.checkinService.getStatusCheckin(this.statusCheckin.emAndamento)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (checkins: Checkin[]) => {
        // console.log(checkins)
        if(checkins.length > 0){
          this.checkins_em_andamento = checkins;
          // this.ordenadoPor('Maior Data/Horário');
          this.loading = false;
        }else{
          setTimeout(() => {
            this.loading = false;
          }, 5000);
        }
      },
      error => {
        // console.error('Erro ao listar os Agendamentos:', error);
      }
    );
  }

  getCheckinRealizado(){
    this.checkinService.getRealizadoCheckinsDia(this.statusCheckin.realizado)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (checkins: Checkin[]) => {
        // console.log(checkins)
        if(checkins.length > 0){
          this.checkins_realizados = checkins;
          // this.ordenadoPor('Maior Data/Horário');
          this.loading = false;
        }else{
          setTimeout(() => {
            this.loading = false;
          }, 5000);
        }
      },
      error => {
        // console.error('Erro ao listar os Agendamentos:', error);
      }
    );
  }

  deletarAgendamento(id: any){
    const index = this.checkins.findIndex(agenda => agenda.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja excluir o agendamento?';
      let mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                        <p class="ag-mb-5">
                          <span>NF ${this.checkins[index].notaFiscal.valor}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span>
                            ${this.checkins[index].status.valor === 'Confirmado cliente' || this.checkins[index].status.valor === 'Confirmado administrador' ? "<i class='bi bi-circle-fill text-cor-info ag-pr-5'></i>" : this.checkins[index].status.valor === 'Atendido' ? "<i class='bi bi-circle-fill text-cor-sucesso ag-pr-5'></i>" : this.checkins[index].status.valor === 'Não atendido' ? "<i class='bi bi-circle-fill text-cor-danger ag-pr-5'></i>" : "<i class='bi bi-circle-fill ag-pr-5'></i>"}
                            ${this.checkins[index].status.valor}
                          </span>
                        </p>
                      </div>`;

          this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
            if (confirmation) {
              // console.log('SIM!');
              // Encontrou um item com o ID correspondente, exclua-o

              const docId = this.checkins[index].id != null ? this.checkins[index].id?.toString() : "";

              await this.conta.p_getSiglaConta('contas', this.empresaSigla)
              .then((conta) => {
                // Faça algo com os dados obtidos
                // console.log('Dados da conta: criarAgenda', conta);

                this.localhostService.deleteDados(`${conta.id}_agendamentos`, { id: docId })
                .then(async (response: any) => {
                  // console.log('Atualização bem-sucedida:', response);
                  // Salve o array de volta no DataService
                  // DataService.set("Agenda", this.agendas)
                  // localStorage.setItem("Agenda", JSON.stringify(this.agendas))
                  this.showSuccess(`Agendamento deletado com sucesso`);

                  const usr = await this.userService.carregarUsuario(this.empresaSigla)

                  this.notificacaoService.criarNotificacao(
                    this.empresaSigla, // 1 - empresaSigla: any,
                    `NF ${this.checkins[index].notaFiscal.valor}`, // 2 - titulo: string,
                    `O checkin`, // 3 - texto: string,
                    'Listar Agendamentos', // 4 - rotaSemParamNome: string,
                    'checkins', // 5 - rotaSemParamListar: string,
                    '', // 6 - rotaComParamNome: string = "",
                    ``, // 7 - rotaComParamCaminho: string = "",
                    ``, // 8 - rotaComParamParam: string = "",
                    '', // 9 - linkExternoNome: string = "",
                    '', // 10 - linkExternoURL: string = "",
                    this.checkins[index].atendimentoId!.toString(), // 11 - idReferencia: string,
                    `Agendamento`, // 12 - tipo: string,
                    `${usr.id}`, // 13 - idResponsavel: string,
                    `${usr.displayName.valor}` // 14 - nomeResponsavel: string
                  )

                  this.displayedCheckins = this.checkins;

                })
                .catch((error: any) => {
                  // console.error('Erro ao deletar dados:', error);
                  this.showError("Agendamento não encontrado para exclusão \n" + error);
                });
              },
              (error: any) => {
                // console.error('Erro ao atualizar dados:', error);
                // Trate o erro conforme necessário
              }).catch((error) => {
                // Trate o erro
                // this.authService.fazerLogout(this.empresaSigla);
                // console.log('Erro ao obter dados da conta:', error);
              });

            } else {
              // console.log('NÃO');
            }
          });

    } else {
      // Não encontrou um item com o ID correspondente
      this.showError("Agendamento não encontrado para exclusão");
    }
  }

  atualizarStatusCheckinEmAndamento(id: any, status: string){

    const index = this.checkins_aguardando.findIndex((item: Checkin) => item.id === id);

    if (index !== -1 && status != '') {
      let titulo = "";
      let mensagem = "";
      titulo = 'Tem certeza que deseja alterar o status?';
      mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                    <p class="ag-mb-5">
                      <span>NF ${this.checkins_aguardando[index].notaFiscal.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-danger">Status atual: </span>
                      <span>${this.checkins_aguardando[index].status.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-sucesso">Novo status: </span>
                      <span>${this.statusCheckin.emAndamento}</span>
                    </p>
                  </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Atualize o item existente
          this.checkins_aguardando[index].status.valor = status;
          this.checkins_aguardando[index].statusAguardando.fim = new Date();
          this.checkins_aguardando[index].statusEmAndamento.inicio = new Date();
          this.checkins_aguardando[index].atualizado = new Date();

          const docId = this.checkins_aguardando[index].id != null ? this.checkins_aguardando[index].id?.toString() : "";
          const plainObject = JSON.stringify(this.checkins_aguardando[index])
          const plainString = JSON.parse(plainObject)

          const fieldsToReset = [
            'nomeMotorista',
            'cpfMotorista',
            'placaVeiculo',
            'veiculo',
            'peso',
            'telefoneMotorista',
            'emailMotorista',
            'notaFiscal',
            'numeroPedido',
            'fornecedor',
            'status'
          ];

          fieldsToReset.forEach(field => {
            plainString[field].status = null;
          });

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: atualizarStatusAgendamento', conta);

            this.localhostService.putDados(`${conta.id}_checkins`, docId! , plainString)
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              // Salve o array de volta no DataService
              // DataService.set("Agenda", this.agendas)
              // localStorage.setItem("Agenda", JSON.stringify(this.agendas))
              this.showSuccess(`Status do checkin NF ${plainString.notaFiscal.valor} alterado para ${plainString.status.valor}`);

              const usr = await this.userService.carregarUsuario(this.empresaSigla)

              this.notificacaoService.criarNotificacao(
                this.empresaSigla, // 1 - empresaSigla: any,
                `NF ${plainString.notaFiscal.valor}`, // 2 - titulo: string,
                `O status do checkin, foi alterado para ${plainString.status.valor}.`, // 3 - texto: string,
                'Listar Checkins', // 4 - rotaSemParamNome: string,
                'checkin/andamento', // 5 - rotaSemParamListar: string,
                'Editar Checkin', // 6 - rotaComParamNome: string = "",
                `checkin`, // 7 - rotaComParamCaminho: string = "",
                plainString.id!.toString(), // 8 - rotaComParamParam: string = "",
                '', // 9 - linkExternoNome: string = "",
                '', // 10 - linkExternoURL: string = "",
                plainString.atendimentoId!.toString(), // 11 - idReferencia: string,
                `Checkin`, // 12 - tipo: string,
                `${usr.id}`, // 13 - idResponsavel: string,
                `${usr.displayName.valor}` // 14 - nomeResponsavel: string
              )

              this.updateCheckins();
            })
            .catch((error: any) => {
              // console.error('Erro ao atualizar dados:', error);
              this.showError("Checkin não encontrado para atualização \n" + error);
            });
          },
          (error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // this.authService.fazerLogout(this.empresaSigla);
            // console.log('Erro ao obter dados da conta:', error);
          });

          // localStorage.setItem("Agendamento", JSON.stringify(this.agendamentos))
          // this.showSuccess(`Agendamento alterado para ${this.agendamentos[index].status.valor}`);

        } else {
          // console.log('NÃO');
        }
      });

    }
  }

  atualizarStatusCheckinRealizado(id: any, status: string){

    const index = this.checkins_em_andamento.findIndex((item: Checkin) => item.id === id);

    if (index !== -1 && status != '') {
      let titulo = "";
      let mensagem = "";
      titulo = 'Tem certeza que deseja alterar o status?';
      mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                    <p class="ag-mb-5">
                      <span>NF ${this.checkins_em_andamento[index].notaFiscal.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-danger">Status atual: </span>
                      <span>${this.checkins_em_andamento[index].status.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-sucesso">Novo status: </span>
                      <span>${this.statusCheckin.realizado}</span>
                    </p>
                  </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Atualize o item existente
          this.checkins_em_andamento[index].status.valor = status;
          this.checkins_em_andamento[index].statusEmAndamento.fim = new Date();
          this.checkins_em_andamento[index].statusRealizado.inicio = new Date();
          this.checkins_em_andamento[index].statusRealizado.fim = new Date();
          this.checkins_em_andamento[index].atualizado = new Date();

          const docId = this.checkins_em_andamento[index].id != null ? this.checkins_em_andamento[index].id?.toString() : "";
          const plainObject = JSON.stringify(this.checkins_em_andamento[index])
          const plainString = JSON.parse(plainObject)

          const fieldsToReset = [
            'nomeMotorista',
            'cpfMotorista',
            'placaVeiculo',
            'veiculo',
            'peso',
            'telefoneMotorista',
            'emailMotorista',
            'notaFiscal',
            'numeroPedido',
            'fornecedor',
            'status'
          ];

          fieldsToReset.forEach(field => {
            plainString[field].status = null;
          });

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: atualizarStatusAgendamento', conta);

            this.localhostService.putDados(`${conta.id}_checkins`, docId! , plainString)
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              // Salve o array de volta no DataService
              // DataService.set("Agenda", this.agendas)
              // localStorage.setItem("Agenda", JSON.stringify(this.agendas))
              this.showSuccess(`Status do checkin NF ${plainString.notaFiscal.valor} alterado para ${plainString.status.valor}`);

              const usr = await this.userService.carregarUsuario(this.empresaSigla)

              this.notificacaoService.criarNotificacao(
                this.empresaSigla, // 1 - empresaSigla: any,
                `NF ${plainString.notaFiscal.valor}`, // 2 - titulo: string,
                `O status do checkin, foi alterado para ${plainString.status.valor}.`, // 3 - texto: string,
                'Listar Checkins', // 4 - rotaSemParamNome: string,
                'checkin/realizados', // 5 - rotaSemParamListar: string,
                'Editar Checkin', // 6 - rotaComParamNome: string = "",
                `checkin`, // 7 - rotaComParamCaminho: string = "",
                plainString.id!.toString(), // 8 - rotaComParamParam: string = "",
                '', // 9 - linkExternoNome: string = "",
                '', // 10 - linkExternoURL: string = "",
                plainString.atendimentoId!.toString(), // 11 - idReferencia: string,
                `Checkin`, // 12 - tipo: string,
                `${usr.id}`, // 13 - idResponsavel: string,
                `${usr.displayName.valor}` // 14 - nomeResponsavel: string
              )
              this.updateCheckins();
            })
            .catch((error: any) => {
              // console.error('Erro ao atualizar dados:', error);
              this.showError("Checkin não encontrado para atualização \n" + error);
            });
          },
          (error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // this.authService.fazerLogout(this.empresaSigla);
            // console.log('Erro ao obter dados da conta:', error);
          });

          // localStorage.setItem("Agendamento", JSON.stringify(this.agendamentos))
          // this.showSuccess(`Agendamento alterado para ${this.agendamentos[index].status.valor}`);

        } else {
          // console.log('NÃO');
        }
      });

    }
  }

  atualizarStatusCheckinNaoRealizado(id: any){

    const checkin = [
      ...(this.checkins_aguardando.length > 0 ? this.checkins_aguardando : []),
      ...(this.checkins_em_andamento.length > 0 ? this.checkins_em_andamento : []),
      ...(this.checkins_realizados.length > 0 ? this.checkins_realizados : [])
    ];
    // console.log(checkin)
    const index = checkin.findIndex((item: Checkin) => item.id === id);
    // console.log(index)

    if (index !== -1) {
      let titulo = "";
      let mensagem = "";
      titulo = 'Tem certeza que deseja alterar o status?';
      mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                    <p class="ag-mb-5">
                      <span>NF ${checkin[index].notaFiscal.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-danger">Status atual: </span>
                      <span>${checkin[index].status.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-sucesso">Novo status: </span>
                      <span>${this.statusCheckin.naoRealizado}</span>
                    </p>
                  </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Atualize o item existente
          this.naoRealizadoObj = Object.assign(new Checkin(), checkin[index]);
          this.abrirModal(this.naoRealizadoModal.nativeElement);
        } else {
          // console.log('NÃO');
          this.resetarNaoRealizado();
        }
      });

    }
  }

  async naoRealizado(id: any){

    const checkin = [
      ...(this.checkins_aguardando.length > 0 ? this.checkins_aguardando : []),
      ...(this.checkins_em_andamento.length > 0 ? this.checkins_em_andamento : []),
      ...(this.checkins_realizados.length > 0 ? this.checkins_realizados : [])
    ];

    const index = checkin.findIndex((item: Checkin) => item.id === id);

    this.comentario.observacao.status = this.validatorService.criarValidacao(this.comentario.observacao.valor, this.comentario.observacao.validacoesCustomizadas);

    if(this.comentario.observacao.status.invalid){
      this.showError("Alguns campos precisam ser preenchidos.");
    }else{

      if (index !== -1) {

        // console.log(checkin[index].id)
        const usr = await this.userService.carregarUsuario(this.empresaSigla)

        checkin[index].status.valor = this.statusCheckin.naoRealizado;
        checkin[index].statusNaoRealizado.fim = new Date();
        checkin[index].statusNaoRealizado.inicio = new Date();
        checkin[index].atualizado = new Date();

        this.comentario.observacao.status = null;
        this.comentario.responsavel.idUsuario = usr.id;
        this.comentario.responsavel.nomeUsuario = usr.displayName.valor;
        this.comentario.id = this.contadorIndexService.contadorIndex();

        checkin[index].statusNaoRealizado.comentarios.push(this.comentario);

        const docId = checkin[index].id != null ? checkin[index].id?.toString() : "";
        const plainObject = JSON.stringify(checkin[index])
        const plainString = JSON.parse(plainObject)

        const fieldsToReset = [
          'nomeMotorista',
          'cpfMotorista',
          'placaVeiculo',
          'veiculo',
          'peso',
          'telefoneMotorista',
          'emailMotorista',
          'notaFiscal',
          'numeroPedido',
          'fornecedor',
          'status'
        ];

        fieldsToReset.forEach(field => {
          plainString[field].status = null;
        });

        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: atualizarStatusAgendamento', conta);

          this.localhostService.putDados(`${conta.id}_checkins`, docId! , plainString)
          .then(async (response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            // Salve o array de volta no DataService
            // DataService.set("Agenda", this.agendas)
            // localStorage.setItem("Agenda", JSON.stringify(this.agendas))
            this.showSuccess(`Status do checkin NF ${plainString.notaFiscal.valor} alterado para ${plainString.status.valor}`);

            this.notificacaoService.criarNotificacao(
              this.empresaSigla, // 1 - empresaSigla: any,
              `NF ${plainString.notaFiscal.valor}`, // 2 - titulo: string,
              `O status do checkin, foi alterado para ${plainString.status.valor}.`, // 3 - texto: string,
              'Listar Checkins', // 4 - rotaSemParamNome: string,
              'checkin/andamento', // 5 - rotaSemParamListar: string,
              'Editar Checkin', // 6 - rotaComParamNome: string = "",
              `checkin`, // 7 - rotaComParamCaminho: string = "",
              plainString.id!.toString(), // 8 - rotaComParamParam: string = "",
              '', // 9 - linkExternoNome: string = "",
              '', // 10 - linkExternoURL: string = "",
              plainString.atendimentoId!.toString(), // 11 - idReferencia: string,
              `Checkin`, // 12 - tipo: string,
              `${usr.id}`, // 13 - idResponsavel: string,
              `${usr.displayName.valor}` // 14 - nomeResponsavel: string
            )

            this.updateCheckins();
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Checkin não encontrado para atualização \n" + error);
          });
        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // this.authService.fazerLogout(this.empresaSigla);
          // console.log('Erro ao obter dados da conta:', error);
        });

        this.fecharModal(this.naoRealizadoModal.nativeElement);
        this.resetarNaoRealizado();
      }
    }
  }

  resetarNaoRealizado(){
    this.naoRealizadoObj = new Checkin();
    this.comentario = new Comentario();
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    // const modal = new bootstrap.Modal(event);
    // modal.show();
    const modalElement = event;
    if (modalElement) {
        const modalInstance = new bootstrap.Modal(modalElement);
        this.modalsMap.set(event, modalInstance); // Armazenar a instância do modal no mapa
        modalInstance.show();
    } else {
        // console.error("Elemento do modal não encontrado");
    }
  }

  fecharModal(event: any){
    // console.log(event)
    // const modal = new bootstrap.Modal(event);
    // modal.hide();
    const modalInstance = this.modalsMap.get(event);
    if (modalInstance) {
        modalInstance.hide(); // Fechar o modal
    } else {
        // console.error("Instância do modal não encontrada");
    }
  }

  ordenadoPor(param: string): void {
    let sortedAgendamentos: Checkin[] = [];
    this.classificado = param;

    switch (param) {
      case "Maior Data/Horário":
        sortedAgendamentos = this.checkins.sort((a, b) => this.compareData(b, a));
        break;
      case "Menor Data/Horário":
        sortedAgendamentos = this.checkins.sort((a, b) => this.compareData(a, b));
        break;
      default:
        break;
    }

    this.checkins = sortedAgendamentos;
    this.displayedCheckins = this.checkins;
  }


  private compareData(agendamentoA: Checkin, agendamentoB: Checkin): number {
    const dataA = new Date(agendamentoA.criado);
    const dataB = new Date(agendamentoB.criado);

    return dataA.getTime() - dataB.getTime();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }


}
