import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Agenda } from 'src/class/agenda';
import { FormCampos } from 'src/class/formCampos';
import { Agendas, RegraCampoFixo, Slot } from 'src/class/slot';
import { newUsuario } from 'src/class/usuario';
import { TipoSlot } from 'src/enum/tipoSlot.enum';
import { CepService } from 'src/services/cep.service';
import { AgendaService } from 'src/services/component/agenda.service';
import { SlotService } from 'src/services/component/slot.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { DataService } from 'src/services/data.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-criar-slot',
  templateUrl: './criar-slot.component.html',
  styleUrls: ['./criar-slot.component.css']
})
export class CriarSlotComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('alertSlotModal', { static: false }) alertSlotModal!: ElementRef;
  @ViewChild('campoFixoModal', { static: false }) campoFixoModal!: ElementRef;
  @ViewChild('campoFixoModalEditar', { static: false }) campoFixoModalEditar!: ElementRef;
  @ViewChild('resources', {static: true}) resources!: MatSelectionList;

  modalsMap = new Map<ElementRef, any>();

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  slot: Slot = new Slot();
  slots: Slot[] = [];
  empresaSigla: any | undefined;

  formCampos: FormCampos = new FormCampos();
  formCamposSelecionados: any;

  tipoSlot = TipoSlot;
  tipoSlotArray = Object.values(this.tipoSlot).map((slot) => ({
    nome: slot,
    nomeDesc: "",
    valor: slot
  }));

  tipoSlotSelecionados: any;
  objRegras = new RegraCampoFixo();

  agendas: any[] = [];

  constructor(
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private slotService: SlotService,
    private route: ActivatedRoute,
    private siglaService: SiglaService,
    private agendaService: AgendaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.agendaService.getAgendas(this.empresaSigla);
        });
      }
    });
  }

  async ngOnInit() {

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        // console.log(agendas)
        // Faça algo com as notificações atualizadas

        agendas.sort((a, b) => {
          const nomeA = a.nome.valor.toUpperCase();
          const nomeB = b.nome.valor.toUpperCase();
          if (nomeA < nomeB) {
            return -1;
          }
          if (nomeA > nomeB) {
            return 1;
          }
          return 0;
        });

        this.agendas = agendas;

        // setTimeout(() => {
        //   const rec = this.agendas.slice(0,5)
        //   this.selectAllResources(rec);
        // }, 1000);

      },
      error => {
        // console.error('Erro ao listar os Tipos:', error);
        this.router.navigate(['/home']);
      }
    );

  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async criarSlot(){
    this.slot.nome.status = this.validatorService.criarValidacao(this.slot.nome.valor, this.slot.nome.validacoesCustomizadas);
    this.slot.descricao.status = this.validatorService.criarValidacao(this.slot.descricao.valor, this.slot.descricao.validacoesCustomizadas);
    this.slot.duracaoSlot.status = this.validatorService.criarValidacao(this.slot.duracaoSlot.valor, this.slot.descricao.validacoesCustomizadas);

    if(this.slot.nome.status.invalid ||
      this.slot.descricao.status.invalid ||
      this.slot.duracaoSlot.status.invalid
    ){
      this.abrirModal(this.alertSlotModal.nativeElement);
    }else{

      this.slot.id = await this.contadorIndexService.contadorIndex();
      let new_slot  = new Slot();
      new_slot = this.slot;

      new_slot.criado = new Date();
      new_slot.atualizado = new Date();

      for (let index = 0; index < this.resources.selectedOptions.selected.length; index++) {
        const element = this.resources.selectedOptions.selected[index];
        // console.log(element.value)
        const rec = new Agendas();

        rec.idAgenda = element.value.id
        rec.NomeAgenda = element.value.nome.valor

        new_slot.agendas.push(rec)
      }

      const slot = JSON.stringify(new_slot);
      const res = await this.slotService.criarSlot(JSON.parse(slot), this.empresaSigla)

      // this.showSuccess("Slot criado com sucesso");
      this.slot = new Slot();
      this.router.navigate([`${this.empresaSigla}/slots`]);
    }
  }

  abrirModal(event: any) {
    const modalElement = event;
    if (modalElement) {
        const modalInstance = new bootstrap.Modal(modalElement);
        this.modalsMap.set(event, modalInstance); // Armazenar a instância do modal no mapa
        modalInstance.show();
    } else {
        // console.log("Elemento do modal não encontrado");
    }
  }

  fecharModal(event: any){
    const modalInstance = this.modalsMap.get(event);
    if (modalInstance) {
        modalInstance.hide(); // Fechar o modal
    } else {
        // console.log("Instância do modal não encontrada");
    }
  }

  fecharModalRegra(event: any){
    const modalInstance = this.modalsMap.get(event);
    if (modalInstance) {
        modalInstance.hide(); // Fechar o modal
        this.formCamposSelecionados = undefined;
        this.tipoSlotSelecionados = undefined;
        this.objRegras = new RegraCampoFixo();
    } else {
        // console.log("Instância do modal não encontrada");
    }
  }

  openModalCampoFixo(){
    this.abrirModal(this.campoFixoModal.nativeElement);
  }

  async addRegra(){
    // console.log(this.formCamposSelecionados)
    // console.log(this.tipoSlotSelecionados)

    if(this.formCamposSelecionados && this.tipoSlotSelecionados){

      this.objRegras.regraValor1.status = this.validatorService.criarValidacao(this.objRegras.regraValor1.valor, this.objRegras.regraValor1.validacoesCustomizadas);

      if(this.tipoSlotSelecionados == this.tipoSlot.estaEntre || this.tipoSlotSelecionados == this.tipoSlot.naoEstaEntre){
        this.objRegras.regraValor2.status = this.validatorService.criarValidacao(this.objRegras.regraValor2.valor, this.objRegras.regraValor2.validacoesCustomizadas);
      }else{
        this.objRegras.regraValor2.status = this.validatorService.criarValidacao(this.objRegras.regraValor2.valor, []);
        this.objRegras.regraValor2.valor = "";
      }

      if(this.objRegras.regraValor1.status.invalid || this.objRegras.regraValor2.status.invalid){
        this.showError("Informe o valor da regra");
      }else{

        const nova_regra = new RegraCampoFixo();
        nova_regra.id = await this.contadorIndexService.contadorIndex();

        // Criar novos objetos para cada propriedade
        nova_regra.nomeCampo.valor = this.formCamposSelecionados;
        nova_regra.regraCampo.valor = this.tipoSlotSelecionados;
        nova_regra.regraValor1.valor = this.objRegras.regraValor1.valor;
        nova_regra.regraValor2.valor = this.objRegras.regraValor2.valor;

        this.slot.regraCampoFixo.push(nova_regra);

        this.showSuccess("Regra adicionada com sucesso!!");
        this.fecharModalRegra(this.campoFixoModal.nativeElement);
        // console.log(this.slot);
      }
    }else{
      // console.log("Selecione o campo, a regra e o valor para adicionar a regra.")
      this.showError("Selecione o campo, a regra e o valor para adicionar.")
    }
  }

  openModalEditarCampoFixo(campoFixo: RegraCampoFixo){
    this.abrirModal(this.campoFixoModalEditar.nativeElement);

    this.formCamposSelecionados = campoFixo.nomeCampo.valor;
    this.tipoSlotSelecionados = campoFixo.regraCampo.valor;

    this.objRegras = new RegraCampoFixo();
    Object.assign(this.objRegras, campoFixo);

  }

  async atualizarRegra(id: any){
    // console.log(this.formCamposSelecionados)
    // console.log(this.tipoSlotSelecionados)
    // console.log(this.objRegras)

    if(this.formCamposSelecionados && this.tipoSlotSelecionados){

      this.objRegras.regraValor1.status = this.validatorService.criarValidacao(this.objRegras.regraValor1.valor, this.objRegras.regraValor1.validacoesCustomizadas);

      if(this.tipoSlotSelecionados == this.tipoSlot.estaEntre || this.tipoSlotSelecionados == this.tipoSlot.naoEstaEntre){
        this.objRegras.regraValor2.status = this.validatorService.criarValidacao(this.objRegras.regraValor2.valor, this.objRegras.regraValor2.validacoesCustomizadas);
      }else{
        this.objRegras.regraValor2.status = this.validatorService.criarValidacao(this.objRegras.regraValor2.valor, []);
        this.objRegras.regraValor2.valor = "";
      }

      if(this.objRegras.regraValor1.status.invalid || this.objRegras.regraValor2.status.invalid){
        this.showError("Informe o valor da regra");
      }else{

        const nova_regra = new RegraCampoFixo();
        // Criar novos objetos para cada propriedade
        nova_regra.id = this.objRegras.id;
        nova_regra.nomeCampo.valor = this.formCamposSelecionados;
        nova_regra.regraCampo.valor = this.tipoSlotSelecionados;
        nova_regra.regraValor1.valor = this.objRegras.regraValor1.valor;
        nova_regra.regraValor2.valor = this.objRegras.regraValor2.valor;

        const indice = this.slot.regraCampoFixo.findIndex(regra => regra.id === id);

        if (indice !== -1) {
          // this.slot.regraCampoFixo.push(nova_regra);
          Object.assign(this.slot.regraCampoFixo[indice], nova_regra);
          this.showSuccess("Regra atualizada com sucesso!!");
          this.fecharModalRegra(this.campoFixoModalEditar.nativeElement);
          // console.log(this.slot);
        }

      }
    }else{
      // console.log("Selecione o campo, a regra e o valor para adicionar a regra.")
      this.showError("Selecione o campo, a regra e o valor para adicionar.")
    }
  }

  deletarRegra(idRegra: any){

    for (let i = 0; i < this.slot.regraCampoFixo.length; i++) {
      const regras = this.slot.regraCampoFixo;
      const nomeCampo = this.slot.regraCampoFixo[i].nomeCampo;
      const indiceParaExcluir = regras.findIndex(regra => regra.id === idRegra);

      if (indiceParaExcluir !== -1) {
        // console.log(`${nomeCampo.valor} <br> ${regras[indiceParaExcluir].regraCampo.valor} ${regras[indiceParaExcluir].regraValor1.valor} ${regras[indiceParaExcluir].regraValor2.valor != '' ? ` e ${regras[indiceParaExcluir].regraValor2.valor}` : `${regras[indiceParaExcluir].regraValor2.valor}`} <br>Regra deletada com sucesso!!<br>`)
        this.showError(`${nomeCampo.valor} <br> ${regras[indiceParaExcluir].regraCampo.valor} ${regras[indiceParaExcluir].regraValor1.valor} ${regras[indiceParaExcluir].regraValor2.valor != '' ? ` e ${regras[indiceParaExcluir].regraValor2.valor}` : `${regras[indiceParaExcluir].regraValor2.valor}`} <br>Regra deletada com sucesso!!<br>`)

        regras.splice(indiceParaExcluir, 1);
        break;
      }

    }
  }

  selectAllResources(agendas: any[]): void {
    const time = setInterval(()=>{
      if(this.resources && this.resources.options){
        clearInterval(time);

        agendas.forEach((rec: any) => {
            const resourceId = rec.id; // Obtém o ID do recurso
            const option = this.resources.options.toArray().find((opt: any) => opt.value.id === resourceId);
            if(option){
              option.selected = true; // Seleciona a opção com o ID correspondente
            }
        });

      }
    })
  }

  getSelectedResources() {
    for (let index = 0; index < this.resources.selectedOptions.selected.length; index++) {
      const element = this.resources.selectedOptions.selected[index];
      // console.log(element.value)
    }
    return this.resources.selectedOptions.selected.map(option => option.value);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}

