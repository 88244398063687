<div style=" display: flex; align-items: center; ">
  <label for="{{id}}" class="form-label label-input-text-padrao" style="display: flex; align-items: center; flex-wrap: nowrap;margin-bottom: 5px;">
    {{label}}
  </label>
  <span mat-raised-button #idTooltip="matTooltip" [matTooltip]="tooltip" (click)="idTooltip.show()" matTooltipPosition="right" class="tooltip-input-icon" style="margin-bottom: 5px;">
    <i class="bi bi-info-circle-fill"></i>
  </span>
  <div *ngIf="isInvalid">
    <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.required">Obrigatório</p>
    <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.cnpjInvalido">CNPJ Inválido</p>
    <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.cpfInvalido">CPF Inválido</p>
    <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.email">E-mail Inválido</p>
  </div>
</div>

<!-- inicialmente para todas as classes que possuem o tipo checkbox de interface -->
<div class="d-flex flex-column align-items-start flex-wrap mb-3" *ngIf="AgSelectType == 'checkbox'" >
  <ng-select  [appendTo]="AgAppendTo"
              [id]="id"
              [placeholder]="AgPlaceholder"
              [multiple]="AgMultiple"
              [closeOnSelect]="AgCloseOnSelect"
              [(ngModel)]="innerValue"
              (ngModelChange)="onValueChange($event)">
    <ng-option *ngFor="let item of AgOptions" [value]="item.nome">
      {{item.nome}}
    </ng-option>
  </ng-select>
</div>

<!-- inicialmente para todas as classes que possuem o tipo campo de interface -->
<div class="d-flex flex-column align-items-start flex-wrap mb-3" *ngIf="AgSelectType == 'campo'">
  <ng-select  [id]="id"
              [placeholder]="AgPlaceholder"
              [multiple]="AgMultiple"
              [closeOnSelect]="AgCloseOnSelect"
              [(ngModel)]="innerValue"
              (ngModelChange)="onValueChange($event)">
    <ng-option *ngFor="let item of AgOptions" [value]="item.id" [disabled]="!item.status.valor">
      {{item.nome.valor}} <span style=" font-size: 10px; font-style: italic; font-weight: bold; letter-spacing: .7px; padding: 0px 5px; ">{{item.id}}</span>
    </ng-option>
  </ng-select>
</div>

<!-- inicialmente para todas as classes que possuem o tipo campo de interface -->
<div class="d-flex flex-column align-items-start flex-wrap mb-3" *ngIf="AgSelectType == 'diasSemana'">
  <ng-select  [id]="id"
              [placeholder]="AgPlaceholder"
              [multiple]="AgMultiple"
              [closeOnSelect]="AgCloseOnSelect"
              [(ngModel)]="innerValue"
              (ngModelChange)="onValueChange($event)">
    <ng-option value="todos">
      Selecionar Todos
    </ng-option>

    <ng-option value="segSex">
      Selecionar de segunda a sexta
    </ng-option>
    <ng-option *ngFor="let item of AgOptions" [value]="item">
      {{item.nomeDesc}}
    </ng-option>
  </ng-select>
</div>

<!-- inicialmente para todas as classes que possuem o tipo campo de interface -->
<div class="d-flex flex-column align-items-start flex-wrap mb-3" *ngIf="AgSelectType == 'agenda'">
  <ng-select  [id]="id"
              [placeholder]="AgPlaceholder"
              [multiple]="AgMultiple"
              [closeOnSelect]="AgCloseOnSelect"
              [(ngModel)]="innerValue"
              (ngModelChange)="onValueChange($event)">
    <ng-option *ngFor="let item of AgOptions" [value]="item.id">
      {{item.nome.valor}} <span style=" font-size: 10px; font-style: italic; font-weight: bold; letter-spacing: .7px; padding: 0px 5px; ">{{item.id}}</span>
    </ng-option>
  </ng-select>
</div>

<!-- inicialmente para todas as classes que possuem o tipo checkbox de interface -->
<div class="d-flex flex-column align-items-start flex-wrap mb-3" *ngIf="AgSelectType == 'form_select'">
  <ng-select  [appendTo]="AgAppendTo"
              [id]="id"
              [placeholder]="AgPlaceholder"
              [multiple]="AgMultiple"
              [closeOnSelect]="AgCloseOnSelect"
              [(ngModel)]="innerValue"
              (ngModelChange)="onValueChange($event)">
    <ng-option *ngFor="let item of AgOptions" [value]="item.value">
      {{item.label}}
    </ng-option>
  </ng-select>
</div>
