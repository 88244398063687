import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class ContadorIndexService {

  indexGlobal: any;

  private apiUrl = 'https://us-central1-paperoff-agenda.cloudfunctions.net/api';

  constructor(private http: HttpClient) {}

  async contadorIndex2(): Promise<number> {
    const response = await this.http.post<{ index: number[] }>(`${this.apiUrl}/index`, {}).toPromise();

    const lastIndex: number = response?.index?.length !== undefined && response.index.length > 0
      ? response.index.pop()!
      : 0;

    // console.log(lastIndex)
    return lastIndex !== undefined ? lastIndex : 0;
  }

   // Função para criar instância sem depender de HttpClient
   static createInstance(): ContadorIndexService {
    return new ContadorIndexService(null!); // Passando null para contornar o erro
  }

  contadorIndex3() {

    // return 1;

    // this.indexGlobal = localStorage.getItem("indexGlobal")

    if(!this.indexGlobal){
      // Pego o último valor do array
      this.indexGlobal = DataService.get("indexGlobal")
    }else{
      const cache = `[${this.indexGlobal}]`
      this.indexGlobal = JSON.parse(cache)
    }

    if(this.indexGlobal == undefined) this.indexGlobal = [];

    let contador = this.indexGlobal[this.indexGlobal.length - 1];
    // Se esse valor for undefined, significa que o array está vazio
    if (contador == undefined) {
      // Contador recebe 1
      contador = 1;
      // Alimenta o array
      this.indexGlobal.push(contador);
      // Retorna o valor do último contador no ID do JSON
      DataService.set("indexGlobal",this.indexGlobal)
      // localStorage.setItem("indexGlobal",this.indexGlobal)
      return contador;
    } else {
      // Soma mais 1 ao valor do último contador
      contador++;
      // Alimenta o array
      this.indexGlobal.push(contador);
      // Retorna o valor do último contador no ID do JSON
      DataService.set("indexGlobal",this.indexGlobal)
      // localStorage.setItem("indexGlobal",this.indexGlobal)
      return contador;
    }
  }

  contadorIndex() {
    // Função para gerar um ID único estilo Firebase
    function generateFirebaseId() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let autoId = '';
      for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return autoId;
    }

    // Gera um novo ID único estilo Firebase
    const newId = generateFirebaseId();

    // Retorna o novo ID
    return `LW${newId}LW`;
  }
}
