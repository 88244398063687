import { FormControl, Validators } from "@angular/forms";
import { campo } from "../interface/campo";
import { ValidatorService } from "../services/validator.service";
import { ContadorIndexService } from "src/services/contador-index.service";
import { StatusAgendamento } from "src/enum/statusAgendamento.enum";
import { StatusExecucao } from "src/enum/statusExecucao.enum";
import { StatusKanban } from "src/enum/statusKanban.enum";

const idx = ContadorIndexService.createInstance();
const validatorService  = new ValidatorService();

export class Checkin {

  id: any | null = null;

  atendimentoId: any | null = null;
  // agendaId: any | null = null;

  nomeMotorista: campo = {
    title: 'Nome do Motorista',
    info: 'Nome do Motorista',
    placeholder: 'Nome completo',
    valor: '',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  cpfMotorista: campo = {
    title: 'CPF',
    info: 'CPF',
    placeholder: 'Informe um CPF válido',
    valor: '',
    inputType: 'text',
    inputMode: 'numeric',
    mascara: '###.###.###-##',
    maxlength: '14',
    tipo: null,
    validacoesCustomizadas: ['required','cpf'],
    status: new FormControl()
  };

  telefoneMotorista: campo = {
    title: 'Telefone',
    info: 'Telefone',
    placeholder: 'Informe o Telefone com DDD',
    valor: '',
    inputType: 'text',
    inputMode: 'tel',
    mascara: '',
    maxlength: '15',
    tipo: 'telefone',
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  emailMotorista: campo = {
    title: 'E-mail',
    info: 'E-mail',
    placeholder: 'Informe um endereço de e-mail válido',
    valor: '',
    inputType: 'text',
    inputMode: 'email',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required', 'email'],
    status: new FormControl()
  };

  notaFiscal: campo = {
    title: 'Nota Fiscal',
    info: 'Nota Fiscal',
    placeholder: 'Número da Nota Fiscal',
    valor: '',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  numeroPedido: campo = {
    title: 'Número do Pedido',
    info: 'Número do Pedido',
    placeholder: 'Número do Pedido',
    valor: '',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  fornecedor: campo = {
    title: 'Fornecedor',
    info: 'Fornecedor',
    placeholder: 'Fornecedor',
    valor: '',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  placaVeiculo: campo = {
    title: 'Placa do Veículo',
    info: 'Placa do Veículo',
    placeholder: 'Placa do Veículo',
    valor: '',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  veiculo: campo = {
    title: 'Veículo',
    info: 'Veículo',
    placeholder: 'Veículo',
    valor: '',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '200',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  peso: campo = {
    title: 'Peso',
    info: 'Peso',
    placeholder: 'Peso',
    valor: '',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '200',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  status: campo = {
    title: 'Status checkin',
    info: 'Status checkin',
    placeholder: 'Status checkin',
    valor: '',
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  criado: Date = new Date();
  atualizado: Date = new Date();

  // observacao: campo = {
  //   title: 'Observação',
  //   info: 'Observação',
  //   placeholder: 'Observação',
  //   valor: '',
  //   inputType: 'text',
  //   inputMode: 'text',
  //   mascara: '',
  //   maxlength: '1000',
  //   tipo: null,
  //   validacoesCustomizadas: [],
  //   status: new FormControl()
  // };

  statusCheckin: any;

  statusAguardando = {
    status: StatusKanban.aguardando,
    inicio: '' as any,
    fim: '' as any,
    comentarios: [] as Comentario[]
  }

  statusEmAndamento = {
    status: StatusKanban.emAndamento,
    inicio: '' as any,
    fim: '' as any,
    comentarios: [] as Comentario[]
  }

  statusRealizado = {
    status: StatusKanban.realizado,
    inicio: '' as any,
    fim: '' as any,
    comentarios: [] as Comentario[]
  }

  statusNaoRealizado = {
    status: StatusKanban.naoRealizado,
    inicio: '' as any,
    fim: '' as any,
    comentarios: [] as Comentario[]
  }

  // KanbanHistoricos: KanbanHistorico[] = [];

  // dataAtendimento: campo = {
  //   title: 'Data do Atendimento',
  //   info: 'Data do Atendimento',
  //   valor: '',
  //   placeholder: 'Data do Atendimento',
  //   inputType: 'text',
  //   inputMode: 'numeric',
  //   mascara: '',
  //   maxlength: '10',
  //   tipo: null,
  //   validacoesCustomizadas: ['required'],
  //   status: new FormControl()
  // };

  // duracaoAtendimento: campo = {
  //   title: 'Duração de Atendimento',
  //   info: 'Este campo define a duração do compromisso ou atendimento. Pode ser expresso em minutos ou horas, dependendo das necessidades da agenda. Por exemplo, se o atendimento dura 50min e você quer 10min de intervalo até o próximo atendimento, preencha esse campo com 60min (01:00).',
  //   valor: '',
  //   placeholder: 'Duração, ex.: 01:00 para 60 minutos',
  //   inputType: 'time',
  //   inputMode: 'numeric',
  //   mascara: '##:##',
  //   maxlength: '5',
  //   tipo: null,
  //   validacoesCustomizadas: ['required'],
  //   status: new FormControl()
  // };

  // hora_inicial: campo = {
  //   title: 'Hora Inicial',
  //   info: 'Hora Inicial',
  //   valor: '00:00',
  //   placeholder: 'Hora Inicial',
  //   inputType: 'time',
  //   inputMode: 'numeric',
  //   mascara: '##:##',
  //   maxlength: '5',
  //   tipo: null,
  //   validacoesCustomizadas: ['required'],
  //   status: new FormControl()
  // };

  // hora_final: campo = {
  //   title: 'Hora Final',
  //   info: 'Hora Final',
  //   valor: '00:00',
  //   placeholder: 'Hora Final',
  //   inputType: 'time',
  //   inputMode: 'numeric',
  //   mascara: '##:##',
  //   maxlength: '5',
  //   tipo: null,
  //   validacoesCustomizadas: ['required'],
  //   status: new FormControl()
  // };

  // observacaoCliente: campo = {
  //   title: 'Observação',
  //   info: 'Observação',
  //   placeholder: 'Observação',
  //   valor: '',
  //   inputType: 'text',
  //   inputMode: 'text',
  //   mascara: '',
  //   maxlength: '1000',
  //   tipo: null,
  //   validacoesCustomizadas: [],
  //   status: new FormControl()
  // };

  // statusExecucao: campo = {
  //   title: 'Status de Execução',
  //   info: 'Status de Execução',
  //   placeholder: 'Status de Execução',
  //   valor: '',
  //   validacoesCustomizadas: ['required'],
  //   status: new FormControl()
  // };

  constructor() {

    this.statusCheckin = this.enumToOptions(StatusKanban);
    // this.hora_inicial.valor = this.getHoraAtual();
    // this.dataAtendimento.valor = this.getDataAtual_ddMMaaaa();
    // this.statusExecucaoOptions = this.enumToOptions(StatusExecucao);

    // console.log(this.statusAgendamentoOptions)

  }

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "cpfMotorista":
        this.cpfMotorista.valor = validatorService.aplicarMascara(this.cpfMotorista.valor, mascara);
        break;
      case "telefoneMotorista":
        if(this.telefoneMotorista.tipo != ""){
          this.telefoneMotorista.valor = validatorService.aplicarMascaraEspecial(this.telefoneMotorista.valor, this.telefoneMotorista.tipo);
        }{
          this.telefoneMotorista.valor = validatorService.aplicarMascara(this.telefoneMotorista.valor, mascara);
        }
        break;
      default:
        break;
    }
  }

  validarInput(tipo: string, valor: any = null) {
    switch (tipo) {
      case "placaVeiculo":
        this.placaVeiculo.valor = this.placaVeiculo.valor.trim();
        this.placaVeiculo.status = validatorService.criarValidacao(this.placaVeiculo.valor, this.placaVeiculo.validacoesCustomizadas);
        break;
        case "veiculo":
          this.veiculo.valor = this.veiculo.valor.trim();
          this.veiculo.status = validatorService.criarValidacao(this.veiculo.valor, this.veiculo.validacoesCustomizadas);
          break;
      case "peso":
        this.peso.valor = this.peso.valor.trim();
        this.peso.status = validatorService.criarValidacao(this.peso.valor, this.peso.validacoesCustomizadas);
        break;
      case "nomeMotorista":
        this.nomeMotorista.valor = this.nomeMotorista.valor.trim();
        this.nomeMotorista.status = validatorService.criarValidacao(this.nomeMotorista.valor, this.nomeMotorista.validacoesCustomizadas);
        break;
      case "cpfMotorista":
        this.cpfMotorista.valor = this.cpfMotorista.valor.trim();
        this.cpfMotorista.status = validatorService.criarValidacao(this.cpfMotorista.valor, this.cpfMotorista.validacoesCustomizadas);
        break;
      case "telefoneMotorista":
        this.telefoneMotorista.valor = this.telefoneMotorista.valor.trim();
        this.telefoneMotorista.status = validatorService.criarValidacao(this.telefoneMotorista.valor, this.telefoneMotorista.validacoesCustomizadas);
        break;
      case "emailMotorista":
        this.emailMotorista.valor = this.emailMotorista.valor.trim();
        this.emailMotorista.status = validatorService.criarValidacao(this.emailMotorista.valor, this.emailMotorista.validacoesCustomizadas);
        break;
      case "notaFiscal":
        this.notaFiscal.valor = this.notaFiscal.valor.trim();
        this.notaFiscal.status = validatorService.criarValidacao(this.notaFiscal.valor, this.notaFiscal.validacoesCustomizadas);
        break;
      case "numeroPedido":
        this.numeroPedido.valor = this.numeroPedido.valor.trim();
        this.numeroPedido.status = validatorService.criarValidacao(this.numeroPedido.valor, this.numeroPedido.validacoesCustomizadas);
        break;
      case "fornecedor":
        this.fornecedor.valor = this.fornecedor.valor.trim();
        this.fornecedor.status = validatorService.criarValidacao(this.fornecedor.valor, this.fornecedor.validacoesCustomizadas);
        break;
      // case "dataAtendimento":
      //   this.dataAtendimento.valor = this.dataAtendimento.valor.trim();
      //   this.dataAtendimento.status = validatorService.criarValidacao(this.dataAtendimento.valor, this.dataAtendimento.validacoesCustomizadas);
      //   break;
      // case "duracaoAtendimento":
      //   this.duracaoAtendimento.valor = this.duracaoAtendimento.valor.trim();
      //   this.duracaoAtendimento.status = validatorService.criarValidacao(this.duracaoAtendimento.valor, this.duracaoAtendimento.validacoesCustomizadas);
      //   break;
      // case "hora_inicial":
      //   this.hora_inicial.valor = this.hora_inicial.valor.trim();
      //   this.hora_inicial.status = validatorService.criarValidacao(this.hora_inicial.valor, this.hora_inicial.validacoesCustomizadas);
      //   break;
      // case "hora_final":
      //   this.hora_final.valor = this.hora_final.valor.trim();
      //   this.hora_final.status = validatorService.criarValidacao(this.hora_final.valor, this.hora_final.validacoesCustomizadas);
      //   break;
      case "status":
        this.status.valor = this.status.valor.trim();
        this.status.status = validatorService.criarValidacao(this.status.valor, this.status.validacoesCustomizadas);
        // console.log(this.status)
        break;
      // case "observacao":
      //   this.observacao.valor = this.observacao.valor.trim();
      //   this.observacao.status = validatorService.criarValidacao(this.observacao.valor.trim(), this.observacao.validacoesCustomizadas);
        // console.log(this.observacao)
        break;
      default:
        break;
    }
  }

  // getDataAtual_ddMMaaaa() {
  //   const now = new Date();
  //   const day = now.getDate().toString().padStart(2, '0');
  //   const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Mês começa em 0
  //   const year = now.getFullYear();
  //   return `${day}/${month}/${year}`;
  // }

  // getHoraAtual(){
  //   const horaAtual = new Date();

  //   const horas = horaAtual.getHours();
  //   const minutos = horaAtual.getMinutes();

  //   const horasFormatadas = horas < 10 ? '0' + horas : horas;
  //   const minutosFormatados = minutos < 10 ? '0' + minutos : minutos;

  //   return `${horasFormatadas}:${minutosFormatados}`;
  // }

  enumToOptions(enumObj: any): { nome: string, value: string }[] {
    return Object.keys(enumObj)
      .filter(key => isNaN(Number(enumObj[key])))
      .map(key => ({ nome: enumObj[key], value: key }));
  }

  // registrarKanbanHistorico(status: KanbanStatus, motivo: string) {
  //   const timestamp = new Date();
  //   const novoStatus = new KanbanHistorico();

  //   novoStatus.status = status;
  //   novoStatus.motivo.valor = motivo;
  //   novoStatus.timestamp = timestamp;

  //   // console.log(novoStatus)

  //   this.KanbanHistoricos.push(novoStatus);
  // }

}

export class Comentario {
  id: any | null = null;
  observacao: campo = {
    title: 'Observação',
    info: 'Observação',
    valor: '',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };
  responsavel: Responsavel = new Responsavel;
  timestamp!:   Date;

  constructor() {
  }

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "observacao":
        this.observacao.valor = validatorService.aplicarMascara(this.observacao.valor, mascara);
        break;
      default:
        break;
    }
  }

  validarInput(tipo: string, valor: any = null) {
    switch (tipo) {
      case "observacao":
        this.observacao.valor = this.observacao.valor.trim();
        this.observacao.status = validatorService.criarValidacao(this.observacao.valor, this.observacao.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }
}

export class Responsavel {
  idUsuario: string = '';
  nomeUsuario: string = '';
}

// export enum KanbanStatus {
//   Aguardando = 'Aguardando',
//   EmAndamento = 'Em andamento',
//   Realizado = 'Realizado',
// }

// export class KanbanHistorico {
//   status!:       KanbanStatus;
//   motivo:        campo = {
//                   title: 'Motivo',
//                   info: 'Motivo',
//                   valor: '',
//                   inputType: 'text',
//                   inputMode: 'text',
//                   mascara: '',
//                   maxlength: '1000',
//                   tipo: null,
//                   validacoesCustomizadas: ['required'],
//                   status: new FormControl()
//                 };
//   timestamp!:   Date;
// }
