import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroDestaque'
})
export class FiltroDestaquePipe implements PipeTransform {

  transform(value: string | number, search: string | number): string | number {
    if (value !== undefined && value !== null && search !== undefined && search !== null) {
      const pattern = new RegExp(`(${search})`, 'gi');
      const textValue = typeof value === 'string' ? value : value.toString();
      const transformedValue = this.applyHighlight(textValue, search.toString(), pattern);
      // console.log(value,search,transformedValue)
      return transformedValue;
    }

    return value;
  }

  private applyHighlight(text: string, search: string, pattern: RegExp): string {
    return text.replace(pattern, (match) => `<span class="highlight">${match}</span>`);
  }
}
