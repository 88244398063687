import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { KeyValue } from '@angular/common';
import { AppComponent } from '../../app.component'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { RouterLinkActive } from '@angular/router';
import { SiglaService } from 'src/services/siglaService.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  usr_nome: string = "";
  usr_tipo_string: string = "";
  usr_tipo: string = "";

  subMenuState: { [key: string]: boolean } = {
    "usuarios": true,
    "agendas": true,
    "agendamentos": true,
    "checkin": true,
    "tipos": true,
    "notificacoes": true,
    "grupos-usuarios": true,
    "grupos-agendas": true,
    "slots": true
  };

  unreadCount = 0;

  fix = false;

  empresaSigla: any | undefined;

  constructor(
    public app: AppComponent,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private siglaService: SiglaService
  ){
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.siglaService.empresaSigla$.subscribe(empresaSigla => {
          this.empresaSigla = empresaSigla;
        });
      }
    });
  }

  ngOnInit(): void{

    setTimeout(() => {
      const nome = JSON.parse(localStorage.getItem('user')!);
      this.usr_nome = nome.Nome

      const tipo = "A"
      switch (nome.Tipo) {
        case "M":
          this.usr_tipo = "M"
          this.usr_tipo_string = "Master"
          break;
        case "A":
          this.usr_tipo = "A"
          this.usr_tipo_string = "Administrador"
          break;
        case "S":
          this.usr_tipo = "S"
          this.usr_tipo_string = "Simples"
          break;
        default:
          break;
      }
    }, 1);

    this.usr_tipo = "M"
    this.usr_tipo_string = "Master"
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  toggleSidebar(){
    if(!this.app.isSidebarClosed) this.app.toggleSidebar()
  }

  togglePinSidebar(): void {
    this.app.isSidebarPinned = !this.app.isSidebarPinned;

    if (!this.app.isSidebarPinned) {
      // Lógica para quando a barra lateral é fixada
      // console.log("Lógica para quando a barra lateral é fixada",this.app.isSidebarPinned)
    } else {
      // Lógica para quando a barra lateral não está fixada
      // console.log("Lógica para quando a barra lateral não está fixada",this.app.isSidebarPinned)

    }
  }

  toggleSubMenu(key: string): void {
    // console.log(this.subMenuState)
    this.subMenuState[key] = !this.subMenuState[key];
    for (let [chv, value] of Object.entries(this.subMenuState)) {
      if(key != chv){
        this.subMenuState[chv] = false; // Fecha os outros submenus
      }
      // console.log(`Key: ${chv}, Value: ${value}`);
    }
    // console.log(this.subMenuState)


    this.app.isSidebarClosed = false;
  }

  isSubMenuOpen(key: string): boolean {
    return this.subMenuState[key];
  }

  openToggleSubMenu(): void {
    if(this.subMenuState){
      for (let [chv, _] of Object.entries(this.subMenuState)) {
        this.subMenuState[chv] = true; // Abre os outros submenus
        // console.log(`Key: ${chv}, Value: ${_}`);
      }
    }
  }

  closeToggleSubMenu(): void {
    if(this.subMenuState){
      for (let [chv, _] of Object.entries(this.subMenuState)) {
        this.subMenuState[chv] = false; // Fecha os outros submenus
        // console.log(`Key: ${chv}, Value: ${_}`);
      }
    }
  }

  // fazerLogout(){
  //   this.authService.fazerLogout();
  // }

  isLinkActive(path: string): boolean {
    const currentUrl = this.router.url;
    const siglaPath = `/${this.empresaSigla}${path}`
    // console.log(currentUrl, siglaPath)
    return currentUrl === siglaPath || currentUrl.startsWith(siglaPath);
  }
}
