import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Links } from 'src/class/links';
import { Servico } from 'src/class/servico';
import { newUsuario } from 'src/class/usuario';
import { CepService } from 'src/services/cep.service';
import { ServicoService } from 'src/services/component/servico.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { DataService } from 'src/services/data.service';
import { LinksService } from 'src/services/links.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';
// import { AngularFireStorage } from '@angular/fire/compat/storage';

declare var bootstrap: any;

@Component({
  selector: 'app-criar-servico-agenda',
  templateUrl: './criar-servico-agenda.component.html',
  styleUrls: ['./criar-servico-agenda.component.css']
})
export class CriarServicoAgendaComponent {
  @ViewChild('alertServicoModal', { static: false }) alertServicoModal!: ElementRef;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  selectedFile: File | null = null;
  uploadProgress: number | null = null;

  servico: Servico = new Servico();
  servicos: Servico[] = [];

  linksAgenda: Links[] = [];
  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private linksService: LinksService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private servicoService: ServicoService,
    private route: ActivatedRoute,
    private siglaService: SiglaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      }
    });
  }

  async ngOnInit() {
  try{
    const usr = JSON.parse(localStorage.getItem('user')!);
    // this.usr_id = usr.Id;
    // this.usr_tipo = usr.Tipo;
    // this.usr_token = usr.Token;
    this.usr_id = "1";
    this.usr_tipo = "M";
    this.usr_token = "usr.Token";
    if(this.usr_tipo != "M" && this.usr_tipo != "A"){
      this.router.navigate(['/home']);
    }
  }catch{
    this.router.navigate(['/home']);
  }
  // Agora você pode usar o ID como quiser

  this.linksAgenda = this.linksService.getLinksAgenda();

}

routerLink(rota: string){
  this.router.navigate([`${this.empresaSigla}/${rota}`]);
}

async criarServico(){
  this.servico.nome.status = this.validatorService.criarValidacao(this.servico.nome.valor, this.servico.nome.validacoesCustomizadas);
  this.servico.valor.status = this.validatorService.criarValidacao(this.servico.valor.valor, this.servico.valor.validacoesCustomizadas);
  this.servico.linkPagamento.status = this.validatorService.criarValidacao(this.servico.linkPagamento.valor, this.servico.linkPagamento.validacoesCustomizadas);
  this.servico.duracaoAtendimento.status = this.validatorService.criarValidacao(this.servico.duracaoAtendimento.valor, this.servico.duracaoAtendimento.validacoesCustomizadas);
  this.servico.numeroPessoasPorHorario.status = this.validatorService.criarValidacao(this.servico.numeroPessoasPorHorario.valor, this.servico.numeroPessoasPorHorario.validacoesCustomizadas);
  this.servico.instrucoesConfirmacao.status = this.validatorService.criarValidacao(this.servico.instrucoesConfirmacao.valor, this.servico.instrucoesConfirmacao.validacoesCustomizadas);

  if(this.servico.nome.status.invalid ||
    this.servico.valor.status.invalid ||
    this.servico.linkPagamento.status.invalid ||
    this.servico.duracaoAtendimento.status.invalid ||
    this.servico.numeroPessoasPorHorario.status.invalid ||
    this.servico.instrucoesConfirmacao.status.invalid
  ){
    this.abrirModal(this.alertServicoModal.nativeElement);
  }else{

    this.servico.id = await this.contadorIndexService.contadorIndex();
    let new_servico  = new Servico();
    new_servico = this.servico;

    new_servico.criado = new Date();
    new_servico.atualizado = new Date();

    const servico = JSON.stringify(new_servico);
    const res = await this.servicoService.criarServico(JSON.parse(servico), this.empresaSigla)
    // console.log(res)
    // let servico = DataService.get("Servico");
    // console.log(servico);

    // if(servico == undefined){
    //   let obj = [];
    //   new_servico.criado = new Date();
    //   new_servico.atualizado = new Date();

    //   // if(!new_servico.vinculoAgenda.some(subArray => subArray.includes(this.agenda.id))){
    //   //   new_servico.vinculoAgenda.push(this.agenda.id)
    //   // }

    //   obj.push(new_servico);
    //   DataService.setItem("Servico", obj);
    //   localStorage.setItem("Servico", JSON.stringify(obj))

    //   // this.listaServico = DataService.get("Servico");
    // }else{
    //   new_servico.criado = new Date();
    //   new_servico.atualizado = new Date();

    //   // if(!new_servico.vinculoAgenda.some(subArray => subArray.includes(this.agenda.id))){
    //   //   new_servico.vinculoAgenda.push(this.agenda.id)
    //   // }

    //   servico.push(new_servico);
    //   DataService.setItem("Servico", servico);
    //   localStorage.setItem("Servico", JSON.stringify(servico))


    //   // this.listaServico = DataService.get("Servico");
    // }

    // this.showSuccess("Serviço adicionado com sucesso")

    // this.showSuccess("Serviço criado com sucesso");
    this.servico = new Servico();
    this.router.navigate([`${this.empresaSigla}/agendas/servicos`]);
  }
}

abrirModal(event: any) {
  // Ativar o modal usando a biblioteca do Bootstrap
  const modal = new bootstrap.Modal(event);
  modal.show();
}

private showSuccess(msg: string){
  this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
}

private showError(msg: string){
  this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
}

private showInfo(msg: string) {
  this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
}
}
