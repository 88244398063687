import { Component, ChangeDetectorRef, ViewChild, SimpleChanges, OnChanges  } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { MenuComponent } from './component/menu/menu.component';
import { ThemeService } from 'src/services/theme.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { RouteLoadingBarControlService } from 'src/services/route-loading-bar-control.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { BehaviorSubject, Subject, takeUntil, throwError } from 'rxjs';
import { ToastService } from 'src/services/toast.service';
import { MatSidenav } from '@angular/material/sidenav';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { RelogioService } from 'src/services/relogio.service';
import { NavigationEnd, Router } from '@angular/router';
import { SiglaService } from 'src/services/siglaService.service';
import { UsuarioService } from 'src/services/component/usuario.service';
import { ContaService } from 'src/services/component/conta.service';
import { Conta } from 'src/class/conta';


interface MenuItem {
  label: string;
  icon: string;
  route?: string;
  subitems?: MenuItem[];
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(MenuComponent, { static: false }) menu!: MenuComponent;
  isSidebarClosed: boolean = false;
  isSidebarPinned: boolean = false;

  lightMode: any;

  title = 'Agenda';
  mostrarMenu: boolean = false;

  usr_nome: any;
  usr_photo: any;
  usr_id: any;

  usr_tipo_string = "Master"

  loader = this.loadingBar.useRef('http');
  loaderRouter = this.loadingBar.useRef('router');

  menuItems: MenuItem[] = [
    { label: 'Página Inicial', icon: 'home', route: '/' },
    {
      label: 'Sobre', icon: 'info', subitems: [
        { label: 'Visão Geral', icon: 'visibility', route: '/sobre/visao-geral' },
        { label: 'Missão', icon: 'stars', route: '/sobre/missao' }
      ]
    },
    { label: 'Contato', icon: 'mail', route: '/contato' }
  ];

  currentMessage = new BehaviorSubject<any>(null);
  dataHora: Date = new Date();
  empresaSigla: any | undefined;
  usuario: any;

  logoEmpresa = '';
  infoEmpresa = '';

  constructor(
    public userService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService,
    private loadingBar: LoadingBarService,
    private routeLoadingBarControlService: RouteLoadingBarControlService,
    private translateWord: TranslateWordService,
    private toast: ToastService,
    private afs: AngularFirestore,
    private messaging: AngularFireMessaging,
    private relogioService: RelogioService,
    private router: Router,
    private siglaService: SiglaService,
    private usuarioService: UsuarioService,
    private conta: ContaService
  ) {
      this.messaging.getToken.subscribe((token) => {
        if (token) {
          // Salvar o token no Firestore para enviar notificações posteriormente
          this.afs.collection('tokens').doc(token).set({ token: token });
        }
      });

      // this.messaging.messages.subscribe(
      //   (payload: any) => {
      //     // console.log('Mensagem recebida:', payload);
      //     this.notificationService.addNotification(`Sistema - ${payload.notification.title}. ${payload.notification.body}`);
      //     this.currentMessage.next(payload);
      //   }
      // );

      // this.messaging.onMessage((payload) => {
      //   // Lidar com a chegada de uma nova notificação quando o aplicativo está em foco
      //   console.log('Mensagem recebida:', payload);
      //   this.notificationService.addNotification(`Sistema - ${payload.notification.title}. ${payload.notification.body}`);
      // });

      this.userService.mostrarMenuEmitter.subscribe(
        mostrar => {
          this.mostrarMenu = mostrar;
          this.changeDetectorRef.detectChanges();
          if (!this.isSidebarPinned) {
            this.isSidebarClosed = true;

            const tms = setInterval(async () => {
              const usr = JSON.parse(localStorage.getItem('user')!);
              if(usr && this.empresaSigla != ""){

                // console.log(this.usuario)

                if (!this.usuario) {
                  this.usuario = await this.userService.carregarUsuario(this.empresaSigla)
                  if(this.usuario.displayName.valor != ""){
                    this.usr_nome = this.usuario.displayName.valor;
                  }else{
                    this.usr_nome = this.usuario.email.valor;
                  }

                  if(this.usuario.photoURL.valor != ""){
                    this.usr_photo = this.usuario.photoURL.valor;
                  }else{
                    this.usr_photo = "../assets/img/sem-foto-min.jpg";
                  }

                  this.usr_id = this.usuario.id;
                }

                clearInterval(tms)
              }
            }, 100)
          } else {
            this.isSidebarClosed = false;
          }
        }
      );

      this.siglaService.empresaSigla$.subscribe(empresaSigla => {
        this.empresaSigla = empresaSigla
      });

  }

  ngOnInit(): void{

    let time = setInterval(()=>{
      if(this.empresaSigla){
        clearInterval(time)
        this.conta.p_getSiglaConta('contas', this.empresaSigla).then((conta: Conta) => {
          // console.log(conta);
          this.logoEmpresa = conta.logoURL.valor;
          this.infoEmpresa = conta.razao.valor + ' - ' +conta.sigla.valor;
        }).catch((error: any) => {
          // console.error('Erro ao obter dados da conta:', error)
          this.logoEmpresa = "";
        });
      }
    })

    // this.relogioService.iniciarRelogio();
    // this.relogioService.obterRelogioObservable().subscribe(dataHora => {
    //   this.dataHora = dataHora;
    // });

    // this.routeLoadingBarControlService.getErrorObservable().subscribe(errorMessage => {
    //   this.loaderRouter.start();
    //   setTimeout(async () => {
    //     // console.log(errorMessage)
    //     const ngxBarElement = document.querySelector('.ngx-bar');
    //           ngxBarElement?.classList.add('error-ngx-bar');
    //     try {
    //       // console.log('Ocorreu um erro ao buscar o endereço:', error);
    //       const trad = await this.translateWord.translateWord(errorMessage, 'pt');
    //       this.loaderRouter.complete();
    //       this.showError(trad)
    //       return throwError(trad)
    //     } catch (error2) {
    //       // console.log('Ocorreu um erro ao buscar o endereço:', error2);
    //       this.showError(errorMessage)
    //       this.loaderRouter.complete();
    //       return throwError(errorMessage)
    //     }
    //   },100);
    // });



    this.verificarTheme();

  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  isMenuExpanded = false;

  expandMenu() {
    this.isMenuExpanded = !this.isMenuExpanded;
  }
  @ViewChild('sidenav') sidenav!: MatSidenav;

  reason = '';

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }

  fazerLogout(){
    // localStorage.removeItem('user');
    // window.location.reload();
    // let int = 0;
    // this.siglaService.empresaSigla$.subscribe(empresaSigla => {
    //   console.log('Teste', int + 1)
    //   this.userService.fazerLogout(empresaSigla?.toString(), "fazerLogout");
    // });

    this.usuario = undefined;

    this.usr_nome = "";
    this.usr_photo = "";

    this.userService.fazerLogout(this.empresaSigla);

    this.destroy$.next();
    this.destroy$.complete();
    this.loader.complete();

  }

  toggleSidebar(): void {
    if (!this.isSidebarPinned) {
      this.isSidebarClosed = !this.isSidebarClosed;
      if(this.isSidebarClosed){
        this.menu.openToggleSubMenu();
      }else{
        this.menu.closeToggleSubMenu();
      }
    }else{
      // console.log("toggleSidebar")
      this.isSidebarClosed = false;
      this.menu.closeToggleSubMenu();
    }
  }


  toggleLightMode() {
    this.themeService.lightMode = !this.themeService.lightMode;
    this.lightMode = this.themeService.lightMode;
    if (this.lightMode) {
      document.documentElement.classList.add('light');
      localStorage.setItem("themeLight", 'true');
      this.updateColorScheme('light');
    } else {
      document.documentElement.classList.remove('light');
      localStorage.setItem("themeLight", 'false');
      this.updateColorScheme('dark');
    }
  }

  updateTheme() {
    const now = new Date();
    const startOfDay = new Date();
    startOfDay.setHours(8, 30, 0); // Define o horário de início do dia (8:30)
    const endOfDay = new Date();
    endOfDay.setHours(18, 0, 0); // Define o horário de fim do dia (18:00)

    if (now >= startOfDay && now <= endOfDay) {
      document.documentElement.classList.add('light');
      this.themeService.lightMode = true;
      this.lightMode = true;
    }else{
      document.documentElement.classList.remove('light');
      this.themeService.lightMode = false;
      this.lightMode = false;
    }
  }

  verificarTheme(){
    var theme = localStorage.getItem("themeLight");

    if(!theme){
      document.documentElement.classList.add('light');
      this.themeService.lightMode = true;
      this.lightMode = true;
    }else if(theme == 'true'){
      document.documentElement.classList.add('light');
      this.themeService.lightMode = true;
      this.lightMode = true;
    } else {
      document.documentElement.classList.remove('light');
      this.themeService.lightMode = false;
      this.lightMode = false;
    }
  }

  updateColorScheme(scheme: 'light' | 'dark') {
    // console.log(scheme)
    const metaTag = document.querySelector('meta[name="color-scheme"]');
    if (metaTag) {
      metaTag.setAttribute('content', scheme);
    }
  }

  sidebarMouseEnter(): void {
    if (!this.isSidebarPinned) {
      this.isSidebarClosed = false;
      this.menu.closeToggleSubMenu();
    }
  }

  sidebarMouseLeave(): void {
    if (!this.isSidebarPinned) {
      this.isSidebarClosed = true;
      this.menu.openToggleSubMenu();
    }
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
