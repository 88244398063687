import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { GrupoUsuario, RecursoGrupo } from 'src/class/grupo-usuario';
import { Recurso } from 'src/class/recurso';
import { newUsuario } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { CepService } from 'src/services/cep.service';
import { ContaService } from 'src/services/component/conta.service';
import { GrupoUsuarioService } from 'src/services/component/grupo-usuario.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { RecursoService } from 'src/services/component/recurso.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { DataService } from 'src/services/data.service';
import { LocalhostService } from 'src/services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-editar-grupo',
  templateUrl: './editar-grupo-usuario.component.html',
  styleUrls: ['./editar-grupo-usuario.component.css']
})
export class EditarGrupoUsuarioComponent  implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertGrupoModal', { static: false }) alertGrupoModal!: ElementRef;
  @ViewChild('resources', {static: true}) resources!: MatSelectionList;

  id: any;
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  grupo: GrupoUsuario = new GrupoUsuario();
  grupos: GrupoUsuario[] = [];
  empresaSigla: any | undefined;

  recursos: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private grupoUsuarioService: GrupoUsuarioService,
    private localhostService: LocalhostService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private userService: AuthService,
    private notificacaoService: NotificacaoSistemaService,
    private recursoService: RecursoService
  ){
    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.recursoService.getRecurso(this.empresaSigla);
        });
      // }
    // });
  }

  async ngOnInit() {
    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    this.grupoUsuarioService.getAllGrupoUsuario()
    .pipe(takeUntil(this.destroy$))
    .subscribe(grupo => {
      this.grupos = grupo;

      for (let index = 0; index < this.grupos.length; index++) {
        const tpd = this.grupos[index];
        if(tpd.id == this.id){
          Object.assign(this.grupo, tpd);

          this.recursoService.getAllRecurso()
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (recursos: Recurso[]) => {
              // console.log(recursos)
              // Faça algo com as notificações atualizadas

              if(recursos.length > 0){
                recursos.sort((a, b) => {
                  const nomeA = a.nome.valor.toUpperCase();
                  const nomeB = b.nome.valor.toUpperCase();
                  if (nomeA < nomeB) {
                    return -1;
                  }
                  if (nomeA > nomeB) {
                    return 1;
                  }
                  return 0;
                });

                this.recursos = recursos;

                // setTimeout(() => {
                  let rec: any[] = [];

                  // console.log(this.grupo.recursos)

                  for (let index1 = 0; index1 < this.recursos.length; index1++) {
                    const element = this.recursos[index1];

                    for (let index = 0; index < this.grupo.recursos.length; index++) {
                      const recId = this.grupo.recursos[index];

                      if(element.id == recId.idRecurso){
                        rec.push(element);
                        break;
                      }
                    }
                  }

                  // console.log(rec)

                  this.selectAllResources(rec);

                // }, 1000);
              }

            },
            error => {
              // console.error('Erro ao listar os Tipos:', error);
              this.router.navigate(['/home']);
            }
          );
        }
      }

      if(this.grupo.id == null){
        this.router.navigate([`${this.empresaSigla}/grupos-usuarios`]);
      }
    });



  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  getSelectedResources() {
    for (let index = 0; index < this.resources.selectedOptions.selected.length; index++) {
      const element = this.resources.selectedOptions.selected[index];
      // console.log(element.value)
    }
    return this.resources.selectedOptions.selected.map(option => option.value);
  }

  selectAllResources(recursos: any[]): void {
    const time = setInterval(()=>{
      if(this.resources && this.resources.options){
        clearInterval(time);

        recursos.forEach((rec: any) => {
            const resourceId = rec.id; // Obtém o ID do recurso
            const option = this.resources.options.toArray().find((opt: any) => opt.value.id === resourceId);
            if(option){
              option.selected = true; // Seleciona a opção com o ID correspondente
            }
        });

      }
    })
  }

  async salvarGrupo(){
    this.grupo.nome.status = this.validatorService.criarValidacao(this.grupo.nome.valor, this.grupo.nome.validacoesCustomizadas);
    this.grupo.descricao.status = this.validatorService.criarValidacao(this.grupo.descricao.valor, this.grupo.descricao.validacoesCustomizadas);

    const resources_qtd = this.getSelectedResources();

    if(resources_qtd.length == 0){
      this.showError("Por favor, selecione pelo menos 1 recurso para o grupo")
    }else if(this.grupo.nome.status.invalid ||
      this.grupo.descricao.status.invalid
    ){
      this.abrirModal(this.alertGrupoModal.nativeElement);
    }else{

      // console.log(this.grupo)

      // Obtenha o array existente do DataService
      let grupo = this.grupos;

      // Encontre o índice do item correspondente em grupo com base no ID
      const index = grupo.findIndex((item: GrupoUsuario) => item.id === this.grupo.id);

      if (index !== -1) {
        this.grupo.atualizado = new Date();
        // Atualize o item existente com as alterações de this.grupo
        grupo[index] = this.grupo;

        const docId = grupo[index].id != null ? grupo[index].id?.toString() : "";

        let recursos: RecursoGrupo[] = [];

        // console.log(this.resources.selectedOptions)
        for (let index = 0; index < this.resources.selectedOptions.selected.length; index++) {
          const element = this.resources.selectedOptions.selected[index];
          // console.log(element.value)
          const rec = new RecursoGrupo();

          rec.idRecurso = element.value.id
          rec.NomeRecurso = element.value.nome.valor

          recursos.push(rec)
        }

        grupo[index].recursos = recursos;

        const plainObject = JSON.stringify(grupo[index])
        const plainString = JSON.parse(plainObject)

        plainString.descricao.status = null;
        plainString.nome.status = null;

        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: atualizarStatusServico', conta);
          this.localhostService.putDados(`${conta.id}_grupos_usuarios`, docId!, plainString)
          .then(async (response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess("Grupo atualizado com sucesso");

            // Nova notificação
            // 1 - empresaSigla: any,
            // 2 - titulo: string,
            // 3 - texto: string,
            // 4 - rotaSemParamNome: string,
            // 5 - rotaSemParamListar: string,
            // 6 - rotaComParamNome: string = "",
            // 7 - rotaComParamCaminho: string = "",
            // 8 - rotaComParamParam: string = "",
            // 9 - linkExternoNome: string = "",
            // 10 - linkExternoURL: string = "",
            // 11 - idReferencia: string,
            // 12 - tipo: string,
            // 13 - idResponsavel: string,
            // 14 - nomeResponsavel: string

            const usr = await this.userService.carregarUsuario(this.empresaSigla)

            this.notificacaoService.criarNotificacao(
              this.empresaSigla,
              `${plainString.nome.valor}`,
              `Grupo de usuários atualizado.`,
              'Listar Grupos Usuários',
              'grupos-usuarios',
              'Editar Grupo Usuários',
              `grupos-usuarios/grupo`,
              plainString.id?.toString(),
              '',
              '',
              plainString.id!.toString(),
              `Grupo Usuários`,
              `${usr.id}`,
              `${usr.displayName.valor}`
            )

            this.router.navigate([`${this.empresaSigla}/grupos-usuarios`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Grupo não encontrado para atualização \n" + error);
          });

        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // console.log('Erro ao obter dados da conta:', error);
        });

        // Salve o array de volta no DataService
        // DataService.setItem("Grupo", grupo);
        // localStorage.setItem("Grupo", JSON.stringify(grupo))
        // this.showSuccess("Grupo atualizado com sucesso");
        // this.router.navigate([`${this.empresaSigla}/grupos-usuarios`]);

      } else {
        // O item não foi encontrado no array, você pode tratar isso conforme necessário
        this.showError("Grupo não encontrado para atualização");
      }
    }
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
