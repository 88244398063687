<div id="error-frame">
  <div class="container">
      <h1>404</h1>
      <h4>Opps! Página não encontrada</h4>
      <p>Desculpe, a página que você procura não existe. </p>
      <div class="btn">

        <app-ag-input-field class="w-100"
          [(ngModel)]="inputValue"
          [label]="'Digite a sigla da empresa'"
          [tooltip]="'Digite a sigla da empresa'"
          [value]="inputValue"
          [id]="'sigla_empresa'"
          AgPlaceholder="Digite a sigla da empresa"
          AgInputType="'text'"
          AgMaxlength="200"
        ></app-ag-input-field>
        <!-- <button (click)="buildURL()">Construir URL</button> -->
        <a [href]="buildURL()" *ngIf="inputValue != ''">Retornar para tela de login</a>
        <!-- <p>URL construída: {{ buildURL() }}</p> -->

      </div>

      <div style="position: absolute;bottom: 0px;right: 0;left: 0;display: flex;flex-direction: column;padding: 10px 10px 0px;">
        <i class="bi bi-outlet" style=" font-size: 35px; color: var(--lw-cor-p)"></i>
        <i class="bi bi-plug" style=" font-size: 35px; color: var(--lw-cor-p)"></i>
      </div>
  </div>
</div>
