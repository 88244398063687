import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Agenda } from 'src/class/agenda';
import { Agendamento, KanbanHistorico, KanbanStatus } from 'src/class/agendamento';
import { Endereco } from 'src/class/endereco';
import { Links } from 'src/class/links';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { DataService } from 'src/services/data.service';
import { LinksService } from 'src/services/links.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';
import { StatusAgendamento } from '../../../../enum/statusAgendamento.enum';
import { AgendaService } from 'src/services/component/agenda.service';
import { AgendamentoService } from 'src/services/component/agendamento.service';
import { SiglaService } from 'src/services/siglaService.service';
import { Subject, takeUntil } from 'rxjs';

declare var bootstrap: any;

@Component({
  selector: 'app-criar-agendamento',
  templateUrl: './criar-agendamento.component.html',
  styleUrls: ['./criar-agendamento.component.css']
})
export class CriarAgendamentoComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertNovoAgendamentoModal', { static: false }) alertNovoAgendamentoModal!: ElementRef;
  @ViewChild('ConfirmacaoNovoAgendamentoModal', { static: false }) ConfirmacaoNovoAgendamentoModal!: ElementRef;


  agendamento: Agendamento = new Agendamento();

  agendas: Agenda[] = [];
  agendaSelecionada: number | null = null;

  linksAgenda: Links[] = [];

  statusAgendamento = StatusAgendamento;
  loading: boolean = true;
  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private toast: ToastService,
    private linksService: LinksService,
    private validatorService: ValidatorService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private agendaService: AgendaService,
    private agendamentoService: AgendamentoService,
    private route: ActivatedRoute,
    private siglaService: SiglaService
  ){
    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.agendaService.getAgendas(this.empresaSigla)
        });
      // }
    // });
  }

  async ngOnInit() {
    this.linksAgenda = this.linksService.getLinksAgenda();

    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        // console.log(agendas)
        // Faça algo com as notificações atualizadas
        if(agendas.length > 0){
          this.agendas = agendas;
          this.loading = false;
        }
      },
      error => {
        // console.error('Erro ao listar as Agenda:', error);
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }


  async criarAgendamento(){
    // console.log(this.agendamento)
    // console.log(this.agendaSelecionada)
    // console.log(this.agendas)

    this.agendamento.placaVeiculo.status = this.validatorService.criarValidacao(this.agendamento.placaVeiculo.valor, this.agendamento.placaVeiculo.validacoesCustomizadas);
    this.agendamento.veiculo.status = this.validatorService.criarValidacao(this.agendamento.veiculo.valor, this.agendamento.veiculo.validacoesCustomizadas);
    this.agendamento.peso.status = this.validatorService.criarValidacao(this.agendamento.peso.valor, this.agendamento.peso.validacoesCustomizadas);
    this.agendamento.nomeMotorista.status = this.validatorService.criarValidacao(this.agendamento.nomeMotorista.valor, this.agendamento.nomeMotorista.validacoesCustomizadas);
    this.agendamento.cpfMotorista.status = this.validatorService.criarValidacao(this.agendamento.cpfMotorista.valor, this.agendamento.cpfMotorista.validacoesCustomizadas);
    this.agendamento.telefoneMotorista.status = this.validatorService.criarValidacao(this.agendamento.telefoneMotorista.valor, this.agendamento.telefoneMotorista.validacoesCustomizadas);
    this.agendamento.emailMotorista.status = this.validatorService.criarValidacao(this.agendamento.emailMotorista.valor, this.agendamento.emailMotorista.validacoesCustomizadas);
    this.agendamento.notaFiscal.status = this.validatorService.criarValidacao(this.agendamento.notaFiscal.valor, this.agendamento.notaFiscal.validacoesCustomizadas);
    this.agendamento.numeroPedido.status = this.validatorService.criarValidacao(this.agendamento.numeroPedido.valor, this.agendamento.numeroPedido.validacoesCustomizadas);
    this.agendamento.fornecedor.status = this.validatorService.criarValidacao(this.agendamento.fornecedor.valor, this.agendamento.fornecedor.validacoesCustomizadas);
    this.agendamento.duracaoAtendimento.status = this.validatorService.criarValidacao(this.agendamento.duracaoAtendimento.valor, this.agendamento.duracaoAtendimento.validacoesCustomizadas);
    this.agendamento.hora_inicial.status = this.validatorService.criarValidacao(this.agendamento.hora_inicial.valor, this.agendamento.hora_inicial.validacoesCustomizadas);

    if(
      this.agendamento.placaVeiculo.status.invalid ||
      this.agendamento.veiculo.status.invalid ||
      this.agendamento.peso.status.invalid ||
      this.agendamento.nomeMotorista.status.invalid ||
      this.agendamento.cpfMotorista.status.invalid ||
      this.agendamento.telefoneMotorista.status.invalid ||
      this.agendamento.emailMotorista.status.invalid ||
      this.agendamento.notaFiscal.status.invalid ||
      this.agendamento.numeroPedido.status.invalid ||
      this.agendamento.fornecedor.status.invalid ||
      this.agendamento.duracaoAtendimento.status.invalid ||
      this.agendamento.hora_inicial.status.invalid ||
      (this.agendaSelecionada == null)
    ){
      this.abrirModal(this.alertNovoAgendamentoModal.nativeElement);
    }else{

      const tempo = this.tempo(this.agendamento.hora_inicial.valor, this.agendamento.duracaoAtendimento.valor)
      this.agendamento.hora_final.valor = tempo;

      this.agendamento.id = await this.contadorIndexService.contadorIndex();
      let new_agendamento  = new Agendamento();
      new_agendamento = this.agendamento;

      new_agendamento.agendaId = this.agendaSelecionada;

      new_agendamento.status.valor = this.statusAgendamento.confirmadoAdm;

      new_agendamento.criado = new Date();
      new_agendamento.atualizado = new Date();

      new_agendamento.statusExecucao.valor = new_agendamento.statusExecucaoOptions[2].nome;

      const agendamento = JSON.stringify(new_agendamento);
      this.agendamentoService.criarAgendamento(JSON.parse(agendamento), this.empresaSigla)

    }


  }

  tempo(inicial: any, duraco: any){
    // Hora Inicial no formato "hh:mm"
    const horaInicial = inicial;

    // Duração de Atendimento no formato "hh:mm"
    const duracaoAtendimento = duraco;

    // Dividir as horas e minutos da Hora Inicial
    const [hiHoras, hiMinutos] = horaInicial.split(":").map(Number);

    // Dividir as horas e minutos da Duração de Atendimento
    const [duracaoHoras, duracaoMinutos] = duracaoAtendimento.split(":").map(Number);

    // Adicionar a duração ao horário inicial
    let horasFinais = hiHoras + duracaoHoras;
    let minutosFinais = hiMinutos + duracaoMinutos;

    // Verificar e corrigir se os minutos ultrapassarem 60
    if (minutosFinais >= 60) {
      horasFinais += Math.floor(minutosFinais / 60);
      minutosFinais %= 60;
    }

    // Formatar o Hora Final com 2 dígitos para horas e minutos
    const horaFinal = `${horasFinais.toString().padStart(2, "0")}:${minutosFinais.toString().padStart(2, "0")}`;

    // console.log("Hora Final:", horaFinal);

    return horaFinal;
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
