
<div class="w-100 mb-3" style="text-align: left;">
  <div style="display: flex; align-items: center;">
    <label for="{{id}}" class="form-label label-input-text-padrao" style="display: flex; align-items: center; flex-wrap: nowrap; margin-bottom: 5px;">
      {{label}}
    </label>
    <span mat-raised-button #idTooltip="matTooltip" [matTooltip]="tooltip" (click)="idTooltip.show()" matTooltipPosition="right" class="tooltip-input-icon" style="margin-bottom: 5px;">
      <i class="bi bi-info-circle-fill"></i>
    </span>
    <div *ngIf="isInvalid">
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.required">Obrigatório</p>
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.cnpjInvalido">CNPJ Inválido</p>
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.cpfInvalido">CPF Inválido</p>
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.email">E-mail Inválido</p>
    </div>
  </div>

  <div *ngFor="let option of options" class="w-100" style="text-align: left; display: flex; align-items: center;">
    <mat-radio-button
      class="mat-radial-primary"
      [checked]="isChecked(option)"
      (change)="onRadioChange(option)">
      {{option.label}}
    </mat-radio-button>
  </div>
</div>
