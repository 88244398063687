<div class="w-100 mb-3" style="text-align: left;">
  <div style=" display: flex; align-items: center; ">
    <label for="{{id}}" class="form-label label-input-text-padrao" style="display: flex; align-items: center; flex-wrap: nowrap;margin-bottom: 5px;">
      {{label}}
    </label>
    <span mat-raised-button #idTooltip="matTooltip" [matTooltip]="tooltip" (click)="idTooltip.show()" matTooltipPosition="right" class="tooltip-input-icon" style="margin-bottom: 5px;">
      <i class="bi bi-info-circle-fill"></i>
    </span>
    <div *ngIf="isInvalid">
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.required">Obrigatório</p>
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.cnpjInvalido">CNPJ Inválido</p>
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.cpfInvalido">CPF Inválido</p>
      <p class="error-message" style="margin-bottom: 5px;" *ngIf="AgStatus?.errors.email">E-mail Inválido</p>
    </div>
  </div>
  <div style="position: relative;">
    <input class="form-control input-text-padrao m-0"
           [placeholder]="AgPlaceholder"
           [id]="id"
           [(ngModel)]="innerValue"
           (ngModelChange)="onValueChange($event)"
           [ngClass]="{'border-danger': isInvalid}"
           [attr.type]="AgInputType ? AgInputType : 'text'"
           [attr.maxlength]="AgMaxlength"
           [attr.inputmode]="AgInputMode ? AgInputMode : 'text'"
           bsDatepicker
           [bsConfig]="bsConfigSimples"
    >
    <i class="bi bi-calendar-event text-cor-padrao" (click)="openCalendar()" style="position: absolute;top: 50%;right: 10px;transform: translateY(-50%);cursor: pointer;font-size: 17px;"></i>
  </div>
</div>

