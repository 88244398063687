import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';


@Injectable({
  providedIn: 'root',
})
export class LoadingBarControlService implements HttpInterceptor {
  loader = this.loadingBar.useRef('http');

  constructor(private loadingBar: LoadingBarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loader.start();

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            this.loader.complete();
          }
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            const ngxBarElement = document.querySelector('.ngx-bar');
            ngxBarElement?.classList.add('error-ngx-bar');
            this.loader.complete();
          }
        }
      )
    );
  }
}
