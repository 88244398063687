import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Links } from 'src/class/links';
import { newUsuario, objUsuario } from 'src/class/usuario';
import { SearchUsuarioPipe } from 'src/pipes/search-usuario.pipe';
import { AuthService } from 'src/services/auth.service';
import { LinksService } from 'src/services/links.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ThemeService } from 'src/services/theme.service';
import { ToastService } from 'src/services/toast.service';
import { UsuariosService } from 'src/services/usuarios.services';

@Component({
  selector: 'app-agenda-regras',
  templateUrl: './agenda-regras.component.html',
  styleUrls: ['./agenda-regras.component.css']
})
export class AgendaRegrasComponent implements OnInit {

  @ViewChild('paginationControls', { read: ElementRef }) paginationControlsRef!: ElementRef;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj = new objUsuario;

  usr_arr: any[] = [];
  tps_arr: any[] = [];

  agenda_arr: any [] = [];

  pesquisa="";
  pesquisaLength = 0;

  pag = 1;
  contador = 5;
  total = 0;

  totalizador = 0;
  maxSize = 5;

  visualizando = this.contador;

  lightMode: any;

  modoView = true;

  modoViewNum = 4;

  private subscription: Subscription;

  linksAgenda: Links[] = [];
  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private authService: AuthService,
    private searchUsr: SearchUsuarioPipe,
    private themeService: ThemeService,
    private linksService: LinksService,
    private siglaService: SiglaService
  ) {
    this.subscription = this.themeService.lightMode$.subscribe(lightMode => {
      this.lightMode = lightMode;
    });

    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      }
    });

   }

  ngOnInit() {
    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    const tipos = [
      {
        Tipo:"Tipo",
        Total:"Tipo"
      },{
        Tipo:"Tipo",
        Total:"Tipo"
      },{
        Tipo:"Tipo",
        Total:"Tipo"
      },{
        Tipo:"Tipo",
        Total:"Tipo"
      },

    ]


    this.tps_arr = tipos;

    setTimeout(() => {

      for (let index = 1; index < 2; index++) {
        const element = {
          "Id": index.toString(),
          "Titulo": `Agenda ${index}`,
          "SubTitulo": `Item ${index}`,
          "Descricao": `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus sapien vulputate, ultricies quam placerat, dignissim enim. Sed tincidunt risus id ipsum consequat faucibus.`,
          "Info": `A item ${index}`,
          "Criado": "31/08/2023 17:00",
          "Valido": "08/09/2023 17:00",
          "Foto": "https://picsum.photos/1080/720",
        };


        this.agenda_arr.push(element)

      }
    }, 1000);

    setTimeout(() => {

      for (let index = 1; index < 10; index++) {
        const element = new objUsuario;;

        element.Id = index.toString();
        element.Usuario = `Usuário ${index}`;
        element.Nome = `Nome ${index}`;
        element.Email = `email${index}@gmail.com`;
        element.Tipo = "A";
        element.Criado = "01/01/2023 14:22";
        element.Atualizado = "01/09/2023 15:22";
        element.Foto = "https://picsum.photos/1080/720";

        this.usr_arr.push(element)

      }
    }, 1000);
    // this.getTotalTiposUsuarios();
    // this.getUsuario();

    this.linksAgenda = this.linksService.getLinksAgenda();

  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async getUsuario(){
    const usuarios = await this.usuarios.getUsuario(this.usr_token);
    // console.log(usuarios)
    this.usr_arr = usuarios;
  }

  async getTotalTiposUsuarios(){
    let totalTipos = await this.usuarios.getTotalTiposUsuarios(this.usr_token);
    totalTipos.sort((a: any, b:any) => {
      const order = ['M', 'A', 'S', 'D', 'Total'];
      return order.indexOf(a.Tipo) - order.indexOf(b.Tipo);
    });
    // console.log(totalTipos)
    totalTipos = totalTipos.filter((item: { Tipo: string; }) => item.Tipo !== "Total");
    this.tps_arr = totalTipos;
  }

  reload(){
    try {
      this.usr_arr = [];
      this.tps_arr = [];
      this.agenda_arr = [];
      // this.getTotalTiposUsuarios();
      // this.getUsuario();

      setTimeout(() => {

        for (let index = 1; index < 10; index++) {
          const element = {
            "Id": index.toString(),
            "Titulo": `Agenda ${index}`,
            "SubTitulo": `Item ${index}`,
            "Descricao": `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus sapien vulputate, ultricies quam placerat, dignissim enim. Sed tincidunt risus id ipsum consequat faucibus.`,
            "Info": `A item ${index}`,
            "Criado": "31/08/2023 17:00",
            "Valido": "08/09/2023 17:00",
            "Foto": "https://picsum.photos/1080/720",
          };


          this.agenda_arr.push(element)

        }

        this.showInfo("Regras atualizadas com sucesso!!")

      }, 1000);

      setTimeout(() => {

        for (let index = 1; index < 10; index++) {
          const element = new objUsuario;;

          element.Id = index.toString();
          element.Usuario = `Usuário ${index}`;
          element.Nome = `Nome ${index}`;
          element.Email = `email${index}@gmail.com`;
          element.Tipo = "A";
          element.Criado = "01/01/2023 14:22";
          element.Atualizado = "01/09/2023 15:22";
          element.Foto = "https://picsum.photos/1080/720";

          this.usr_arr.push(element)

        }

        this.showInfo("Agenda atualizada com sucesso!!")

      }, 1000);

    } catch (error) {
      this.showError("Ocorreu um erro na atualização da agenda")

    }

  }

  searchUsrReturn(){
    const ts = this.searchUsr.transform(this.usr_arr, this.pesquisa);
    if(this.pesquisa == ""){
      this.pesquisaLength = 0;
    }else{
      this.pesquisaLength = ts.length;
    }
  }

  resetSearch(){
    this.pesquisa = "";
    this.pesquisaLength = 0;
  }

  pags(pags: any) {
    const target = pags as HTMLTextAreaElement;
    this.contador = parseInt(target.value)
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }
  }

  handlePageChange(event: any) {
    this.pag = event;
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }

    this.ajusteMaxSize();
  }

  ajusteMaxSize(){
    if(this.paginationControlsRef != undefined){
      // Obtém o elemento de paginação
      const paginationElement: HTMLElement = this.paginationControlsRef.nativeElement;

      // Calcula o número máximo de itens visíveis com base no tamanho da tela
      const availableWidth: number = paginationElement.offsetWidth;
      const itemWidth: number = 55; // Largura estimada de cada item da paginação
      const maxSize: number = Math.floor(availableWidth / itemWidth) - 1;

      // Define o maxSize calculado
      this.maxSize = maxSize;
    }
  }

  modo(){

    this.modoView = !this.modoView

    if(this.modoView){
      this.modoViewNum = 12;
    }else{
      this.modoViewNum = 4;
    }

  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
