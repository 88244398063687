import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { GrupoAgenda } from 'src/class/grupo-agenda';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';

@Injectable({
  providedIn: 'root'
})
export class GrupoAgendaService {

  private gruposAgenda: GrupoAgenda[] = [];
  private grupoAgendaSubject = new BehaviorSubject<GrupoAgenda[]>(this.gruposAgenda);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService
  ) {
    this.gruposAgenda = [];
    // this.getGrupoUsuario();
  }



  async criarGrupoAgenda(grupoAgenda: GrupoAgenda, empresaSigla: any) {
    // console.log("criarGrupoAgenda", tipo);
    grupoAgenda.descricao.status = null;
    grupoAgenda.nome.status = null;

    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarGrupoAgenda', conta);

      const response = await this.localhostService.postDadosId(`${conta.id}_grupos_agendas`, grupoAgenda);
      // console.log('GrupoAgenda criado com sucesso:', response);

      // Atualize a lista local de agendas
      this.gruposAgenda.push(grupoAgenda);
      this.grupoAgendaSubject.next(this.gruposAgenda);

      // Notifique o usuário sobre o sucesso
      this.showSuccess(`${grupoAgenda.nome.valor}, grupo de agenda criado com sucesso`);

      // Nova notificação
      // 1 - empresaSigla: any,
      // 2 - titulo: string,
      // 3 - texto: string,
      // 4 - rotaSemParamNome: string,
      // 5 - rotaSemParamListar: string,
      // 6 - rotaComParamNome: string = "",
      // 7 - rotaComParamCaminho: string = "",
      // 8 - rotaComParamParam: string = "",
      // 9 - linkExternoNome: string = "",
      // 10 - linkExternoURL: string = "",
      // 11 - idReferencia: string,
      // 12 - tipo: string,
      // 13 - idResponsavel: string,
      // 14 - nomeResponsavel: string

      const usr = await this.userService.carregarUsuario(empresaSigla)

      this.notificacaoService.criarNotificacao(
        empresaSigla,
        `${grupoAgenda.nome.valor}`,
        `Grupo de agendas criado.`,
        'Listar Grupos Agendas',
        'grupos-agendas',
        'Editar Grupo Agendas',
        `grupos-agendas/grupo`,
        grupoAgenda.id?.toString(),
        '',
        '',
        grupoAgenda.id!.toString(),
        `Grupo Agendas`,
        `${usr.id}`,
        `${usr.displayName.valor}`
      )

      return true;
    } catch (error) {
      // console.error('Erro ao criar tipo:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllGrupoAgenda() {
    return this.grupoAgendaSubject.asObservable().pipe(
      map(gruposAgenda => gruposAgenda)
    );
  }

  async getGrupoAgenda(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getGrupoAgenda', conta);

      this.localhostService.getDados(`${conta.id}_grupos_agendas`).subscribe(
        (tipoFromServer: any[]) => {
          if (tipoFromServer) {
            // Mapeia os dados para a estrutura desejada
            const tipoArray: GrupoAgenda[] = tipoFromServer.map(tipo => plainToClass(GrupoAgenda, tipo));

            // console.log(tipoArray);

            this.gruposAgenda = tipoArray;
            this.grupoAgendaSubject.next(this.gruposAgenda);
            // this.updateLocalStorage();
          } else {
            this.gruposAgenda = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter gruposAgenda:', error);
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao obter gruposAgenda:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });


  }

  private updateLocalStorage() {
    localStorage.setItem("GrupoAgenda", JSON.stringify(this.gruposAgenda));
    DataService.set("GrupoAgenda", this.gruposAgenda);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
