import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ModalLinkComponent } from 'src/app/element-library/modal-link/modal-link.component';

@Injectable({
  providedIn: 'root'
})
export class ModalLinkService {

  modalRef!: BsModalRef;
  private linkSubject = new Subject<boolean>();

  constructor(private modalService: BsModalService) {}


  openModal(titulo: string,link: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const initialState = {
        titulo: titulo,
        link: link,
        linkSubject: this.linkSubject
      };
      this.modalRef = this.modalService.show(
        ModalLinkComponent,
        {
          initialState,
          ignoreBackdropClick: true,
          animated: false,
          class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'
        }
      );
      this.linkSubject.subscribe((confirmation: boolean) => {
        resolve(confirmation);
      });

    });
  }

  // confirm(link: string) {
  //   return this.linkSubject.asObservable();
  // }

  // setConfirmation(value: boolean) {
  //   this.linkSubject.next(value);
  // }

}
