<div>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title p-0 m-0">Link</h4>
    <span id="btn-close-x-foto" class="text-cor-padrao" (click)="modalRef.hide()" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
      <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
    </span>
  </div>
  <!-- Modal body -->
  <div class="modal-body p-0 m-0" >
    <div style="padding: 0px 15px;">
      <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">{{titulo}}</h4>
    </div>

    <div class="ag-pl-15 ag-pr-15">

      <div class="ag-mb-15 card-padrao-list-box-bg-value" style=" display: flex; align-items: center; padding: 15px; font-weight: bold; ">
        <span style=" word-break: break-all; ">{{link}}</span>
      </div>

      <div style="display: flex;">
        <button (click)="abrirLinkEmNovaGuia(link)" class="btn btn-primary ag-mb-15 ag-mr-15" style="padding: .25rem .5rem !important;display: flex;align-items: center;justify-content: center;">
          <span style=" font-size: 10px; ">Abrir link em uma nova página</span>
          <i class="bi bi-box-arrow-up-right ag-pl-5"></i>
        </button>

        <button (click)="copyToClipboard(link)" class="btn btn-success ag-mb-15" style="padding: .25rem .5rem !important;display: flex;align-items: center;justify-content: center;">
          <span style=" font-size: 10px; ">Copiar link</span>
          <i class="bi bi-copy ag-pl-5"></i>
        </button>

      </div>

    </div>
  </div>
  <div class="modal-footer" style="display: flex;justify-content: flex-end;align-items: center;">
    <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Fechar</button>
    <!-- <button type="button" class="btn btn-primary" (click)="confirmAction(true)">Sim</button> -->
  </div>
</div>
