import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastContainer: HTMLElement | null = null;
  private renderer: Renderer2;
  private activeToasts: any[] = [];

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private getToastContainer(): HTMLElement {
    if (!this.toastContainer) {
      this.toastContainer = this.renderer.createElement('div');
      this.toastContainer!.classList.add('toast-container');
      if (document.body) {
        this.renderer.appendChild(document.body, this.toastContainer);
      }
    }
    return this.toastContainer!;
  }

  showToast(message: string, color: string, duration: number = 5000) {
    const toast = this.renderer.createElement('div');
    toast.className = `toast bg-${color}`;

    const toastContent = this.renderer.createElement('div');
    toastContent.className = 'toast-content';
    toastContent.innerHTML = message;
    this.renderer.appendChild(toast, toastContent);

    const progressBar = this.renderer.createElement('div');
    progressBar.className = 'toast-progress-bar';
    progressBar.style.animationDuration = duration + 'ms';
    this.renderer.appendChild(toast, progressBar);

    // Adicione um botão "X" para fechar o toast
    const closeBtn = this.renderer.createElement('button');
    closeBtn.className = 'toast-close-button';
    closeBtn.innerHTML = 'X';
    this.renderer.appendChild(toast, closeBtn);

    let remainingTime = duration;
    let timeout: any;

    this.renderer.listen(progressBar, 'animationiteration', () => {
      this.removeToast(toast);
    });

    this.renderer.listen(closeBtn, 'click', () => {
      this.removeToast(toast);
    });

    this.renderer.listen(toast, 'click', () => {
      this.removeToast(toast);
    });

    this.renderer.listen(toast, 'mouseenter', () => {
      clearTimeout(timeout);
      progressBar.style.animationPlayState = 'paused';
    });

    this.renderer.listen(toast, 'mouseleave', () => {
      progressBar.style.animationPlayState = 'running';
      timeout = setTimeout(() => {
        this.removeToast(toast);
      }, remainingTime);
    });

    const toastContainer = this.getToastContainer()!;
    this.renderer.appendChild(toastContainer, toast);

    timeout = setTimeout(() => {
      this.removeToast(toast);
    }, duration);

    this.activeToasts.push({ toast, timeout });
  }

  private removeToast(toast: HTMLElement) {
    const toastContainer = this.getToastContainer()!;
    this.renderer.removeChild(toastContainer, toast);

    const toastIndex = this.activeToasts.findIndex(item => item.toast === toast);
    if (toastIndex !== -1) {
      clearTimeout(this.activeToasts[toastIndex].timeout);
      this.activeToasts.splice(toastIndex, 1);
    }
  }

  clearToasts() {
    for (const activeToast of this.activeToasts) {
      clearTimeout(activeToast.timeout);
      this.renderer.removeChild(this.toastContainer!, activeToast.toast);
    }
    this.activeToasts = [];
  }

  enviarNotificacao(titulo: string, corpo: string, icone: string, linkRedirecionamento?: string) {
    if ('Notification' in window && Notification.permission === 'granted') {
      const opcoes = {
        body: corpo.replace(/\\n/g, '\n'), // Substitui "\\n" por quebras de linha reais
        icon: icone,
        data: {
          link: linkRedirecionamento || null // Pode ser null se nenhum link for fornecido
        }
      };

      const notificacao = new Notification(titulo, opcoes);

      // Adicione um evento de clique na notificação para redirecionamento
      if (linkRedirecionamento) {
        notificacao.addEventListener('click', () => {
          window.open(linkRedirecionamento, '_blank');
          notificacao.close();
        });
      }
    } else if ('Notification' in window && Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          this.enviarNotificacao(titulo, corpo, icone, linkRedirecionamento);
        }
      });
    }
  }
}
