import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SearchAgendamento'
})
export class SearchAgendamentoPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    // console.log(value.data, args)
    if (!args) {
      return value;
    }

    const searchValue = args.toLowerCase();

    return  value.filter((val: any) => {
      const id = val.id?.toString();
      const placaVeiculo = val.placaVeiculo.valor?.toLowerCase().toString();
      const nomeMotorista = val.nomeMotorista.valor?.toLowerCase().toString();
      const cpfMotorista = val.cpfMotorista.valor?.toLowerCase().toString();
      const telefoneMotorista = val.telefoneMotorista.valor?.toLowerCase().toString();
      const emailMotorista = val.emailMotorista.valor?.toLowerCase().toString();
      const notaFiscal = val.notaFiscal.valor?.toLowerCase().toString();
      const numeroPedido = val.numeroPedido.valor?.toLowerCase().toString();
      const fornecedor = val.fornecedor.valor?.toLowerCase().toString();
      const dataAtendimento = val.dataAtendimento.valor?.toLowerCase().toString();
      const duracaoAtendimento = val.duracaoAtendimento.valor?.toLowerCase().toString();
      const hora_inicial = val.hora_inicial.valor?.toLowerCase().toString();
      const hora_final = val.hora_final.valor?.toLowerCase().toString();
      const status = val.status.valor?.toLowerCase().toString();
      const statusExecucao = val.statusExecucao.valor?.toLowerCase().toString();

      return  id.includes(searchValue)||
              placaVeiculo.includes(searchValue) ||
              nomeMotorista.includes(searchValue) ||
              cpfMotorista.includes(searchValue) ||
              telefoneMotorista.includes(searchValue) ||
              emailMotorista.includes(searchValue) ||
              notaFiscal.includes(searchValue) ||
              numeroPedido.includes(searchValue) ||
              fornecedor.includes(searchValue) ||
              dataAtendimento.includes(searchValue) ||
              duracaoAtendimento.includes(searchValue) ||
              hora_inicial.includes(searchValue) ||
              status.includes(searchValue) ||
              statusExecucao.includes(searchValue) ||
              hora_final.includes(searchValue)
    });

  }

}
