import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchAgenda'
})
export class SearchAgendaPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    // console.log(value.data, args)
    if (!args) {
      return value;
    }

    const searchValue = args.toLowerCase();

    return value.filter((val: any) => {
      const id = val.id?.toString();
      const nome = val.nome.valor?.toLowerCase().toString();
      const subNome = val.subNome.valor?.toLowerCase().toString();
      const link = val.link.valor?.toLowerCase().toString();
      const instrucoes = val.instrucoes.valor?.toLowerCase().toString();
      const duracaoAtendimento = val.duracaoAtendimento.valor?.toLowerCase().toString();
      const antecedenciaMinimaHoras = val.antecedenciaMinimaHoras.valor?.toLowerCase().toString();
      const antecedenciaMaximaDias = val.antecedenciaMaximaDias.valor?.toLowerCase().toString();
      const linkImagem = val.linkImagem.valor?.toLowerCase().toString();
      // const agenda_inicio = val.agenda_inicio.valor?.toLowerCase().toString();
      const agenda_inicio = val.agenda_inicio.valor;
      // const agenda_fim = val.agenda_fim.valor?.toLowerCase().toString();
      const agenda_fim = val.agenda_fim.valor ;

      // return id.includes(searchValue) || usuario.includes(searchValue) || nome.includes(searchValue) || nome.includes(searchValue) || email.includes(searchValue) || tipo.includes(searchValue) || criado.includes(searchValue) || atualizado.includes(searchValue);
      return id.includes(searchValue) ||
             nome.includes(searchValue) ||
             subNome.includes(searchValue) ||
             link.includes(searchValue) ||
             instrucoes.includes(searchValue) ||
             duracaoAtendimento.includes(searchValue) ||
             antecedenciaMinimaHoras.includes(searchValue) ||
             antecedenciaMaximaDias.includes(searchValue) ||
             linkImagem.includes(searchValue) ||
             agenda_inicio.includes(searchValue) ||
             agenda_fim.includes(searchValue)
    });

  }

}
