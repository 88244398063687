import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SearchGrupoUsuario'
})
export class SearchGrupoUsuarioPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    // console.log(value.data, args)
    if (!args) {
      return value;
    }

    const searchValue = args.toLowerCase();

    return  value.filter((val: any) => {
      const id = val.id?.toString();
      const nome = val.nome.valor?.toLowerCase().toString();
      const descricao = val.descricao.valor?.toLowerCase().toString();
      const criado = val.criado != "" ? this.formatarDataHora(val.criado) : val.criado;
      const atualizado = val.atualizado != "" ? this.formatarDataHora(val.atualizado) : val.atualizado;

      return  id.includes(searchValue)||
              nome.includes(searchValue) ||
              descricao.includes(searchValue) ||
              criado.includes(searchValue) ||
              atualizado.includes(searchValue)
    });

  }

  formatarDataHora(dataHora: string): string {
    const data = new Date(dataHora);// 2023-10-24T18:12:17.221Z
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    const hora = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano}, ${hora}:${minutos}`;
  }

}
