<div class="pb-4">

  <div class="container text-center min-width p-0">

    <!-- Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('recursos')" class="text-padrao">Recursos</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{id}}</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('recursos')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>


    <!-- Recurso -->
    <div class="row p-0 m-0">
      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Recurso - <span style="font-style: italic; font-size: 13px;">{{id}}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Recurso Dados -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">
          <div class="row p-0 m-0">
            <!-- recurso.nome -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="recurso.nome.valor"
                  [label]="recurso.nome.title"
                  [tooltip]="recurso.nome.info"
                  [value]="recurso.nome.valor"
                  [isInvalid]="recurso.nome.status?.invalid"
                  [AgStatus]="recurso.nome.status"
                  [id]="'recurso_nome'"
                  AgPlaceholder="{{recurso.nome.placeholder}}"
                  AgInputType="{{recurso.nome.inputType}}"
                  AgMaxlength="{{recurso.nome.maxlength}}"
                  AgInputMode="{{recurso.nome.inputMode}}"
                  (ngModelChange)="recurso.mascara(recurso.nome.mascara, 'nome');"
                  (change)="recurso.validarInput('nome');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- recurso.descricao -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-textarea-field class="w-100"
                  [(ngModel)]="recurso.descricao.valor"
                  [label]="recurso.descricao.title"
                  [tooltip]="recurso.descricao.info"
                  [value]="recurso.descricao.valor"
                  [isInvalid]="recurso.descricao.status?.invalid"
                  [AgStatus]="recurso.descricao.status"
                  [id]="'recurso_descricao'"
                  AgPlaceholder="{{recurso.descricao.placeholder}}"
                  AgInputType="{{recurso.descricao.inputType}}"
                  AgMaxlength="{{recurso.descricao.maxlength}}"
                  AgInputMode="{{recurso.descricao.inputMode}}"
                  (ngModelChange)="recurso.mascara(recurso.descricao.mascara, 'descricao');"
                  (change)="recurso.validarInput('descricao');"
                  >
                </app-ag-textarea-field>
              </div>
            </div>

            <!-- recurso.tipo -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <!-- <app-ag-input-field class="w-100"
                  [(ngModel)]="recurso.tipo.valor"
                  [label]="recurso.tipo.title"
                  [tooltip]="recurso.tipo.info"
                  [value]="recurso.tipo.valor"
                  [isInvalid]="recurso.tipo.status?.invalid"
                  [AgStatus]="recurso.tipo.status"
                  [id]="'recurso_tipo'"
                  AgPlaceholder="{{recurso.tipo.placeholder}}"
                  AgInputType="{{recurso.tipo.inputType}}"
                  AgMaxlength="{{recurso.tipo.maxlength}}"
                  AgInputMode="{{recurso.tipo.inputMode}}"
                  (ngModelChange)="recurso.mascara(recurso.tipo.mascara, 'tipo');"
                  (change)="recurso.validarInput('tipo');"
                  >
                </app-ag-input-field> -->
                <app-ag-ng-select class="w-100"
                  [(ngModel)]="recurso.tipo.valor"
                  [label]="recurso.tipo.title"
                  [id]="'recurso_tipo'"
                  [tooltip]="recurso.tipo.info"
                  AgPlaceholder="{{recurso.tipo.placeholder}}"
                  [AgMultiple]="false"
                  [AgCloseOnSelect]="true"
                  [AgOptions]="recurso.tipoRecursoOptions"
                  [isInvalid]="recurso.tipo.status?.invalid"
                  [AgStatus]="recurso.tipo.status"
                  AgSelectType="checkbox"
                  (ngModelChange)="recurso.validarInput('tipo')">
                </app-ag-ng-select>
              </div>
            </div>

            <!-- recurso.status -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="recurso.status.valor"
                [label]="recurso.status.title"
                [tooltip]="recurso.status.info"
                [value]="recurso.status.valor"
                [id]="'recurso_status'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>
        </div>
      </div>

      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <!-- <div class="w-100 mb-3" style="text-align: left; display: flex; align-items: center; ">
            <mat-checkbox [(ngModel)]="agenda.status.valor" [color]="'radial-primary'">
              {{agenda.status.title}}
            </mat-checkbox>
            <span mat-raised-button #status="matTooltip" [matTooltip]="agenda.status.info" matTooltipPosition="right" (click)="status.show()" class="tooltip-input-icon">
              <i class="bi bi-info-circle-fill"></i>
            </span>
          </div> -->

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="salvarRecurso()">Salvar Recurso</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('recursos')" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<!-- alert Recurso Modal -->
<div class="modal" id="alertRecursoModal" #alertRecursoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="recurso.nome.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ recurso.nome.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ recurso.nome.info }}</p>
          </div>

          <div *ngIf="recurso.descricao.status?.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ recurso.descricao.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ recurso.descricao.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>
