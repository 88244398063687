<div class="pb-4">

  <div class="container text-center min-width p-0">

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('agendas')" class="text-padrao">Agendas</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('agendas/controle-acesso')" class="text-padrao">Controle de Aacesso</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page"> {{id}}</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('agendas/controle-acesso')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>

    <div class="row p-0 m-0">

      <!-- Loading -->
      <div class="row p-0 m-0" *ngIf="usr_obj.length == 0">
        <div class="min-width col-12 mb-3">
          <div  class="p-0 m-0 pulsate" style="min-height: 100px;border-radius: 15px;display: flex;align-items: center;flex-direction: column;flex-wrap: wrap;justify-content: space-between;box-shadow: var(--box-shadow_s);border: var(--border-padrao);">
          </div>
        </div>
      </div>
      <div class="min-width col-lg-12 pb-5" *ngIf="usr_obj.length == 0">
        <div class="p-0 m-0 pulsate box-gen-card" style="min-height: 50vh;width: 100%;overflow: auto;flex-direction: column;">
        </div>
      </div>
      <!-- Loading -->




      <div class="row p-0 m-0" *ngIf="usr_obj.length > 0">

        <div class="min-width col-lg mb-3">
          <div class="box-gen-card" style=" display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; flex-direction: row; ">
            <div>
              <p class="p-0 m-0 text-cor-padrao text-start w-100">
                Dados do Controle de Acesso
              </p>
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                {{usr_edt.Usuario}}
              </h3>
            </div>
            <div>
              <div (mouseenter)="showBtnMdl = usr_edt.Foto != '' ? true : false" (mouseleave)="showBtnMdl = usr_edt.Foto != '' ? false : false" style="min-height: 60px;width: 60px;border-radius: 15px;border: var(--border-padrao); background-color: var(--background);background-attachment: inherit; background-origin: initial; background-clip: inherit; background-position: center center; background-size: cover; background-repeat: no-repeat;" [ngStyle]="{'background-image': usr_edt.Foto != '' ? 'url('+usr_edt.Foto+')' : ''}">
                <!-- <img [src]="usr_edt.Foto" style="width: 100%;border-radius: 40%; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#imgUsr"> -->
                <div *ngIf="showBtnMdl" data-bs-toggle="modal" data-bs-target="#imgUsr" style=" background: var(--text-cor-padrao); border-radius: 15px; height: 58px; display: flex; justify-content: center; align-items: center; opacity: 0.7;cursor: pointer; ">
                  <i class="bi bi-eye" style=" font-size: 25px; color: var(--background); "></i>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="min-width col-lg-12 pb-5" *ngIf="usr_obj.length > 0">
        <div class="p-0 m-0 box-gen-card" style="width: 100%;overflow: auto;flex-direction: column;">

          <div class="form-floating w-100 mb-3" style="text-align: left; ">
            <input type="text" class="form-control input-text-padrao m-0" id="usr_edt_nome" placeholder="Nome" [(ngModel)]="usr_edt.Nome">
            <label for="usr_edt_nome" class="label-input-text-padrao" >Nome</label>
          </div>

          <div class="form-floating w-100 mb-3" style="text-align: left; ">
            <input type="text" class="form-control input-text-padrao m-0" id="usr_edt_email" placeholder="Email" [(ngModel)]="usr_edt.Email">
            <label for="usr_edt_email" class="label-input-text-padrao" >Email</label>
          </div>

          <div class="form-floating w-100 mb-3" style="text-align: left; ">
            <select id="usr_edt_tipo" class="form-control input-text-padrao m-0" [(ngModel)]="usr_edt.Tipo">
              <option >Selecione...</option>
              <option value="S">Simples</option>
              <option value="A">Administrador</option>
              <option value="M">Master</option>
              <option value="D">Desativado</option>
            </select>
            <label for="usr_edt_tipo" class="label-input-text-padrao" >Tipo</label>
          </div>

          <div class="form-floating w-100 mb-2" style="text-align: left; ">
            <input type="file" #fileInput class="form-control input-text-padrao m-0" id="usr_edt_foto" placeholder="Foto"  (change)="onFileSelected($event)" style=" padding: 20px 25px; font-size: 13px; ">
            <!-- <label for="usr_edt_foto" class="label-input-text-padrao" >Foto</label> -->
          </div>

          <hr class="hr-padrao">

          <div class="row" style="display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;">
            <!-- <div class="col">
              <button type="button" class="btn btn-outline-secondary mb-2 w-100" (click)="routerLink('usuarios')">Voltar</button>
            </div> -->

            <div class="col">
              <button type="button" class="btn btn-outline-primary mb-2 w-100 text-cor-branco" (click)="updateUsuario()">Atualizar</button>
            </div>

            <div class="col">
              <button type="button" class="btn btn-outline-secondary mb-2 w-100" (click)="recuperarSenha()" [disabled]="rec_pwd_load">Recuperar Senha</button>
            </div>

            <div class="col">
              <button type="button" class="btn btn-outline-danger mb-2 w-100" data-bs-toggle="modal" data-bs-target="#delUsr" >Deletar</button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Deletar Usuário Modal -->
    <div class="modal" id="delUsr">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

          <!-- Modal Header -->
          <div class="modal-header">
            <h5 class="modal-title">Deletar Usuário</h5>
            <span id="btn-close-x-del" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
              <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
            </span>
          </div>

          <!-- Modal body -->
          <div class="modal-body d-flex flex-column justify-content-center">
            <p>Tem certeza que deseja deletar o usuário: </p>
            <p style="font-size: 30px; font-weight: bold;">{{usr_edt.Usuario}}</p>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Voltar</button>
            <button type="button" class="btn btn-danger text-cor-branco" (click)="delUsuario()">SIM</button>
          </div>

        </div>
      </div>
    </div>

    <!-- Deletar Usuário Modal -->
    <div class="modal" id="imgUsr">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">

          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title p-0 m-0">{{usr_edt.Nome}}</h4>
            <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
              <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
            </span>
          </div>

          <!-- Modal body -->
          <div class="modal-body p-0 m-0 d-flex flex-column justify-content-center">
            <!-- <p>Tem certeza que deseja deletar o usuário: </p> -->
            <img [src]="usr_edt.Foto" style="width: 100%; border-radius: 0px; ">
          </div>

          <!-- Modal footer -->
          <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Voltar</button>
            <button type="button" class="btn btn-danger text-cor-branco" (click)="deleteFileByUrl(usr_edt.Foto)">Deletar</button>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>


