import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { Conta } from 'src/class/conta';
import { newUsuario } from 'src/class/usuario';
import { CepService } from 'src/services/cep.service';
import { ContaService } from 'src/services/component/conta.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-criar-conta',
  templateUrl: './criar-conta.component.html',
  styleUrls: ['./criar-conta.component.css']
})
export class CriarContaComponent implements OnInit {
  @ViewChild('alertContaModal', { static: false }) alertContaModal!: ElementRef;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  conta: Conta = new Conta();
  contas: Conta[] = [];
  empresaSigla: any | undefined;

  constructor(
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private contaService: ContaService,
    private route: ActivatedRoute,
    private siglaService: SiglaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      }
    });
  }

  async ngOnInit() {
    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    // Agora você pode usar o ID como quiser

  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async criarConta(){

    const sigla = await this.verificarSigla(this.conta.sigla.valor)

    if(sigla){
      this.conta.razao.status = this.validatorService.criarValidacao(this.conta.razao.valor, this.conta.razao.validacoesCustomizadas);
      this.conta.cnpj.status = this.validatorService.criarValidacao(this.conta.cnpj.valor, this.conta.cnpj.validacoesCustomizadas);
      this.conta.fantasia.status = this.validatorService.criarValidacao(this.conta.fantasia.valor, this.conta.fantasia.validacoesCustomizadas);
      this.conta.sigla.status = this.validatorService.criarValidacao(this.conta.sigla.valor, this.conta.sigla.validacoesCustomizadas);
      this.conta.email.status = this.validatorService.criarValidacao(this.conta.email.valor, this.conta.email.validacoesCustomizadas);
      this.conta.telefone.status = this.validatorService.criarValidacao(this.conta.telefone.valor, this.conta.telefone.validacoesCustomizadas);
      this.conta.whatsapp.status = this.validatorService.criarValidacao(this.conta.whatsapp.valor, this.conta.whatsapp.validacoesCustomizadas);
      this.conta.site.status = this.validatorService.criarValidacao(this.conta.site.valor, this.conta.site.validacoesCustomizadas);
      this.conta.subTitulo.status = this.validatorService.criarValidacao(this.conta.subTitulo.valor, this.conta.subTitulo.validacoesCustomizadas);

      this.conta.cep.status = this.validatorService.criarValidacao(this.conta.cep.valor, this.conta.cep.validacoesCustomizadas);
      this.conta.logradouro.status = this.validatorService.criarValidacao(this.conta.logradouro.valor, this.conta.logradouro.validacoesCustomizadas);
      this.conta.numero.status = this.validatorService.criarValidacao(this.conta.numero.valor, this.conta.numero.validacoesCustomizadas);
      this.conta.complemento.status = this.validatorService.criarValidacao(this.conta.complemento.valor, this.conta.complemento.validacoesCustomizadas);
      this.conta.bairro.status = this.validatorService.criarValidacao(this.conta.bairro.valor, this.conta.bairro.validacoesCustomizadas);
      this.conta.localidade.status = this.validatorService.criarValidacao(this.conta.localidade.valor, this.conta.localidade.validacoesCustomizadas);
      this.conta.uf.status = this.validatorService.criarValidacao(this.conta.uf.valor, this.conta.uf.validacoesCustomizadas);

      if(
        this.conta.cnpj.status.invalid ||
        this.conta.razao.status.invalid ||
        this.conta.fantasia.status.invalid ||
        this.conta.sigla.status.invalid ||
        this.conta.email.status.invalid ||
        this.conta.telefone.status.invalid ||
        this.conta.whatsapp.status.invalid ||
        this.conta.site.status.invalid ||
        this.conta.subTitulo.status.invalid ||
        this.conta.logradouro.status.invalid ||
        this.conta.numero.status.invalid ||
        this.conta.complemento.status.invalid ||
        this.conta.bairro.status.invalid ||
        this.conta.localidade.status.invalid ||
        this.conta.uf.status.invalid
      ){
        this.abrirModal(this.alertContaModal.nativeElement);
      }else{

        this.conta.id = await this.contadorIndexService.contadorIndex();
        let new_conta  = new Conta();
        new_conta = this.conta;

        new_conta.criado = new Date();
        new_conta.atualizado = new Date();

        // console.log("new_conta", new_conta)

        const tipo = JSON.stringify(new_conta);
        const res = await this.contaService.criarConta(JSON.parse(tipo))
        // console.log(res)

        // this.showSuccess("Tipo criado com sucesso");
        this.conta = new Conta();
        this.router.navigate([`${this.empresaSigla}/contas`]);
      }
    }


  }

  buscarCep() {
    if(this.conta.cep.valor == undefined || this.conta.cep.valor.trim() == ""){
      this.showError("Por favor, informe um cep para realizar a busca")
    }else{
      this.cepService.buscarEnderecoPorCEP(this.conta.cep.valor)
      .pipe(
        catchError(async error => {
          try {
            // console.log('Ocorreu um erro ao buscar o endereço:', error);
            const trad = await this.translateWord.translateWord(error.message, 'pt');
            this.showError(trad)
            return throwError(trad)
          } catch (error2) {
            // console.log('Ocorreu um erro ao buscar o endereço:', error2);
            this.showError(error.message)
            return throwError(error.message)
          }
        })
      )
      .subscribe((resultado: any) => {
        if(resultado.erro){
          this.showError("O CEP não foi encontrado. Por favor, verifique o número digitado e tente novamente.")
        }else{
          this.conta.cep.valor = resultado.cep;
          this.conta.logradouro.valor = resultado.logradouro;
          this.conta.complemento.valor = resultado.complemento;
          this.conta.bairro.valor = resultado.bairro;
          this.conta.localidade.valor = resultado.localidade;
          this.conta.uf.valor = resultado.uf;

          this.conta.cep.status = this.validatorService.criarValidacao(this.conta.cep.valor, this.conta.cep.validacoesCustomizadas);
          this.conta.logradouro.status = this.validatorService.criarValidacao(this.conta.logradouro.valor, this.conta.logradouro.validacoesCustomizadas);
          this.conta.numero.status = this.validatorService.criarValidacao(this.conta.numero.valor, this.conta.numero.validacoesCustomizadas);
          this.conta.complemento.status = this.validatorService.criarValidacao(this.conta.complemento.valor, this.conta.complemento.validacoesCustomizadas);
          this.conta.bairro.status = this.validatorService.criarValidacao(this.conta.bairro.valor, this.conta.bairro.validacoesCustomizadas);
          this.conta.localidade.status = this.validatorService.criarValidacao(this.conta.localidade.valor, this.conta.localidade.validacoesCustomizadas);
          this.conta.uf.status = this.validatorService.criarValidacao(this.conta.uf.valor, this.conta.uf.validacoesCustomizadas);
        }

      });
    }
  }


  async verificarSigla(empresaSigla: any){
    if(empresaSigla == ''){
      // console.log(empresaSigla, false)
      this.showError("Por favor, informe um valor para a sigla da empresa.")
      this.conta.sigla.status = this.validatorService.criarValidacao(this.conta.sigla.valor, this.conta.sigla.validacoesCustomizadas);
      return false
    }
    else if(empresaSigla == this.empresaSigla){
      // console.log(empresaSigla, false)
      this.showError("A sigla que você escolheu já está sendo utilizada. Por favor, escolha outra sigla.");
      return false;
    }else{
      return await this.contaService.p_getSiglaConta('contas', empresaSigla)
      .then((conta) => {
        // console.log('Conta:', conta, false);
        this.showError("A sigla que você escolheu já está sendo utilizada. Por favor, escolha outra sigla.");
        return false;
      },
      (error: any) => {
        // this.showSuccess("A sigla OK");
        // console.log('Erro ao criar conta:', error, true);
        // Trate o erro conforme necessário
        return true;
      }).catch((error) => {
        // Trate o erro
        // console.log('Erro ao obter dados da conta:', error, true);
        // this.showSuccess("A sigla OK");

        return true;
      });
    }
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
