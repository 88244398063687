<div>
  <h2>Configuração de Estilos</h2>

  <form (submit)="aplicarEstilo()">
    <label for="modoEstilo">Selecione o modo de estilo:</label>
    <select id="modoEstilo" [(ngModel)]="modoEstilo" name="modoEstilo">
      <option value="dark">Modo Dark</option>
      <option value="light">Modo Light</option>
    </select>

    <button type="submit">Aplicar</button>
  </form>
</div>
